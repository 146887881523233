import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChapterIcons } from "../../../../../data/enums/ChapterIcons";
import { COURSE_OVERVIEW } from "../../../../../data/RouteUrls";
import StandardButton from "../../../../shared/formComponents/StandardButton/StandardButton";
import { LineProgressBar } from "../../../../shared/LineProgressBar/LineProgressBar";
import "./course-progress-card.scss";

export const CourseProgressCard = (props) => {
  const section = props.section;
  const course = props.course;

  // const renderChapters = (chapters) => {
  //   let chaptersCard = [];

  //   for (let chapter of chapters) {
  //     let progressIcon;
  //     if (chapter.isCompleted) {
  //       progressIcon = (
  //         <div className="progress-icon completed">
  //           <span class="material-icons">done</span>
  //         </div>
  //       );
  //     } else if (true || chapter.current) {
  //       progressIcon = (
  //         <div className="progress-icon current">
  //           <span class="material-icons">fiber_manual_record</span>
  //         </div>
  //       );
  //     } else {
  //       progressIcon = <div className="progress-icon"></div>;
  //     }

  //     let chapterDesign = (
  //       <div className="chapter d-flex align-items-center">
  //         <div>{progressIcon}</div>
  //         <div className="ms-3">{chapter.name}</div>
  //       </div>
  //     );
  //     chaptersCard.push(chapterDesign);
  //   }
  //   return chaptersCard;
  // };

  const renderChapters = () => {
    let chapters = [];
    let index = 0;
    for (let chapter of section.chapters) {
      chapter.index = index;
      let chapter_type = chapter.chapter_type;
      let classes = "chapter d-flex align-items-center justify-content-between";
      let progressIcon;
      if (chapter.is_current) {
        progressIcon = (
          <div className="progress-icon current">
            <span class="material-icons">adjust</span>
          </div>
        );
        classes += " active";
        chapter_type += "_ACTIVE";
      } else if (chapter.is_completed) {
        progressIcon = (
          <div className="progress-icon completed">
            <span class="material-icons">check_circle</span>
          </div>
        );
      } else {
        progressIcon = (
          <div className="progress-icon blank">
            <span class="material-icons">radio_button_unchecked</span>
          </div>
        );
      }
      let chapterDesign = (
        <>
          <div
            className={classes}
            onClick={() => props.goToChapter(chapter.index)}
          >
            <div className="d-flex align-items-center">
              <div>{progressIcon}</div>
              <div className="ms-3">
                <img src={ChapterIcons[chapter_type]} alt="chapter type" />
              </div>
              <div className="ms-3 mt-1 text-primary-new">{chapter.name}</div>
            </div>
          </div>
        </>
      );
      chapters.push(chapterDesign);
      index += 1;
    }
    return chapters;
  };

  const calculateProgress = () => {};
  return (
    <>
      <Card className="course-progress-card">
        <div className="card-body">
          <div className="title cs-sub-heading">{section.name}</div>
          <div className="progress-bar-container">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <LineProgressBar
                percentage={course.course_status.replace("%", "")}
              />
              <div className="d-flex justify-content-center">
                <div className="percentage">{course.course_status}</div>
                <div className="ms-2">Complete</div>
              </div>
            </div>
          </div>
          <div className="chapters">
            <div>{renderChapters()}</div>
          </div>
          <div>
            <Link to={COURSE_OVERVIEW.replace(":courseId", course.id)}>
              <StandardButton
                color="btn-success"
                bold={true}
                block={true}
                text="Back To Course Overview"
              ></StandardButton>
            </Link>
          </div>
        </div>
      </Card>
    </>
  );
};
