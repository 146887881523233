import axios from "axios";
import XraySlice from "./xraySlice";
import { errorNoti, successNoti } from "../../../../base/BasicNotifications";
import {
  getErrorMessage,
  pickFromObject,
  sortRecomsByDiscipline,
} from "../../../../utils/generalUtils";
import { XrayURLs } from "./xrayUrls";
import { SurveyScoreEditingEnums } from "../../../../data/enums/SurveyEums";

const instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
const scoreInstance = axios.create({
  baseURL: process.env.REACT_APP_SCORE_URL,
});

const nodeInstance = axios.create({
  baseURL: process.env.REACT_APP_NEST_BASE_URL,
});
export const getInitiativesData =
  (practiceId, surveyId, level) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let url = XrayURLs.GET_INITIATIVE.replace(
        "{practiceId}",
        practiceId
      )
        .replace("{surveyId}", surveyId)
        .replace("{level}", level);

      nodeInstance
        .get(url, {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        })
        .then((responses) => {
          resolve(responses.data.data);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
          // errorNoti(err?.response?.data?.message);
        });
    });
  };
export const getRecommendationHistory =
  (practiceId, surveyId, level) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let url = XrayURLs.GET_RECOMMENDATION_HISTORY.replace(
        "{practiceId}",
        practiceId
      )
        .replace("{surveyId}", surveyId)
      nodeInstance
        .get(url, {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        })
        .then((responses) => {
          resolve(responses.data.data);
        })
        .catch((err) => {
          resolve(false);
          console.log(err)
          errorNoti(err?.response?.data?.message);
        });
    });
  };

export const getRecommendationLevelBase =
  (practiceId, surveyId, level) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let url = XrayURLs.GET_RECOMMENDATION_LEVEL_BASE.replace(
        "{practiceId}",
        practiceId
      )
        .replace("{surveyId}", surveyId)
        .replace("{level}", level);

      nodeInstance
        .get(url, {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        })
        .then((responses) => {
          resolve(responses.data.data);
        })
        .catch((err) => {
          resolve(false);
          console.log(err)
          // errorNoti(err?.response?.data?.message);
        });
    });
  };

export const getRecomendationContentsData =
  (recommendationId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let userId =
        getState().authDetails.userProfile.ceoUserId ||
        getState().authDetails.userProfile.id;
      let url = XrayURLs.GET_RECOMMENDATIONS_CONTENT.replace(
        "{recommendationId}",
        recommendationId
      );

      nodeInstance
        .get(url, {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        })
        .then((responses) => {
          resolve(responses.data.data);
        })
        .catch((err) => {
          resolve(false);
        });
    });
  };

export const getUserQuestionAnswers =
  (practiceId, surveyId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let url = XrayURLs.GET_QUESTION_ANSWERS.replace(
        "{practiceId}",
        practiceId
      ).replace("{surveyId}", surveyId);

      nodeInstance
        .get(url, {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        })
        .then((responses) => {
          resolve(responses.data.data);
        })
        .catch((err) => {
          console.log(err)
          resolve(false);
          // errorNoti(err?.response?.data?.message);
        });
    });
  };
// data keys to send to edit recommendation
const recomKeys = [
  "userRankId",
  "customRecomm",
  "isCustomVisible",
  "customRank",
  "overallRank",
];

export const updateXrayState = (xRayState) => (dispatch) => {
  dispatch(XraySlice.actions.setXRayState(xRayState));
};

// export const removeData = () => (dispatch) => {
//   dispatch(XraySlice.actions.recommendationsData());
// };

export const updateCoachRecommendations =
  (recommendations, discipline = null) =>
    (dispatch) => {
      dispatch(
        XraySlice.actions.setCoachRecommendations({ recommendations, discipline })
      );
    };

export const getLatestScore = (userId) => (dispatch, getState) => {
  let coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");
  if (!userId) return Promise.reject("User Id missing");

  let url = XrayURLs.LATEST_SCORE.replace("{coachId}", coachId).replace(
    "{userId}",
    userId
  );

  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const getSubDisciplinePractices =
  (userId, subDisciplineId) => (dispatch, getState) => {
    let coachId = getState().authDetails?.userProfile?.coachId;
    if (!coachId) return Promise.reject("Coach Id missing");
    if (!userId) return Promise.reject("User Id missing");
    if (!subDisciplineId) return Promise.reject("Sub Discipline Id missing");

    let url = XrayURLs.SUB_DISCIPLINE_PRACTICES.replace(
      "{userId}",
      userId
    ).replace("{subDisciplineId}", subDisciplineId);

    return instance
      .get(url, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        let errMsg = getErrorMessage(err);
        errorNoti(errMsg);
        throw new Error(errMsg);
      });
  };

export const getSubSubDisciplinePractices =
  (userId, subSubDisciplineId) => (dispatch, getState) => {
    let coachId = getState().authDetails?.userProfile?.coachId;
    if (!coachId) return Promise.reject("Coach Id missing");
    if (!userId) return Promise.reject("User Id missing");
    if (!subSubDisciplineId)
      return Promise.reject("Sub Sub Discipline Id missing");

    let url = XrayURLs.SUB_SUB_DISCIPLINE_PRACTICES.replace(
      "{userId}",
      userId
    ).replace("{subSubDisciplineId}", subSubDisciplineId);

    return instance
      .get(url, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        let errMsg = getErrorMessage(err);
        errorNoti(errMsg);
        throw new Error(errMsg);
      });
  };

export const getDivergenceScore =
  (userId, type, disciplineId = null) =>
    (dispatch, getState) => {
      let coachId = getState().authDetails?.userProfile?.coachId;
      if (!coachId) return Promise.reject("Coach Id missing");
      if (!userId) return Promise.reject("User Id missing");
      let url;
      if (disciplineId) {
        url = XrayURLs.DIVERGENCE_DISCIPLINE.replace(
          "{disciplineId}",
          disciplineId
        );
      } else {
        url = XrayURLs.DIVERGENCE;
      }
      url = url
        .replace("{userId}", userId)
        .replace("{type}", type)
        .replace("{coachId}", coachId);

      return instance
        .get(url, {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        })
        .then((response) => response.data.data)
        .catch((err) => {
          let errMsg = getErrorMessage(err);
          errorNoti(errMsg);
          throw new Error(errMsg);
        });
    };

export const getRecommendations =
  (userId, disciplineId = null) =>
    (dispatch, getState) => {
      let coachId = getState().authDetails?.userProfile?.coachId;
      if (!coachId) return Promise.reject("Coach Id missing");
      if (!userId) return Promise.reject("User Id missing");

      let url;
      if (disciplineId) {
        url = XrayURLs.RECOMMENDATIONS_DISCIPLINE.replace(
          "{disciplineId}",
          disciplineId
        );
      } else {
        url = XrayURLs.RECOMMENDATIONS;
      }
      url = url.replace("{userId}", userId).replace("{coachId}", coachId);

      return instance
        .get(url, {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        })
        .then((responses) => responses.data.data)
        .catch((err) => {
          let errMsg = getErrorMessage(err);
          errorNoti(errMsg);
          throw new Error(errMsg);
        });
    };

export const getCoachRecommendations = (userId) => (dispatch, getState) => {
  let coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");
  if (!userId) return Promise.reject("User Id missing");

  let url = XrayURLs.COACH_RECOMMENDATIONS;
  url = url.replace("{userId}", userId).replace("{coachId}", coachId);

  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((responses) => {
      const defaultDisciplineMap = responses.data.data.disciplineMap;
      const coachDisciplineMap = responses.data.data.coachDisciplineMap;
      const disciplineMap = coachDisciplineMap ?? defaultDisciplineMap;
      let recomId = 1;
      let coachRecomms = {};
      const defaultRecomms = {};
      for (let discipline of Object.keys(defaultDisciplineMap)) {
        defaultRecomms[discipline] = defaultDisciplineMap[
          discipline
        ].userRankList.map((recom) => ({
          ...recom,
          isCustomVisible: recom.isCustomVisible ?? true,
          id: recomId++,
        }));
        if (coachDisciplineMap) {
          coachRecomms[discipline] = coachDisciplineMap[
            discipline
          ].userRankList.map((recom) => ({
            ...recom,
            isCustomVisible: recom.isCustomVisible ?? true,
            id: recomId++,
          }));
        }
      }
      if (Object.keys(coachRecomms).length === 0) coachRecomms = null;
      const disciplinesNameAndReadStatus = Object.keys(disciplineMap).map(
        (k) => ({
          name: k,
          read: disciplineMap[k].read,
          disciplineId: disciplineMap[k].disciplineId,
        })
      );
      sortRecomsByDiscipline(disciplinesNameAndReadStatus, "name");
      dispatch(
        updateXrayState({
          ...getState().xrayReport.xRayState,
          snapshotId: responses.data.data.snapshotId,
        })
      );
      dispatch(
        XraySlice.actions.setCoachRecommendations({
          discipline: null,
          recommendations: coachRecomms ?? defaultRecomms,
          defaultRecommendations: defaultRecomms,
          isInitial: true,
          disciplines: disciplinesNameAndReadStatus,
        })
      );
    })
    .catch((err) => {
      console.error(err);
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
    });
};

export const getXrayStatus = (userId) => (dispatch, getState) => {
  let coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");
  if (!userId) return Promise.reject("User Id missing");

  let url = XrayURLs.GET_XRAY_STATUS;
  url = url.replace("{userId}", userId).replace("{coachId}", coachId);
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      const data = response.data.data;
      if (response.data.data) {
        const xrayState = {
          ...getState().xrayReport.xRayState,
          surveyId: data.surveyId,
          userId: userId,
          respondantsEdited: data.isEditRespondant ?? false,
          recommendationsEdited: data.isEditRecomm ?? false,
          isDummy: data.isDummy ?? false,
          xrayPublished: data.isSentResult ?? false,
          contact: {
            firstName: data.userName,
            companyName: data.companyName,
          },
        };
        dispatch(updateXrayState(xrayState));
      }
      return true;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const editCoachRecommendations = () => (dispatch, getState) => {
  const coachRecommendations = getState().xrayReport?.coachRecommendations;
  const recommendationsData = Object.keys(coachRecommendations).flatMap(
    (dName) =>
      coachRecommendations[dName].flatMap((rm) => pickFromObject(rm, recomKeys))
  );
  let url = XrayURLs.UPDATE_COACH_RECOMMENDATIONS;
  // const dataToPrint = Object.keys(coachRecommendations).flatMap((dName) =>
  //   coachRecommendations[dName].flatMap((rm) =>
  //     pickFromObject(rm, [...recomKeys, 'praName'])
  //   )
  // );
  // dataToPrint.sort((r1, r2) => {
  //   if (!r1.overallRank) return 1;
  //   if (!r2.overallRank) return -1;
  //   return r1.overallRank < r2.overallRank ? -1 : 1;
  // });
  // const or = [];
  // dataToPrint.map((r) => or.push({ or: r.overallRank, pn: r.praName }));
  // return true;
  return instance
    .put(url, recommendationsData, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((responses) => {
      successNoti("Recommendations Updated");
      return true;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const updateReadStatusDiscipline =
  (snapshotId, disciplineId) => (dispatch, getState) => {
    let coachId = getState().authDetails?.userProfile?.coachId;
    if (!coachId) return Promise.reject("Coach Id missing");
    if (!snapshotId) return Promise.reject("Snapshot Id missing");
    if (!disciplineId) return Promise.reject("Discipline Id missing");

    let url = XrayURLs.UPDATE_READ_STATUS_DISCIPLINE.replace(
      "{coachId}",
      coachId
    )
      .replace("{snapshotId}", snapshotId)
      .replace("{disciplineId}", disciplineId);
    return instance
      .post(
        url,
        {},
        {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        }
      )
      .then((response) => {
        dispatch(XraySlice.actions.setDisciplineRead({ disciplineId }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const updateReadStatus = (userId, status) => (dispatch, getState) => {
  let coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");
  if (!userId) return Promise.reject("User Id missing");

  let url =
    XrayURLs.UPDATE_READ_STATUS.replace("{coachId}", coachId).replace(
      "{userId}",
      userId
    ) +
    "?" +
    "status=" +
    status;

  return instance
    .put(
      url,
      {},
      {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      }
    )
    .then((response) => {
      let key =
        status === SurveyScoreEditingEnums.RECOMM_EDITED
          ? "recommendationsEdited"
          : "respondantsEdited";
      if (response.data.data) {
        const xrayState = {
          ...getState().xrayReport.xRayState,
          [key]: true,
        };
        dispatch(updateXrayState(xrayState));
      }
      return true;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const editCoachNotes = (recomm) => (dispatch, getState) => {
  let data = pickFromObject(recomm, recomKeys);
  let url = XrayURLs.UPDATE_COACH_NOTES;
  return instance
    .put(url, data, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((responses) => {
      successNoti("Coach Notes Updated");
      return true;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const publishXrayResults = (userId) => (dispatch, getState) => {
  let coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");
  if (!userId) return Promise.reject("User Id missing");

  let url = XrayURLs.PUBLISH_XRAY_RESULTS;
  url = url.replace("{userId}", userId).replace("{coachId}", coachId);
  return instance
    .post(
      url,
      {},
      {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      }
    )
    .then((response) => {
      successNoti("X-Ray results sent!");
      const xrayState = {
        ...getState().xrayReport.xRayState,
        xrayPublished: true,
      };

      dispatch(XraySlice.actions.setXRayState(xrayState));
      return true;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const updateCurrentDiscipline = (discipline) => (dispatch) => {
  dispatch(XraySlice.actions.setCurrentDiscipline(discipline));
};

export const setUsersInStore = (users) => (dispatch) => {
  dispatch(
    XraySlice.actions.setUsers({
      users,
    })
  );
};

export const getSurveyMembers = (userId) => (dispatch, getState) => {
  let surveyId = getState().xrayReport.xRayState.surveyId;
  return instance
    .get(XrayURLs.GET_SURVEY_MEMBERS + `/${userId}/survey/${surveyId}`, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const getSurveyProgress = (userId) => (dispatch, getState) => {
  let url = XrayURLs.GET_SURVEY_PROGRESS.replace("{userId}", userId);
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      console.error(errMsg);
    });
};
export const getSurveyProgressFromNode = (surveyId) => (dispatch, getState) => {
  let url = XrayURLs.GET_SURVEY_PROGRESS_FROM_NODE.replace(
    "{surveyId}",
    surveyId
  );
  return nodeInstance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      console.error(errMsg);
    });
};

export const getDisciplines = () => () => {
  return instance
    .get(XrayURLs.GET_DISCIPLINES)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const updateUserDisciplineMapping = (data) => (dispatch, getState) => {
  return instance
    .put(XrayURLs.UDPATE_USER_DISCIPLINE, data, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return true;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const getPdfUrl = (surveyId) => (dispatch, getState) => {
  let url = XrayURLs.GET_PDF_URL.replace("{surveyId}", surveyId);
  return scoreInstance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};
