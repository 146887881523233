import moment from 'moment';
import React, { useState } from 'react'
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StandardButton from "../../../../shared/formComponents/StandardButton/StandardButton";
import LoadingAnimation from '../../../../../base/LoadingAnimation';
import {NoInitiativesForLevelScreen} from "./NoInitiativesForLevelScreen";
import { VIEW_RECOMMENDATION_HISTORY } from '../../../../../data/RouteUrls';
import "./recommendations-card.scss";

export const InitiativeContent = ({ surveyId ,initiatives, initiativeStatus, userQuestionsAnsData, recommendation }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showUserQuestionsAns, setShowUserQuestionsAns] = useState(false)
  const [initExpandedIndex, setInitExpandedIndex] = useState();

  const initStatusNotStartedOrInProgressOrRejected = (status) => {
    return status === "NOT_STARTED" || status === "IN_PROGRESS" || status === "REJECTED";
  }
  const renderRecommendationHistoryUrl = () => {
    const url = VIEW_RECOMMENDATION_HISTORY.replace(
      ":practiceId",
      recommendation?.practiceId
    ).replace(":surveyId", surveyId);
    return url
  }

  const renderExpandCollapseButton = (initiative, index) => {
    return (
      <div className="d-flex align-items-center ">
        <div className="ms-2">
          <StandardButton
            color="btn-outline-dark"
            bold={true}
            className="d-flex justify-content-center align-items-center expand-btn ms-auto"
            text={
              index === initExpandedIndex
                ? "Collapse"
                : "Expand"
            }
            icon={
              index === initExpandedIndex ? (
                <span className="material-icons icon-in-btn">
                  expand_less
                </span>
              ) : (
                <span className="material-icons icon-in-btn">
                  expand_more
                </span>
              )
            }
            reversed={true}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateInitExpandedIndex(index);
            }}
          />
        </div>
      </div>
    )
  }


  const statusRejectedOrApproveOrSubmitForApproval = (status) => {
    return initiativeStatus === "REJECTED" || initiativeStatus === "APPROVE" || initiativeStatus === "SUBMIT_FOR_APPROVAL"
  }
  const renderStatusCard = () => {
    return (
      <div className="recom-bg rounded p-2 my-2">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/rejected_initiative_image.svg"
            alt="Remark"
            width="40px"
            height="40px"
          />
          <div className="d-flex  align-items-center font-weight-bold ms-3">Status: {<div className="ms-2">{renderRecommendationStatus()}</div>}</div>
        </div>
      </div>
    )

  }
  const updateInitExpandedIndex = (index) => {
    if (index === initExpandedIndex) {
      setInitExpandedIndex(null);
    } else {
      setInitExpandedIndex(index);
    }
  };
  const renderRecommendationStatus = () => {
    let mainClass = "", symbol = "", text = "";
    if (initiativeStatus === "APPROVE") {
      mainClass = "status_approve";
      symbol = "done";
      text = "Approved";
    } else if (initiativeStatus === "REJECTED") {
      mainClass = "status_rejected";
      symbol = "close";
      text = "Rejected";
    } else if (initiativeStatus === "SUBMIT_FOR_APPROVAL") {
      mainClass = "status_awaiting_approval";
      symbol = "schedule";
      text = "Awaiting Approval";
    }

    let allClasses = `${mainClass} d-flex align-items-center py-1 px-2`
    return <div className={allClasses}>
      <span class="material-symbols-outlined">{symbol}</span>
      <span>{text}</span>
    </div>
  }

  const renderDateForInitiative = (initiative) => {
    if (initStatusNotStartedOrInProgressOrRejected(initiativeStatus)) {
      return ["Target Date of Completion", moment(initiative?.due_date).format("Do MMM YYYY")]
    } else {
      return ["Start Date", moment(initiative?.createdAt).format("Do MMM YYYY")]
    }
  }

  const renderInitiativeDataCard = (imgPath, title, content) => {
    return (
      <div className="card p-2 my-2">
        <div className="d-flex align-items-center">
          <img
            src={`/assets/images/initiative-icons/${imgPath}.svg`}
            height={{ height: "14px", width: "14px" }}
          />
          <span className="ms-3">
            {title} : <b>{content}</b>
          </span>
        </div>
      </div>
    )
  }

  const renderInitiativeProgressAndDates = (initiative, index) => {
    let imgPath = "/assets/images/initiative-icons/Progress.svg"
    let imgElement = <img
      src={imgPath}
      height={{ height: "14px", width: "14px" }}
    />

    if (initiativeStatus === "APPROVE" || initiativeStatus === "SUBMIT_FOR_APPROVAL") {
      return <div className="card p-2 my-2">
        <div className="d-flex align-items-center">
          {imgElement}
          <div className="d-flex flex-1">
            <span className="ms-3">
              Est. Date of Completion :{" "}
              <b>
                {moment(initiative?.due_date).format(
                  "Do MMM YYYY"
                )}
              </b>
            </span>
          </div>

          <div className="d-flex flex-1 ms-3">
            <span className="ms-3">
              Actual Date of Completion :{" "}
              <b>
                {moment(initiative?.completionDate).format(
                  "Do MMM YYYY"
                )}
              </b>
            </span>
          </div>
        </div>
      </div>
    } else {
      return <div className="card p-2 my-2">
        <div className="d-flex align-items-center">
          {imgElement}
          <div className="d-flex flex-grow-1 ms-3">
            <span>Progress :</span>
            <div className="d-flex align-items-center w-75 ms-2">
              <input
                type="range"
                className="w-100"
                name="OKRProgress"
                min="0"
                max="100"
                value={initiative?.progress}
              />
              <div className="mx-2 font-weight-bold">
                {initiative?.progress
                  ? initiative?.progress + "%"
                  : "0%"}
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }
  const renderUserQuestionAnswer = () => {
    return (
      <div className="my-3 recom-bg p-4">
        <div className="bg-white rounded p-2 mt-2">
          <div className="d-flex align-items-center">
            <img
              src="/assets/images/logo-small.png"
              alt=""
              width="28px"
              height="28px"
            />
            <div className="font-weight-bold ms-3">
              Recommendation
            </div>
          </div>
          <div>{userQuestionsAnsData?.recommendationText}</div>
        </div>
        <div className="bg-white rounded p-2 mt-2">
          <div className="d-flex align-items-center">
            <img
              src="/assets/images/initiative-icons/Q&A.svg"
              alt="Q&A"
              width="28px"
              height="28px"
            />
            <div className="font-weight-bold ms-3">
              {userQuestionsAnsData?.questionText}
            </div>
          </div>
          <div>{userQuestionsAnsData?.answerText}</div>
        </div>
        <div className="bg-white rounded p-2 mt-2">
          <div className="d-flex align-items-center">
            <img
              src="/assets/images/initiative-icons/Remark.svg"
              alt="Remark"
              width="28px"
              height="28px"
            />
            <div className="font-weight-bold ms-3">Remarks</div>
          </div>
          <div>{userQuestionsAnsData?.remark}</div>
        </div>
      </div>
    )
  }
  if (showLoader) {
    return <LoadingAnimation />
  }
  return (
    <div>
        {
        statusRejectedOrApproveOrSubmitForApproval(initiativeStatus) ||
          userQuestionsAnsData?.ceoRemark ? (
          <>
            {!initiatives.length ? null : renderStatusCard()}
            {userQuestionsAnsData?.ceoRemark ? <div className="recom-bg rounded p-2 my-2">
              <div className="d-flex align-items-center">
                <img
                  src="/assets/images/initiative-icons/Remark.svg"
                  alt="Remark"
                  width="40px"
                  height="40px"
                />
                <div className="font-weight-bold ms-3">CEO Remarks</div>
              </div>
              <div className="ms-2">{userQuestionsAnsData?.ceoRemark}</div>
            </div> : null}
          </>
        ) : null}
        {!initiatives?.length ? <NoInitiativesForLevelScreen initiativeStatus={initiativeStatus} /> : initiatives?.map((initiative, index) => {
          return initiatives?.length === 1 ?(
            <>
              {renderInitiativeDataCard("Initiative", "Initiative", initiative?.initiative)}
              {renderInitiativeDataCard("Expected_result", "Expected Result", initiative.goal)}
              {renderInitiativeDataCard("Expected_result", renderDateForInitiative(initiative)[0], renderDateForInitiative(initiative)[1])}
              {renderInitiativeProgressAndDates(initiative, index)}

            </>
          ):(
            <div>
                    <div className="recom-bg my-2">
                      <>

                          <Card
                            className="init-card mb-4"
                            key={`recom-${initiative.initiative}`}
                            id={`recom-${index}`}
                          >
                            <Card.Header
                              className="card-header cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                updateInitExpandedIndex(index);
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="font-weight-bold">
                                  Initiative {index + 1}
                                </div>
                                {renderExpandCollapseButton(initiative, index)}
                              </div>
                            </Card.Header>

                            {initExpandedIndex === index ? (
                              <>
                                <Card.Body className="card-body">
                                  {(
                                    <div>
                                      {renderInitiativeDataCard("Initiative", "Initiative", initiative?.initiative)}
                                      {renderInitiativeDataCard("Expected_result", "Expected Result", initiative.goal)}
                                      {renderInitiativeDataCard("Expected_result", renderDateForInitiative(initiative)[0], renderDateForInitiative(initiative)[1])}
                                      {renderInitiativeProgressAndDates(initiative, index)}
                                    </div>
                                  )}
                                </Card.Body>
                              </>
                            ) : null}
                          </Card>
                      </>
                    </div>
                  </div>
          )
        })}

        {statusRejectedOrApproveOrSubmitForApproval(initiativeStatus) &&
          userQuestionsAnsData ? (
          <div>
            <div>
              {showUserQuestionsAns ?
                (
                  renderUserQuestionAnswer()
                ) : null}
            </div>
          </div>
        ) : null}
        <div className="d-flex justify-content-end">
        {initiativeStatus !== "REJECTED" ? initiativeStatus == "APPROVE" || initiativeStatus == "SUBMIT_FOR_APPROVAL" ? showUserQuestionsAns ? (
          <div
            className="text-primary cursor-pointer  d-flex align-items-center justify-content-end"
            onClick={() => setShowUserQuestionsAns(false)}
          >
            <span>View Less</span>{" "}
            <span class="material-symbols-outlined">expand_less</span>
          </div>
        ) : (
          !initiatives.length && initiativeStatus === "APPROVE" ? null : <div
            className="text-primary cursor-pointer  d-flex align-items-center justify-content-end"
            onClick={() => setShowUserQuestionsAns(true)}
          >
            <span>View More Details</span>{" "}
            <span class="material-symbols-outlined">expand_more</span>
          </div>
        ) : <></> : <></>}
        {initiativeStatus == "APPROVE" ? <Link className="text-decoration-none ms-2" to={renderRecommendationHistoryUrl} params={{ recomm: recommendation }}> <div
          className="text-primary  cursor-pointer me-2 d-flex align-items-center justify-content-end"
        >
          <img src="/assets/images/view_history.svg" className="me-1" />
          <span>
            View History
          </span>
        </div></Link> : <></>}
        </div>
    </div>
  )
}
