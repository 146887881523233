import React, { useEffect, useReducer, useState } from 'react';
import { Modal, Image } from 'react-bootstrap';
import * as coachDetailActions from '../../../CoachDetails/_redux/coachDetailActions';
import * as authActions from '../../../AuthContainer/_redux/authAction';
import { useDispatch } from 'react-redux';
import './profile-update-popup.scss';
import StandardInput from '../../../shared/formComponents/StandardInput/StandardInput';
import StandardButton from '../../../shared/formComponents/StandardButton/StandardButton';
import LoadingAnimation from '../../../../base/LoadingAnimation';
import { CoachProfileValidations } from '../../../../validations/CoachProfile';
import validationUtils from '../../../../utils/validationUtils';
import { toBase64 } from '../../../../utils/fileUtils';
import AsyncImage from '../../../shared/AsyncImage/AsyncImage';
import { coachDetailsUrls } from '../../../CoachDetails/_redux/coachDetailsUrls';

function profileDispatcher(state, action) {
  switch (action.type) {
    case 'firstName':
      return { ...state, firstName: action.payload };
    case 'lastName':
      return { ...state, lastName: action.payload };
    case 'companyName':
      return { ...state, companyName: action.payload };
    case 'profileImage':
      return {
        ...state,
        profileImageFile: { ...action.payload.base64File, type: 'PROFILE' },
        profileImageUrl: action.payload.profileImageUrl,
      };
    case 'initial':
      return { ...action.payload };
    case 'reset':
      return { firstName: '', lastName: '', companyName: '', email: '' };
    default:
      return { ...state };
  }
}

const ProfileUpdatePopup = ({ show, handleHide, setPasswordPopupVisible }) => {
  const dispatch = useDispatch();
  const [profileDetails, dispatchProfileDetails] = useReducer(
    profileDispatcher,
    {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
    }
  );
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleClose = () => {
    dispatchProfileDetails({ type: 'reset' });
    handleHide();
  };

  const editProfileSubmit = async () => {
    let data = { ...profileDetails };
    delete data.profileImageFile;
    delete data.profileImageUrl;
    if (!validationUtils.checkFormValidity(data, CoachProfileValidations)) {
      setShowError(true);
      return;
    } else setShowError(false);
    try {
      setShowLoader(true);
      if (profileDetails.profileImageFile) {
        const uploadedFileId = await dispatch(
          coachDetailActions.uploadProfileImage(profileDetails.profileImageFile)
        );
        data.profileId = uploadedFileId;
      }
      await dispatch(coachDetailActions.editCoachProfile(data));
      await dispatch(authActions.getUserDetails());
      handleClose();
    } catch (err) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(async () => {
    if (show) {
      setShowLoader(true);
      const profileData = await dispatch(coachDetailActions.getCoachProfile());
      if (profileData.profileId) {
        // profileData.profileImageUrl =
        //   process.env.REACT_APP_BASE_URL +
        //   coachDetailsUrls.GET_FILE.replace('{fileId}', profileData.profileId);
        profileData.profileImageUrl =
          process.env.REACT_APP_LMS_URL + 'file/open/' + profileData.profileId;
      }
      dispatchProfileDetails({ type: 'initial', payload: profileData });
      setShowLoader(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      fullscreen="md-down"
      dialogClassName="profile-update-popup"
      centered
    >
      <Modal.Header>
        <Modal.Title className="d-flex mx-auto">Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showLoader ? (
          <LoadingAnimation
            style={{
              display: 'block',
              justifyContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: '40%',
            }}
          />
        ) : (
          <>
            <div className="mb-5 d-flex justify-content-center align-items-center flex-column">
              <div className="position-relative mb-3">
                <div className="profile-img-outline"></div>
                <AsyncImage
                  src={
                    profileDetails.profileImageUrl ??
                    '/assets/images/coach_contact.svg'
                  }
                  roundedCircle
                  className="profile-img"
                />
              </div>
              <label className="upload-photo">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file)
                      toBase64(file).then((result) => {
                        dispatchProfileDetails({
                          type: 'profileImage',
                          payload: {
                            base64File: result,
                            profileImageUrl: URL.createObjectURL(file),
                          },
                        });
                      });
                  }}
                />
                Upload Photo
              </label>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-sm-6">
                <StandardInput
                  value={profileDetails.firstName}
                  onChange={(e) =>
                    dispatchProfileDetails({
                      type: 'firstName',
                      payload: e.target.value,
                    })
                  }
                  label="First Name"
                  placeholder="Your First Name"
                  validations={CoachProfileValidations.firstName}
                  showError={showError}
                />
              </div>
              <div className="col-12 col-sm-6">
                <StandardInput
                  value={profileDetails.lastName}
                  onChange={(e) =>
                    dispatchProfileDetails({
                      type: 'lastName',
                      payload: e.target.value,
                    })
                  }
                  label="Last Name"
                  placeholder="Your Last Name"
                  validations={CoachProfileValidations.lastName}
                  showError={showError}
                />
              </div>
            </div>
            <StandardInput
              value={profileDetails.email}
              label={
                <div className="d-flex justify-content-between align-items-center">
                  <div>Email</div>
                  <div
                    className="change-password-link"
                    onClick={() => {
                      handleHide();
                      setPasswordPopupVisible(true);
                    }}
                  >
                    Change Password
                  </div>
                </div>
              }
              disabled
              className="mb-4 email-input-with-password-link"
              validations={CoachProfileValidations.email}
            />
            <StandardInput
              value={profileDetails.companyName}
              onChange={(e) =>
                dispatchProfileDetails({
                  type: 'companyName',
                  payload: e.target.value,
                })
              }
              label="Company Name"
              validations={CoachProfileValidations.companyName}
              showError={showError}
            />
            <div className="d-flex mt-5">
              <StandardButton
                text="Cancel"
                color="btn-outline-dark"
                onClick={handleClose}
                className="flex-fill me-2"
              />
              <StandardButton
                text="Update"
                color="btn-success"
                disabled={showLoader}
                onClick={editProfileSubmit}
                className="flex-fill ms-2"
              />
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProfileUpdatePopup;
