export const AUTH_ROUTE = '/auth';
export const LOGIN_ROUTE = `${AUTH_ROUTE}/login`;
export const SIGNUP_ROUTE = `${AUTH_ROUTE}/signup`;
export const OTP_ROUTE = `${AUTH_ROUTE}/otp`;
export const RESET_PASSWORD = `${AUTH_ROUTE}/reset-password`;
export const RESET_PASSWORD_UPDATE = `${AUTH_ROUTE}/reset-password/:resetCode`;

export const COACH_DETAILS = '/coach-details';

export const COACH_PANEL = '/coach-panel';
export const DASHBOARD = `${COACH_PANEL}/dashboard`;
export const XRAY = `${COACH_PANEL}/x-ray`;
export const QUEST = `${COACH_PANEL}/goals-and-rhythms`;
export const CONTACTS = `${COACH_PANEL}/contacts`;
export const FEED = `${COACH_PANEL}/feed`;

export const XRAY_SCORE = `${XRAY}/score`;
export const XRAY_USER_SCORE = `${XRAY_SCORE}/:userId`;

export const ACADEMY = COACH_PANEL + '/scaling-resources';
export const COURSE_OVERVIEW = ACADEMY + '/course/:courseId';
export const STUDY_COURSE = ACADEMY + '/study-course/:courseId';
export const FEEDBACK_COURSE = ACADEMY + '/feedback/:courseId';
export const RESOURCE_DETAIL = ACADEMY + '/resource/:resourceId';
export const VIEW_RECOMMENDATION_HISTORY = XRAY + "/recomm-initiative/history/:practiceId/:surveyId"