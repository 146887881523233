import React, { useEffect, useState } from 'react'
import { ScoreStats } from "./ScoreStats";
import { useDispatch } from 'react-redux';
import * as questActions from "../_redux/QuestActions";
import LoadingAnimation, { SkeletonLoadingAnimation } from '../../../../base/LoadingAnimation';
import { StandardTooltip } from '../../../shared/StandardTooltip/StandardTooltip';


export const QuestWinningTeamScore = ({ smeId, questData }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [questSuccessScore, setQuestSuccessScore] = useState({});

  useEffect(() => {
    handleGetQuestTeamSuccessScore();
  }, []);

  const handleGetQuestTeamSuccessScore = async () => {
    try {
      const resp = await dispatch(questActions.getQuestTeamSuccessScore(smeId));
      if (resp) {
        setQuestSuccessScore(resp);
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const renderTooltipContent = () => {
    return (
      <div className="text-start ">
        <span className="tooltip-title  text-black">Team success score</span>
        <br />
        <span className="text-black tooltip-information ">
          Team success score is critical to taming the whirlwind and building a
          loveable company <br />
          <br />
          Red indicates you need to catch up, yellow indicates that you are
          making progress and greens indicates you are doing a great job in
          taming the whirlwind. <br />
          <br />
          The trendline shows if your results are improving or worsening. <br />
          <br />
          The ranks indicates your standing against peers in the same cohort.{" "}
          <br />
          <br />
        </span>
      </div>
    );
  };

  return (
    <div className="me-2" id="quest-winning-team-score">
      <div className="bg-white text-center rounded-3 h-100">
        <div className="border-bottom p-2 mx-2 mb-2 text-start d-flex justify-content-start align-items-top">
          <span className="font-weight-bold title-font-size dark-grey">
            Team success score
          </span>
          {/* <StandardTooltip
            tooltTipContent={renderTooltipContent()}
            applyMarginTop={true}
          ></StandardTooltip> */}
        </div>
        {isLoading ?
          <SkeletonLoadingAnimation height={167} count={2} />
          : <ScoreStats
            questData={questData}
            scoreData={questSuccessScore}
          />}
      </div>
    </div>
  )
}
