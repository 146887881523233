import React from 'react';

export const DashboardContainer = (props) => {
  return (
    <div
      className="container-fluid xray ms-0 p-0 flex-fill"
      style={{ maxWidth: props.expand ? '100%' : '1600px' }}
    >
      <div className="px-3 px-md-4 px-lg-2">{props.children}</div>
    </div>
  );
};
