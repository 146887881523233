import { createSlice } from '@reduxjs/toolkit';
import { clearCookie, getCookie, setCookie } from '../../../utils/cookieUtils';
import {
  ACCESS_TOKEN_COOKIE_KEY,
  ADMINS_COACH_ID_COOKIE_KEY,
  ADMIN_ACCESS_TOKEN_COOKIE_KEY,
} from '../../../data/enums/TokenEnums';

const initialState = {
  email: '',
  accessToken: getCookie(ACCESS_TOKEN_COOKIE_KEY)
    ? `Bearer ${getCookie(ACCESS_TOKEN_COOKIE_KEY)}`
    : getCookie(ADMIN_ACCESS_TOKEN_COOKIE_KEY) &&
      getCookie(ADMINS_COACH_ID_COOKIE_KEY)
    ? `Bearer ${getCookie(ADMIN_ACCESS_TOKEN_COOKIE_KEY)}`
    : null,
  userProfile: null,
  signupDetails: {},
};

const authSlice = createSlice({
  name: 'authDetails',
  initialState,
  reducers: {
    setEmail: (state, actions) => {
      const { email } = actions.payload;
      state.email = email;
    },
    setAccessToken: (state, actions) => {
      const { accessToken } = actions.payload;
      setCookie(ACCESS_TOKEN_COOKIE_KEY, `${accessToken}`);
      state.accessToken = `Bearer ${accessToken}`;
    },
    setSignupDetails: (state, actions) => {
      const { signupDetails } = actions.payload;
      state.signupDetails = signupDetails;
    },
    setUserProfile: (state, actions) => {
      state.userProfile = actions.payload;
    },
    setUserState: (state, actions) => {
      state.userState = actions.payload;
    },
    removeAccessToken: (state, actions) => {
      clearCookie(ACCESS_TOKEN_COOKIE_KEY);
      clearCookie(ADMINS_COACH_ID_COOKIE_KEY);
      state.accessToken = null;
    },
  },
});

export default authSlice;
