import React, { Component } from 'react';
import { SyncLoader, ClockLoader } from 'react-spinners';
import { colors } from '../data/Colors';
import classNames from 'classnames';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default class LoadingAnimation extends Component {
  render() {
    const style = {
      position: 'absolute',
      display: 'block',
      justifyContent: 'center',
      textAlign: 'center',
      top: '40vh',
      bottom: 0,
      left: 0,
      right: 0,
    };

    return (
      <div style={this.props.style || style}>
        {this.props.text ? (
          <ClockLoader
            css={{ display: 'block', margin: 'auto' }}
            sizeUnit={'px'}
            size={40}
            color={colors['base-success']}
          />
        ) : (
          <SyncLoader
            css={{ display: 'block' }}
            sizeUnit={'px'}
            size={15}
            color={colors['base-success']}
          />
        )}
        <div className="px-5 font-weight-bold mt-3 text-center">
          {this.props.text}
        </div>
      </div>
    );
  }
}

export const SkeletonLoadingAnimation = ({
  height,
  className,
  width,
  count,
  noPadding,
  containerClassNames,
}) => {
  let containerClasses = classNames(`${containerClassNames}`);
  let classes = classNames(`skeleton-loading-animation ${className}`);
  return (
    <div className={`${noPadding ? "pb-2" : "px-2 pb-2"}`}>
      <SkeletonTheme baseColor="#F6F6F6" highlightColor="#E6E6E6">
        <Skeleton
          containerClassName={containerClasses}
          className={classes}
          height={height}
          width={width}
          count={count}
        />
      </SkeletonTheme>
    </div>
  );
};