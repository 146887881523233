export function generateChartData(xrayScore) {
  let formattedTabbedData = [
    {
      tabName: 'Overall',
      actualScore: xrayScore.overall.actualScore,
      spiderChartData: [],
      disciplineId: null,
    },
  ];
  for (let discipline of xrayScore.disciplines) {
    formattedTabbedData[0].spiderChartData.push({
      label: discipline.name + `(${discipline.actualScore})`,
      actualScore: discipline.actualScore,
      gutScore: discipline.gutScore,
      requiredScore: discipline.requiredScore,
      maxScore: 5,
    });

    let disciplineSpiderChartData = [];

    for (let subDiscipline of discipline.subDisciplines) {
      if (discipline.name.indexOf('Sales') === -1) {
        disciplineSpiderChartData.push({
          label: subDiscipline.name + `(${subDiscipline.actualScore})`,
          actualScore: subDiscipline.actualScore,
          gutScore: discipline.gutScore,
          requiredScore: discipline.requiredScore,
          maxScore: 5,
        });
      } else {
        for (let ssd of subDiscipline.subSubDisciplines) {
          disciplineSpiderChartData.push({
            label: ssd.name + `(${ssd.actualScore})`,
            actualScore: ssd.actualScore,
            gutScore: discipline.gutScore,
            requiredScore: discipline.requiredScore,
            maxScore: 5,
          });
        }
      }
    }
    formattedTabbedData.push({
      tabName: discipline.name,
      actualScore: discipline.actualScore,
      spiderChartData: disciplineSpiderChartData,
      disciplineId: discipline.disciplineId,
    });
  }
  return formattedTabbedData;
}
