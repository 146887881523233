import React, { useState } from "react";
import { Card } from "react-bootstrap";
import StandardButton from "../../../shared/formComponents/StandardButton/StandardButton";
import StandardInput from "../../../shared/formComponents/StandardInput/StandardInput";
import StandardScore from "../../../shared/formComponents/StandardScore/StandardScore";
import validationUtils from "../../../../utils/validationUtils";
import "./feedback-course.scss";
import { FeedbackValidation } from "../../../../validations/Feedback";
import { useDispatch } from "react-redux";
import { submitFeedback } from "../_redux/AcademyAction";
import { useHistory, useParams } from "react-router-dom";
import { successNoti } from "../../../../base/BasicNotifications";
import { ACADEMY } from "../../../../data/RouteUrls";

export const FeedbackCourse = (props) => {
  const [rating, setRating] = useState();
  const [review, setReview] = useState();
  const [showError, setShowError] = useState(false);
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const validations = FeedbackValidation;
  const submit = async () => {
    let data = { rating, review };
    if (!validationUtils.checkFormValidity(data, validations)) {
      setShowError(true);
      return;
    }
    try {
      await dispatch(submitFeedback(courseId, data));
      successNoti("Thanks for your feedback");
      history.push(ACADEMY);
    } catch (err) {}

    // history.push("/dashboard");
  };
  return (
    <div className="feedback-course">
      <div className="d-flex justify-content-between">
        <div className="cs-heading">Course complete, well done!</div>
      </div>
      <Card>
        <div className="card-body p-5">
          <div className="row">
            <div className="col-6 align-self-center">
              <StandardScore
                className="mt-3"
                label="How likely are you to recommend this course to a friend or colleague?"
                placeholder="Select Period"
                start={1}
                end={10}
                value={rating}
                showError={showError}
                validations={validations.rating}
                onChange={(e) => setRating(e)}
              />
              <StandardInput
                value={review}
                onChange={(e) => setReview(e.target.value)}
                className="mt-3"
                label="How can we improve?"
                type="text"
                showError={showError}
                validations={validations.review}
                placeholder="We'd love to hear your suggestions!"
              />
              <StandardButton
                className="mt-5"
                color="btn-success"
                bold={true}
                text="Submit"
                onClick={submit}
              ></StandardButton>
            </div>
            <div className="col-6 text-center">
              <img src="/assets/images/feedback.svg" alt="feedback" />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
