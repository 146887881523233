import Table from "react-bootstrap/Table";
import "./standard-table.scss";
import { getHBScoreColor } from "../../../utils/generalUtils";

function StandardTable({ columns, data }) {
  const renderHeaders = () => {
    let headersTh = [];
    for (let column of columns) {
      headersTh.push(<th>{column.title}</th>);
    }
    return <tr>{headersTh}</tr>;
  };

  const renderData = () => {
    let rows = [];
    for (let record of data) {
      let columnElements = [];
      for (let column of columns) {
        let color;
        if (column.colored) {
          color = getHBScoreColor(record[column.dataField]);
        }
        columnElements.push(
          <td style={{ color: color }}>{record[column.dataField]}</td>,
        );
      }
      rows.push(<tr>{columnElements}</tr>);
    }
    return rows;
  };
  return (
    <Table hover className="habit-builder-table">
      <thead>{renderHeaders()}</thead>
      <tbody>{renderData()}</tbody>
    </Table>
  );
}

export default StandardTable;
