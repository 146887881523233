import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import StandardButton from "../formComponents/StandardButton/StandardButton";

import "./upgrade-confirmation-popup.scss";

const UpgradeConfirmationPopup = ({
  isOpen = true,
  title,
  content,
  confirm = "Schedule meeting",
  cancel = "Cancel",
  img = "/assets/images/upgrade-popup.svg",
  closePopup,
  upgradePopup
}) => {
  const profileDetails = useSelector((state) => state.authDetails?.userProfile);
  const [openUpgradePopup, setOpenUpgradePopup] = useState(upgradePopup);    
  if (openUpgradePopup) {
    return (
      <Modal
        show={isOpen}
        size="xl"
        onHide={() => {
          closePopup();
          setOpenUpgradePopup(false);
        }}
        dialogClassName="upgrade-plan-popup"
      >
        <Modal.Body className="p-0">
          <>
            <div>
              <div>
                <iframe
                  title="Book your meeting"
                  src={`https://calendly.com/whartmann/nxt-9-demo-website?name=${profileDetails?.firstName}&email=${profileDetails?.email}`}
                  frameborder="0"
                  allowfullscreen
                  width="100%"
                  marginwidth="0"
                  marginheight="0"
                ></iframe>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <Modal
      show={isOpen}
      size="md"
      centered
      dialogClassName="upgrade-confirmation-popup"
    >
      {/* <Modal.Header>
        <Modal.Title className="mx-auto">{title}</Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="pb-4 text-center">
        <img src={img} alt="popup" className="mt-3" />
        <div className={`content mt-3`}>{content}</div>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <StandardButton
            text={cancel}
            color="btn-outline-dark"
            onClick={closePopup}
            className="me-3"
          />
          <StandardButton
            text={confirm}
            color="btn-success"
            onClick={() => setOpenUpgradePopup(true)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpgradeConfirmationPopup;
