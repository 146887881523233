import { useEffect, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import './edit-respondants-popup.scss';
import * as actions from '../../_redux/xrayActions';
import StandardCheckbox from '../../../../shared/formComponents/StandardCheckbox/StandardCheckbox';
import { convertToLabelValue } from '../../../../../utils/generalUtils';
import StandardButton from '../../../../shared/formComponents/StandardButton/StandardButton';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { responsiveConfig } from './CaraousalConfig';
import LoadingAnimation from '../../../../../base/LoadingAnimation';
import { useParams } from 'react-router-dom';
import { errorNoti } from '../../../../../base/BasicNotifications';

export const EditRespondantsPopup = (props) => {
  let dispatch = useDispatch();
  const params = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [showSaveLoader, setSaveShowLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const getInitialData = async () => {
    let usersData = await dispatch(actions.getSurveyMembers(params.userId));
    let disciplinesData = await dispatch(actions.getDisciplines());

    let index = 0;
    for (let user of usersData) {
      user.index = index;
      let disciplines = [];
      for (let discipline of disciplinesData) {
        if (discipline.showForSelection) {
          disciplines.push(discipline);
        }
      }
      user.disciplines = convertToLabelValue(
        disciplines,
        'dName',
        'disciplineId'
      );
      user.selectedDisciplines = [];
      user.allowedDisciplines = [];
      let disciplineIndex = 0;
      for (let discipline of user.userDisciplineMap) {
        if (!discipline.showForSelection) {
          continue;
        }
        user.disciplines[disciplineIndex].active = discipline.active;
        user.disciplines[disciplineIndex].disabled =
          !discipline.active || !user.isCalculate;
        if (discipline.calculate && discipline.active) {
          user.selectedDisciplines.push(discipline.disciplineId);
          user.considerUser = true;
        }
        disciplineIndex += 1;
      }
      index += 1;
    }
    setUsers(usersData);
    setDisciplines(disciplinesData);
    setShowLoader(false);
  };

  useEffect(() => {
    // This code runs on loading the component
    getInitialData();
  }, []);

  const updateSelectedDisciplines = (index, e) => {
    let copiedUsers = JSON.parse(JSON.stringify(users));
    copiedUsers[index].selectedDisciplines = e;
    setUsers(copiedUsers);
  };

  const updateIsCalculate = (index) => {
    let copiedUsers = JSON.parse(JSON.stringify(users));
    copiedUsers[index].isCalculate = !copiedUsers[index].isCalculate;
    for (let discipline of copiedUsers[index].disciplines) {
      discipline.disabled =
        !discipline.active || !copiedUsers[index].isCalculate;
    }
    setUsers(copiedUsers);
  };

  const submit = async () => {
    const selectedDisciplinesMap = new Map();
    let formattedData = [];
    for (let user of users) {
      let i = 0;
      for (let discipline of user.userDisciplineMap) {
        if (discipline.showForSelection) {
          discipline.calculate =
            user.selectedDisciplines.indexOf(discipline.disciplineId) === -1
              ? false
              : true;
          if (!i) {
            discipline.deActivateUser = !user.isCalculate;
          }
          if (user.isCalculate && discipline.calculate) {
            selectedDisciplinesMap.set(discipline.disciplineId, true);
          }
        } else {
          discipline.calculate = true;
        }
        formattedData.push(discipline);
      }
    }
    let isAtleastOneUserSelectedForDisc = disciplines.reduce((acc, curr) => {
      if (curr.showForSelection) {
        return acc && selectedDisciplinesMap.has(curr.disciplineId);
      } else return acc;
    }, true);
    if (!isAtleastOneUserSelectedForDisc) {
      errorNoti('Please select atleast one user per discipline');
      return;
    }
    setButtonDisabled(true);
    setSaveShowLoader(true);

    try {
      await dispatch(actions.updateUserDisciplineMapping(formattedData));
      props.handleClose();
      window.location.reload();
    } catch (err) {}
    setButtonDisabled(false);
    setSaveShowLoader(false);
  };
  const renderUserCards = () => {
    return users.map((user) => {
      return (
        <div className="p-3">
          <Card className="user-card">
            <div className="card-header align-items-center d-flex justify-content-start">
              <div className="respondant-container">
                <img src="/assets/images/respondant.svg" alt="respondant" />
              </div>
              {user.firstName}{' '}
            </div>
            <div className="card-body py-0">
              <StandardCheckbox
                value={user.selectedDisciplines}
                onChange={(e) => updateSelectedDisciplines(user.index, e)}
                className="mb-3"
                options={user.disciplines}
              />
            </div>
            <div className="card-footer">
              <div
                key={user.index.toString()}
                // className={classes}
                onClick={() => updateIsCalculate(user.index)}
              >
                <div className="d-flex">
                  <div className="mx-3">
                    <label className="radio-container mx-auto">
                      <input
                        id={user.index}
                        type="radio"
                        name={user.index}
                        value={!user.isCalculate}
                        checked={!user.isCalculate}
                      />
                      <label
                        name={user.index}
                        className="checkmark"
                        onClick={() => updateIsCalculate(user.index)}
                      ></label>
                    </label>
                  </div>
                  <div>
                    <label name={user.index}>Remove All</label>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    });
  };

  if (showLoader) {
    return (
      <Modal show={true} size="xl" dialogClassName="edit-respondants-popup">
        <Modal.Header className="pb-0">
          <Modal.Title className="text-center w-100">
            <div className="cs-heading">Edit X-Ray respondents</div>
            <div className="cs-sub-heading">
              Add or remove a respondents results to the x-ray
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ height: '500px', backgroundColor: 'white' }}
          className="d-flex justify-content-center align-items-center"
        >
          <LoadingAnimation style={{}} />
        </Modal.Body>
      </Modal>
    );
  }
  if (showSaveLoader) {
    return (
      <Modal show={true} size="xl" dialogClassName="edit-respondants-popup">
        <Modal.Header className="pb-0">
          <Modal.Title className="text-center w-100">
            <div className="cs-heading">Edit X-Ray respondents</div>
            <div className="cs-sub-heading">
              Add or remove a respondents results to the x-ray
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ height: '500px', backgroundColor: 'white' }}
          className="d-flex justify-content-center align-items-center"
        >
          <LoadingAnimation
            style={{}}
            text={
              <>
                <div className="cs-heading loading-header">Processing...</div>
                <div className="loading-text">Please wait</div>
              </>
            }
          />
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <>
      <Modal show={true} size="xl" dialogClassName="edit-respondants-popup">
        <Modal.Header className="pb-0">
          <Modal.Title className="text-center w-100">
            <div className="cs-heading">Edit X-Ray respondents</div>
            <div className="cs-sub-heading">
              Add or remove a respondents results to the x-ray
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="row flex-row flex-nowrap user-cards-container">
            <Carousel responsive={responsiveConfig} autoPlay={false}>
              {renderUserCards()}
            </Carousel>
            ;
          </div>
          <div className="text-center">
            <StandardButton
              className="me-3"
              color="btn-outline-dark"
              bold={true}
              text="Cancel"
              onClick={props.handleClose}
            ></StandardButton>
            <StandardButton
              color="btn-success"
              bold={true}
              text="Save"
              onClick={submit}
            ></StandardButton>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
