import axios from 'axios';
import queryString from 'query-string';
import coachDetailsSlice from './coachDetailsSlice';
import { errorNoti, successNoti } from '../../../base/BasicNotifications';
import { coachDetailsUrls } from './coachDetailsUrls';
import { QuestionTypes } from '../../../data/enums/QuestionTypes';
import { getErrorMessage } from '../../../utils/generalUtils';

const { actions } = coachDetailsSlice;

const instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

const defaultQuestions = [
  {
    question: 'company name',
    type: QuestionTypes.TEXT,
  },
  {
    question: 'client',
    type: QuestionTypes.DROPDOWN,
    options: [
      { value: '1-10', label: '1-10' },
      { value: '10-50', label: '10-50' },
      { value: '50-100', label: '50-100' },
    ],
  },
];

export const getQuestions = () => (dispatch, getState) => {
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject(`Coach Id not present`);
  let url = coachDetailsUrls.GET_COACH_QUESTIONS.replace(`{coachId}`, coachId);
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      const ques = response.data.data.map((que) => {
        const dqi = defaultQuestions.findIndex(
          (dq) => que.question.toLowerCase().indexOf(dq.question) >= 0
        );
        if (dqi >= 0) {
          return {
            ...que,
            type: defaultQuestions[dqi].type,
            options: defaultQuestions[dqi].options,
          };
        } else return que;
      });

      dispatch(actions.setQuestions({ questions: ques }));
    })
    .catch((err) => {
      errorNoti(err.response.data.message);
      throw new Error(err.response.data.message);
    });
};

export const answerQuestions = (data) => (dispatch, getState) => {
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject(`Coach Id not present`);
  data.forEach((d) => {
    d.coachId = coachId;
    d.coachQuestionId = d.id;
  });
  return instance
    .post(coachDetailsUrls.POST_COACH_ANSWERS, data, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      successNoti('Answers updated');
      return true;
    })
    .catch((err) => {
      errorNoti(err.response.data.message);
      throw new Error(err.response.data.message);
    });
};

export const getCoachProfile = () => (dispatch, getState) => {
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject('Coach Id missing');
  let url = coachDetailsUrls.GET_PROFILE.replace('{coachId}', coachId);
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      let msg = getErrorMessage(err);
      errorNoti(msg);
    });
};

export const editCoachProfile = (data) => (dispatch, getState) => {
  let url = coachDetailsUrls.EDIT_PROFILE;
  return instance
    .post(url, data, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      let msg = getErrorMessage(err);
      errorNoti(msg);
      throw new Error(err);
    });
};

export const uploadProfileImage = (fileData) => (dispatch, getState) => {
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject('Coach Id missing');
  let url = coachDetailsUrls.UPLOAD_FILE;
  let data = { ...fileData, coachId };
  return instance
    .post(url, data, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      let msg = getErrorMessage(err);
      errorNoti(msg);
      throw new Error(err);
    });
};
