import React, { useEffect, useMemo, useState, useReducer } from "react";
import { Modal, Card } from "react-bootstrap";
import StandardButton from "../../../../shared/formComponents/StandardButton/StandardButton";

import RecommHeader from "../RecommendationsCard/RecommHeader";
import "./edit-recomm-popup.scss";
import "../RecommendationsCard/recommendations-card.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "../../_redux/xrayActions";
import LoadingAnimation from "../../../../../base/LoadingAnimation";
import {
  sortDisciplineNames,
  sortRecomsByDiscipline,
} from "../../../../../utils/generalUtils";
import StandardTextarea from "../../../../shared/formComponents/StandardTextarea/StandardTextarea";
import {
  RenderRecomToggler,
  RenderSituationAndRecommendation,
} from "../RecommendationsCard/RenderRecommendation";

import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import { addTextTooltip } from "../../../../../utils/generalUtils";
import { InitiativeStepper } from "../../../../shared/InitiativeStepper/InitiativeStepper";
import cheerio from "cheerio";

function recomAndLevelDispatcher(state, action) {
  switch (action.type) {
    case "selectSituation":
      return {
        ...state,
        recomType: "situation",
        level: action.payload,
      };
    case "selectRecommendation":
      return {
        ...state,
        recomType: "recommendation",
        level: action.payload,
      };
    case "selectCoachNotes":
      return {
        ...state,
        recomType: "coachNotes",
      };
    case "setLevel":
      if (state.recomType === "recommendation") {
        return {
          ...state,
          recomType: "situation",
          level: action.payload,
        };
      }
      return {
        ...state,
        level: action.payload,
      };
    default:
      return { ...state };
  }
}

function getCurrentLevelFromRecom(recom) {
  let currentLevel = recom?.answerMap.findIndex((ans) => ans.isCurrentLevel);
  return currentLevel;
}

function getNextLevelFromRecom(recom) {
  let currentLevel = recom?.answerMap.findIndex((ans) => ans.isNextLevel);
  return currentLevel;
}

const EditRecommPopup = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);
  const [step, setStep] = useState(1);
  const [initiativeStatus, setInitiativeStatus] = useState("NOT_STARTED");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  let currentLevel;
  const [textToShow, setTextToShow] = useState(currentLevel);
  const [levelInView, setLevelInView] = useState();
  const [coachNote, setCoachNote] = useState();

  const coachRecommendations = useSelector(
    (state) => state.xrayReport.coachRecommendations
  );
  const defaultCoachRecommendations = useSelector(
    (state) => state.xrayReport.defaultCoachRecommendations
  );

  const gloassaryWords = useSelector(
    (state) => state.sharedRedux.gloassaryWords
  );

  useEffect(() => {
    const customBoundary = document.querySelector("#rightsection");
    Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
      (tooltipNode) =>
        new Tooltip(tooltipNode, {
          boundary: customBoundary || document.body,
        })
    );
  });

  const xrayState = useSelector((state) => state.xrayReport.xRayState);
  const [recommendations, setRecommendations] = useState([]);
  const [recommendationText, setRecommendationText] = useState();
  const userProfile = useSelector((state) => state.authDetails.userProfile);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [userQuestionsAnsData, setUserQuestionsAnsData] = useState();
  const [recomTypeAndLevel, dispatchRecomTypeAndLevel] = useReducer(
    recomAndLevelDispatcher,
    {
      recomType: "recommendation",
      level: 0,
    }
  );

  function setRecomType(recomType) {
    if (recomType === "coachNotes")
      dispatchRecomTypeAndLevel({ type: "selectCoachNotes" });
    else if (recomType === "situation")
      dispatchRecomTypeAndLevel({
        type: "selectSituation",
        payload: getCurrentLevelFromRecom(recommendations[expandedIndex]),
      });
    else if (recomType === "recommendation")
      dispatchRecomTypeAndLevel({
        type: "selectRecommendation",
        payload: "recommendation",
      });
  }

  const disciplines = useSelector((state) => state.xrayReport.disciplines);

  const [currentDisciplineIndex, setCurrentDisciplineIndex] = useState(null);
  const [practiceId, setPracticeId] = useState();
  const [recommendationData, setRecommendationData] = useState([]);
  const [recommendationPretext, setRecommendationPretext] = useState("");

  const getDefaultRecommendations = async () => {
    await dispatch(actions.getCoachRecommendations(params.userId));
    setShowLoader(false);
  };

  useEffect(() => {
    getDefaultRecommendations();
  }, []);

  useEffect(() => {
    if (disciplines[0] && currentDisciplineIndex === null)
      setCurrentDisciplineIndex(0);
  }, [disciplines]);

  useEffect(() => {
    if (currentDisciplineIndex !== null) {
      changeDiscpline(0, false);
    }
  }, [step]);

  useEffect(() => {
    initializeRecommendations();
    setExpandedIndex(null);
  }, [currentDisciplineIndex, step]);

  const handleOnChangeRecomText = (index) => (e) => {
    setRecommendations((prev) => {
      return prev.map((r, ridx) => {
        const nr = { ...r };
        if (ridx === index) {
          nr.customRecomm = e.target.value;
        }
        return nr;
      });
    });
  };

  const updateExpandedIndex = (index) => {
    if (expandedIndex === index) setExpandedIndex(null);
    else setExpandedIndex(index);
  };

  const submitEditRecomm = async () => {
    updateRecommendations("Overall");
    try {
      setShowLoader(true);
      setButtonDisabled(true);
      let done = await dispatch(actions.editCoachRecommendations());
      window.location.reload();
    } catch (err) {
    } finally {
      setButtonDisabled(false);
      setShowLoader(false);
    }
  };

  function initializeRecommendations() {
    if (step === 1 && coachRecommendations && currentDisciplineIndex !== null) {
      const recomToUse = coachRecommendations;
      setRecommendations(
        setSerialNumbers(
          recomToUse[disciplines[currentDisciplineIndex]?.name]?.map((cr) => ({
            ...cr,
          })),
          getSerialNumbersBound()
        )
      );
    } else if (step === 2 && coachRecommendations) {
      let recomsToUse = coachRecommendations;
      const overallRecoms = getOverallRecoms(recomsToUse);
      setRecommendations(
        setSerialNumbers(overallRecoms, getSerialNumbersBound())
      );
    }
  }

  function sortRecommendationsByDefault(recomsToSort, recomsUsedAsBase) {
    const indicesReqd = {};
    recomsUsedAsBase.forEach((r, idx) => (indicesReqd[r.id] = idx));
    const sortedRecoms = recomsToSort.map((r) => ({ ...r }));
    sortedRecoms.sort((r1, r2) =>
      indicesReqd[r1.id] < indicesReqd[r2.id] ? -1 : 1
    );
    return sortedRecoms;
  }

  function resetRecommendations() {
    if (step === 1 && coachRecommendations && currentDisciplineIndex !== null) {
      setRecommendations(
        setSerialNumbers(
          sortRecommendationsByDefault(
            recommendations,
            defaultCoachRecommendations[
            disciplines[currentDisciplineIndex].name
            ]
          ),
          getSerialNumbersBound(),
          true
        )
      );
    } else if (step === 2 && coachRecommendations) {
      const overallRecoms = getOverallRecoms();
      setRecommendations(
        setSerialNumbers(
          sortRecommendationsByDefault(recommendations, overallRecoms),
          getSerialNumbersBound(),
          true
        )
      );
    }
  }

  function getSerialNumbersBound() {
    return step === 2 ? 10 : 5;
  }

  function getOverallRecoms() {
    const overallRecomsLogic = [
      { name: "Strategy", count: 4 },
      { name: "Execution", count: 3 },
      { name: "People", count: 3 },
      { name: "Board & Advisors", count: 3 },
      { name: "Sales & Marketing", count: 3 },
      { name: "Operations", count: 3 },
      { name: "Finance", count: 3 },
      { name: "Capital", count: 3 },
    ];
    if (!coachRecommendations) return [];

    // CRISIS Recommendations
    const overallRecoms = Object.keys(coachRecommendations).flatMap(
      (discName) => coachRecommendations[discName].filter((r) => r.isCrisis)
    );

    // DISCIPLINE Recommendations
    overallRecomsLogic.forEach((recoLogic) => {
      let added = 0;
      if (!coachRecommendations[recoLogic.name]) return;
      for (let i = 0; i < coachRecommendations[recoLogic.name].length; i++) {
        if (added === recoLogic.count) break;
        const currentRecommendation = coachRecommendations[recoLogic.name][i];
        let alreadyPresent =
          overallRecoms.filter((r) => r.id === currentRecommendation.id)
            .length > 0;
        if (!alreadyPresent) {
          overallRecoms.push({ ...currentRecommendation });
          added++;
        }
      }
    });
    return overallRecoms;
  }

  const updateRecommendations = (disciplineName) => {
    dispatch(
      actions.updateCoachRecommendations(recommendations, disciplineName)
    );
  };

  const updateReadStatus = (snapshotId, disciplineId) => {
    dispatch(actions.updateReadStatusDiscipline(snapshotId, disciplineId));
  };

  const changeDiscpline = (disciplineIndex, update = true) => {
    if (disciplineIndex >= disciplines.length || disciplineIndex < 0) return;
    setCurrentDisciplineIndex((prev) => {
      if (update) {
        updateRecommendations(disciplines[prev].name);
        if (
          !disciplines[disciplineIndex].read &&
          disciplines[disciplineIndex].disciplineId
        ) {
          if (!disciplines[prev].read) {
            updateReadStatus(
              xrayState.snapshotId,
              disciplines[prev].disciplineId
            );
          }
          updateReadStatus(
            xrayState.snapshotId,
            disciplines[disciplineIndex].disciplineId
          );
        }
      }
      return disciplineIndex;
    });
  };

  const changeStep = (stepNo) => {
    setStep((prev) => {
      const discName =
        prev === 1 ? disciplines[currentDisciplineIndex].name : "Overall";
      updateRecommendations(discName);
      return stepNo;
    });
  };

  const renderDisciplineList = () => {
    const disciplineList =
      step === 1 ? disciplines : [{ name: "Overall", read: true }];
    if (disciplineList && disciplineList.length === 0) return [];

    return disciplineList.map((discipline, index) => {
      let classList = "d-flex align-items-center";
      let imgEnd = discipline.name
        .replace(/ /g, "-")
        .replace("&", "")
        .replace("--", "-");
      let imgPath =
        "/assets/images/disciplineIcons/discipline-icon-" + imgEnd + ".svg";

      if (currentDisciplineIndex === index) {
        classList += " active";
        imgPath =
          "/assets/images/disciplineIcons-active/discipline-icon-" +
          imgEnd +
          ".svg";
      }

      return (
        <div className={classList} onClick={() => changeDiscpline(index)}>
          {!discipline?.read ? (
            <span className="unread-circle position-absolute"></span>
          ) : null}
          <div className="img-container ms-3">
            <img src={imgPath} alt="discipline logo" />
          </div>
          <div className="ms-3">{discipline.name}</div>
        </div>
      );
    });
  };

  function setSerialNumbers(recoms, MAX_COUNT = 5, animate = false) {
    let count = 1;
    return recoms.map((r) => {
      const r2 = { ...r };
      if (r.isCustomVisible && count <= MAX_COUNT) {
        r2.serialNumber = count++;
      } else r2.serialNumber = null;
      if (animate) r2.animate = true;
      else r2.animate = false;
      return r2;
    });
  }

  function renderNumber(number, animate = false) {
    return (
      <div className="recom-number-container">
        <div className={`recom-number ${animate ? "jiggle" : ""}`}>
          {parseInt(number)}
        </div>
      </div>
    );
  }

  const toggleHideRecomm = (index) => {
    setRecommendations((prev) =>
      setSerialNumbers(
        prev.map((recom, rindex) => {
          if (rindex === index)
            return { ...recom, isCustomVisible: !recom.isCustomVisible };
          else return recom;
        }),
        getSerialNumbersBound()
      )
    );
  };

  const reorderItems = (items, startIndex, endIndex) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getRecomendationContentData = async (recommendationId) => {
    let data = await dispatch(
      actions.getRecomendationContentsData(recommendationId)
    );
    if (data) {
      if (data.pretext) {
        let $ = cheerio.load(data.pretext);
        $("a").each(function () {
          $(this).attr("target", "_blank");
        });
        data.pretext = $.html();

        setRecommendationPretext(data.pretext);
      }
      setRecommendationData(data.content);
    }
  };

  const setLineGraphLevel = async (level, levelDetails, pracId) => {
    if (recomTypeAndLevel.recomType == "recommendation" || recomTypeAndLevel.recomType == "situation") {
      let data = await dispatch(
        actions.getRecommendationLevelBase(
          pracId ? pracId : practiceId,
          props.surveyId,
          level
        )
      );
      {console.log(levelDetails)}
      if (data) {
        setRecommendationText(data?.recommendation);
        setCoachNote(levelDetails?.customRecomm);
        setInitiativeStatus(data?.status);
        setLevelInView(level);
        if (data?.questionAnswer) {
          let formatedData = {
            recommendationText: data.recommendation,
            remark: data?.questionAnswer.remark,
            answerText: data?.questionAnswer?.answers[0].answer,
            questionText: data?.questionAnswer.question,
            ceoRemark: data?.questionAnswer.ceoRemark,
          };
          setUserQuestionsAnsData(formatedData);
        }
        getRecomendationContentData(data?.recommendationId);
      } else {
        setInitiativeStatus("NOT_STARTED")
      }
    }
    setTextToShow(level);

  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newRecomms = reorderItems(
      recommendations,
      result.source.index,
      result.destination.index
    );
    setRecommendations(setSerialNumbers(newRecomms, getSerialNumbersBound()));

    if (expandedIndex === result.source.index) {
      updateExpandedIndex(result.destination.index);
    }
    if (expandedIndex === result.destination.index) {
      updateExpandedIndex(result.source.index);
    }
  };


  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={props.handleClose}
      dialogClassName="edit-recomm-popup"
      fullscreen="lg-down"
      className="edit-recomm-modal"
    >
      <Modal.Header
        className="d-flex justify-content-start align-items-start"
        closeButton
      >
        <Modal.Title className="d-flex flex-column recom-title">
          <div className="cs-heading">Edit Recommendations</div>
          <div className="cs-sub-heading">
            {step === 1
              ? "First, prioritise the Top 5 Practices for EACH discipline"
              : "Finally, prioritise the top 10 practices Overall"}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-3 row m-0" style={{ minHeight: "80vh" }}>
        {showLoader ? (
          <LoadingAnimation />
        ) : (
          <>
            {" "}
            <div className="recom-builder-breadcrumbs mb-3">
              <div className={`breadcrumb-item ${step >= 1 ? "active" : ""}`}>
                <div className="step-number me-2">1</div>
                <div className="text"> Discipline Ranking</div>
              </div>
              <div className={`breadcrumb-item ${step >= 2 ? "active" : ""}`}>
                <div className="step-number me-2">2</div>
                <div className="text"> Overall Ranking</div>
              </div>
            </div>
            <div className="discipline-list col-2">
              {renderDisciplineList()}
            </div>
            <div className="col-10 ps-5 recoms-list">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="reorder-text">Drag to reorder</div>
                <div className="d-flex ">
                  <div title="Reset to System Defaults">
                    <StandardButton
                      text={
                        <div className="d-flex align-items-center">
                          <img
                            src="/assets/images/logo-small.png"
                            className="me-1"
                            width="25px"
                            height="auto"
                          />
                          Reset
                        </div>
                      }
                      icon={<span className="material-icons">refresh</span>}
                      color="btn-sm me-3"
                      className="reset-button"
                      reversed
                      onClick={() => {
                        resetRecommendations();
                      }}
                    />
                  </div>
                  <StandardButton
                    text="Back"
                    color="btn-outline-dark"
                    className="me-3 btn-sm"
                    onClick={() => changeStep(1)}
                  />
                  <StandardButton
                    text={step === 2 ? "Save" : "Next"}
                    color="btn-success"
                    className="btn-sm"
                    onClick={() => {
                      if (step === 2) {
                        submitEditRecomm();
                      } else changeStep(2);
                    }}
                    disabled={buttonDisabled}
                  />
                </div>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="recomm-droppable">
                  {(provided, snapshot) => (
                    <div
                      className="w-100"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {recommendations.map((recom, dindex) => (
                        <Draggable
                          key={dindex}
                          draggableId={`recomm-draggable-${dindex}`}
                          index={dindex}
                        >
                          {(provided, snapshot) => (
                            <Card
                              className={`recommendations-card mb-3 ${snapshot.isDragging ? "dragging" : ""
                                }`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <RecommHeader
                                userProfile={userProfile}
                                recommendation={recom}
                                cardClick={() => {
                                  console.log(recom);
                                  setPracticeId(recom?.practiceId);
                                  updateExpandedIndex(dindex);
                                  setRecomType("recommendation");
                                  recom.answerMap.map((answer, index) => {
                                    if (answer.isCurrentLevel) {
                                      setCoachNote(answer?.customRecomm);
                                      setLineGraphLevel(answer.level, answer, recom?.practiceId)
                                      getCurrentLevelFromRecom(recom)
                                    }
                                  })
                                }}
                                className={
                                  recom?.isCustomVisible ? "" : "recomm-hidden"
                                }
                                hideNumber
                                actions={
                                  <div className="d-flex justify-content-end">
                                    <StandardButton
                                      color={
                                        recom.customRecomm
                                          ? "btn-outline-success"
                                          : "btn-success"
                                      }
                                      className="btn-sm me-2 coach-note-btn"
                                      text={
                                        recom.customRecomm ? (
                                          <span className="material-icons">
                                            edit_note
                                          </span>
                                        ) : (
                                          <i className="fa-solid fa-pen-to-square"></i>
                                        )
                                      }
                                      bold={true}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setPracticeId(recom?.practiceId);
                                        setExpandedIndex(dindex);
                                        recom.answerMap.map((answer, index) => {
                                          if (answer.isCurrentLevel) {
                                            setCoachNote(answer?.customRecomm);
                                            setLineGraphLevel(answer.level, answer, recom?.practiceId)
                                            getCurrentLevelFromRecom(recom)
                                          }
                                        })
                                        dispatchRecomTypeAndLevel({
                                          type: "selectRecommendation",
                                          payload: getNextLevelFromRecom(recom),
                                        });
                                      }}
                                    />
                                    <StandardButton
                                      color="btn-outline-dark"
                                      bold={true}
                                      className="d-flex justify-content-center align-items-center expand-btn ms-auto"
                                      text={
                                        dindex === expandedIndex
                                          ? "Collapse"
                                          : "Expand"
                                      }
                                      icon={
                                        dindex === expandedIndex ? (
                                          <span className="material-icons icon-in-btn">
                                            expand_less
                                          </span>
                                        ) : (
                                          <span className="material-icons icon-in-btn">
                                            expand_more
                                          </span>
                                        )
                                      }
                                      reversed={true}
                                      onClick={(e) => {
                                        setRecomType("recommendation");
                                        recom.answerMap.map((answer, index) => {
                                          if (answer.isCurrentLevel) {
                                            setCoachNote(answer?.customRecomm);
                                            setLineGraphLevel(answer.level, answer, recom?.practiceId)
                                            getCurrentLevelFromRecom(recom)
                                          }
                                        })
                                        setPracticeId(recom?.practiceId);
                                        updateExpandedIndex(dindex);
                                        dispatchRecomTypeAndLevel({
                                          type: "selectRecommendation",
                                          payload:
                                            getCurrentLevelFromRecom(recom),
                                        });
                                      }}
                                    />
                                  </div>
                                }
                                preActions={
                                  <>
                                    <div className="material-icons mx-3">
                                      menu
                                    </div>
                                  </>
                                }
                                extras={
                                  recom.serialNumber
                                    ? renderNumber(
                                      recom.serialNumber,
                                      recom.animate
                                    )
                                    : null
                                }
                                dragHandleProps={{
                                  ...provided.dragHandleProps,
                                }}
                              />
                              {dindex === expandedIndex ? (
                                <>
                                  {/* TOGGLER */}
                                  <RenderRecomToggler
                                    recomType={recomTypeAndLevel.recomType}
                                    setRecomType={setRecomType}
                                    coachNotesPresent={
                                      recom.customRecomm ? true : false
                                    }
                                    inEdit={true}
                                  />
                                  <Card.Body>
                                    {recomTypeAndLevel.recomType ===
                                      "situation" ||
                                      recomTypeAndLevel.recomType ===
                                      "recommendation" ? (
                                      <RenderSituationAndRecommendation
                                        recomType={recomTypeAndLevel.recomType}
                                        recommendation={recom}
                                        setLineGraphLevel={setLineGraphLevel}
                                        coachNote={coachNote}
                                        recommText={recommendationText}
                                        textToShow={textToShow}
                                        gloassaryWords={gloassaryWords}
                                        recommendationData={recommendationData}
                                        recommendationPretext={recommendationPretext}
                                        setTextToShow={(lvl) =>
                                          dispatchRecomTypeAndLevel({
                                            type: "setLevel",
                                            payload: lvl,
                                          })
                                        }
                                        CoachNoteComponent={() => (
                                          <StandardTextarea
                                            value={recom?.customRecomm ?? ""}
                                            onChange={handleOnChangeRecomText(
                                              dindex
                                            )}
                                            rows={3}
                                            placeholder="Type your recommendations here ..."
                                          />
                                        )}
                                      />
                                    ) : null}
                                    {/* {!showLoader && levelInView <= getCurrentLevelFromRecom(recom) && recomTypeAndLevel.recomType === "recommendation" ? (
                                      <InitiativeStepper
                                        status={initiativeStatus}
                                        practiceOwnerName={recom?.practiceOwnerName}
                                        isCurrentLevel={currentLevel === levelInView}
                                        recomType={() => setRecomType("Initiative")}
                                      />
                                    ) : null} */}
                                  </Card.Body>
                                </>
                              ) : null}
                            </Card>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditRecommPopup;
