import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./resource-card.scss";

const withLink =
  (WrappedResource) =>
  ({ ...props }) => {
    if (props.resource.resourceType.toLowerCase().includes("external")) {
      return (
        <a
          href={props.resource.link}
          target="_blank"
          className="text-decoration-none"
        >
          <WrappedResource {...props} />
        </a>
      );
    } else
      return (
        <Link
          to={`/coach-panel/scaling-resources/resource/${props.resource.id}`}
          className="text-decoration-none"
        >
          <WrappedResource {...props} />
        </Link>
      );
  };

const ResourceCard = ({ resource, onClick }) => {
  let resourceDiscplineClassName = resource.disciplineName
    ?.split(" ")[0]
    ?.toLowerCase();
  let discImgEnd = resource.disciplineName
    ?.replace(/ /g, "-")
    .replace("&", "")
    .replace("--", "-");
  let resourceTypeName = resource.resourceType.toLowerCase();
  let resourceTypeImgEnd = resourceTypeName.includes("external")
    ? "external"
    : resourceTypeName
        ?.replace(/ /g, "-")
        .replace("&", "")
        .replace("--", "-")
        .replace("internal-", "");
  return (
    <Card className="resource-card h-100" onClick={onClick}>
      <Card.Header
        style={{
          backgroundImage: `url(/assets/images/resources-banners/small/${discImgEnd}.png)`,
        }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={`/assets/images/disciplineIcons-white/discipline-icon-${discImgEnd}.svg`}
            className="me-3"
          />
          {resource.disciplineName}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="resource-title">{resource.title}</div>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex align-items-center">
          <img
            src={`/assets/images/resource-icons/${resourceTypeImgEnd}.svg`}
            className="me-2"
            style={{ position: "relative", top: "-1px" }}
          />
          {resource.resourceType
            .replace("Internal ", "")
            .replace("External ", "")}
        </div>
      </Card.Footer>
    </Card>
  );
};

export default withLink(ResourceCard);
