import React from "react";
import { Modal } from "react-bootstrap";
import { copyToClipboard } from "../../../utils/generalUtils";
import { successNoti } from "../../../base/BasicNotifications";
import "./support-popup.scss";

const SupportPopup = ({ visible, hidePopup }) => {
  const mail = "support@artofscale.io";
  return (
    <Modal
      show={visible}
      onHide={hidePopup}
      centered
      dialogClassName="support-popup"
      size="lg"
    >
      <Modal.Body>
        <div className="row g-0">
          <div className="col-6 d-flex flex-column justify-content-center p-4">
            <div className="cs-heading">Contact Us</div>
            <div className="support-text my-2">Need Support?</div>
            <div className="reach-out-area">
              Reach out to us at: 
              <div className="d-flex">
                    <div>
                    <a href={`mailto:${mail}`}>{mail}</a>
                    </div>
                    <div>
                    <span className="material-icons ms-2" onClick={() => {copyToClipboard(mail);successNoti("Email address copied!")}}>
              content_copy
              </span>
                    </div>
              </div>
              
              
            </div>
          </div>
          <div className="col-6 d-flex flex-column bg-sky p-4">
            <div className="align-self-end">
              <span className="material-icons" onClick={hidePopup}>
                close
              </span>
            </div>
            <div>
              <img
                src="/assets/images/contact-popup.svg"
                alt="User Support"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SupportPopup;
