import { ProgressBar } from "react-bootstrap";

import "./floating-button.scss";

export const FloatingButton = () => {
  return (
    <div
      className="floating-button"
      onClick={() => {
        window.userGuiding.launchResourceCenter(2341);
        window.userGuiding.expandResourceCenter()

      }}
    >
      <div>
        <img
          src="https://media.userguiding.com/files/launcher/27756/file-1659351765640.png"
          width="34px"
          height="34px"
        />
      </div>
      <div>Resource Centre</div>
    </div>
  );
};
