import axios from "axios";
import { QuestUrls } from "./QuestUrls";
import authSlice from "../../../AuthContainer/_redux/authSlice";


const { actions } = authSlice;

const getToken = (getState) => {
  return getState().authDetails.accessToken;
};

const nodeInstance = axios.create({ baseURL: process.env.REACT_APP_NEST_BASE_URL });
const lmsInstance = axios.create({
  baseURL: process.env.REACT_APP_LMS_URL,
});


function defaultParams(id) {
  return {
    sme_id: id
  }
}

export const getQuestCommonData = (id) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    nodeInstance
      .get(QuestUrls.QUEST_COMMON_DATA, {
        headers: {
          Authorization: getToken(getState)
        },
        params: defaultParams(id)
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getQuestTeamSuccessScore = (id) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    nodeInstance
      .get(QuestUrls.QUEST_TEAM_SUCCESS_SCORE, {
        headers: {
          Authorization: getToken(getState)
        },
        params: defaultParams(id)
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getQuestTeamHabitMystery = (weekId, smeId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    nodeInstance
      .get(QuestUrls.QUEST_TEAM_HABIT_MYSTERY.replace("{weekId}", weekId), {
        params: defaultParams(smeId),
        headers: {
          Authorization: getToken(getState),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getQuestLastWeekTeamSuccess = (weekId, smeId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    nodeInstance
      .get(QuestUrls.QUEST_LAST_WEEK_TEAM_SUCCESS.replace("{weekId}", weekId), {
        params: defaultParams(smeId),
        headers: {
          Authorization: getToken(getState),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWeekList = (smeId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    nodeInstance
      .get(QuestUrls.QUEST_WEEK_LIST, {
        params: defaultParams(smeId),
        headers: {
          Authorization: getToken(getState),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const getInsightActionsForTeamScoreImprovement =
  (smeId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      nodeInstance
        .get(QuestUrls.QUEST_IMPROVE_TEAM_SCORE, {
          params: defaultParams(smeId),
          headers: {
            Authorization: getToken(getState),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  export const getActionPanelData = (smeId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      nodeInstance
        .get(QuestUrls.QUEST_ACTION_PANEL, {
          params: defaultParams(smeId),
          headers: {
            Authorization: getToken(getState),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  
  export const getPlaybooks =
    (resolveOnlyRoleBasedPlaybooks,smeId) => (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        let userId = getState().authDetails.userProfile.id;
  
        let url = QuestUrls.QUEST_PLAYBOOKS.replace("{jobseeker_id}", userId);
  
        lmsInstance
          .get(url + "/filter?type=PLAYBOOK", {
            params: defaultParams(smeId),
            headers: {
              Authorization: getState().authDetails.accessToken,
            },
          })
          .then((responses) => {
            if (resolveOnlyRoleBasedPlaybooks) {
              let data = [...responses.data.roleRelatedPlaybooks];
              resolve(data);
            } else {
              let data = [
                ...responses.data.roleRelatedPlaybooks,
                ...responses.data.otherPlaybooks,
              ];
              resolve(data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    };