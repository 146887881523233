import { ProgressBar } from "react-bootstrap";

import "./line-progress-bar.scss";

export const LineProgressBar = (props) => {
  return (
    <div className="line-progress mt-3">
      <div className="bar d-inline-block">
        <ProgressBar now={parseInt(props.percentage)} />
      </div>
      {props.current ? (
        <div className="text d-inline">
          <span className="current">{props.current}</span>

          <span className="total"> of {props.total}</span>
        </div>
      ) : null}
      {props.content ? (
        <div className="text d-inline">
          {props.content}
        </div>
      ) : null}
    </div>
  );
};
