import { createSlice } from '@reduxjs/toolkit';
import { clearCookie, getCookie, setCookie } from '../../../utils/cookieUtils';

const INITIAL_STATE = {
  questions: null,
};

const coachDetailsSlice = createSlice({
  name: 'coachDetails',
  initialState: INITIAL_STATE,
  reducers: {
    setQuestions: (state, action) => {
      const { questions } = action.payload;
      state.questions = questions;
    },
  },
});

export default coachDetailsSlice;
