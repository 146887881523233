import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as contactActions from "../../Contacts/_redux/contactActions";
import StandardButton from "../../../shared/formComponents/StandardButton/StandardButton";
import "./associated-contacts.scss";
import LoadingAnimation from "../../../../base/LoadingAnimation";
import ContactColumn from "./ContactColumn";
import { CONTACT_XRAY_STATE_ENUMS } from "../../../../data/enums/XrayStateEnums";
import InviteContactPopup from "./InviteContactPopup/InviteContactPopup";
import { useDebounce } from "../../../../base/hooks";
import StandardInput from "../../../shared/formComponents/StandardInput/StandardInput";
import StandardSelect from "../../../shared/formComponents/StandardSelect/StandardSelect";
import moment from "moment";
import ContactForm from "../../Contacts/ContactForm/ContactForm";
import { HeaderContext } from "../../../../HeaderContext";
import NoData from "../../../shared/NoData/NoData";
// import { DateRangePicker } from 'react-date-range';

const daysOptions = [
  { label: "Last 30 days", value: 30 },
  { label: "Last 60 days", value: 60 },
  { label: "Last 90 days", value: 90 },
  { label: "Custom", value: "custom" },
];

function contactPopupDispatcher(state, action) {
  switch (action.type) {
    case "open":
      return { ...state, open: action.payload };
    case "contactInfo":
      return { ...state, contactInfo: action.payload };
    case "openAndView":
      return { ...state, open: true, contactInfo: action.payload };
    case "close":
      return { ...state, open: false, contactInfo: null };
    default:
      return state;
  }
}

const AssociatedContacts = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [inviteContactToggle, setInviteContactToggle] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 200);
  const [contactsToShow, setContactsToShow] = useState([]);
  const [filterByDays, setFilterByDays] = useState(daysOptions[0].value);
  const [customDayLower, setCustomDayLower] = useState(null);
  const [customDayHigher, setCustomDayHigher] = useState(null);
  const HeaderMetaData = useContext(HeaderContext);

  // const [dateRanges, setDateRanges] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: moment(new Date()).subtract(30, 'days').toDate(),
  //     key: 'selection',
  //   },
  // ]);

  const [contactPopup, dispatchContactPopup] = useReducer(
    contactPopupDispatcher,
    {
      open: false,
      contactInfo: null,
    }
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const coachContacts = useSelector((state) => state.coachContacts);
  const { associatedContacts } = coachContacts;

  const areAssociactedContactsEmpty = useMemo(() => {
    return contactsToShow.reduce((tillNow, cc) => {
      return tillNow && cc.associatedContacts.length === 0;
    }, true);
  }, [contactsToShow]);

  const formatAssociatedContacts = (as) => {
    const contactColumns = [
      {
        status: CONTACT_XRAY_STATE_ENUMS.INVITED,
        headerText: "Invite Sent",
        headerIconSrc: "/assets/images/contact_invite_sent.svg",
        associatedContacts: [],
      },
      {
        status: CONTACT_XRAY_STATE_ENUMS.REGISTERED,
        headerText: "Registered",
        headerIconSrc: "/assets/images/contact_registered.svg",
        associatedContacts: [],
      },
      {
        status: CONTACT_XRAY_STATE_ENUMS.XRAY_STARTED,
        headerText: "X-Ray Started",
        headerIconSrc: "/assets/images/contact_xray_started.svg",
        associatedContacts: [],
      },
      {
        status: CONTACT_XRAY_STATE_ENUMS.XRAY_SUBMITTED,
        headerText: "Coach Review",
        headerIconSrc: "/assets/images/contact_xray_submitted.svg",
        headerClassName: "xray-submitted-header",
        associatedContacts: [],
      },
      {
        status: CONTACT_XRAY_STATE_ENUMS.XRAY_COMPLETED,
        headerText: "X-Ray Completed",
        headerIconSrc: "/assets/images/contact_xray_complete.svg",
        associatedContacts: [],
      },
    ];
    if (as) {
      as.forEach((asc) => {
        const index = contactColumns.findIndex(
          (cc) => cc.status === asc.status
        );
        if (index >= 0) contactColumns[index].associatedContacts.push(asc);
      });
    }
    return contactColumns;
  };

  const filterContacts = (contacts, debouncedSearchText) => {
    if (!contacts) return [];
    if (!debouncedSearchText || debouncedSearchText === "") return contacts;
    const regex = new RegExp(debouncedSearchText, "ig");
    return contacts.filter((contact) => {
      const { companyName, firstName, lastName } = contact;
      const name = `${firstName} ${lastName}`;
      if (name && name.match(regex)) return contact;
      if (companyName && companyName.match(regex)) return contact;

      return null;
    });
  };

  useEffect(() => {
    HeaderMetaData.setHeaderMetadata({
      headerTitle: "Dashboard",
      HeaderActions: () => (
        <div>
          <StandardButton
            text="X-Ray Contact"
            icon={<img src="/assets/images/x-ray-contact.svg" width="15px" />}
            color="btn-success"
            className="btn-sm"
            reversed
            textClassName="m-0"
            onClick={() => setInviteContactToggle(true)}
          />
        </div>
      ),
    });
    try{
      // window.userGuiding.launchChecklist(14086);
    }catch(err){

    }
    
  }, []);

  useEffect(() => {
    setContactsToShow(
      formatAssociatedContacts(
        filterContacts(associatedContacts, debouncedSearchText)
      )
    );
  }, [associatedContacts, debouncedSearchText]);

  const getAssociatedContactsByDays = async (options) => {
    try {
      setShowLoader(true);
      await dispatch(contactActions.getAssociatedContacts(options));
    } catch (err) {
    } finally {
      setShowLoader(false);
    }
  };

  const getAssociatedContacts = async () => {
    const format = "YYYY-MM-DD HH:mm:ss";
    let today = new Date();
    let date1 = moment(today),
      date2 = moment(today);
    date1 = moment().set({ year: 2022, month: 0, date: 1 });
    getAssociatedContactsByDays({
      startDate: date1.format(format),
      endDate: date2.format(format),
    });
    return;

    if (filterByDays !== "custom") {
      let today = new Date();
      today.setHours(23, 59, 59, 59);
      date2 = moment(today);
      date1 = moment(today)
        .subtract(filterByDays, "days")
        .set("hour", 0)
        .set("minutes", 0)
        .set("seconds", 0);
    } else if (customDayLower !== null) {
      date1 = moment(customDayLower);
      date2 = moment(customDayHigher)
        .set("hour", 23)
        .set("minutes", 59)
        .set("seconds", 59);
    }
    if (date1 && date2 && date1.isValid() && date2.isValid()) {
      getAssociatedContactsByDays({
        startDate: date1.format(format),
        endDate: date2.format(format),
      });
    }
  };

  useEffect(() => {
    document.title = "Art of Scale | Dashboard";
    if (searchParams.get("invite")) {
      setInviteContactToggle(true);
    }
  }, []);

  useEffect(() => {
    getAssociatedContacts();
  }, [filterByDays, customDayLower, customDayHigher]);

  useEffect(() => {
    if (customDayLower !== null) {
      setCustomDayLower(null);
    }
    if (customDayHigher !== null) {
      setCustomDayHigher(null);
    }
  }, [filterByDays]);

  return (
    <div className="container-fluid px-0 h-100 associated-contacts">
      {/* Header */}
      <div className="w-100 d-flex d-md-none justify-content-between align-items-center flex-wrap">
        <div className="cs-heading">Dashboard</div>
        <StandardButton
          text="X-Ray Contact"
          icon={<img src="/assets/images/x-ray-contact.svg" width="15px" />}
          color="btn-success"
          className="btn-sm"
          reversed
          textClassName="m-0"
          onClick={() => setInviteContactToggle(true)}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <StandardInput
          afterButtonIcon={
            <button
              onClick={() => setSearchText("")}
              className="icon-text after after-icon-button"
            >
              {searchText === "" ? (
                <span className="material-icons">search</span>
              ) : (
                <span className="material-icons">clear</span>
              )}
            </button>
          }
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search Contact"
          className="me-3 w-50 search-input-container"
          inputClasses="search-input"
        />
        {/* <div className="d-flex justify-content-end align-items-center">
          <div className="me-2 filter-text">Filter: </div>
          <StandardSelect
            options={daysOptions}
            value={filterByDays}
            onChange={(e) => setFilterByDays(e.value)}
            className="filter-select"
          />
          {filterByDays === 'custom' ? (
            <>
              <StandardInput
                type="date"
                className="ms-3"
                inputClasses="search-input"
                value={customDayLower}
                onChange={(e) => setCustomDayLower(e.target.value)}
                inputProps={{
                  max: new Date().toISOString().split('T')[0],
                }}
              />
              <StandardInput
                type="date"
                className="ms-3"
                inputClasses="search-input"
                value={customDayHigher}
                onChange={(e) => setCustomDayHigher(e.target.value)}
                inputProps={{
                  max: new Date().toISOString().split('T')[0],
                }}
              />
            </>
          ) : null}
        </div> */}
      </div>
      {/* <DateRangePicker
        editableDateInputs
        maxDate={new Date()}
        moveRangeOnFirstSelection={false}
        ranges={dateRanges}
        onChange={(item) => setDateRanges([item.selection])}
        rangeColors={['#08B3C3']}
      /> */}
      <div className="contacts-container-fixed mt-2 py-2">
        <div className="contacts-container">
          {showLoader ? (
            <LoadingAnimation />
          ) : (
            contactsToShow &&
            contactsToShow.map((fac) => (
              <ContactColumn
                {...fac}
                viewContact={(contact) =>
                  dispatchContactPopup({
                    type: "openAndView",
                    payload: contact,
                  })
                }
              />
            ))
          )}
        </div>
      </div>
      {areAssociactedContactsEmpty && !showLoader ? (
        <NoData
          title={
            associatedContacts === null
              ? "We're adding a demo X-Ray report for you to test out !"
              : "No contacts available"
          }
          content={
            associatedContacts === null
              ? "Refresh this page in a couple of minutes..."
              : "Please adjust your filter or X-Ray a contact!"
          }
          imageSrc={
            associatedContacts === null
              ? "/assets/images/sandclock.svg"
              : undefined
          }
          CTAs={
            <StandardButton
              text="X-Ray Contact"
              icon={
                <span className="material-icons position-relative">add</span>
              }
              color="btn-success"
              className="btn-sm"
              reversed
              textClassName="m-0"
              onClick={() => setInviteContactToggle(true)}
            />
          }
        />
      ) : null}
      <InviteContactPopup
        inviteContactToggle={inviteContactToggle}
        handleClose={() => {
          setInviteContactToggle(false);
          getAssociatedContacts();
        }}
        setInviteContactToggle={setInviteContactToggle}
      />
      <ContactForm
        open={contactPopup.open}
        setOpen={(op) => dispatchContactPopup({ type: "open", payload: op })}
        getCoachContacts={() => getAssociatedContacts()}
        handleContactClose={() => dispatchContactPopup({ type: "close" })}
        contactInfo={contactPopup.contactInfo}
      />
    </div>
  );
};

export default AssociatedContacts;
