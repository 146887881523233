import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import LoadingAnimation from '../../../../base/LoadingAnimation';
import StandardButton from '../../../shared/formComponents/StandardButton/StandardButton';
import {
  chapterCompleteAction,
  getCourseContent,
  recordEvent,
} from '../_redux/AcademyAction';
import { CourseProgressCard } from './CourseProgressCard/CourseProgressCard';
import CourseProgressSidebar from './CourseProgressSidebar/CourseProgressSidebar';
import './study-course.scss';
import cheerio from 'cheerio';
import queryString from 'query-string';
import { duplicate } from '../../../../utils/generalUtils';
import { downloadFileAsBlob } from '../../../../utils/fileUtils';
import {
  ACADEMY,
  COURSE_OVERVIEW,
  FEEDBACK_COURSE,
} from '../../../../data/RouteUrls';
import { loadingNoti } from '../../../../base/BasicNotifications';
import { toast } from 'react-toastify';
import { contentTypes } from '../../../../data/enums/contentTypes';
import { eventTypes } from '../../../../data/enums/eventTypes';

export const StudyCourse = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  const progressToast = useRef(null);
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [courseDetail, setCourseDetail] = useState({});
  const [currentChapter, setCurrentChapter] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);
  const [courseProgressSidebarVisible, setCourseProgressSidebarVisible] =
    useState(false);
  const hideProgressSidebar = () => setCourseProgressSidebarVisible(false);
  const showProgressSidebar = () => setCourseProgressSidebarVisible(true);
  const getInitialData = async () => {
    setShowLoader(true);
    let courseDetailData = await dispatch(getCourseContent(courseId));
    let queryObj = queryString.parse(props.location.search);
    if (courseDetailData.sections) {
      let currentChapterIndex,
        currentSectionIndex,
        queryChapterIndex,
        querySectionIndex;
      for (let sectionIndex in courseDetailData.sections) {
        let section = courseDetailData.sections[sectionIndex];
        for (let chapterIndex in section.chapters) {
          let chapter = section.chapters[chapterIndex];
          if (parseInt(queryObj.chapter) === chapter.id) {
            queryChapterIndex = chapterIndex;
            querySectionIndex = sectionIndex;
          } else if (chapter.is_current) {
            currentChapterIndex = chapterIndex;
            currentSectionIndex = sectionIndex;
          }
          let $ = cheerio.load(chapter.content);
          $('oembed').each(function () {
            let videoUrlParts = $(this).attr('url').split('/');

            $(this).replaceWith(
              `<iframe src="https://player.vimeo.com/video/${
                videoUrlParts[videoUrlParts.length - 1]
              }" height="500x" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>`
            );
          });

          $("a").each(function(){
            $(this).attr("target", "_blank");
          })

          chapter.content = $.html();
        }
      }
      if (queryChapterIndex) {
        setCurrentChapter(queryChapterIndex);
        setCurrentSection(querySectionIndex);
      } else {
        setCurrentChapter(currentChapterIndex);
        setCurrentSection(currentSectionIndex);
      }
    }

    // for (let chapterIndex in courseDetailData.chapters) {
    //   if (courseDetailData.chapters[chapterIndex].is_current) {
    //     setCurrentChapter(chapterIndex);
    //     break;
    //   }
    // }

    setCourseDetail(courseDetailData);
    setShowLoader(false);
  };

  const completeChapter = async () => {
    let copiedCourseDetail = duplicate(courseDetail);
    copiedCourseDetail.sections[currentSection].chapters[
      currentChapter
    ].is_completed = true;
    copiedCourseDetail.sections[currentSection].chapters[
      currentChapter
    ].is_current = false;

    await dispatch(
      chapterCompleteAction(
        copiedCourseDetail.sections[currentSection].chapters[currentChapter].id
      )
    );
    if (
      copiedCourseDetail.sections.length === parseInt(currentSection) + 1 &&
      copiedCourseDetail.sections[currentSection].chapters.length ===
        parseInt(currentChapter + 1)
    ) {
      //Go to Feedback page.

      for (let section of copiedCourseDetail.sections) {
        for (let chapter of section.chapters) {
          if (!chapter.is_completed) {
            return history.push(
              COURSE_OVERVIEW.replace(':courseId', copiedCourseDetail.id)
            );
          }
        }
      }
      return history.push(
        FEEDBACK_COURSE.replace(':courseId', copiedCourseDetail.id)
      );
    } else if (
      parseInt(currentChapter) + 1 ===
      copiedCourseDetail.sections[currentSection].chapters.length
    ) {
      copiedCourseDetail.sections[currentSection].is_completed = true;
      copiedCourseDetail.sections[
        parseInt(currentSection) + 1
      ].is_current = true;
      copiedCourseDetail.sections[
        parseInt(currentSection) + 1
      ].chapters[0].is_current = true;
      setCurrentChapter(0);
      setCurrentSection(parseInt(currentSection) + 1);
    } else {
      copiedCourseDetail.sections[currentSection].chapters[
        parseInt(currentChapter) + 1
      ].is_current = true;
      setCurrentChapter(parseInt(currentChapter) + 1);
    }
    let courseDetailData = await dispatch(getCourseContent(courseId));
    copiedCourseDetail.course_status = courseDetailData.course_status;
    setCourseDetail(copiedCourseDetail);
    document.getElementById('content-container').scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const goBack = () => {
    let copiedCourseDetail = duplicate(courseDetail);
    if (currentChapter == 0) {
      setCurrentChapter(
        courseDetail.sections[parseInt(currentSection) - 1].chapters.length - 1
      );
      setCurrentSection(parseInt(currentSection) - 1);
      let currentSectionDetail =
        copiedCourseDetail.sections[parseInt(currentSection)];
      currentSectionDetail.is_current = false;
      currentSectionDetail.chapters[0].is_current = false;
      let futureCurrentSectionDetail =
        copiedCourseDetail.sections[parseInt(currentSection) - 1];
      futureCurrentSectionDetail.is_current = true;
      futureCurrentSectionDetail.chapters[
        futureCurrentSectionDetail.chapters.length - 1
      ].is_current = true;
      setCourseDetail(copiedCourseDetail);
    } else {
      let futureCurrentSectionDetail =
        copiedCourseDetail.sections[parseInt(currentSection)];
      futureCurrentSectionDetail.chapters[currentChapter - 1].is_current = true;
      futureCurrentSectionDetail.chapters[currentChapter].is_current = false;
      setCurrentChapter(currentChapter - 1);
      setCourseDetail(copiedCourseDetail);
    }
    document.getElementById('content-container').scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const updateCurrentChapter = (index) => {
    let copiedCourseDetail = duplicate(courseDetail);
    let currentSectionDetail =
      copiedCourseDetail.sections[parseInt(currentSection)];
    currentSectionDetail.chapters[currentChapter].is_current = false;
    currentSectionDetail.chapters[index].is_current = true;
    setCourseDetail(copiedCourseDetail);
    setCurrentChapter(index);
  };

  const recordDownload =  async () => {
    await dispatch(recordEvent({
      id : courseId, 
      type : contentTypes.RESOURCE, 
      event : eventTypes.DOWNLOAD
    }));
  }

  useEffect(() => {
    getInitialData();
  }, []);

  const downloadProgressCB = (pe) => {
    const pct = Math.floor((pe.loaded / pe.total) * 100);
    if (progressToast.current) {
      if (pct === 100) {
        toast.dismiss(progressToast.current);
        progressToast.current = null;
      } else
        toast.update(progressToast.current, { render: `Downloading ${pct}%` });
    } else progressToast.current = loadingNoti(`Downloading ${pct}%`);
  };

  const downloadFileFromApi = (e) => {
    e.stopPropagation();
    let target = e.target;
    while (
      target.parentNode &&
      target.parentNode.nodeName.toLowerCase() != 'body'
    ) {
      if (target.tagName === 'A') {
        break;
      } else target = target.parentNode;
    }
    if (target.tagName === 'A') {
      let href = target.getAttribute('href');
      let fileName = target.innerText;
      if (href && href.startsWith(process.env.REACT_APP_LMS_URL)) {
        e.preventDefault();
        recordDownload();
        downloadFileAsBlob(href, downloadProgressCB, fileName);
      }
    }
  };

  if (showLoader) {
    return <LoadingAnimation />;
  }
  return (
    <div className="study-course d-flex">
      <div className="w-100">
        <div className="cs-heading">
          {courseDetail.sections[currentSection].chapters[currentChapter].name}
        </div>
        <div className="breadcrumb align-self-end mt-1">
          <div>
            <Link to={ACADEMY}>Academy</Link>
          </div>
          <div>
            {' '}
            /{' '}
            <Link to={COURSE_OVERVIEW.replace(':courseId', courseDetail.id)}>
              {courseDetail.name}
            </Link>
          </div>
          <div>
            {' '}
            /{' '}
            {
              courseDetail.sections[currentSection].chapters[currentChapter]
                .name
            }
          </div>
        </div>
        <div className="progress-sidebar mt-2 d-block d-xl-none">
          <StandardButton
            color="btn-sm"
            text={
              <div className="d-flex justify-content-center align-items-center">
                Check your Course Progress
                <span className="material-icons icon-in-btn">
                  chevron_right
                </span>
                <span className="material-icons icon-in-btn stick">
                  chevron_right
                </span>
              </div>
            }
            onClick={showProgressSidebar}
          ></StandardButton>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-xl-8">
            <Card>
              <div className="card-body px-3 px-lg-4">
                <div
                  className="chapter-content"
                  dangerouslySetInnerHTML={{
                    __html:
                      courseDetail.sections[currentSection].chapters[
                        currentChapter
                      ].content,
                  }}
                  onClick={downloadFileFromApi}
                ></div>
                <div className="chapter-navigation d-flex justify-content-between align-items-center">
                  {!currentSection && !currentChapter ? null : (
                    <StandardButton
                      color="btn-outline-dark"
                      text={
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="material-icons icon-in-btn">
                            chevron_left
                          </span>
                          Prev Chapter
                        </div>
                      }
                      onClick={goBack}
                    ></StandardButton>
                  )}
                  <StandardButton
                    color="btn-success"
                    text={
                      <div className="d-flex justify-content-center align-items-center">
                        Next Chapter
                        <span className="material-icons icon-in-btn">
                          chevron_right
                        </span>
                      </div>
                    }
                    onClick={completeChapter}
                  ></StandardButton>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-0 d-none d-xl-block col-xl-4 mb-3">
            <CourseProgressCard
              section={courseDetail.sections[currentSection]}
              course={courseDetail}
              goToChapter={updateCurrentChapter}
            ></CourseProgressCard>
          </div>
        </div>
      </div>
      <div className="d-block d-xl-none">
        <CourseProgressSidebar
          section={courseDetail.sections[currentSection]}
          course={courseDetail}
          courseProgressSidebarVisible={courseProgressSidebarVisible}
          hideProgressSidebar={hideProgressSidebar}
          goToChapter={updateCurrentChapter}
        />
      </div>
    </div>
  );
};
