import React, { useEffect, useState } from 'react'
import { StandardPopup } from "../../../shared/StandardPopup/StandardPopup";
import { BillingDetailsValidations } from "../../../../validations/BillingDetails";
import { BoldText, SecondarySmallNormalText } from '../../../../utils/typographyUtils';
import * as sharedActions from "../../../sharedRedux/sharedAction";
import validationUtils from "../../../../utils/validationUtils";
import StandardInput from "../../../shared/formComponents/StandardInput/StandardInput";
import StandardSelect from "../../../shared/formComponents/StandardSelect/StandardSelect";
import { useDispatch } from 'react-redux';
import { successNoti } from '../../../../base/BasicNotifications';

export const BillingDetailsPopup = (
  { closePopup, billingDetails, callback }
) => {
  const billingDetailsValidations = BillingDetailsValidations;
  const dispatch = useDispatch();
  const [companyName, setCompanyName] = useState(
    billingDetails?.company_name || "",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState(billingDetails?.address_line_1 || "");
  const [city, setCity] = useState(billingDetails?.city || "");
  const [province, setProvince] = useState(billingDetails?.province || "");
  const [country, setCountry] = useState();
  const [zipCode, setZipCode] = useState(billingDetails?.zip_code || "");
  const [vat, setVat] = useState(billingDetails?.vat || "");
  const [showError, setShowError] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [showTaxInfo, setShowTaxInfo] = useState(false);

  useEffect(() => {
    handleGetCountries();
  }, []);

  const handleGetCountries = async () => {
    try {
      setIsLoading(true);
      let tempCountries = [];
      const resp = await dispatch(sharedActions.getCountryList());
      if (resp) {
        resp.map((country) => {
          tempCountries.push({ label: country, value: country });
        });
      }
      setCountry(billingDetails?.country || "");
      setCountryList(tempCountries);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdateBillingDetails = async () => {
    let data = {
      company_name: companyName,
      address_line_1: address,
      city: city,
      province: province,
      country: country,
      zip_code: zipCode,
      vat: vat
    }

    if (!validationUtils.checkFormValidity({ company_name: companyName, country: country }, billingDetailsValidations)) {
      setShowError(true);
      return;
    }
    try {
      const resp = await dispatch(sharedActions.updateBillingDetails(data));
      if (resp) {
        successNoti(resp.data.message);
        closePopup();
        callback();
      }
    } catch (e) {
      console.log(e);
    }
  }

  const renderBodyContent = () => {
    return (
      <div>
        <div className="mb-3">
          <BoldText>This one-time setup allows us to issue invoices seamlessly. You can update it anytime. It is mandatory to setup your billing profile to send invites to SME.</BoldText>
        </div>
        <StandardInput
          disabled={isLoading}
          variant="bordered"
          label="Billing Entity Name*"
          validations={billingDetailsValidations.companyName}
          showError={showError}
          onChange={(e) => setCompanyName(e.target.value)}
          value={companyName}
          placeholder="Enter Company Name"
        />
        <StandardInput
          disabled={isLoading}
          variant="bordered"
          label="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="mt-3"
          placeholder="Enter Address"
        />
        <div className="d-flex align-items-top">
          <div className="w-50">
            <StandardInput
              disabled={isLoading}
              variant="bordered"
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="mt-3"
              placeholder="City"
              indexNumber={1}
            />
          </div>
          <div className="w-50 ms-2">
            <StandardInput
              disabled={isLoading}
              variant="bordered"
              label="Province"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              className="mt-3"
              placeholder="Province"
              indexNumber={1}
            />
          </div>
        </div>
        <div className="d-flex justify-content-evenly">
          <div className="w-50">
            <StandardSelect
              disabled={isLoading}
              options={countryList}
              variant="bordered"
              label="Country*"
              labelClasses="mt-3"
              value={country}
              showError={showError}
              isSearchable={true}
              maxMenuHeight="135px"
              validations={billingDetailsValidations.country}
              onChange={(e) => {
                setShowTaxInfo(true);
                setCountry(e.value);
              }}
              className="mt-3"
              placeholder="Country"
              indexNumber={1}
            />
          </div>
          <div className="w-50">
            <StandardInput
              disabled={isLoading}
              variant="bordered"
              label="ZIP code"
              value={zipCode}
              type="number"
              maxLength={10}
              onChange={(e) => setZipCode(e.target.value)}
              className="mt-3 ms-2"
              placeholder="Enter ZIP code"
              indexNumber={1}
            />
          </div>
          <div className="w-50">
            <StandardInput
              disabled={isLoading}
              variant="bordered"
              label="VAT Number"
              type="number"
              value={vat}
              onChange={(e) => setVat(e.target.value)}
              className="mt-3 ms-2"
              placeholder="VAT Number"
            />
          </div>
        </div>
        {showTaxInfo ? (
          <SecondarySmallNormalText className="">
            For the selected country, applicable VAT will be at{" "}
            {country === "South Africa" ? "15" : "0"}%
          </SecondarySmallNormalText>
        ) : null}
      </div>
    );
  };

  return (
    <StandardPopup
      bodyContent={renderBodyContent()}
      headerTitle={"Billing Details"}
      imageName={"billing_details_dark.svg"}
      onCancel={() => closePopup()}
      onClose={() => closePopup()}
      onConfirm={handleUpdateBillingDetails}
      disabled={isLoading}
      isLoading={isLoading}
    />
  )
}
