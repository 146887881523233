import { isValidPhoneNumber } from 'react-phone-number-input';
var validationFunctions = {
  /**
   * @param {value} value the value that needs to be tested.
   * @param {value} length allowed length of the string.
   *
   * Returns true/false based on if the value length is not more than specified length.
   */
  MaxLength: (value, length) => {
    return length >= value.length;
  },

  /**
   * @param {value} value the value that needs to be tested.
   * @param {value} min minimum value allowed.
   */
  Min: (value, min) => {
    if (!value) {
      return true;
    }
    return value >= min;
  },

  /**
   * @param {value} value the value that needs to be tested.
   * @param {value} max maximum value allowed.
   */
  Max: (value, max) => {
    if (!value) {
      return true;
    }
    return value <= max;
  },

  /**
   * @param {value} value the value that needs to be tested.
   *
   * Returns true/false based on if the value is not null, undefined and empty string.
   */
  Required: (value) => {
    return (
      value !== undefined && value !== null && value.toString().trim() !== ''
    );
  },

  /**
   * @param {value} value the value that needs to be tested.
   * @param {value} regex regular expression that the value should satisfy.
   *
   * Returns true/false based on if the value passed the regular expression test or not.
   */
  Pattern: (value, regex) => {
    if (!value) {
      return true;
    }
    return regex.test(value);
  },

  /**
   * @param {value} value the value that needs to be tested.
   * @param {value}  comparisonValue expression that the value should satisfy.
   *
   * Returns true/false based on if the value is same as comparisonValue.
   */

  CompareValue: (value, comparisonValue) => {
    return value === comparisonValue;
  },

  /**
   * @param {value} value the value that needs to be tested.
   *
   *
   * Returns true/false based on if the value is a valid RSA ID number.
   */
  RSAidnumber: (value) => {
    // the anatomy of an RSA ID Number : http://warwickchapman.com/the-anatomy-of-an-rsa-id-number
    // structure: (YYMMDD GSSS CAZ)

    var idnumber = value;

    // check that value submitted is a number
    if (isNaN(idnumber)) {
      return false;
    }

    // check length of 13 digits
    if (idnumber.length !== 13) {
      return false;
    }

    // check that YYMMDD group is a valid date
    var yy = idnumber.substring(0, 2),
      mm = idnumber.substring(2, 4),
      dd = idnumber.substring(4, 6);

    var dob = new Date(yy, mm - 1, dd);

    // check values - add one to month because Date() uses 0-11 for months
    if (
      !(
        (dob.getFullYear() + '').substring(2, 4) === yy &&
        dob.getMonth() === mm - 1 &&
        dob.getDate() === dd
      )
    ) {
      return false;
    }

    // ensure third to last digit is a 1 or a 0
    if (idnumber.substring(10, 11) > 1) {
      return false;
    }

    // ensure second to last digit is a 8 or a 9
    if (idnumber.substring(11, 12) < 8) {
      return false;
    }

    // calculate check bit (Z) using the Luhn algorithm
    var ncheck = 0,
      beven = false;

    for (var c = idnumber.length - 1; c >= 0; c--) {
      var cdigit = idnumber.charAt(c),
        ndigit = parseInt(cdigit, 10);

      if (beven) {
        if ((ndigit *= 2) > 9) ndigit -= 9;
      }

      ncheck += ndigit;
      beven = !beven;
    }

    return ncheck % 10 === 0;
  },

  PhoneNumber: (value) => {
    if (value.startsWith('0') && value.length === 10) {
      return true;
    } else if (value.startsWith('27') && value.length === 11) {
      return true;
    }
  },
  IntlPhoneNumber: (value) => isValidPhoneNumber(value),
};

validationFunctions.checkFormValidity = (form, validations) => {
  for (let key in form) {
    for (let validation of validations[key] || []) {
      if (
        !validation.disabled &&
        !validationFunctions[validation.type](form[key], validation.value)
      ) {
        return false;
      }
    }
  }
  return true;
};

export default validationFunctions;
