import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import StandardButton from '../../shared/formComponents/StandardButton/StandardButton';
import StandardInput from '../../shared/formComponents/StandardInput/StandardInput';
import StandardSelect from '../../shared/formComponents/StandardSelect/StandardSelect';
import * as coachDetailsActions from '../_redux/coachDetailActions';
import { QuestionTypes } from '../../../data/enums/QuestionTypes';
import { ValidationEnum } from '../../../data/enums/ValidationEnum';
import validationUtils from '../../../utils/validationUtils';
import { DASHBOARD } from '../../../data/RouteUrls';
import { pickFromObject } from '../../../utils/generalUtils';

const CoachInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const coachDetails = useSelector((state) => state.coachDetails);
  const { questions } = coachDetails;

  const [answers, setAnswers] = useState([]);
  const [showError, setShowError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const defaultValidation = [
    {
      type: ValidationEnum.REQUIRED,
      message: 'Company is required.',
    },
  ];

  useEffect(() => {
    dispatch(coachDetailsActions.getQuestions());
  }, []);

  useEffect(() => {
    if (questions) {
      setAnswers([...questions]);
    }
  }, [questions]);

  const submit = async (e) => {
    let data = [...answers].map((ans) =>
      pickFromObject(ans, ['answer', 'id', 'question'])
    );
    try {
      setButtonDisabled(true);
      let success = await dispatch(coachDetailsActions.answerQuestions(data));
      if (success) {
        history.push(DASHBOARD);
      }
    } catch (err) {
      console.error(err);
      setButtonDisabled(false);
    }
  };

  const handleAnsChange = (index, type) => (e) => {
    setAnswers((prev) => {
      return prev.map((an, idx) => {
        if (idx === index) {
          if (type === QuestionTypes.TEXT)
            return { ...an, answer: e.target.value };
          else if (type === QuestionTypes.DROPDOWN)
            return { ...an, answer: e.value };
        } else return an;
      });
    });
  };

  return (
    <div className="my-auto w-100 text-center px-3 p-md-3">
      <img
        src="/assets/images/logo.png"
        alt="logo"
        className="m-auto auth-side-logo"
      />
      <div className="mt-3 cs-sub-heading">
        Tell us more about your practice
      </div>
      {questions &&
        answers &&
        questions.length > 0 &&
        answers.length > 0 &&
        questions.map((question, index) => {
          const currAnswer = answers[index].answer;
          const currOnChange = handleAnsChange(index, question.type);
          if (question.type === QuestionTypes.TEXT) {
            return (
              <StandardInput
                value={currAnswer}
                onChange={currOnChange}
                className="mt-4"
                label={question.question}
                showError={showError}
                validations={defaultValidation}
              />
            );
          }
          if (question.type === QuestionTypes.DROPDOWN) {
            return (
              <StandardSelect
                value={currAnswer}
                className="mt-4"
                label={question.question}
                showError={showError}
                validations={defaultValidation}
                onChange={currOnChange}
                options={question.options}
              />
            );
          }
        })}
      <StandardButton
        className="mt-5"
        color="btn-success"
        bold={true}
        block={true}
        text="Next"
        onClick={submit}
        type="button"
        disbled={buttonDisabled}
      />
      <div className="text-center terms-conditions mt-3">
        By continuing, you're agreeing to our{' '}
        <a href="https://artofscale.io/terms-and-conditions" target="_blank">
          Customer Terms of Service
        </a>
        ,{' '}
        <a href="https://artofscale.io/privacy-policy" target="_blank">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a href="https://artofscale.io/terms-and-conditions" target="_blank">
          Cookie Policy
        </a>
        .
      </div>
    </div>
  );
};

export default CoachInfo;
