import React from 'react';
import {
  Card, OverlayTrigger,
  Tooltip as Tooltip2
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './recomm-header.scss';

const RecommHeader = ({
  recommendation,
  preActions,
  actions,
  extras,
  hideNumber,
  className,
  userProfile,
  dragHandleProps,
  cardClick = null,
}) => {
  const discName = recommendation?.dName?.toLowerCase()?.split(' ')[0];
  const disciplineCategoryClassName = `discipline-category ${discName}`;

  return (
    <Card.Header
      className={`card-header ${className ? className : ''} ${cardClick ? 'cursor-pointer' : ''
        }`}
      {...dragHandleProps}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        cardClick();
      }}
    >
      <div className="w-100 d-flex justify-content-between align-items-center flex-column flex-md-row">
        {extras ? extras : null}
        {preActions ? <div className="pre-actions">{preActions}</div> : null}
        {hideNumber ? null : (
          <div className="d-flex flex-column justify-content-center">
            <div className="me-3 mt-1 recom-number align-self-start">
              {parseInt(recommendation?.index) + 1}
            </div>
          </div>
        )}
        <div className="d-flex flex-column mb-3 mb-md-0" style={{ flex: 1 }}>
          <div className="cs-sub-heading mb-3">
            {recommendation?.praName}
            {recommendation?.isCrisis ? (
              <OverlayTrigger
                overlay={
                  <Tooltip2>Potential Crisis - address with priority</Tooltip2>
                }
                placement="bottom"
              >
                <span className="material-icons ms-2 crisis-flag">
                  report_problem
                </span>
              </OverlayTrigger>
            ) : null}
          </div>
          <div className="d-flex">
            <div className={`${disciplineCategoryClassName} discipline me-3`}>
              {recommendation?.dName}
            </div>
            {recommendation?.sdName ? (
              <div
                className={`${disciplineCategoryClassName} sub-discipline me-3`}
              >
                {recommendation?.sdName}
              </div>
            ) : null}
            {recommendation?.ssdName ? (
              <div
                className={`${disciplineCategoryClassName} sub-sub-discipline me-3`}
              >
                {recommendation?.ssdName}
              </div>
            ) : null}
          </div>
        </div>
        {recommendation?.practiceOwnerName !== "" ? <div
          style={{
            background: "#f9f9f9",
            borderRadius: "100px",
          }}
          className="d-flex px-3 py-2 align-items-center justify-content-center me-2"
        >
          {<span>
            <OverlayTrigger
              overlay={
                <Tooltip2 className="text-primary bg-white">
                  Owner: {recommendation?.practiceOwnerName}
                </Tooltip2>
              }
              placement="bottom"
            >
              <span class="name">
                <span className="initials text-primary-new">
                  {recommendation?.practiceOwnerName[0].toUpperCase() +
                    recommendation?.practiceOwnerName[
                      recommendation?.practiceOwnerName.indexOf(" ") !==
                        -1
                        ? recommendation?.practiceOwnerName.indexOf(
                          " "
                        ) + 1
                        : 0
                    ].toUpperCase()}
                </span>
              </span>
            </OverlayTrigger>
          </span>}
          {userProfile?.roleName == "CEO" && (
            <img
              src="/assets/images/editIcon.svg"
              height={16}
              width={16}
              alt="Edit"
              className={`cursor-pointer ${recommendation?.practiceOwnerName !== "" ? "ms-2" : "ms-0"}`}
            />
          )}
        </div> : null}
        {actions ? <div className="actions">{actions}</div> : null}
      </div>
    </Card.Header>
  );
};

export default RecommHeader;
