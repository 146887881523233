import { getCookie } from './cookieUtils';
import {
  ACCESS_TOKEN_COOKIE_KEY,
  ADMIN_ACCESS_TOKEN_COOKIE_KEY,
} from '../data/enums/TokenEnums';
import axios from 'axios';

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const fileResult = fileReader.result;
      resolve({
        file: fileResult.split('base64,')[1],
        fileType: file.type.split('/')[1],
        fileName: file.name,
      });
    };
    fileReader.onerror = (error) => reject(error);
  });
}

export async function getFileAsBlob(url, callback) {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${
          getCookie(ACCESS_TOKEN_COOKIE_KEY) ??
          getCookie(ADMIN_ACCESS_TOKEN_COOKIE_KEY)
        }`,
      },
      onDownloadProgress: (progressEvent) => {
        if (callback) callback(progressEvent);
      },
    });
    const file = new Blob([response.data], { type: response.data.type });
    const objectUrl = URL.createObjectURL(file);
    return {
      url : objectUrl ||null,
      name : response.headers["file-name"]
    } ;
  } catch (e) {
    return false;
  }
}

export function downloadFile(url, name = null) {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name || '';
  document.body.appendChild(a);
  a.click();
}

export async function downloadFileAsBlob(url, callback, name = null) {
  const blobUrl = await getFileAsBlob(url, callback);
  downloadFile(blobUrl.url, blobUrl.name || name);
  window.URL.revokeObjectURL(blobUrl);
}
