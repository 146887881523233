export const XrayURLs = {
  LATEST_SCORE: "/api/xray/coach/{coachId}/score/user/{userId}",
  SUB_DISCIPLINE_PRACTICES:
    "/api/xray/practice/score/user/{userId}/sub-discipline/{subDisciplineId}",
  SUB_SUB_DISCIPLINE_PRACTICES:
    "/api/xray/practice/score/user/{userId}/sub-sub-discipline/{subSubDisciplineId}",
  DIVERGENCE: "/api/xray/coach/{coachId}/team-divergence/{type}/user/{userId}",
  DIVERGENCE_DISCIPLINE:
    "/api/xray/coach/{coachId}/team-divergence/{type}/user/{userId}/discipline/{disciplineId}",
  RECOMMENDATIONS: "/api/xray/recommendation/coach/{coachId}/user/{userId}",
  RECOMMENDATIONS_DISCIPLINE:
    "/api/xray/recommendation/coach/{coachId}/user/{userId}/discipline/{disciplineId}",
  COACH_RECOMMENDATIONS:
    "/api/xray/recommendation/edit/coach/{coachId}/user/{userId}",
  UPDATE_COACH_RECOMMENDATIONS: "/api/xray/recommendation/edit",
  UPDATE_READ_STATUS: "/api/coach/coach-status/{coachId}/user/{userId}",
  UPDATE_READ_STATUS_DISCIPLINE:
    "/api/coach/read/{coachId}/snapshot/{snapshotId}/discipline/{disciplineId}",
  UPDATE_COACH_NOTES: "/api/xray/recommendation/edit/custom-recomm",
  PUBLISH_XRAY_RESULTS: "/api/xray/coach/{coachId}/publish/user/{userId}",
  GET_SURVEY_MEMBERS: "/api/user-team-member/user",
  GET_SURVEY_PROGRESS: "api/user-team-member/progress/user/{userId}",
  GET_SURVEY_PROGRESS_FROM_NODE: "/user/surveyProgress/{surveyId}",
  GET_DISCIPLINES: "/api/open/discipline",
  UDPATE_USER_DISCIPLINE: "/api/user-discipline",
  GET_XRAY_STATUS: "/api/coach/{coachId}/user/{userId}/status",
  GET_PDF_URL: "/api/pdf/{surveyId}",
  GET_INITIATIVE: "/recomm-initiative/{practiceId}/{surveyId}/{level}",
  GET_RECOMMENDATIONS_CONTENT: "/recommendation/content/{recommendationId}",
  GET_RECOMMENDATION_HISTORY: "recomm-initiative/history/{practiceId}/{surveyId}",
  GET_RECOMMENDATION_LEVEL_BASE:
    "/recomm-initiative/previousData/{practiceId}/{surveyId}/{level}",
    GET_QUESTION_ANSWERS:
    "/recomm-initiative/question/answer/{practiceId}/{surveyId}",
};
