import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./base/ProtectedRoute";
import PublicRoute from "./base/PublicRoute";
import AuthContainer from "./components/AuthContainer/Auth";
import CoachDetails from "./components/CoachDetails/CoachDetails";
import CoachPanel from "./components/CoachPanel/CoachPanel";

import { AUTH_ROUTE, COACH_DETAILS, COACH_PANEL } from "./data/RouteUrls";
import * as actions from "./components/sharedRedux/sharedAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export function AppRoutes() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getGloassaryWords());
  }, []);
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={COACH_PANEL} />
      </Route>
      <PublicRoute path={AUTH_ROUTE} component={AuthContainer} />
      <ProtectedRoute path={COACH_PANEL} component={CoachPanel} />
      <ProtectedRoute path={COACH_DETAILS} component={CoachDetails} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
