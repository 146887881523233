import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { COURSE_OVERVIEW, STUDY_COURSE } from '../../../../../data/RouteUrls';
import { getFileAsBlob } from '../../../../../utils/fileUtils';
import StandardButton from '../../../../shared/formComponents/StandardButton/StandardButton';
import { LineProgressBar } from '../../../../shared/LineProgressBar/LineProgressBar';
import './course-card.scss';

export const CourseCard = (props) => {
  const [course, setCourse] = useState(props.course);

  // useEffect(async () => {
  //   if (course && course.file_url && !course.blob_url) {
  //     const courseCopy = { ...course };
  //     courseCopy.blob_url = await getFileAsBlob(course.file_url);
  //     courseCopy.blob_url = courseCopy.blob_url.url;
  //     setCourse(courseCopy);
  //   }
  // }, [course]);

  const getClickLink = () => {
    return COURSE_OVERVIEW.replace(':courseId', course.id);
    if (course.progress !== null) {
      if (course.progress === 100) {
        return COURSE_OVERVIEW.replace(':courseId', course.id);
      } else {
        return STUDY_COURSE.replace(':courseId', course.id);
      }
    } else {
      return COURSE_OVERVIEW.replace(':courseId', course.id);
    }
  };
  const renderFooter = () => {
    if (course.progress !== null) {
      if (course.progress === 100) {
        return (
          <Link to={COURSE_OVERVIEW.replace(':courseId', course.id)}>
            <div className="completed justify-content-center align-items-center d-flex">
              <div>Completed</div>
            </div>
          </Link>
        );
      } else {
        return (
          <Link to={STUDY_COURSE.replace(':courseId', course.id)}>
            <div className="d-flex justify-content-center align-items-center">
              <LineProgressBar
                percentage={course.progress}
                content={
                  <>
                    <div className="percentage">{course.progress}%</div>
                    <div>Complete</div>
                  </>
                }
              />
            </div>
          </Link>
        );
      }
    } else {
      return (
        <Link to={COURSE_OVERVIEW.replace(':courseId', course.id)}>
          <StandardButton
            color="btn-success"
            bold={true}
            block={true}
            text={
              <>
                <span class="name">Overview</span>
                <span className="material-icons icon-in-btn">
                  {' '}
                  chevron_right{' '}
                </span>
              </>
            }
          ></StandardButton>
        </Link>
      );
    }
  };
  return course ? (
    <>
      <Link to={getClickLink()} className="text-decoration-none" onClick={props.onClick}>
        <Card className="course-card">
          <div
            className="card-header d-flex justify-content-end"
            style={{
              backgroundImage: `url(${course.file_url || ''})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <div
              className="category"
              style={{
                backgroundColor: "white",
                color: "#333",
                fontSize: "14px",
                boxShadow: "0px 0px 4px 0px #00000040",
              }}
            >
              {course.category_name}
            </div>
          </div>
          <div className="card-body">
            <div className="title cs-sub-heading">{course.name}</div>
            <div
              className="overview"
              dangerouslySetInnerHTML={{ __html: course.overview }}
            ></div>
            <div className="info d-flex justify-content-between align-items-center">
              <div className="author">
                <span class="name">
                  <span className="material-icons me-2 icon-in-btn">
                    {' '}
                    person{' '}
                  </span>
                  {course.author}
                </span>
              </div>
            </div>
          </div>
          <div className="card-footer">{renderFooter()}</div>
        </Card>
      </Link>
    </>
  ) : null;
};
