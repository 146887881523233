import axios from "axios";
import queryString from "query-string";
import authSlice from "./authSlice";
import { errorNoti, successNoti } from "../../../base/BasicNotifications";
import { authUrls } from "./authUrls";
import {
  getCurrentPlatform,
  getErrorMessage,
  pickFromObject,
} from "../../../utils/generalUtils";
import { getCookie } from "../../../utils/cookieUtils";
import {
  ADMINS_COACH_ID_COOKIE_KEY,
  ADMIN_ACCESS_TOKEN_COOKIE_KEY,
  ACCESS_TOKEN_COOKIE_KEY,
} from "../../../data/enums/TokenEnums";

const { actions } = authSlice;

const instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

export const setAuthEmail = (email) => (dispatch) => {
  dispatch(
    actions.setEmail({
      email,
    })
  );
};

export const login = (data) => (dispatch) => {
  return instance
    .post(authUrls.COACH_LOGIN, data)
    .then((response) => {
      successNoti("Login Successful");
      dispatch(setAuthEmail(data.email));
      dispatch(
        actions.setAccessToken({
          accessToken: response.data.data.accessToken,
        })
      );
      return true;
    })
    .catch((err) => {
      errorNoti(err.response.data.message);
      throw new Error(err);
    });
};

export const signup = (data, isFreeAccess) => (dispatch) => {
  return instance
    .post(
      isFreeAccess ? authUrls.COACH_FREE_ACCESS_SIGNUP : authUrls.COACH_SIGNUP,
      data
    )
    .then((response) => {
      dispatch(setAuthEmail(data.email));
      dispatch(
        actions.setAccessToken({
          accessToken: response.data.data.accessToken,
        })
      );
      return true;
    })
    .catch((err) => {
      errorNoti(err.response.data.message);
      throw new Error(err);
    });
};

export const checkEmail = (data) => (dispatch) => {
  let url = authUrls.CHECK_EMAIL + "?" + queryString.stringify(data);
  return new Promise((resolve, reject) => {
    instance
      .get(url, data)
      .then((response) => {
        dispatch(setAuthEmail(data.email));
        resolve(true);
      })
      .catch((err) => {
        dispatch(setAuthEmail(data.email));
        resolve(false);
      });
  });
};

export const validateInviteCode = (inviteCode) => (dispatch, getState) => {
  let data = { inviteCode };
  const url = authUrls.VALIDATE_INVITE_CODE + "?" + queryString.stringify(data);
  return instance
    .get(url)
    .then((response) => {
      const coachDetails = pickFromObject(response.data.data, [
        "email",
        "firstName",
        "lastName",
        "id",
        "coachId",
      ]);
      dispatch(actions.setSignupDetails({ signupDetails: coachDetails }));
      return true;
    })
    .catch((err) => {
      errorNoti("Invalid Invite Code. Please check with admin");
      return false;
    });
};

export const changePassword = (data) => (dispatch, getState) => {
  return instance
    .post(
      authUrls.CHANGE_PASSWORD,
      { ...data, email: getState().authDetails.email },
      {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      }
    )
    .then((response) => {
      successNoti("Password updated!");
      return true;
    })
    .catch((err) => {
      const errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(err);
    });
};

export const forgetPassword = (data) => (dispatch) => {
  let url = authUrls.FORGET_PASSWORD + "?" + queryString.stringify(data);
  return instance
    .post(url)
    .then((response) => {
      successNoti("Reset password link is sent to your Email ID");
      return true;
    })
    .catch((err) => {
      const errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(err);
    });
};

export const resetPassword = (data) => (dispatch) => {
  const url = authUrls.RESET_PASSWORD.replace("{resetCode}", data.resetCode);
  return instance
    .post(url, pickFromObject(data, ["email", "password"]))
    .then((response) => {
      successNoti("Password updated successfully!");
      dispatch(
        actions.setAccessToken({
          accessToken: response.data.data.accessToken,
        })
      );
      return true;
    })
    .catch((err) => {
      const errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(err);
    });
};

export const generateOTP = (data) => (dispatch, getState) => {
  let url = authUrls.GENERATE_OTP + "?" + queryString.stringify(data);
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return true;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const validateOTP = (data) => (dispatch, getState) => {
  let url = authUrls.VALIDATE_OTP + "?" + queryString.stringify(data);
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      return true;
    })
    .catch((err) => {
      errorNoti("Invalid OTP.");
      throw new Error(err);
    });
};

export const getUserDetails = () => (dispatch, getState) => {
  let accessToken = getCookie(ACCESS_TOKEN_COOKIE_KEY);
  let adminAccessToken = getCookie(ADMIN_ACCESS_TOKEN_COOKIE_KEY);
  let adminsCoachId = getCookie(ADMINS_COACH_ID_COOKIE_KEY);
  let isAdminDetailSufficient = adminAccessToken && adminsCoachId;
  let url =
    !accessToken && isAdminDetailSufficient
      ? authUrls.ADMIN_COACH_DETAILS.replace("{coachId}", adminsCoachId)
      : authUrls.USER_DETAILS;
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      dispatch(actions.setUserProfile(response.data.data));
      dispatch(setAuthEmail(response.data.data.email));
      try{

      
      window.userGuiding.identify(response.data.data.id, {
        name: response.data.data.firstName + " " + response.data.data.lastName,
        email: response.data.data.email,
        created_at: 123456789,
        user_type: "Coach",
        platform: getCurrentPlatform(),
        access_type : response.data.data.demoAccess ? 'Demo' : 'Full'
      });
    }catch(err){
      
    }
      return response.data.data;
    })
    .catch((err) => {
      dispatch(actions.removeAccessToken());
      throw new Error(err);
    });
};

export const updateUserDetails = (data) => (dispatch, getState) => {
  let url = authUrls.UPDATE_USER_DETAILS;
  return instance
    .put(url, data, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      successNoti("Profile updated.");
      dispatch(actions.setUserProfile(data));
      return true;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const logout = () => (dispatch) => {
  dispatch(actions.removeAccessToken());
};
