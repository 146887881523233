import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { VIEW_RECOMMENDATION_HISTORY, XRAY, XRAY_SCORE, XRAY_USER_SCORE } from '../../../data/RouteUrls';
import AssociatedContacts from './AssociatedContacts/AssociatedContacts';
import { RecommendationHistory } from './Score/RecommendationHistory/RecommendationHistory';
import Score from './Score/Score';
import './xray.scss';

const Xray = () => {
  return (
    <div className="xray-container h-100">
      <Switch>
        <Route exact path={XRAY} component={AssociatedContacts} />
        <Route exact path={XRAY_SCORE}>
          <Redirect to={XRAY} />
        </Route>
        <Route path={XRAY_USER_SCORE} component={Score} />
        <Route path={VIEW_RECOMMENDATION_HISTORY} component={RecommendationHistory} />
        <Route path="/*">
          <Redirect to={XRAY} />
        </Route>
      </Switch>
    </div>
  );
};

export default Xray;
