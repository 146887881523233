import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './redux/store';
import { toast } from 'react-toastify';
import { AppRoutes } from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { HeaderContext } from './HeaderContext';
import "react-loading-skeleton/dist/skeleton.css";
import { ConditionalRender } from './components/shared/ConditionalRender/ConditionalRender';
import RedirectionPopup from './components/shared/RedirectionPopup/RedirectionPopup';
import { successNoti } from './base/BasicNotifications';

toast.configure();

function App() {
  const [headerMetadata, setHeaderMetadata] = useState({
    headerTitle: '',
    HeaderActions: () => null,
  });
  return (
    <Provider store={store}>
      <HeaderContext.Provider value={{ headerMetadata, setHeaderMetadata }}>
        <Router>
          <main className="App h-100">
            {/* <RebrandingPopup /> */}
            <AppRoutes />
          </main>
        </Router>
      </HeaderContext.Provider>
      <ConditionalRender condition={window.location.href.includes("nxt-9.com")}>
        <RedirectionPopup />
      </ConditionalRender>
    </Provider>
  );
}

export default App;
