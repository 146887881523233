export const colors = {
  'primary-color': '#ffa400',
  'base-success': '#08B3C3',
  'base-primary': '#056C75',
  'base-danger': '#f45b05',
  'base-dark': '#2b2a2a',
  'base-light': '#b5cded',
  'base-secondary': '#c3bbad',
  'base-background': 'lightgrey',
  'base-border': '#7B88A5',
  "quest-green": "#D1FFBB",
  "quest-orange": "#FFF6A6",
  "quest-red": "#FFC8C8",
};