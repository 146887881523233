import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChapterIcons } from "../../../../../data/enums/ChapterIcons";
import { COURSE_OVERVIEW, STUDY_COURSE } from "../../../../../data/RouteUrls";
import StandardButton from "../../../../shared/formComponents/StandardButton/StandardButton";
import { LineProgressBar } from "../../../../shared/LineProgressBar/LineProgressBar";
import "./course-detail-card.scss";
import { getFileAsBlob } from "../../../../../utils/fileUtils";
import UpgradeConfirmationPopup from "../../../../shared/UpgradeConfirmationPopup/UpgradeConfirmationPopup";

const CourseDetailCard = (props) => {
  // const course = props.course;
  const [course, setCourse] = useState(props.course);
  const [upgradePopup, setUpgradePopup] = useState(false);

  // useEffect(async () => {
  //   if (course && course.file_url && !course.blob_url) {
  //     const courseCopy = { ...course };
  //     courseCopy.blob_url = await getFileAsBlob(course.file_url);
  //     courseCopy.blob_url = courseCopy.blob_url.url;
  //     setCourse(courseCopy);
  //   }
  // }, [course]);

  const [expandedIndex, setExpandedIndex] = useState(
    course.initialSectionIndex
  );
  const updateExpandedIndex = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const renderChapters = (chapters) => {
    let chaptersCard = [];

    for (let chapter of chapters) {
      let progressIcon;
      let chapter_type = chapter.chapter_type;
      let classes = "chapter d-flex align-items-center";
      if (chapter.is_completed) {
        progressIcon = (
          <div className="progress-icon completed">
            <span class="material-icons">check_circle</span>
          </div>
        );
      } else if (chapter.is_current) {
        progressIcon = (
          <div className="progress-icon current">
            <span class="material-icons">adjust</span>
          </div>
        );
        classes += " active";
        chapter_type += "_ACTIVE";
      } else {
        progressIcon = (
          <div className="progress-icon blank">
            <span class="material-icons">radio_button_unchecked</span>
          </div>
        );
      }

      let chapterDesign = (
        <Link
          to={
            course.progress === null
              ? null
              : STUDY_COURSE.replace(":courseId", course.id) +
              "?chapter=" +
              chapter.id
          }
        >
          <div className={classes}>
            <div>{progressIcon}</div>
            <div className="ms-3">
              <img src={ChapterIcons[chapter_type]} alt="chapter type" />
            </div>
            <div className="ms-3">{chapter.name}</div>
          </div>
        </Link>
      );
      chaptersCard.push(chapterDesign);
    }
    return chaptersCard;
  };

  const renderSections = () => {
    let sections = [];
    let index = 0;
    for (let section of course.sections) {
      section.index = index;
      let progressIcon;
      let classes = "section d-flex align-items-center justify-content-between";
      if (section.is_completed) {
        progressIcon = (
          <div className="progress-icon completed">
            <span class="material-icons">check_circle</span>
          </div>
        );
      } else if (section.is_current) {
        progressIcon = (
          <div className="progress-icon current">
            <span class="material-icons">adjust</span>
          </div>
        );
        classes += " active";
      } else {
        progressIcon = (
          <div className="progress-icon blank">
            <span class="material-icons">radio_button_unchecked</span>
          </div>
        );
      }
      let sectionDesign = (
        <>
          <div
            className={classes}
            onClick={() => updateExpandedIndex(section.index)}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>{progressIcon}</div>
              {/* <div className="ms-3">
                <img
                  src={sectionIcons[section.section_type]}
                  alt="section type"
                />
              </div> */}
              <div className="ms-3 font-weight-bold">{section.name}</div>
            </div>
            <div>
              <StandardButton
                className="d-flex align-items-center justify-content-center expand-btn ms-auto"
                color="btn-outline-dark"
                bold={true}
                icon={
                  section.index === expandedIndex ? (
                    <span className="material-icons">expand_less</span>
                  ) : (
                    <span className="material-icons">expand_more</span>
                  )
                }
                text={section.index === expandedIndex ? "Collapse" : "Expand"}
              ></StandardButton>
            </div>
          </div>
          {section.index === expandedIndex ? (
            <div className="chapter-container">
              {renderChapters(section.chapters)}
            </div>
          ) : null}
        </>
      );
      sections.push(sectionDesign);
      index += 1;
    }
    return sections;
  };

  const getButtonText = () => {
    if (course.progress === null) {
      return "Enrol";
    } else {
      return "Continue";
    }
  };

  const renderUpgradePlanPopup = () => {
    if (upgradePopup) {
      return (
        <UpgradeConfirmationPopup
          title={"Upgrade"}
          content={
            <>
              Talk to us to unlock access to this course, it’s free!
            </>
          }
          closePopup={() => setUpgradePopup(false)}
          isOpen={upgradePopup}
          upgradePopup={false}
        />
      );
    }
  };

  const enrollButton = (
    <StandardButton
      color="btn-success btn-sm"
      bold={true}
      text={getButtonText()}
      onClick={() => {
        props.demoAccess ? setUpgradePopup(true) : props.enrollCourse();
      }}
      style={{ minWidth: "100px" }}
    ></StandardButton>
  );

  const renderFooter = () => {
    if (course.progress !== null) {
      return (
        <Link to={STUDY_COURSE.replace(":courseId", course.id)}>
          <div className="d-flex justify-content-right align-items-center">
            <LineProgressBar
              percentage={course.progress}
              content={
                <>
                  <div className="percentage">{course.progress}%</div>
                  <div>Complete</div>
                </>
              }
            />
          </div>
        </Link>
      );
    } else {
      return enrollButton;
    }
  };

  return course ? (
    <>
      <Card className="course-detail-card w-100">
        <div
          className="card-header d-flex justify-content-end align-items-end p-3"
          style={{
            backgroundImage: `url(${course.file_url})`,
          }}
        >
          <div className="progress-container">{renderFooter()}</div>
        </div>
        <div className="card-body">
          <div className="title cs-sub-heading">{course.name}</div>
          <div
            className="course-overview"
            dangerouslySetInnerHTML={{ __html: course.overview }}
          ></div>
          <div className="info d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <div className="d-flex align-items-center">
              <div
                className="category mt-2 mt-sm-0"
                style={{
                  backgroundColor: "white",
                  color: "#333",
                  fontSize: "14px",
                  boxShadow: "0px 0px 4px 0px #00000040",
                }}
              >
                {course.category_name}
              </div>
              <div className="author ms-3">
                <div class="name">
                  <span
                    className="material-icons me-2 position-relative"
                    style={{ top: "7px" }}
                  >
                    {" "}
                    person{" "}
                  </span>
                  {course.author}
                </div>
              </div>
            </div>
            <div>{enrollButton}</div>
          </div>
          <div className="sections">
            <div className="cs-sub-heading">Course Overview</div>
            <div>{renderSections()}</div>
          </div>
        </div>
      </Card>
      {renderUpgradePlanPopup()}
    </>
  ) : null;
};

export default CourseDetailCard;
