import React from 'react';
import { Card, CardActionArea, CardContent } from '@mui/material';
import './contacts.scss';

const ContactCard = ({ cardActionOnClick, contact }) => {
  return (
    <Card className="contact-card">
      <CardActionArea onClick={cardActionOnClick}>
        <CardContent className="row">
          <div className="d-flex col-12 col-lg-3 mt-2 mt-lg-0 ">
            <img src="/assets/images/coach_contact.svg" alt="User" />
            <div className="ms-3 field">
              <div className="label">Company</div>
              <div className="value">
                <img
                  src="/assets/images/company_icon.svg"
                  alt="Company"
                  className="me-2"
                />
                {contact.companyName || <i>NA</i>}
              </div>
            </div>
          </div>
          <div className="field col-12 col-lg-3 mt-0">
            <div className="label">Name</div>
            <div className="value">
              {contact.firstName + ' ' + contact.lastName}
            </div>
          </div>
          <div className="field col-12 col-lg-4 mt-2 mt-lg-0">
            <div className="label">Email ID</div>
            <div className="value">
              <span className="material-icons me-2">email</span>
              {contact.email}
            </div>
          </div>
          <div className="field col-12 col-lg-2 mt-2 mt-lg-0">
            <div className="label">Contact No.</div>
            <div className="value">
              <span className="material-icons me-2">phone_android</span>

              {contact.phoneNo || 'NA'}
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ContactCard;
