import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  feed: [],
};

const dashboardSlice = createSlice({
  name: 'dashboardDetails',
  initialState,
  reducers: {
    setFeed: (state, action) => {
      state.feed = action.payload;
    },
  },
});

export default dashboardSlice;
