import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  AUTH_ROUTE,
  CONTACTS,
  OTP_ROUTE,
  XRAY,
  DASHBOARD,
  FEED,
  ACADEMY,
  COACH_DETAILS,
  QUEST,
} from '../../data/RouteUrls';
import * as authActions from '../AuthContainer/_redux/authAction';
import * as coachDetailActions from '../CoachDetails/_redux/coachDetailActions';
import LoadingAnimation from '../../base/LoadingAnimation';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import './coach-panel.scss';
import Contacts from './Contacts/Contacts';
import Dashboard from './Dashboard/Dashboard';
import Xray from './Xray/Xray';
import { DashboardContainer } from '../shared/DashboardContainer/DashboardContainer';
import { Header } from './Header/Header';
import Footer from './Footer/Footer';
import { Academy } from './Academy/Academy';
import StandardButton from '../shared/formComponents/StandardButton/StandardButton';
import SupportPopup from '../shared/SupportPopup/SupportPopup';
import { FloatingButton } from '../shared/FloatingButton/FloatingButton';
import { Quest } from './Quest/Quest';

function WithContainer(props) {
  return (
    <Switch>
      <Route path={props.expandOn}>
        <DashboardContainer expand>{props.children}</DashboardContainer>
      </Route>
      <Route path="/*">
        <DashboardContainer>{props.children}</DashboardContainer>
      </Route>
    </Switch>
  );
}

const CoachPanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;
  const pathnameSplit = pathname.split('/');

  const [showLoader, setShowLoader] = useState(true);
  const [showSupportPopup, setShowSupportPopup] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const userProfile = useSelector((state) => state.authDetails.userProfile);

  const toggleSidebar = () => setSidebarCollapsed((prev) => !prev);

  const checkForUserProfile = async () => {
    const uspr = await dispatch(authActions.getUserDetails());
    if (!uspr.emailVerified) {
      dispatch(authActions.generateOTP({ email: uspr.email }));
    }
    const coachProfile = await dispatch(coachDetailActions.getCoachProfile());
    if (!coachProfile.companyName) {
      history.push(COACH_DETAILS);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    checkForUserProfile();
  }, []);

  if (showLoader) return <LoadingAnimation />;
  else if (!userProfile) history.push(AUTH_ROUTE);
  else if (!userProfile.emailVerified) history.push(OTP_ROUTE);
  return (
    <div className="d-flex h-100 coach-panel w-100">
      <SupportPopup visible={showSupportPopup} hidePopup={() => setShowSupportPopup(false)} />
      <ProSidebar
        breakPoint="md"
        toggled={sidebarCollapsed}
        onToggle={toggleSidebar}
      >
        <SidebarHeader className="text-center position-relative">
          <Link to={DASHBOARD}>
            <img
              src="/assets/images/logo.png"
              alt="logo"
              className="mx-auto my-5 main-logo"
            />
          </Link>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square" className="flex-fill">
            <MenuItem
              active={pathnameSplit[2] === 'x-ray' || pathnameSplit[2] === 'quest'}
              icon={<div className="cs-icon dashboard"></div>}
              onClick={() => setSidebarCollapsed(false)}
            >
              <Link to={XRAY}>Coach Dashboard</Link>
            </MenuItem>
            <MenuItem
              active={pathnameSplit[2] === 'contacts'}
              icon={<div className="cs-icon contacts"></div>}
              onClick={() => setSidebarCollapsed(false)}
            >
              <Link to={CONTACTS}>Clients</Link>
            </MenuItem>
            <MenuItem
              active={pathnameSplit[2] === 'scaling-resources'}
              icon={<div className="cs-icon academy"></div>}
              onClick={() => setSidebarCollapsed(false)}
            >
              <Link to={ACADEMY}>Scaling Resources</Link>
            </MenuItem>
            <MenuItem
              icon={<div className="cs-icon support"></div>}
              onClick={() => { setSidebarCollapsed(false); setShowSupportPopup(true) }}
            >
              <Link>User Support</Link>
            </MenuItem>
            {/* <MenuItem onClick={() => setSidebarCollapsed(false)}>
              <a href="/knowledge-guide.html" target="_blank">
                <StandardButton
                  color="btn-success guide-btn w-100"
                  bold={true}
                  text={
                    <>
                      <img
                        src="/assets/images/guide.svg"
                        alt="guide"
                        className="me-3"
                      />
                      Guide
                    </>
                  }
                />
              </a>
            </MenuItem> */}
          </Menu>
        </SidebarContent>
      </ProSidebar>
      <div className="flex-fill" style={{ overflow: 'auto' }}>
        <div
          className="content-container d-flex flex-column"
          id="content-container"
        >
          <Header setSidebarCollapsed={setSidebarCollapsed} />
          <WithContainer expandOn={[]}>
            <Switch>
              <Route path={CONTACTS} component={Contacts} />
              <Route path={[DASHBOARD, XRAY]} component={Xray} />
              <Route path={QUEST} component={Quest} />
              <Route path={ACADEMY} component={Academy} />
              <Redirect path="/*" to={DASHBOARD} />
            </Switch>
          </WithContainer>
          {/* <Footer /> */}
        </div>
      </div>
      <FloatingButton />
    </div>
  );
};

export default CoachPanel;
