import classNames from 'classnames';
import React, { useState } from 'react'
import { StandardCircularProgressBar } from "../../../shared/StandardCircularProgressBar/StandardCircularProgressBar";
import {ScorePopup} from './ScorePopup';
import StandardTable from '../../../shared/StandardTable/StandardTable';

export const ScoreStats = ({ scoreData, questData }) => {

  const [showScorePopup, setShowScorePopup] = useState();

  const renderScoreStat = (isEmpty, score) => {
    let imagePath = "";
    let scoreDiff = score?.difference === null ? "-" : score?.difference + "%";
    let textClasses = classNames("font-weight-normal dark-grey extra-small", {
      "light-text": isEmpty,
    });
    if (score?.difference > 0) {
      imagePath = "/assets/images/score_up.svg";
    } else if (score?.difference < 0) {
      imagePath = "/assets/images/score_down.svg";
    } else {
      imagePath = "/assets/images/score_up_light.svg";
    }
    return (
      <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex">
          <img src={imagePath} alt="score" className="me-2" />
          <div className={textClasses}>
            {isEmpty ? " 0.0%" : ` ${scoreDiff}`}
          </div>
        </div>
        <div className="light-text extra-small">vs last week</div>
      </div>
    );
  };

  const getQuestScoreColor = (percantage) => {
    if (percantage == 0) {
      return "#878B9D";
    }
    if (percantage < 25) {
      return "#FFC8C8";
    } else if (percantage < 70) {
      return "#FFA95C";
    } else {
      return "#08B3C3";
    }
  };
  const renderScoreStats = (score, type) => {
    let rank =
      type == "Team" ? score.ranks?.companyRank : score.ranks?.userRank || 0;
    rank = rank ? rank : "-";
    return (
      <div>
        <div>
          <StandardCircularProgressBar
            radius={50}
            thresholdValue={score?.threshold}
            progress={score?.score ?? "-"}
            strokeWidth={10}
            strokeColor={getQuestScoreColor(score?.score)}
            trackStrokeWidth={6}
            pointerRadius={6}
            pointerStrokeWidth={1}
            pointerStrokeColor="#000"
            scoreType={type}
            textColor={getQuestScoreColor(score?.score)}
          />
        </div>
        {renderScoreStat(undefined, score)}
        <div className="d-flex justify-content-center align-items-center extra-small">
          <img
            src="/assets/images/score_rank_trophy.svg"
            className="me-2"
            alt="score_trophy"
            height={18}
            width={18}
          />
          <span className="dark-grey">
            Rank{" "}
            <span className="font-weight-bold">
              {rank === null ? "-" : rank}
            </span>
          </span>
        </div>
        <div
          onClick={() => setShowScorePopup(type)}
          className="light-text extra-small text-decoration-underline cursor-pointer"
        >
          View All
        </div>
      </div>
    );
  };
  const otherTeamScoreColumns = [
    { title: "Rank", dataField: "rank" },
    { title: "Team Name", dataField: "companyName" },
    { title: "Team Score", dataField: "score", colored: true },
  ];
  const otherMemberScoreColumns = [
    { title: "Rank", dataField: "rank" },
    { title: "Member Name", dataField: "name" },
    { title: "Member Score", dataField: "score", colored: true },
  ];
  const renderScorePopups = () => {
    if (showScorePopup === "Me") {
      return (
        <ScorePopup
          heading="Member Rankings"
          data={scoreData.myScore.ranks.allRanks}
          columns={otherMemberScoreColumns}
          closePopup={() => setShowScorePopup("")}
        />
      );
    } if (showScorePopup === "Team") {
      return (
        <ScorePopup
          heading="Team Rankings"
          data={scoreData.teamScore.ranks.allRanks}
          columns={otherTeamScoreColumns}
          closePopup={() => setShowScorePopup("")}
        />
      );
    }
  };
  const renderEmptyScoreStats = (type, score) => {
    let rank = type == "" ? "-" : score?.rank;
    return (
      <div>
        <div>
          <StandardCircularProgressBar
            radius={50}
            thresholdValue={90}
            progress={"-"}
            strokeWidth={10}
            strokeColor={getQuestScoreColor(score?.score)}
            trackStrokeWidth={6}
            pointerRadius={6}
            pointerStrokeWidth={1}
            pointerStrokeColor="#000"
            scoreType={type}
            textColor={getQuestScoreColor(score?.score)}
          />
        </div>
        {renderScoreStat(true)}
        <div className="d-flex justify-content-center align-items-center extra-small">
          <img
            src="/assets/images/score_rank_trophy.svg"
            className="me-2"
            alt="score_trophy"
            height={18}
            width={18}
          />
          <span>
            Rank <span className="font-weight-bold">{rank}</span>
          </span>
        </div>
        <span className="light-text extra-small text-decoration-underline cursor-pointer">
          View All
        </span>
      </div>
    );
  };
  if (
    !questData.hbSetupCompleted ||
    !questData.actionStarted ||
    !questData.scoreGenerated
  ) {
    return (
      <div className=" my-3 d-flex flex-column justify-content-center align-content-center">
        {renderEmptyScoreStats("", scoreData.myScore)}
        {renderEmptyScoreStats("", scoreData.teamScore)}
      </div>
    );
  }
  return (
    <div>{renderScoreStats(scoreData?.myScore, "Me")}
      {renderScoreStats(scoreData?.teamScore, "Team")}
      {showScorePopup? renderScorePopups():null}
    </div>
  )
}
