import React, { useEffect } from "react";
import StandardButton from "../../../shared/formComponents/StandardButton/StandardButton";
import * as questActions from "../_redux/QuestActions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { SkeletonLoadingAnimation } from "../../../../base/LoadingAnimation";
import "./QuestActionsCard.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { StandardTooltip } from "../../../shared/StandardTooltip/StandardTooltip";

export const QuestActionsCard = (props) => {
  const showResponsive = useMediaQuery({ query: "(max-width: 1181px)" });

  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useSelector((state) => state.authDetails.userProfile);
  const [isLoading, setIsLoading] = useState(true);
  const [actionsData, setActionsData] = useState([]);
  const [showWcmPopup, setShowWcmPopup] = useState(false);
  const [actionStarting, setActionStarting] = useState(false);
  const [actionStartPopupData, setActionStartPopupData] = useState({
    show: false,
    actionsData: {},
  });
  const [wcmDay, setWcmDay] = useState();

  useEffect(() => {
    handleGetActionPanelData();
  }, []);

  const handleGetActionURLs = (slug) => {
    switch (slug) {
      case "daily_goals":
        return "start_daily_action";
      case "weekly_goals":
        return "start_wrap_action";
      case "weekly_meeting":
        return "start_wcm_action";
      case "quarterly_okr":
        return "start_q_okr_action";
      case "quarterly_goals":
        return "start_qrap_action";
      default:
        return null;
    }
  };

  const handleGetActionPanelData = async () => {
    try {
      const resp = await dispatch(questActions.getActionPanelData(props.smeId));
      let tempArr = [];
      if (resp) {
        resp.actions.map((action, index) => {
          action.imageName = action.slug;
          action.actionsURL = handleGetActionURLs(action.slug);
          tempArr.push(action);
        });
        setActionsData(tempArr);
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleShowNotStartedByCEOText = (data) => {
    if (!props.isHBCeo && !data.activated) {
      return (
        <div className="d-flex justify-content-end align-items-center light-text ms-auto">
          Not active{" "}
        </div>
      );
    } else {
      return <div className="mb-4"></div>;
    }
  };

  const handleStartAction = (data) => {
    if (!props.questData.questMember) {
      return null;
    }
    if (data.action_slug === "view_details") {
      if (data.slug === "weekly_meeting") {
        history.push({ pathname: data.navigationUrl, search: `?tab=wcm` });
      } else {
        history.push(data.navigationUrl);
      }
    }
    if (data.action_slug === "wrap_popup") {
      setActionStartPopupData({ show: true, actionData: data });
    } else {
      if (data.action_slug === "start") {
        if (data.slug === "weekly_meeting") {
          history.push({ pathname: data.navigationUrl, search: `?tab=wcm` });
        } else {
          if (
            data.slug === "daily_goals" &&
            data.action_slug === "view_details"
          ) {
            history.push({
              pathname: data.navigationUrl,
              search: `?tab=my-daily-1-goals`,
            });
          } else {
            history.push(data.navigationUrl);
          }
        }
      } else {
        if (props.isHBCeo) {
          setActionStartPopupData({ show: true, actionData: data });
        } else {
          return null;
        }
      }
    }
  };

  const handleDisableStartAction = (data) => {
    if (!props.questData.questMember) {
      return true;
    }
    if (data.action_slug === "activate") {
      if (props.isHBCeo) {
        return false;
      } else {
        return true;
      }
    } else if (data.action_slug === "wrap_popup") {
      if (props.isHBCeo) {
        return false;
      } else {
        return true;
      }
    } else {
      if (data.action_slug === "start") {
        return false;
      }
    }
  };

  const renderCTA = (data) => {
    switch (data.slug) {
      case "daily_goals":
        if (data.action_slug === "activate") {
          return "Activate";
        } else if (data.action_slug === "start") {
          return "Complete Set-up";
        } else {
          return "View Details";
        }
      case "weekly_goals":
        if (data.action_slug === "activate") {
          return "Activate";
        } else if (data.action_slug === "start") {
          return "Start WRAP";
        } else {
          return "View Details";
        }
      case "weekly_meeting":
        if (
          data.action_slug === "activate" ||
          data.action_slug === "wrap_popup"
        ) {
          return "Activate";
        } else if (data.action_slug === "start") {
          if (data.show_join) {
            return "Join";
          } else {
            if (
              userProfile.roleName === "CEO" ||
              userProfile.habitBuilderRole === "CEO"
            ) {
              return "Conduct";
            } else {
              return "Join";
            }
          }
        } else {
          return "View Details";
        }
      case "quarterly_okr":
        if (data.action_slug === "activate") {
          return "Activate";
        } else if (data.action_slug === "start") {
          return "Start Q-OKR";
        } else {
          return "View/Edit Q-OKR";
        }
      case "quarterly_goals":
        if (data.action_slug === "activate") {
          return "Activate";
        } else if (data.action_slug === "start") {
          return "Start QRAP";
        } else {
          return "View/Edit QRAP";
        }
      default:
        return null;
    }
  };

  const renderResponsiveLayoutForActionPanel = (data, isEmpty) => {
    let classes = classNames(
      "d-flex justify-content-center align-items-center justify-content-center",
      {
        "flex-column": !showResponsive,
      },
    );
    return (
      <div className={classes}>
        <div>
          <img
            src={`/assets/images/${data.imageName}${handleDisableStartAction(data) ? "_disabled" : ""}.svg`}
          />
        </div>
        <div>
          <div
            className={`title-font-size dark-grey mt-1 ${!props.questData.questMember || isEmpty ? "light-text" : ""}`}
          >
            {data.name}
          </div>
        </div>
      </div>
    );
  };

  const renderActions = (data, isEmpty) => {
    return (
      <div className="d-flex action-panel-card-div flex-column align-items-center">
        {handleShowNotStartedByCEOText(data)}
        <div id="action-div">
          {renderResponsiveLayoutForActionPanel(data, isEmpty)}
          {isEmpty ? (
            <div className="border light-text mt-2">Start</div>
          ) : (
            <StandardButton
              text={renderCTA(data)}
              className={`font-weight-normal py-1 mt-2 light-text`}
              onClick={() => handleStartAction(data)}
              textClassName={"small"}
              disabled={true}
              color={`${handleDisableStartAction(data) ? "btn-outline-disabled" : "btn-outline-success"}`}
            />
          )}
        </div>
      </div>
    );
  };

  const handleShowActivateAndManageTeamButton = () => {
    let content;
    if (!props.questData.questMember && !props.isHBCeo && !props.isMainUser) {
      content = (
        <div>
          <span className="text-base-primary">
            Only the Goals & Rhythms participants can access this
          </span>
        </div>
      );
    } else {
      if (!props.questData.actionStarted) {
        content = (
          <div className="">
            <span className="text-base-primary">
              No active Challenge for your team
            </span>
          </div>
        );
      } else if (
        !props.questData.actionStarted ||
        !props.questData.scoreGenerated
      ) {
        content = null;
      }
    }
    return content;
  };

  // if (isLoading) {
  //     return (
  //         <div className="bg-white rounded-3 my-2  d-flex justify-content-center align-items-center pt-5">
  //             <CircularGradientLoadingAnimation  />
  //         </div>
  //     )
  // }

  return (
    <div className="bg-white rounded-3 my-2 px-3 py-2">
      <div id="action-panel">
        <div className="action-panel-text-div">
          <span className="font-weight-bold title-font-size dark-grey">
            Action Panel
            <StandardTooltip
              tooltTipContent={
                <div className="text-start">
                  <span className="tooltip-title  title-font-size text-black">
                    Action Panel
                  </span>
                  <br />
                  <span className="text-black tooltip-information">
                    Click these buttons to perform specific actions
                  </span>
                </div>
              }
            />
          </span>
          {handleShowActivateAndManageTeamButton()}
        </div>
        {isLoading ? (
          <SkeletonLoadingAnimation
            containerClassNames={
              "d-flex justify-content-center align-items-center p-0"
            }
            className="me-2"
            height={122}
            width={168}
            count={5}
          />
        ) : (
          <div className="d-flex overflow-auto flex-grow-1 pb-2">
            {actionsData.map((data) => {
              return renderActions(data);
            })}
          </div>
        )}
      </div>
    </div>
  );
};
