import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import cheerio from "cheerio";
import { useHistory, useParams } from "react-router-dom";
import LoadingAnimation from "../../../../base/LoadingAnimation";
import { STUDY_COURSE } from "../../../../data/RouteUrls";
import {
  enrollCourseAction,
  getCourseDetail,
  recordEvent,
} from "../_redux/AcademyAction";
import CourseDetailCard from "./CourseDetailCard/CourseDetailCard";
import "./course-overview.scss";

import { contentTypes } from "../../../../data/enums/contentTypes";
import { eventTypes } from "../../../../data/enums/eventTypes";



export const CourseOverview = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [courseDetail, setCourseDetail] = useState({});
  const [sectionIndex, setSectionIndex] = useState();
  const demoAccess = useSelector(
    (state) => state.authDetails?.userProfile?.demoAccess
  );

  //data functions

  const getInitialData = async () => {
    setShowLoader(true);
    let courseDetailData = await dispatch(getCourseDetail(courseId));
    let status = courseDetailData.course_status.replace("%", "");
    if (isNaN(status)) {
      courseDetailData.progress = null;
    } else {
      courseDetailData.progress = parseInt(status);
    }
    courseDetailData.initialSectionIndex = 0;
    for (let sectionI in courseDetailData.sections) {
      let section = courseDetailData.sections[sectionI];
      section.is_current = false;
      section.is_completed = true;

      for (let chapter of section.chapters) {
        if (chapter.is_current) {
          section.is_current = true;
          break;
        }
      }
      if (section.is_current) {
        courseDetailData.initialSectionIndex = sectionI;
      }
      for (let chapter of section.chapters) {
        if (!chapter.is_completed) {
          section.is_completed = false;
          break;
        }
      }
    }
    let $ = cheerio.load(courseDetailData.overview);
    $("oembed").each(function () {
      let videoUrlParts = $(this).attr("url").split("/");

      $(this).replaceWith(
        `<iframe src="https://player.vimeo.com/video/${
          videoUrlParts[videoUrlParts.length - 1]
        }" height="500x" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>`
      );
    });

    $("a").each(function () {
      $(this).attr("target", "_blank");
    });

    courseDetailData.overview = $.html();
    setCourseDetail(courseDetailData);
    setShowLoader(false);
  };

  const enrollCourse = async () => {
    setShowLoader(true);
    if (courseDetail.progress === null)
      await dispatch(enrollCourseAction(courseId));
    history.push(STUDY_COURSE.replace(":courseId", courseId));
    setShowLoader(false);
  };

  const recordView = async () => {
    await dispatch(
      recordEvent({
        id: courseId,
        type: contentTypes.COURSE,
        event: eventTypes.VIEW,
      })
    );
  };

  useEffect(() => {
    recordView();
    getInitialData();
  }, []);
  if (showLoader) {
    return <LoadingAnimation />;
  }
  return (
    <div className="course-overview-container">
      <CourseDetailCard
        course={courseDetail}
        enrollCourse={enrollCourse}
        sectionIndex={sectionIndex}
        demoAccess={demoAccess}
      ></CourseDetailCard>
    </div>
  );
};
