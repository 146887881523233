// get shade of a hex color

import { colors } from "../data/Colors";

// percent > 0 - lighter
export function getGreenToRed(percent) {
  let g =
    percent < 50 ? 255 : Math.floor(255 - ((percent * 2 - 100) * 255) / 100);
  let r = percent > 50 ? 255 : Math.floor((percent * 2 * 255) / 100);
  return "rgb(" + r + "," + g + ",0,0.4)";
}
export function getHBScoreColor(value) {
  if (value < 50) {
    return colors["hb-red"];
  } else if (value < 75) {
    return colors["hb-orange"];
  } else {
    return colors["hb-green"];
  }
}

export function duplicate(item) {
  return JSON.parse(JSON.stringify(item));
}
// percent < 0 - darker
export function shadeColor(color, percent, alpha = 1) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = (R < 255 ? R : 255).toString(16);
  G = (G < 255 ? G : 255).toString(16);
  B = (B < 255 ? B : 255).toString(16);

  let RR = R.length === 1 ? `0${R}` : R;
  let GG = G.length === 1 ? `0${G}` : G;
  let BB = B.length === 1 ? `0${B}` : B;
  return "#" + RR + GG + BB;
}

export function hexToRgb(hex, getAsString = true) {
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
  const rgbString = `${rgb.r}, ${rgb.g}, ${rgb.b}`;
  return result ? (getAsString ? rgbString : rgb) : null;
}

export function getErrorMessage(err) {
  return (
    err.response?.data?.message ||
    err.response?.message ||
    err.message ||
    JSON.stringify(err)
  );
}

export function generateInviteSlug(length) {
  let slug = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    slug += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return slug;
}

export function convertToLabelValue(arr, labelKey, valueKey, extraFields = []) {
  return arr.map((item) => {
    let formattedItem = {};
    formattedItem.label = item[labelKey];
    formattedItem.value = item[valueKey];
    for (let field of extraFields) {
      if (item[field]) {
        formattedItem[field] = item[field];
      }
    }
    return formattedItem;
  });
}

export function pickFromObject(object, keys) {
  const ans = {};
  for (let key of keys) {
    ans[key] = object[key] ?? null;
  }
  return ans;
}

export function kNeighborsOfIndex(arr, index, k = 5) {
  let dl = index,
    ul = arr.length - index - 1;
  let lowerIndex, upperIndex;
  if (dl < ul) {
    lowerIndex = Math.max(index - 2, 0);
    upperIndex = Math.min(lowerIndex + k, arr.length);
  } else {
    upperIndex = Math.min(index + 3, arr.length);
    lowerIndex = Math.max(upperIndex - k, 0);
  }
  return arr.slice(lowerIndex, upperIndex);
}

export function copyToClipboard(value) {
  navigator.clipboard.writeText(value);
}

export const disciplineOrder = [
  "strategy",
  "execution",
  "people",
  "sales",
  "board",
  "operations",
  "finance",
  "capital",
];
export function sortDisciplineNames(discList) {
  const ordering = {};
  for (let i = 0; i < disciplineOrder.length; i++) {
    ordering[disciplineOrder[i]] = i;
  }
  discList.sort((r1, r2) => {
    const dn1 = r1.split(" ")[0].toLowerCase();
    const dn2 = r2.split(" ")[0].toLowerCase();
    return ordering[dn1] - ordering[dn2];
  });
}
export function sortRecomsByDiscipline(recomms, key = "dName") {
  const ordering = {};
  for (let i = 0; i < disciplineOrder.length; i++) {
    ordering[disciplineOrder[i]] = i;
  }
  recomms.sort((r1, r2) => {
    const dn1 = r1[key].split(" ")[0].toLowerCase();
    const dn2 = r2[key].split(" ")[0].toLowerCase();
    return ordering[dn1] - ordering[dn2] || r1.rank - r2.rank;
  });
}

export function getCurrentPlatform() {
  if (window.location.hostname.includes("localhost")) {
    return "Local";
  } else if (window.location.hostname.includes("staging")) {
    return "Staging";
  } else {
    return "Production";
  }
}

export function getQuestScoreColor(value) {
  if (value < 30) {
    return colors["quest-red"];
  } else if (value < 70) {
    return colors["quest-orange"];
  } else {
    return colors["quest-green"];
  }
}

export const getIndicesFromString = (str, regex, word) => {   
  let result = [];

  let idx = str.search(regex)
    
  while (idx !== -1) {
    let position = idx;
    if(result.length){
      position += result[result.length - 1] + word.length;
    }
    result.push(position);
    str = str.substr(idx + word.length);
    idx = str.search(regex);
  }
  return result;
}

export const indexOfRegex = (string,regex, fromIndex) =>{
  var str = fromIndex ? string.substring(fromIndex) : string;
  var match = str.match(regex);
  return match ? str.indexOf(match[0]) + fromIndex : -1;
}


export function addTextTooltip(gloassaryWords, text) {
  let dataText = text;
  let updates = [];
  gloassaryWords.forEach((e) => { 
    let word = new RegExp("\\b" + e.word + "\\b", "ig");
    let wordIndices = getIndicesFromString(text, word, e.word);    
    for (let index of wordIndices) {
      updates.push({
        word: text.substring(index,index + e.word.length),
        tooltip: e.tooltip,
        position: index,
      });
    }    
  });
  for (let update of updates.reverse()) {       
    dataText =
      dataText.substring(0, update.position) +
      `<span data-bs-toggle="tooltip" class="tooltip-text" data-bs-placement="top"  data-bs-original-title="${update.tooltip}">
    ${update.word}
  </span>` +
      dataText.substring(update.position + update.word.length);
  }
  return dataText;
}

