import React from 'react'

export const NoInitiativesForLevelScreen = ({initiativeStatus}) => {
    return (
        <div className="text-center border p-5">
            <div className='p-5'>
            <img src="/assets/images/no_initiatives_image.svg" className='mt-2 mb-2' /><br />
            <p className="cs-content title-font-size mt-3">{initiativeStatus === "NOT_STARTED" ?"No initiatives created yet" : "Click on View History to view details of the initiative/s"}</p></div>
        </div>
    )
}
