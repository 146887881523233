import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import StandardButton from "../../shared/formComponents/StandardButton/StandardButton";
import StandardInput from "../../shared/formComponents/StandardInput/StandardInput";
import { GetEmailValidation } from "../../../validations/GetEmail";
import validationUtils from "../../../utils/validationUtils";
import * as actions from "../_redux/authAction";
import { LOGIN_ROUTE, SIGNUP_ROUTE } from "../../../data/RouteUrls";
import { errorNoti } from "../../../base/BasicNotifications";
const GetEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(
    useSelector((state) => state.authDetails.email)
  );
  const [showError, setShowError] = useState(false);
  const validations = GetEmailValidation;
  const submit = async (e) => {
    e.preventDefault();
    let data = { email: email };
    if (!validationUtils.checkFormValidity(data, validations)) {
      setShowError(true);
      return;
    }
    try {
      let emailExists = await dispatch(actions.checkEmail(data));
      if (emailExists) {
        history.push(LOGIN_ROUTE, history.location.state);
      } else {
        history.push(SIGNUP_ROUTE, history.location.state);
        // errorNoti(
        //   "Art of Scale Coach is currently invite only. If you've been invited by admin, check your mail and follow the instructions."
        // );
      }
    } catch (err) {}
  };
  return (
    <div className="d-flex flex-column h-100">
      <form onSubmit={submit}>
        <div className="m-auto text-center">
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className="m-auto auth-side-logo"
          />
          <div className="mt-3 cs-sub-heading">
            Login or Sign Up with your email
          </div>
          <StandardInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-5"
            label="Email"
            type="email"
            placeholder="example@mail.com"
            showError={showError}
          />
          <div className="hint text-left">
            Type in your email to get started! If you already have an account
            we'll get you signed in, if you're new, we'll help you get set up!
          </div>
        </div>
        <div className="mt-auto">
          <StandardButton
            className="my-3"
            color="btn-success"
            block={true}
            text="Next"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
export default GetEmail;
