import React, { Component } from "react";
import "./standard-checkbox.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import validationUtils from "../../../../utils/validationUtils";
import _ from "underscore";
class StandardCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      errorText: "",
      dirty: false,
    };
  }
  selectedOptions = [];
  componentDidMount() {
    this.checkValidatity(this.props.value);

    if (this.props.value && Array.isArray(this.props.value)) {
      this.selectedOptions = JSON.parse(JSON.stringify(this.props.value));
    } else {
    }
  }

  componentDidUpdate() {
 
    if(!_.isEqual(this.selectedOptions, this.props.value)){
      this.selectedOptions = JSON.parse(JSON.stringify(this.props.value));
      this.forceUpdate();
    }
  }
  renderLabel = () => {
    const { label } = this.props;
    if (label) {
      return <label className="font-weight-bold mb-2">{label}</label>;
    }
    return null;
  };

  renderError = () => {
    const { errorText } = this.state;

    if (errorText && (this.state.dirty || this.props.showError)) {
      return <p className="error-text mb-0 mt-2">{errorText}</p>;
    }
    return null;
  };

  checkValidatity = (value) => {
    let isValid = true;
    let errorText = "";
    for (let validation of this.props.validations || []) {
      if (
        !validation.disabled &&
        !validationUtils[validation.type](value, validation.value)
      ) {
        errorText = validation.message;
        isValid = false;
        break;
      }
    }
    this.setState({
      valid: isValid,
      errorText: errorText,
    });
  };

  onChecked = (value) => {
    let valueIndex = this.selectedOptions.indexOf(value);    
    if(!this.selectedOptions){
      this.selectedOptions = [value];
    }else if (valueIndex === -1) {
      this.selectedOptions.push(value);
    } else {
      this.selectedOptions.splice(valueIndex, 1);
    }
    this.onChange(this.selectedOptions);
  };
  onChange = (e) => {
    this.props.onChange(e);
    this.checkValidatity(this.props.value);
  };

  onBlur = () => {
    this.setState({
      dirty: true,
    });
  };

  renderOptions = () => {
    return this.props.options.map((option, index) => {
      let classes = "option";
      if (this.props.columned) {
        classes += " col-12 col-md-3";
      }

      classes +=
        this.selectedOptions.indexOf(option.value) !== -1 ? " active" : "";
      classes += 
      option.disabled ? " disabled" : "";
      return (
        <div
          key={index.toString()}
          className={classes}
          onClick={() =>
            !option.disabled ? this.onChecked(option.value) : null
          }
        >
          <div className="d-flex">
            <div className="mx-3">
              <label class="checkbox-container mx-auto">
                <input
                  type="checkbox"
                  onBlur={this.onBlur}
                  checked={this.selectedOptions.indexOf(option.value) !== -1}
                  disabled={option.disabled}
                />
                <span class="checkmark" onClick={() =>
            !option.disabled ? this.onChecked(option.value) : null
          }></span>
              </label>
            </div>
            <div>
              <label dangerouslySetInnerHTML={{ __html: option.label }}></label>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    const { props } = this;

    const style = classNames("standard-checkbox col-12", {
      [props.className]: props.className,
      error: (this.state.dirty || this.props.showError) && !this.state.valid,
    });

    return (
      <div className={style}>
        {this.renderLabel()}
        {this.props.columned ? (
          <div className="row">{this.renderOptions()}</div>
        ) : (
          this.renderOptions()
        )}

        {this.state.valid ? null : this.renderError()}
      </div>
    );
  }
}

StandardCheckbox.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  className: PropTypes.string,
};

StandardCheckbox.defaultProps = {
  value: [],
};

export default StandardCheckbox;
