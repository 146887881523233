import React, { useEffect } from "react";
import { useState } from "react";
import "./QuestScoreBreakdown.scss";
import StandardSelect from "../../../shared/formComponents/StandardSelect/StandardSelect";
import StandardButton from "../../../shared/formComponents/StandardButton/StandardButton";
import { StandardTooltip } from "../../../shared/StandardTooltip/StandardTooltip";
import { QuestTooltip } from "../QuestTooltip/QuestTooltip";
import { useDispatch, useSelector } from "react-redux";
import * as questActions from "../_redux/QuestActions";
import {
  CircularGradientLoadingAnimation,
  RelativeLoadingAnimation,
  SkeletonLoadingAnimation,
} from "../../../../base/LoadingAnimation";
import { QuestDashboardTable } from "../QuestDashboardTable/QuestDashboardTable";
import classNames from "classnames";
import {
  BoldText,
  NormalText,
  SmallLightText,
  SmallNormalText,
} from "../../../../utils/typographyUtils";

export const QuestScoreBreakdown = (props) => {
  const dispatch = useDispatch();

  const [fetchingScore, setFetchingScore] = useState(true);
  const [fetchingInsightAndActions, setFetchingInsightAndActions] =
    useState(false);

  const [weeks, setWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState("");
  const [latestWeekId, setLatestWeekId] = useState("");
  const [activeTab, setActiveTab] = useState("teamSuccess");
  const [activeInsightTab, setActiveInsightTab] = useState("improveTeamScore");
  const [expandObjectives, setExpandObjectives] = useState(false);
  const [expandInsightAndActions, setExpandInsightAndActions] = useState(false);
  const userProfile = useSelector((state) => state.authDetails.userProfile);

  const [scoreData, setScoreData] = useState([]);
  const [insightActionData, setInsightActionData] = useState([]);

  let isQuestMember = props.questData?.questMember;
  let showEmptyState =
    !props.questData.hbSetupCompleted ||
    !props.questData.actionStarted ||
    !props.questData.scoreGenerated;
  let insightActionEmptyState = !props.questData.hbSetupCompleted;

  useEffect(() => {
    handleGetWeekList(props.smeId);
    handleGetInsightActionsForTeamScoreImprovement();
  }, []);

  const handleGetWeekList = async (smeId) => {
    try {
      const resp = await dispatch(questActions.getWeekList(smeId));
      if (resp) {
        handleGetQuestLastWeekTeamSuccess(
          resp[0]?.week_id ? resp[0].week_id : "",
        );
        setSelectedWeek(resp[0].week_id);
        setLatestWeekId(resp[0].week_id);
        resp.map((week, index) => {
          week.label = week.weekString;
          week.value = week.week_id;
          delete week.weekString;
          delete week.week_id;
        });
        setWeeks(resp);
      }
    } catch (e) {
      console.log(e);
      setFetchingScore(false);
    }
  };

  const handleGetQuestLastWeekTeamSuccess = async (weekId, showLoader) => {
    try {
      if (showLoader) {
        setFetchingScore(true);
      }
      const resp = await dispatch(
        questActions.getQuestLastWeekTeamSuccess(weekId, props.smeId),
      );
      if (resp) {
        setScoreData(resp.membersScore);
      }
    } catch (e) {
      console.log(e);
    }
    setFetchingScore(false);
  };

  const handleGetQuestTeamHabitMystery = async () => {
    try {
      setFetchingScore(true);
      const resp = await dispatch(
        questActions.getQuestTeamHabitMystery(latestWeekId, props.smeId),
      );
      if (resp) {
        setScoreData(resp.membersScore);
      }
    } catch (e) {
      console.log(e);
    }
    setFetchingScore(false);
  };

  const handleGetInsightActionsForTeamScoreImprovement = async () => {
    try {
      setFetchingInsightAndActions(true);
      const resp = await dispatch(
        questActions.getInsightActionsForTeamScoreImprovement(props.smeId)
      );
      if (resp) {
        setInsightActionData(resp.data);
      }
    } catch (e) {
      console.log(e);
    }
    setFetchingInsightAndActions(false);
  };

  // const handleGetInsightActionsForYourScoreImprovement = async () => {
  //   try {
  //     setFetchingInsightAndActions(true);
  //     const resp = await dispatch(
  //       questActions.getInsightActionsForYourScoreImprovement(""),
  //     );
  //     if (resp) {
  //       setInsightActionData(resp.data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setFetchingInsightAndActions(false);
  // };

  const headerData = [
    {
      title: "Last Week's Team Success",
      isActive: activeTab === "teamSuccess",
      key: "teamSuccess",
    },
    {
      title: "Team Habit Mastery",
      isActive: activeTab === "habitMastery",
      key: "habitMastery",
    },
  ];

  const handleNoQuestMemberInsightActionHeaderTabs = () => {
    let insightActionsSectionrHeaderTabs = [
      {
        title: "Improving Your Team Score",
        isActive: activeInsightTab === "improveTeamScore",
        key: "improveTeamScore",
      },
    ];
    return insightActionsSectionrHeaderTabs;
  };

  const insightActionsSectionrHeaderTabs =
    handleNoQuestMemberInsightActionHeaderTabs();

  const renderTooltipContent = (key) => {
    let red = <span style={{ color: "#EE1616" }}>Red</span>;
    let yellow = <span style={{ color: "#FFAE00" }}>Yellow</span>;
    let green = <span style={{ color: "#3ECF78" }}>Green</span>;
    let title, information;
    if (key === "habitMastery") {
      title = "Team Habit Mastery";
      information = (
        <span>
          Team 5-week scores reflect habit mastery.
          <br />
          <br />
          {red}: you haven’t maintained the winning habit, refocus and improve.
          <br />
          <br />
          {yellow}: you’re making progress; keep working towards mastery.
          <br />
          <br />
          {green}: Congratulations, you've mastered the past 5 weeks, now master
          the next 5!
          <br />
          <br />
        </span>
      );
    } else {
      title = "Last Week's Team Success";
      information = (
        <span>
          Team scores reflect habit progress of individual team members in the
          last week.
          <br />
          <br />
          {red}: you’ve missed your habit goals last week and need to catch up.
          <br />
          <br />
          {yellow}: you’re making progress, keep going.
          <br />
          <br />
          {green}: you met your habit goals, great job!
          <br />
          <br />
        </span>
      );
    }
    return (
      <div className="text-start ">
        <span className="dark-grey">{title}</span>
        <br />
        <span className="text-black tooltip-information ">{information}</span>
      </div>
    );
  };

  const renderHeaderTabs = () => {
    return headerData.map((data, index) => {
      return (
        <>
        <div className={`${index === 0 ? "" : "ms-2"}`}>
          <span
            onClick={() => {
              setActiveTab(data.key);
              if (!showEmptyState) {
                if (data.key === "habitMastery") {
                  handleGetQuestTeamHabitMystery();
                } else {
                  handleGetQuestLastWeekTeamSuccess(
                    selectedWeek || latestWeekId,
                  );
                }
              }
            }}
            className={`cursor-pointer dark-grey title-font-size quest-score-breakdown-header-tab${data.isActive ? "-active" : ""} `}
          >
            {data.title}
          </span>
          <StandardTooltip tooltTipContent={renderTooltipContent(data.key)} />
        </div>
        {index == 0 ? <span className="vertical-separator mx-2"></span> : null}

        </>
      );
    });
  };

  const renderTooltipContentForInsightAndAction = (key) => {
    let title, information;
    if (key === "improveTeamScore") {
      title = "Improving Your Team Score";
      information = (
        <span>
          Boost your team's weekly scores and habits with the insights and
          actions here.
        </span>
      );
    } else {
      title = "Improving Your Score";
      information = (
        <span>
          Boost your weekly scores and reach your habit goals with the insights
          and actions here
        </span>
      );
    }
    return (
      <div className="text-start ">
        <span className="tooltip-title  text-black">{title}</span>
        <br />
        <span className="text-black tooltip-information ">{information}</span>
      </div>
    );
  };

  const rendeInsightActionsSectionrHeaderTabs = () => {
    return insightActionsSectionrHeaderTabs.map((data, index) => {
      return (
        <div>
          <span
            className={`cursor-pointer dark-grey title-font-size quest-score-breakdown-header-tab${data.isActive ? "-active" : ""} ms-2 pb-1`}
          >
            {data.title}
          </span>
          <StandardTooltip
            tooltTipContent={renderTooltipContentForInsightAndAction(data.key)}
          />
        </div>
      );
    });
  };

  const getTeamScoreData = () => {
    let data = [];
    if (!expandObjectives) {
      data.push(
        {
          objective: renderWeeklyScoreData(),
        },
        {
          objective: renderStreakData(),
          type: "streak",
        },
      );
    } else {
      data.push(
        {
          objective: renderWeeklyScoreData(),
        },
        {
          objective: "Q-OKRs Set",
        },
        {
          objective: "WRAP Held",
        },
        {
          objective: "WCM Attended",
        },
        {
          objective: "Daily #1 Achieved",
          type: "promiseDelivered",
        },
        {
          objective: "Weekly Promises Delivered",
          type: "promiseDelivered",
        },
      );
    }
    return data;
  };

  const renderDataDenominator = (data) => {
    if (data !== null) {
      if (activeTab === "habitMastery") {
        return data + " / 5";
      } else {
        if (typeof data == "boolean") {
          return data;
        } else {
          return data + " / 5";
        }
      }
    } else {
      return null;
    }
  };

  const renderDailyAchievedDataDenominator = (data) => {
    if (data !== null) {
      if (activeTab === "habitMastery") {
        return data + " / 25";
      } else {
        return data + " / 5";
      }
    } else {
      return null;
    }
  };

  const renderObjectivesTable = () => {
    let teamScoreColumns = [
      {
        title: "",
        dataField: "objective",
        width: "20%",
        className: "text-left",
      },
    ];

    for (let scoreIndex in scoreData) {
      let score = scoreData[scoreIndex];
      teamScoreColumns.push({
        title: (
          <span>
            {score.firstName}
            <br />
            {score.lastName}
          </span>
        ),
        dataField: scoreIndex.toString(),
        colored: true,
        loggedInUser: userProfile.id == score.user_id,
      });
    }

    let teamScoreData = getTeamScoreData();

    for (let scoreIndex in scoreData) {
      let score = scoreData[scoreIndex];
      if (!expandObjectives) {
        teamScoreData[0][scoreIndex.toString()] = score.weeklyScore;
        teamScoreData[0][scoreIndex.toString() + "value-for-color"] =
          score.weeklyScore;
        teamScoreData[1][scoreIndex.toString() + "value-for-color"] =
          score.streak;
        teamScoreData[1][scoreIndex.toString()] = score.streak;
      } else {
        teamScoreData[0][scoreIndex.toString()] = score.weeklyScore;
        teamScoreData[0][scoreIndex.toString() + "value-for-color"] =
          score.weeklyScore;
        teamScoreData[1][scoreIndex.toString()] = renderDataDenominator(
          score.okrSet,
        );
        teamScoreData[1][scoreIndex.toString() + "value-for-color"] =
          score.okrSetScore;
        teamScoreData[2][scoreIndex.toString()] = renderDataDenominator(
          score.wrapHeld,
        );
        teamScoreData[2][scoreIndex.toString() + "value-for-color"] =
          score.wrapHeldScore;
        teamScoreData[3][scoreIndex.toString()] = renderDataDenominator(
          score.wcmAttended,
        );
        teamScoreData[3][scoreIndex.toString() + "value-for-color"] =
          score.wcmAttendedScore;
        teamScoreData[4][scoreIndex.toString()] =
          score.dailyAchieved != null && score.totalDaily !== null
            ? `${score.dailyAchieved} / ${score.totalDaily}`
            : "-";
        teamScoreData[4][scoreIndex.toString() + "value-for-color"] =
          score.dailyAchieved != null && score.totalDaily !== null
            ? `${score.dailyAchieved} / ${score.totalDaily}`
            : "-";
        teamScoreData[5][scoreIndex.toString()] =
          score.achievedPromises != null && score.totalPromises != null
            ? `${score.achievedPromises} / ${score.totalPromises}`
            : "-";
        teamScoreData[5][scoreIndex.toString() + "value-for-color"] =
          score.achievedPromises != null && score.totalPromises != null
            ? `${score.achievedPromises} / ${score.totalPromises}`
            : "-";
      }
    }
    return (
      <div className="px-2">
        <QuestDashboardTable
          currentUserId={userProfile.id}
          columns={teamScoreColumns}
          data={teamScoreData}
        />
      </div>
    );
  };

  const renderStreakData = () => {
    return (
      <div>
        <div>
          <span className="p-1">
            Streak{" "}
            <span className="bg-light p-1 sub-content-font-size font-weight-bold rounded">{`> 70`}</span>
          </span>
        </div>
        <div></div>
      </div>
    );
  };

  const renderWeeklyScoreData = () => {
    return (
      <div>
        <div className="quest-light-bg-border-radius p-1">
          <span className="font-weight-bold sub-content-font-size">
            {activeTab == "habitMastery"
              ? "Rolling 5-weeks Score"
              : "Weekly Score"}
          </span>
        </div>
        <div></div>
      </div>
    );
  };

  const renderProgressStatsInfo = () => {
    let data = [
      {
        title: "Great Going",
        background: "#D1FFBB",
      },
      {
        title: "Making progress",
        background: "#FFF6A6",
      },
      {
        title: "Need to catch up",
        background: "#FFC8C8",
      },
    ];
    return data.map((object, index) => {
      return (
        <div className="d-flex flex-column me-2 ms-2">
          <div
            className="rounded-1"
            style={{
              background: object.background,
              height: "10px",
              width: "30px",
            }}
          ></div>
          <span className="dark-grey" style={{ fontSize: "12px" }}>
            {object.title}
          </span>
        </div>
      );
    });
  };

  const renderInsightAndActionsCard = (cardType, data, hideImage) => {
    let imgName = cardType;
    return (
      <div id="insight-action-div" className="d-flex flex-column">
        {hideImage ? null : (
          <div className={`d-flex mt-2`}>
            <img src={`/assets/images/top_${imgName}.svg`} />
            <span className="dark-grey sub-content-font-size font-weight-bold text-capitalize ms-1">
              Top {cardType}
            </span>
          </div>
        )}
        <div
          className={classNames(
            "bg-light sub-content-font-size p-2 rounded insight-action-data h-100 d-flex",
            {
              "mt-2": hideImage,
            },
          )}
        >
          <div className="my-auto dark-grey">{data}</div>
        </div>
      </div>
    );
  };

  const renderInsightAndActions = (insightActionObject, number, index) => {
    return (
      <div className="d-flex justify-content-evenly rounded-1">
        {renderInsightAndActionsCard(
          "insight",
          insightActionObject?.insight,
          index > 0,
        )}
        <div
          className={`d-flex justify-content-center align-items-center mx-2 ${index > 0 ? "" : "pt-4"} dark-grey`}
        >
          <img src="/assets/images/base_primary_left.svg" className="me-1" />
          {number}
          <img src="/assets/images/base_primary_right.svg" className="ms-1" />
        </div>
        {renderInsightAndActionsCard(
          "action",
          insightActionObject?.action,
          index > 0,
        )}
      </div>
    );
  };

  const renderInsightAndActionsDiv = () => {
    return (
      <div className="bg-white w-100 rounded-3 p-2 mt-2 flex-grow-1">
        <div className="d-flex justify-content-start align-items-center p-2">
          {rendeInsightActionsSectionrHeaderTabs()}
        </div>
        {fetchingInsightAndActions ? (
          <SkeletonLoadingAnimation height={138} />
        ) : !props.questData.hbSetupCompleted ? (
          renderEmptyInsightAndAction()
        ) : (
          <div
            className={`d-flex ${expandInsightAndActions ? "flex-column" : ""} px-2 overflow-auto`}
          >
            {renderInsightAndActions(insightActionData[0], 1, 0)}
            {expandInsightAndActions
              ? insightActionData.slice(1).map((insight, index) => {
                return renderInsightAndActions(insight, index + 2, index + 1);
              })
              : null}
          </div>
        )}
      </div>
    );
  };

  const renderEmptyStatScoreBreakdown = () => {
    let content;
    let nailedContent = (
      <>
        This section shows if your team nailed last week.
        <br /> Start the journey by inviting your team members
      </>
    );
    if (activeTab === "habitMastery") {
      nailedContent = (
        <>
          This section shows team's 5-week rolling scores reflecting habit
          mastery.
          <br /> Start the journey by inviting your team members
        </>
      );
    }
    if (props.isHBCeo) {
      if (!props.questData.hbSetupCompleted) {
        content = (
          <span className="text-center empty-stat-text">{nailedContent}</span>
        );
      } else if (!props.questData.actionStarted) {
        content = (
          <span className="text-center empty-stat-text">
            ACTION NOT STARTED TEXT FOR CEO
          </span>
        );
      }
    } else {
      if (!props.questData.hbSetupCompleted) {
        content = (
          <span className="text-center empty-stat-text">{nailedContent}</span>
        );
      } else if (!props.questData.actionStarted) {
        content = (
          <span className="text-center empty-stat-text">
            ACTION NOT STARTED TEXT FOR TM
          </span>
        );
      }
    }
    return (
      <div className="bg-light p-4 rounded d-flex flex-column justify-content-center align-items-center mt-2 mb-2">
        <img src="/assets/images/quest_settings_disabled.svg" />
        {content}
      </div>
    );
  };

  const renderEmptyInsightAndAction = () => {
    let content;

    let text = `This section shows insights and actions at team level.`;
    if (activeInsightTab === "improveIndividualScore") {
      text = `This section shows top insights and actions to improve your individual score.`;
    }
    if (props.isHBCeo) {
      if (!props.questData.hbSetupCompleted) {
        content = (
          <span className="text-center empty-stat-text">
            {text} <br />
            This will be revealed when you start your Goals & Rhythms journey
          </span>
        );
      }
    } else {
      if (!props.questData.hbSetupCompleted) {
        content = (
          <span className="text-center empty-stat-text">
            {text}
            <br />
            This will be revealed when you start your Goals & Rhythms journey
          </span>
        );
      }
    }
    return (
      <div className="bg-light p-4 rounded d-flex flex-column justify-content-center align-items-center">
        <img src="/assets/images/compass_light.svg" alt="compass" />
        {content}
      </div>
    );
  };

  const renderWeekScore = () => {
    let separator = <span className="vertical-separator mx-2"></span>;
    return (
      <div className="bg-white w-100 rounded-3 p-3 flex-grow-1">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">{renderHeaderTabs()}</div>
          {activeTab === "teamSuccess" ? (
            <div className="rounded d-flex justify-content-center align-items-center mb-2">
              <StandardSelect
                renderLabelWithBorder={true}
                label="Week"
                className="ps-2"
                options={weeks}
                value={selectedWeek}
                onChange={(e) => {
                  setSelectedWeek(e.value);
                  handleGetQuestLastWeekTeamSuccess(e.value, true);
                }}
              />
            </div>
          ) : null}
        </div>
        {fetchingScore ? (
          <SkeletonLoadingAnimation height={138} />
        ) : !props.questData.hbSetupCompleted ? (
          renderEmptyStatScoreBreakdown()
        ) : (
          renderObjectivesTable()
        )}
        {!props.questData.hbSetupCompleted ? null : (
          <div className="d-flex justify-content-between align-items-center">
            <span className="small dark-grey fst-italic me-2">
              {activeTab === "teamSuccess"
                ? "This score measures the practice of winning teams, for a single week. "
                : "This score measures the practice of winning teams, over rolling 5 weeks. "}
              <br />
            </span>
            {separator}
            <div className="d-flex justify-content-start align-items-center me-5">
              {renderProgressStatsInfo()} {separator}
            </div>
            <StandardButton
              onClick={() => {
                setExpandObjectives(!expandObjectives);
                setExpandInsightAndActions(false);
              }}
              text="Breakdown"
              color="btn-breakdown"
              className="font-weight-normal py-2 0"
              icon={
                <i
                  class={`fa-solid fa-chevron-${expandObjectives ? "up" : "down"}`}
                ></i>
              }
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div id="quest-score-breakdown">
      {renderWeekScore()}
      {renderInsightAndActionsDiv()}
    </div>
  );
};
