import axios from 'axios';
import dashboardSlice from './dashboardSlice';

import { errorNoti } from '../../../../base/BasicNotifications';
import { DASHBOARD_URLS } from './dashboardUrls';
import { getErrorMessage } from '../../../../utils/generalUtils';

const instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
const { actions } = dashboardSlice;

export const getFeed = () => (dispatch, getState) => {
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject('Coach Id missing');
  let url = DASHBOARD_URLS.GET_FEED.replace('{coachId}', coachId);
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      const feedObject = response.data.data;
      let feed;
      if (!feedObject) {
        feed = [];
      } else {
        feed = Object.keys(feedObject)
          .map((key) => ({
            date: key,
            feeds: feedObject[key]
              .map((fd) => ({
                text: fd.feed,
                time: fd.minutes,
              }))
              .filter((fd) => (fd.text ? fd : null)),
          }))
          .filter((fd) => fd.feeds.length > 0);
      }
      dispatch(actions.setFeed(feed));
      return true;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
    });
};
