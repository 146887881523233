import React, { useReducer, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import * as authActions from '../../../AuthContainer/_redux/authAction';
import StandardButton from '../../../shared/formComponents/StandardButton/StandardButton';
import StandardInput from '../../../shared/formComponents/StandardInput/StandardInput';
import validationUtils from '../../../../utils/validationUtils';
import './change-password-popup.scss';
import { ChangePasswordValidation } from '../../../../validations/ChangePassword';

const INITAL_STATE = {
  old: '',
  new: '',
  confirm: '',
};

function passwordReducer(state, action) {
  switch (action.type) {
    case 'old':
      return { ...state, old: action.payload };
    case 'new':
      return { ...state, new: action.payload };
    case 'confirm':
      return { ...state, confirm: action.payload };
    case 'reset':
      return { ...INITAL_STATE };
    default:
      return state;
  }
}

const ChangePasswordPopup = ({ show, handleHide }) => {
  const dispatch = useDispatch();
  const [passwords, passwordDispatcher] = useReducer(
    passwordReducer,
    INITAL_STATE
  );
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);

  const changePasswordSubmit = async (e) => {
    e.preventDefault();
    if (
      !validationUtils.checkFormValidity(passwords, ChangePasswordValidation) ||
      passwords.new !== passwords.confirm
    ) {
      setShowError(true);
      return;
    } else setShowError(false);
    try {
      setShowLoader(true);
      // Email injected from state in action
      let data = {
        oldPassword: passwords.old,
        newPassword: passwords.new,
      };
      await dispatch(authActions.changePassword(data));
      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoader(false);
    }
  };

  function handleClose() {
    passwordDispatcher({ type: 'reset' });
    setShowError(false);
    handleHide();
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      fullscreen="md-down"
      dialogClassName="change-password-popup"
      centered
    >
      <Modal.Header>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={changePasswordSubmit}>
          <StandardInput
            value={passwords.old}
            onChange={(e) =>
              passwordDispatcher({ type: 'old', payload: e.target.value })
            }
            className="mb-3"
            label="Old Password"
            type="password"
            showError={showError}
            validations={ChangePasswordValidation.old}
          />
          <StandardInput
            value={passwords.new}
            onChange={(e) =>
              passwordDispatcher({ type: 'new', payload: e.target.value })
            }
            className="mb-3"
            label="New Password"
            type="password"
            showError={showError}
            validations={ChangePasswordValidation.new}
          />
          <StandardInput
            value={passwords.confirm}
            onChange={(e) =>
              passwordDispatcher({ type: 'confirm', payload: e.target.value })
            }
            className="mb-3"
            label="Confirm Password"
            type="password"
            validations={ChangePasswordValidation.confirm}
            showError={showError}
            checkValidity={(value) => ({
              isValid: value === passwords.new,
              errorText: 'Passwords must match',
            })}
          />
          <StandardButton
            color="btn-success"
            block={true}
            text="Update Password"
            type="submit"
            disabled={showLoader}
          />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordPopup;
