
  import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { styled } from "@mui/system";
  import React, { useEffect } from "react";
  import "../CustomBarChart/customBarChart.scss";
  
  export const CustomBarChart = ({
    recommendation,
    recomType,
    improved,
    setLineGraphLevel,
    textToShow,
  }) => {
    const { currentLevel, nextLevel } = recommendation;
    const [activeStep, setActiveStep] = React.useState(textToShow);
  
    useEffect(() => {
      setActiveStep(textToShow);
    }, [textToShow]);
  
    function renderImg(props) {
      const { active, completed } = props;
      return active ? (
        <img src="/assets/images/initiative-icons/barIcons/active.svg" />
      ) : completed ? (
        <img src="/assets/images/initiative-icons/barIcons/complete.svg" />
      ) : (
        <img src="/assets/images/initiative-icons/barIcons/inComplete.svg" />
      );
    }
    return (
      <>
        <div className="bar-container p-4 my-3 w-100">
          <Box>
            <Stepper activeStep={activeStep} orientation="vertical">
              {recommendation?.answerMap?.map((step, index) => {
                let showPercent = step.percent;
  
                if(improved){
                  if(currentLevel == step.level){
                    showPercent = 100;
                  }else{
                    showPercent = 0;
                  }
                }
                return <Step key={step?.level}>
                  <StepLabel StepIconComponent={renderImg}>
                    <div
                      className="row mx-0 cursor-pointer"
                      onClick={() =>
                        recomType == "recommendation" && step?.level <= nextLevel
                          ? setLineGraphLevel(step?.level, step)
                          : null
                      }
                    >
                      <div className="col-1">
                        <div className="font-weight-bold">level {step.level}</div>
                      </div>
                      <div
                        className="col-9 text-truncate position-relative"
                        style={{ zIndex: "1" }}
                      >
                        <span className="font-weight-bold">{`${showPercent}%`}</span>{" "}
                        <span
                          className="ms-1"
                          style={{
                            color: "#18123F",
                          }}
                        >
                          {step.answer}
                        </span>
                        <span
                          style={{
                            width: `${showPercent}%`,
                            height: "20px",
                            position: "absolute",
                            borderRadius: "0px 100px 100px 0px",
                            left: 0,
                            zIndex: "-1",
                            backgroundColor: `${(step.isCurrentLevel &&
                              recomType === "situation") ||
                              (step.isNextLevel && recomType === "recommendation")
                              ? "#72C6FF"
                              : "#C7C7C7"
                              }`,
                          }}
                        ></span>
                      </div>
                      <div className="col-2 font-weight-bold">{step.label}</div>
                    </div>
                  </StepLabel>
                  {/* <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? "Finish" : "Continue"}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box>
                  </StepContent> */}
                </Step>
              }
              )}
            </Stepper>
          </Box>
        </div>
      </>
    );
  };
  