import { ValidationEnum } from '../data/enums/ValidationEnum';
export const FeedbackValidation = {
  rating: [
    {
      type: ValidationEnum.REQUIRED,
      message: 'Rating is required.',
    },
  ],
  review: [],
};
