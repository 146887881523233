import axios from "axios";
import queryString from "query-string";
import { errorNoti } from "../../base/BasicNotifications";
import { sharedReduxSlice } from "./sharedSlice";
import { SharedUrls } from "./sharedUrls";

const { actions } = sharedReduxSlice;

const AppInstance = axios.create({ baseURL: "" });
const nodeInstance = axios.create({ baseURL: process.env.REACT_APP_NEST_BASE_URL });
const GLOASSARYWORDS_URL = "/GloassaryWords.json";

export const getGloassaryWords = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    AppInstance
      .get(GLOASSARYWORDS_URL)
      .then((response) => {
        resolve(response.data.data);
        dispatch(actions.setGloassaryWords(response.data.data));
      })
      .catch((err) => {
        resolve(false);
        errorNoti(err.response.data.message);
      });
  });
};

export const getCountryList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    nodeInstance
      .get(SharedUrls.GET_COUNTRIES)
      .then((response) => {
        resolve(response.data);
        dispatch(actions.setCountryList(response.data.data));
      })
      .catch((err) => {
        resolve(false);
        errorNoti(err.response.data.message);
      });
  });
};

export const getBillingDetails = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let url = SharedUrls.BILLING_DETAILS;
    nodeInstance
      .get(url, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        resolve(false);
        errorNoti(err.response.data.message);
      });
  });
};

export const updateBillingDetails = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let url = SharedUrls.BILLING_DETAILS;
    nodeInstance
      .post(url, data, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(false);
        errorNoti(err.response.data.message);
      });
  });
}