import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import LoadingAnimation from '../../../../../base/LoadingAnimation';
import StandardButton from '../../../../shared/formComponents/StandardButton/StandardButton';
import StandardBackButton from '../../../../shared/formComponents/StandardButton/StandardBackButton';
import * as actions from "../../_redux/xrayActions";
import "../RecommendationsCard/recommendations-card.scss";


export const RecommendationHistory = (props) => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [recommendationHistoryData, setRecommendationHistoryData] = useState()
  const [showUserQuestionsAns, setShowUserQuestionsAns] = useState(false);
  const [expandedLevelIndex, setExpandedLevelIndex] = useState(0)
  const [expandedLevelInitiativeIndex, setExpandedLevelInitiativeIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const discName = recommendationHistoryData?.practiceData?.disciplineName.toLowerCase().split(" ")[0];
  const disciplineCategoryClassName = `discipline-category ${discName}`;
  useEffect(() => {
    handleFetchRecommendationHistory()
  }, [])
  const handleFetchRecommendationHistory = async () => {
    try {
      setIsLoading(true)
      const resp = await dispatch(actions.getRecommendationHistory(params.practiceId, params.surveyId));
      console.log(resp);
      if (resp) {
        setRecommendationHistoryData(resp)
        setIsLoading(false)
      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }
  const renderRecommendationHistoryInitiativesData = (initiative, index, showExpandCollapseButton) => {
    return (
      <div className="recom-bg rounded p-2 my-3 m-3">
        <div className="d-flex align-items-center" onClick={() => {
          if (showExpandCollapseButton) {
            if (index !== expandedLevelInitiativeIndex) {
              setExpandedLevelInitiativeIndex(index)
            } else {
              setExpandedLevelInitiativeIndex()
            }
          }
        }}>
          <span className="ms-3">
            Initiative <b>{index + 1}</b>
          </span>
          {showExpandCollapseButton ? <StandardButton
            color="btn-outline-dark"
            bold={true}
            className="d-flex justify-content-center align-items-center expand-btn ms-auto"
            text={
              index === expandedLevelInitiativeIndex
                ? "Collapse"
                : "Expand"}
            icon={
              index === expandedLevelInitiativeIndex ? (
                <span className="material-icons icon-in-btn">
                  expand_less
                </span>
              ) : (
                <span className="material-icons icon-in-btn">
                  expand_more
                </span>
              )
            }
            reversed={true}
            onClick={(e) => {
              if (index !== expandedLevelInitiativeIndex) {
                setExpandedLevelInitiativeIndex(index);
              } else {
                setExpandedLevelInitiativeIndex()
              }
            }}
          /> : <></>}
        </div>
        {index === expandedLevelInitiativeIndex ? <div>
          <div className="card p-2 my-2">
            <div className="d-flex align-items-center">
              <img
                src="/assets/images/initiative-icons/Initiative.svg"
                height={{ height: "14px", width: "14px" }}
              />
              <span className="ms-3">
                Initiative : <b>{initiative.initiative}</b>
              </span>
            </div>
          </div>
          <div className="card p-2 my-2">
            <div className="d-flex align-items-center">
              <img
                src="/assets/images/initiative-icons/Expected_result.svg"
                height={{ height: "14px", width: "14px" }}
              />
              <span className="ms-3">
                Expected Result : <b>{initiative.goal}</b>
              </span>
            </div>
          </div>
          <div className="card p-2 my-2">
            <div className="d-flex align-items-center">
              <img
                src="/assets/images/initiative-icons/target_date.svg"
                height={{ height: "14px", width: "14px" }}
              />
              <span className="ms-3">
                Start Date :{" "}
                <b>
                  {moment(initiative.createdAt).format(
                    "Do MMM YYYY"
                  )}
                </b>
              </span>
            </div>
          </div>
          <div className="card p-2 my-2">
            <div className="d-flex align-items-center">
              <img
                src="/assets/images/initiative-icons/target_date.svg"
                height={{ height: "14px", width: "14px" }}
              />
              <div>
                <span className="ms-3">
                  Est. Date of Completion: {" "}
                  <b>
                    {moment(initiative.due_date).format(
                      "Do MMM YYYY"
                    )}
                  </b>
                </span>
              </div>
              <div>
                <span className="ms-3 border-left">
                  Actual Date of Completion :{" "}
                  <b>
                    {moment(initiative.completionDate).format(
                      "Do MMM YYYY"
                    )}
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div> : <></>}
      </div>)
  }

  const renderShowQuestionAnsData = (imgPath, imgAlt, title, data) => {
    if (!data) {
      return null;
    } else {
      return (
        <div className="bg-white rounded p-2 mt-2">
          <div className="d-flex align-items-center">
            <img
              src={imgPath}
              alt={imgAlt}
              width="28px"
              height="28px"
            />
            <div className="font-weight-bold ms-3">
              {title}
            </div>
          </div>
          <div>{data}</div>
        </div>
      )
    }
  }

  const handleInitiativeExpandCollapse = (index) => {
    if (recommendationHistoryData?.initiative?.length > 1) {
      if (index !== expandedLevelIndex) {
        setExpandedLevelIndex(index);
        if (index === 0) {
          setExpandedLevelInitiativeIndex(0)
        }
      } else {
        setExpandedLevelIndex()
      }
    }
  }
  if (isLoading) {
    return (
      <LoadingAnimation />
    )
  } else {
    return (
      <div className="container-fluid p-0">
        <StandardBackButton />
        <Card>
          <Card.Header className="bg-white mt-3">
            <div className="d-flex">
              <div className='cs-sub-heading mb-3'>
                <div>
                  {recommendationHistoryData?.practiceData?.name}
                </div>
                <div className="d-flex mt-2">
                  <div className={`${disciplineCategoryClassName} discipline me-3`}>
                    {recommendationHistoryData?.practiceData?.disciplineName}
                  </div>
                  {recommendationHistoryData?.practiceData?.subDisciplineName ? <div className={`${disciplineCategoryClassName} sub-discipline me-3`}>
                    {recommendationHistoryData?.practiceData?.subDisciplineName}
                  </div> : <></>}
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="m-1 p-2">
            {recommendationHistoryData?.initiative?.map((recommHist, index) => {
              return (
                <div className='border m-2'>
                  <div className="level-div p-2 d-flex align-items-center border" onClick={(e) => {
                    handleInitiativeExpandCollapse(index)
                  }}>
                    Level {recommHist?.level} to Level {recommHist?.answers[0].level}
                    {recommendationHistoryData?.initiative.length > 1 ? <StandardButton
                      color="btn-outline-dark"
                      bold={true}
                      className="d-flex justify-content-center align-items-center expand-btn ms-auto"
                      text={
                        index === expandedLevelIndex
                          ? "Collapse"
                          : "Expand"}
                      icon={
                        index === expandedLevelIndex ? (
                          <span className="material-icons icon-in-btn">
                            expand_less
                          </span>
                        ) : (
                          <span className="material-icons icon-in-btn">
                            expand_more
                          </span>
                        )
                      }
                      reversed={true}
                      onClick={(e) => {
                        if (index !== expandedLevelIndex) {
                          setExpandedLevelIndex(index);
                        } else {
                          setExpandedLevelIndex()
                        }
                      }
                      }
                    /> : <></>}
                  </div>

                  {index == expandedLevelIndex ? <div className="recom-bg rounded p-2 my-2 m-3">
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/images/initiative-icons/Remark.svg"
                        alt="Remark"
                        width="40px"
                        height="40px"
                      />
                      <div className="font-weight-bold ms-2">CEO Remarks</div>
                    </div>
                    <div className="ms-5">{recommHist?.ceoRemark}</div>
                  </div> : <></>}
                  {index === expandedLevelIndex ? (
                    <div>
                      {recommHist?.initiatives?.map((initiative, index) => {
                        return (
                          renderRecommendationHistoryInitiativesData(initiative, index, recommHist?.initiatives?.length > 1)
                        )
                      })}
                      {showUserQuestionsAns ?
                        (
                          <div className="my-3 recom-bg p-4 m-3">
                            {renderShowQuestionAnsData("/assets/images/logo-small.png", "logo", "Recommendation", recommendationHistoryData?.initiative[index].recommendation)}
                            {renderShowQuestionAnsData("/assets/images/initiative-icons/Remark.svg", "Remark", "Remarks", recommendationHistoryData?.initiative[index].remark)}
                            {renderShowQuestionAnsData("/assets/images/initiative-icons/Q&A.svg", "Q&A", `${recommendationHistoryData?.initiative[index].question}`, recommendationHistoryData?.initiative[index].answers[0]?.answer)}
                          </div>
                        ) : null}
                      {showUserQuestionsAns ? (
                        <div
                          className="text-primary cursor-pointer  d-flex align-items-center justify-content-end me-2 mb-2"
                          onClick={() => setShowUserQuestionsAns(false)}
                        >
                          <span>View Less</span>{" "}
                          <span class="material-symbols-outlined">expand_less</span>
                        </div>
                      ) : (
                        <div
                          className="text-primary cursor-pointer  d-flex align-items-center justify-content-end me-2 mb-2"
                          onClick={() => setShowUserQuestionsAns(true)}
                        >
                          <span>View More Details</span>{" "}
                          <span class="material-symbols-outlined">expand_more</span>
                        </div>
                      )}
                    </div>) : <></>}
                </div>
              )
            })}
          </Card.Body>
        </Card>
      </div>
    )
  }
}