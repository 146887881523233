import React, { useContext, useEffect, useState } from "react";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { HeaderContext } from "../../../HeaderContext";

import * as authActions from "../../AuthContainer/_redux/authAction";
import * as sharedActions from "../../sharedRedux/sharedAction";
import ChangePasswordPopup from "./ChangePasswordPopup/ChangePasswordPopup";
import "./header.scss";
import ProfileUpdatePopup from "./ProfileUpdatePopup/ProfileUpdatePopup";
import AsyncImage from "../../shared/AsyncImage/AsyncImage";
import { ConditionalRender } from "../../shared/ConditionalRender/ConditionalRender";
import StandardButton from "../../shared/formComponents/StandardButton/StandardButton";
import UpgradeConfirmationPopup from "../../shared/UpgradeConfirmationPopup/UpgradeConfirmationPopup";
import { BillingDetailsPopup } from "./BillingDetailsPopup/BillingDetailsPopup";

export const Header = (props) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.authDetails.userProfile);
  const [passwordPopupVisible, setPasswordPopupVisible] = useState(false);
  const [showBillingDetailsPopup, setShowBillingDetailsPopup] = useState(false);
  const [profilePopupVisible, setProfilePopupVisible] = useState(false);
  const HeaderMetaData = useContext(HeaderContext);
  const [billingDetails, setBillingDetails] = useState({});
  const [upgradePopup, setUpgradePopup] = useState(false);
  const Logout = () => dispatch(authActions.logout());
  const profileDetails = useSelector((state) => state.authDetails?.userProfile);
  const toggleSidebar = () => props.setSidebarCollapsed((prev) => !prev);
  const getInitials = (firstName, lastName) => {
    const lni = lastName[0];
    let initials = firstName[0];
    if (lni) initials += lni;
    return initials;
  };

  useEffect(() => {
    handleGetBillingDetails();
  }, []);

  const handleGetBillingDetails = async () => {
    try {
      const resp = await dispatch(sharedActions.getBillingDetails());
      if (resp) {
        setBillingDetails(resp);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const renderUpgradePlanPopup = () => {
    if (upgradePopup) {
      return <UpgradeConfirmationPopup
        title={"Invite to X-Ray"}
        content={
          <>
            Talk to us to unlock access to invite your first client
          </>
        }
        closePopup={() => setUpgradePopup(false)}
        isOpen={upgradePopup}
        upgradePopup={true}
      />
    }
  };
  return (
    <div className="header mb-4 mb-md-0">
      {profileDetails.demoAccess ? <div className="py-3 d-flex justify-content-center align-items-center coach-upgrade-banner m-3 mx-lg-5">
        <img src='/assets/images/upgrade-icon.svg' alt="upgrade" className="me-3" />
        Talk to us to unlock full access. It’s free!
        <a className="ms-3" onClick={() => setUpgradePopup(true)}>
          Schedule meeting
        </a>

      </div> : null}
      <div className="px-3 px-md-4 px-lg-2 py-3 d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-start align-items-center flex-fill me-3">
          <div className="d-inline-block d-md-none">
            <img
              src="/assets/images/logo.png"
              alt="logo"
              className="me-3"
              height="32px"
            />
          </div>
          <div
            className="material-icons menu-toggle-btn"
            onClick={toggleSidebar}
          >
            menu
          </div>
          <div className="flex-fill d-none d-md-flex justify-content-between align-items-center">
            <div className="cs-heading">
              {HeaderMetaData?.headerMetadata?.headerTitle}
            </div>
            {HeaderMetaData?.headerMetadata?.HeaderActions ? (
              <HeaderMetaData.headerMetadata.HeaderActions />
            ) : null}
          </div>
        </div>
        {/* {profileDetails?.demoAccess && (
          <div className="d-flex justify-content-end align-items-center mx-3">
            <StandardButton
              text="Upgrade"
              icon={<img src="/assets/images/x-ray-contact.svg" width="15px" />}
              color="btn-success"
              className="btn-sm"
              reversed
              textClassName="m-0"
              onClick={() => setUpgradePopup(true)}
            />
          </div>
        )} */}
        <div className="d-flex justify-content-end align-items-center">
          <DropdownButton
            id="profile-dropdown"
            className="d-inline-block user-dropdown"
            title={
              <>
                {userProfile && userProfile?.profileId ? (
                  <span className="name">
                    <AsyncImage
                      src={`${process.env.REACT_APP_LMS_URL}file/open/${userProfile?.profileId}`}
                      roundedCircle
                      className="profile-img me-2"
                      hideLoader
                    />
                    <span className="d-none d-lg-inline-block">
                      {userProfile?.firstName + " " + userProfile?.lastName}
                    </span>
                  </span>
                ) : (
                  <span className="name">
                    <span className="initials me-2">
                      {userProfile
                        ? getInitials(
                          userProfile?.firstName,
                          userProfile?.lastName
                        )
                        : null}
                    </span>
                    <span className="d-none d-lg-inline-block">
                      {userProfile?.firstName + " " + userProfile?.lastName}
                    </span>
                  </span>
                )}
              </>
            }
          >
            <Dropdown.Item onClick={() => setProfilePopupVisible(true)}>
              Update Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setShowBillingDetailsPopup(true)}>
            Set Up Billing Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={Logout}>Logout</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      {renderUpgradePlanPopup()}
      <ChangePasswordPopup
        show={passwordPopupVisible}
        handleHide={() => setPasswordPopupVisible(false)}
      />
      <ProfileUpdatePopup
        show={profilePopupVisible}
        setPasswordPopupVisible={setPasswordPopupVisible}
        handleHide={() => setProfilePopupVisible(false)}
      />
      <ConditionalRender condition={showBillingDetailsPopup}>
        <BillingDetailsPopup
          callback={() => {
            handleGetBillingDetails();
          }}
          billingDetails={billingDetails}
          closePopup={() => setShowBillingDetailsPopup(false)}
        />
      </ConditionalRender>
    </div>
  );
};
