import React, { useEffect, useState } from "react";
import "./StandardCircularProgressBar.scss";

export const StandardCircularProgressBar = ({
  radius,
  progress,
  thresholdValue,
  steps,
  cut,
  rotate,
  strokeWidth,
  strokeColor,
  fillColor,
  strokeLinecap,
  transition,
  pointerRadius,
  pointerStrokeWidth,
  pointerStrokeColor,
  pointerFillColor,
  trackStrokeColor,
  trackStrokeWidth,
  trackStrokeLinecap,
  trackTransition,
  counterClockwise,
  inverse,
  initialAnimation,
  initialAnimationDelay,
  className,
  children,
  scoreType,
  textColor,
}) => {
  const [animationInited, setAnimationInited] = useState(false);

  useEffect(() => {
    if (initialAnimation) {
      setTimeout(() => setAnimationInited(true), initialAnimationDelay);
    }
  }, []);

  const getProgress = () =>
    initialAnimation && !animationInited ? 0 : progress;

  const getExtendedWidth = () => {
    const pointerWidth = pointerRadius + pointerStrokeWidth;

    if (pointerWidth > strokeWidth && pointerWidth > trackStrokeWidth) {
      return pointerWidth * 2;
    } else if (strokeWidth > trackStrokeWidth) {
      return strokeWidth * 2;
    }

    return trackStrokeWidth * 2;
  };

  const d = 2 * radius;
  const width = d + getExtendedWidth();

  const circumference = 2 * Math.PI * radius;
  const strokeLength = (circumference / 360) * (360 - cut);

  return (
    <div className={`RCP ${className}`}>
      <svg
        width="90"
        viewBox="0 0 100 100"
        fill="none"
        stroke-width="10"
        stroke-linecap="round"
      >
        <circle
          opacity="0.2"
          stroke="#878B9D"
          cx="50"
          cy="50"
          r="40"
          width="200"
          height="100"
          fill="none"
        />
        <circle
          id="meter"
          transform="rotate(-90 50 50)"
          stroke={strokeColor}
          cx="50"
          cy="50"
          r="40"
          pathLength="100"
          stroke-dasharray={`${progress == "-" ? "0" : progress} 100`}
        />
        <g
          id="threshold"
          transform={`translate(50 50) rotate(-90) rotate(${3.6 * thresholdValue})`}
        >
          <line
            x1="37.5"
            y1="0"
            x2="44"
            y2="0"
            stroke="black"
            stroke-width="3"
          />
        </g>
        <text x="50%" y="40%" dominant-baseline="middle" text-anchor="middle">
          <tspan
            className={`${progress !== "-" ? "text-black" : "light-text"}`}
            x="50%"
            dy=".0em"
          >
            {progress}
          </tspan>
          <tspan
            x="50%"
            dy="1.2em"
            style={{ fill: textColor, fontSize: "14px" }}
          >
            {scoreType}
          </tspan>
        </text>
      </svg>
    </div>
  );
};

StandardCircularProgressBar.defaultProps = {
  radius: 100,
  progress: 0,
  steps: 100,
  cut: 0,
  rotate: -90,

  strokeWidth: 20,
  strokeColor: "indianred",
  fillColor: "none",
  strokeLinecap: "round",
  transition: ".3s ease",

  pointerRadius: 0,
  pointerStrokeWidth: 20,
  pointerStrokeColor: "indianred",
  pointerFillColor: "white",

  trackStrokeColor: "#e6e6e6",
  trackStrokeWidth: 20,
  trackStrokeLinecap: "round",
  trackTransition: ".3s ease",

  counterClockwise: false,
  inverse: false,

  initialAnimation: false,
  initialAnimationDelay: 0,
  className: "",
};