import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import { COACH_PANEL, RESET_PASSWORD } from '../../../data/RouteUrls';
import validationUtils from '../../../utils/validationUtils';
import { LoginValidation } from '../../../validations/Login';
import StandardButton from '../../shared/formComponents/StandardButton/StandardButton';
import StandardInput from '../../shared/formComponents/StandardInput/StandardInput';
import * as actions from '../_redux/authAction';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(
    useSelector((state) => state.authDetails.email)
  );
  const [password, setPassword] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const validations = LoginValidation;
  const [showError, setShowError] = useState(false);
  const passwordRef = useRef();

  const submit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const data = { email, password };
    if (!validationUtils.checkFormValidity(data, validations)) {
      setShowError(true);
      return;
    }
    try {
      const loggedIn = await dispatch(actions.login(data));
      if (history?.location?.state?.from?.pathname) {
        history.push(history.location.state.from.pathname);
      } else if (loggedIn) {
        history.push(COACH_PANEL);
      }
    } catch (e) {
      console.error(e);
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (email) {
      passwordRef.current?.focus();
    }
  }, [passwordRef]);

  return (
    <div className="d-flex flex-column h-100 p-md-3">
      <form onSubmit={submit}>
        <div className="my-auto text-center">
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className="m-auto auth-side-logo"
          />
          <div className="mt-3 cs-sub-heading">Login</div>
          <StandardInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-3"
            label="Email"
            type="email"
            placeholder="example@mail.com"
            showError={showError}
            validations={validations.email}
          />
          <div className="hint text-left">
            Welcome back {email}, Let's get you signed in!
          </div>
          <StandardInput
            value={password}
            innerRef={passwordRef}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-3"
            label="Password"
            type="password"
            showError={showError}
            validations={validations.password}
          />
        </div>
        <div className="mt-auto">
          <StandardButton
            className="my-3"
            color="btn-success"
            bold={true}
            block={true}
            text="Log In"
            type="submit"
            disabled={buttonDisabled}
          ></StandardButton>
        </div>
        <div className="text-center">
          <Link className="do-later" to={RESET_PASSWORD}>
            I forgot my password
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
