import { CONTACT_XRAY_STATE_INVERSE_ENUMS } from '../data/enums/XrayStateEnums';

const CONTACT_XRAY_STATE_NUMBERS = {
  ADDED: 0,
  INVITED: 1,
  REGISTERED: 2,
  ACCEPTED: 2,
  XRAY_STARTED: 3,
  XRAY_SUBMITTED: 4,
  XRAY_COMPLETED: 5,
};

export function isContactInvited(status) {
  if (!status) return false;
  return (
    CONTACT_XRAY_STATE_NUMBERS[CONTACT_XRAY_STATE_INVERSE_ENUMS[status]] >=
    CONTACT_XRAY_STATE_NUMBERS.INVITED
  );
}
export function isContactRegistered(status) {
  if (!status) return false;
  return (
    CONTACT_XRAY_STATE_NUMBERS[CONTACT_XRAY_STATE_INVERSE_ENUMS[status]] >=
    CONTACT_XRAY_STATE_NUMBERS.REGISTERED
  );
}
export function isContactXrayCompleted(status) {
  if (!status) return false;
  return (
    CONTACT_XRAY_STATE_NUMBERS[CONTACT_XRAY_STATE_INVERSE_ENUMS[status]] >=
    CONTACT_XRAY_STATE_NUMBERS.XRAY_SUBMITTED
  );
}

export function isContactXrayStarted(status) {
  if (!status) return false;
  return (
    CONTACT_XRAY_STATE_NUMBERS[CONTACT_XRAY_STATE_INVERSE_ENUMS[status]] >=
    CONTACT_XRAY_STATE_NUMBERS.XRAY_STARTED
  );
}
