import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { StatsCard } from './StatsCard/StatsCard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './score.scss';
import { RecommendationsCard } from './RecommendationsCard/RecommendationsCard';
import { ExploreCard } from './ExploreCard/ExploreCard';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../_redux/xrayActions';
import LoadingAnimation from '../../../../base/LoadingAnimation';
import { DivergenceCard } from './DivergenceCard/DivergenceCard';
import { useParams, useHistory } from 'react-router-dom';
import { QUEST, XRAY } from '../../../../data/RouteUrls';
import * as xrayActions from "../_redux/xrayActions";
import StandardButton from '../../../shared/formComponents/StandardButton/StandardButton';

const TAB_INDEX_VAR = 'xrayTabIndex';

const Score = (props) => {
  const dispatch = useDispatch();
  const xrayState = useSelector((state) => state.xrayReport.xRayState);

  const params = useParams();
  const history = useHistory();

  const [currentTabIndex, setCurrentTabIndex] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [xrayScore, setXrayScore] = useState({});
  const [surveyId, setSurveyId] = useState();

  const getXrayScore = async () => {
    let xRayScoreData = await dispatch(actions.getLatestScore(params.userId));
    setXrayScore(xRayScoreData);
    const tabIndex = parseInt(localStorage.getItem(TAB_INDEX_VAR));
    if (tabIndex && tabIndex >= 0 && tabIndex <= 2) {
      setCurrentTabIndex(tabIndex);
    } else setCurrentTabIndex(0);
    setShowLoader(false);
  };

  const getXrayStatus = async () => {
    await dispatch(actions.getXrayStatus(params.userId));
  };

  useEffect(async () => {
    // This code runs on loading the component
    document.title = 'Art of Scale | X-Ray';
    await getXrayStatus();
    await getXrayScore();
  }, []);

  useEffect(() => {
    if (currentTabIndex !== null) {
      localStorage.setItem(TAB_INDEX_VAR, currentTabIndex);
    }
  }, [currentTabIndex]);

  if (showLoader) {
    return <LoadingAnimation />;
  }
  if (isNaN(xrayScore.overall.actualScore)) {
    return (
      <>
        <div className="container-fluid xray px-5">
          <div className="cs-heading mt-5">Your X ray is not complete yet.</div>
        </div>
      </>
    );
  }
  const redirectToQuest = (asContacts) => {
    dispatch(
      xrayActions.updateXrayState({
        surveyId: asContacts.surveyId,
        userId: asContacts.userId,
        companyName: asContacts.companyName,
        respondantsEdited: asContacts.isEditRespondant ?? false,
        recommendationsEdited: asContacts.isEditRecomm ?? false,
        xrayPublished: asContacts.isSentResult ?? false,
        contact: { ...asContacts },
      })
    );
    history.push({ pathname: QUEST, search: `?id=${asContacts.userId}` });
  }

  return (
    <>
      <div className='d-flex'>
        <StandardButton
          text="View Goals and Rhythms Progress"
          color="btn-success"
          className="py-2 ms-auto mb-2"
          onClick={() => {
            redirectToQuest(xrayState)
          }}
        />
      </div>
      <StatsCard
        xrayScore={xrayScore} surveyId={xrayScore.surveyId} />
      <div className="my-3">
        <Card>
          <Tabs onSelect={setCurrentTabIndex} selectedIndex={currentTabIndex}>
            <TabList className="mt-3 mb-0">
              <Tab>
                <div className="d-flex align-items-center">
                  <div className="tab-logo align-self-center">
                    <img
                      src={
                        currentTabIndex === 0
                          ? '/assets/images/icon_gaps_active_1.svg'
                          : '/assets/images/icon_gaps_1.svg'
                      }
                      alt="logo"
                    />
                  </div>
                  <div className="ms-3">
                    <div className="cs-sub-heading tab-title">
                      {'Recommendations'}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab>
                <div className="d-flex align-items-center">
                  <div className="tab-logo align-self-center">
                    <img
                      src={
                        currentTabIndex === 1
                          ? '/assets/images/icon_explore_active.svg'
                          : '/assets/images/icon_explore.svg'
                      }
                      alt="logo"
                    />
                  </div>
                  <div className="ms-3">
                    <div className="cs-sub-heading tab-title">
                      {'Your Results'}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab>
                <div className="d-flex align-items-center">
                  <div className="tab-logo align-self-center">
                    <img
                      src={
                        currentTabIndex === 2
                          ? '/assets/images/icon_team_divergence_active.svg'
                          : '/assets/images/icon_team_divergence.svg'
                      }
                      alt="logo"
                    />
                  </div>
                  <div className="ms-3">
                    <div className="cs-sub-heading tab-title">
                      {'Team Divergence'}
                    </div>
                  </div>
                </div>
              </Tab>
            </TabList>

            <TabPanel>
              <RecommendationsCard />
            </TabPanel>
            <TabPanel>
              <ExploreCard xrayScore={xrayScore} />
            </TabPanel>
            <TabPanel>
              <DivergenceCard />
            </TabPanel>
          </Tabs>
        </Card>
      </div>
    </>
  );
};

export default Score;
