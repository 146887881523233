export const authUrls = {
  COACH_SIGNUP: "/auth/coach/signup",
  COACH_FREE_ACCESS_SIGNUP: "auth/signup/coach",
  COACH_LOGIN: "/auth/coach/login",
  CHECK_EMAIL: "/auth/coach/check-mail",
  CHANGE_PASSWORD: "/auth/updatePassword",
  FORGET_PASSWORD: "/auth/coach/forgetPassword",
  RESET_PASSWORD: "/auth/resetPassword/{resetCode}",
  VALIDATE_INVITE_CODE: "/auth/coach/validate-invite-code",
  VALIDATE_OTP: "/validateOtp",
  GENERATE_OTP: "/generateOtp",
  USER_DETAILS: "/api/coach/me",
  UPDATE_USER_DETAILS: "/api/user",
  ADMIN_COACH_DETAILS: "/api/coach/{coachId}",
};
