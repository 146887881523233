import { useEffect, useRef, useState } from 'react';

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
};

// NOT USED but WIP to generalize it
export const useTimeout = (callback, delay) => {
  const stableCallback = useRef(null);

  useEffect(() => {
    stableCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!stableCallback.current) return;
    const tick = () => stableCallback.current();
    if (typeof delay !== 'number') return;

    const tout = setTimeout(tick, delay);

    return () => clearTimeout(tout);
  }, []);
};
