import React, { useState } from "react";
import { useDispatch } from "react-redux";

import StandardButton from "../../../shared/formComponents/StandardButton/StandardButton";
import * as coachContactsActions from "../_redux/contactActions";
import ConfirmationPopup from "../../../shared/ConfirmationPopup/ConfirmationPopup";
import "./invite-contact-to-xray.scss";
import { ConditionalRender } from "../../../shared/ConditionalRender/ConditionalRender";
import { BillingDetailsPopup } from "../../Header/BillingDetailsPopup/BillingDetailsPopup";
import {
  isContactInvited,
  isContactRegistered,
} from "../../../../utils/contactUtils";
import UpgradeConfirmationPopup from "../../../shared/UpgradeConfirmationPopup/UpgradeConfirmationPopup";

const InviteContactToXray = ({
  contact,
  open,
  billingDetails,
  handleCancel,
  handleGetBillingDetails,
  setInviteToXrayToggle,
  handleOK,
  callback,
  demoAccess,
}) => {
  const dispatch = useDispatch();
  const [inviteCode, setInviteCode] = useState(null);
  const [inviteBtnDisabled, setInviteBtnDisabled] = useState(false);
  const [showBillingDetailsPopup, setShowBillingDetailsPopup] = useState(false);

  const handleClose = () => {
    setInviteBtnDisabled(false);
    handleGetBillingDetails();
    setInviteCode(null);
    handleCancel();

  };

  const handleOKClose = () => {
    setInviteBtnDisabled(false);
    setInviteCode(null);
    handleOK();
  };

  const isCurrentInvited = isContactInvited(contact?.status);
  const isCurrentRegistered = isContactRegistered(contact?.status);

  const title = isCurrentInvited
    ? "Resend Invite to Art of Scale"
    : inviteCode
      ? "Success!"
      : "Invite to Art of Scale";
  const content = inviteCode ? (
    <>
      An invitation has been sent to{" "}
      <span className="contact-name">
        {contact?.firstName + " " + contact?.lastName}
      </span>{" "}
      - please ask them to look out for an email from us!
      <span className="contact-email">(support@artofscale.io) </span>
    </>
  ) : (
    <>
      <p>
        You are about to invite{" "}
        <span className="contact-name">
          {contact?.firstName + " " + contact?.lastName}
        </span>{" "}
        <span className="contact-email">({contact?.email})</span> to sign-up on Art of Scale, click "Send Invite"{" "}
      </p>
      <p className="text-light-grey">
        {contact?.firstName} will receive an email from{" "}
        <span className="contact-email">support@artofscale.io</span> with
        instructions on how to proceed
      </p>
    </>
  );

  const cancelCTA = inviteCode ? null : (
    <StandardButton
      text="Cancel"
      className="btn-sm me-2"
      onClick={handleClose}
    />
  );

  const confirmCTA = inviteCode ? (
    <StandardButton
      text="OK"
      className="btn-sm"
      color="btn-success"
      onClick={handleOK ? handleOKClose : handleClose}
    />
  ) : (

    <StandardButton
      text="Send Invite"
      icon={<span className="material-icons send-icon">send</span>}
      reversed
      className="btn-sm"
      color="btn-success"
      disabled={inviteBtnDisabled || isCurrentRegistered || demoAccess}
      onClick={(e) => {
        if (!billingDetails || Object.keys(billingDetails).length === 0 || billingDetails === "" || billingDetails === undefined) {
          setInviteToXrayToggle(false);
          setShowBillingDetailsPopup(true);
        } else {
          inviteContactToXray(e, contact);
        }
      }
      }
    />
  );

  const inviteContactToXray = async (e, contact) => {
    e.preventDefault();
    try {
      setInviteBtnDisabled(true);
      const userId = contact.userId;
      let icode = await dispatch(
        coachContactsActions.inviteContactToXray(userId)
      );
      setInviteCode(icode);
      await coachContactsActions.sendInvitationNotification(contact);

    } catch (err) {
      console.error(err);
    } finally {
      setInviteBtnDisabled(false);
    }
  };
  if (demoAccess && open) {
    return (
      <UpgradeConfirmationPopup
        title={"Invite to X-Ray"}
        content={
          <>
            Talk to us to unlock access to invite your first client
          </>
        }
        closePopup={handleClose}
        isOpen={open}
      />
    );
  }

  return (
    <React.Fragment>
      <ConfirmationPopup
        title={title}
        content={content}
        cancelAction={cancelCTA}
        confirmAction={confirmCTA}
        isOpen={open}
      />
      <ConditionalRender condition={showBillingDetailsPopup}>
        <BillingDetailsPopup
          callback={() => callback()}
          closePopup={() => setShowBillingDetailsPopup(false)}
          billingDetails={billingDetails} />
      </ConditionalRender>
    </React.Fragment>
  );
};

export default InviteContactToXray;
