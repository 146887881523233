import React, { useEffect, useState } from 'react'
import { QuestWinningTeamScore } from "./QuestWinningTeamScore/QuestWinningTeamScore";
import { QuestScoreBreakdown } from './QuestScoreBreakdown/QuestScoreBreakdown';
import { QuestActionsCard } from "./QuestActionsCard/QuestActionsCard";
import { QuestChallengePlaybookCard } from "./QuestChallengePlaybookCard/QuestChallengePlaybookCard";
import * as questActions from "./_redux/QuestActions";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import "./Quest.scss";
import StandardButton from '../../shared/formComponents/StandardButton/StandardButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { XRAY_SCORE, XRAY_USER_SCORE } from '../../../data/RouteUrls';
import * as xrayActions from "../Xray/_redux/xrayActions";
import LoadingAnimation from '../../../base/LoadingAnimation';
import { StandardTooltip } from '../../shared/StandardTooltip/StandardTooltip';

export const Quest = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [questData, setQuestData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const searchParams = new URLSearchParams(location.search);
    const xrayState = useSelector((state) => state.xrayReport.xRayState);
    const [showViewXrayButton, setShowViewXrayButton] = useState();
    const history = useHistory();

    useEffect(() => {
        handleGetQuestCommonData();
        getXrayScore();
    }, []);
    const getXrayScore = async () => {
        let xRayScoreData = await dispatch(xrayActions.getLatestScore(searchParams.get("id")));
        if (xRayScoreData) {
            setShowViewXrayButton(xRayScoreData.overall.actualScore);
        }
    }

    const handleGetQuestCommonData = async () => {
        try {
            const resp = await dispatch(questActions.getQuestCommonData(searchParams.get("id")));
            if (resp) {
                setQuestData(resp)
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }

    const redirectToXrayScore = (asContacts) => {
        dispatch(
            xrayActions.updateXrayState({
                surveyId: asContacts.surveyId,
                userId: asContacts.userId,
                respondantsEdited: asContacts.isEditRespondant ?? false,
                recommendationsEdited: asContacts.isEditRecomm ?? false,
                xrayPublished: asContacts.isSentResult ?? false,
                contact: { ...asContacts },
            })
        );
        history.push(XRAY_USER_SCORE.replace(`:userId`, asContacts.userId));
    };

    if(isLoading){
        return <LoadingAnimation />
    }
    
    return (
        <div className='quest-container h-100 mb-2'>
            <div className='pb-2'><span className='font-weight-bold'>Note: </span> This is the Goals & Rhythms dashboard as seen by the client, allowing you to only view their progress.</div>
            <div className="d-flex quest-nav align-items-center justify-content-between bg-white border-bottom rounded mb-1 px-2">
                <div className='d-flex align-items-center'>
                    <StandardButton
                        icon={<span className="material-icons icon">arrow_back</span>}
                        text="Back"
                        onClick={() => history.goBack()}
                        reversed
                    />
                    <h3 className="quest-company-name ms-2">
                        {questData?.companyName} Goals & Rhythms
                    </h3>
                </div>
                <div>
                    {showViewXrayButton ? <StandardButton text="View X-Ray"
                        color="btn-success"
                        className="py-2"
                        onClick={() => redirectToXrayScore(xrayState)}
                    /> : null}
                </div>
            </div>
            <div className="d-flex">
                <QuestWinningTeamScore questData={questData} smeId={searchParams.get("id")} />
                <QuestScoreBreakdown questData={questData} smeId={searchParams.get("id")} />
            </div>
            <div>
            <StandardTooltip
            tooltTipContent={<span className='text-black'>Access to the Action Panel for coaches is currently disabled. We will inform you as soon as it is enabled.</span>}
            >
                <div>
            <QuestActionsCard
                    smeId={searchParams.get("id")}
                    questData={questData}
                />
                </div>
            </StandardTooltip>
            </div>
        </div>
    )
}
