import { toast } from 'react-toastify';
import './noti.scss';

const options = {
  position: toast.POSITION.TOP_RIGHT,
  progressClassName: 'custom-progress',
};

export const successNoti = (msg) => {
  toast.success(msg, {...options,className: "cs-toast-success"});
};

export const errorNoti = (msg) => {
  toast.error(msg, options);
};

export const warnNoti = (msg) => {
  toast.warning(msg, options);
};

export const loadingNoti = (msg) => {
  return toast.loading(msg, options);
};
