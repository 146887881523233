import React, { useContext, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../../../base/LoadingAnimation";
import { useDebounce } from "../../../../base/hooks";
import { convertToLabelValue } from "../../../../utils/generalUtils";
import StandardButton from "../../../shared/formComponents/StandardButton/StandardButton";
import StandardInput from "../../../shared/formComponents/StandardInput/StandardInput";
import StandardSelect from "../../../shared/formComponents/StandardSelect/StandardSelect";
import {
  getAuthors,
  getCategories,
  getCourses,
  getResources,
  getDisciplines,
  getResourceTypes,
  recordEvent,
} from "../_redux/AcademyAction";
import { CourseCard } from "./CourseCard/CourseCard";
import ResourceCard from "./ResourceCard/ResourceCard";
import "./home.scss";
import { HeaderContext } from "../../../../HeaderContext";
import UpgradeConfirmationPopup from "../../../shared/UpgradeConfirmationPopup/UpgradeConfirmationPopup";
import { contentTypes } from "../../../../data/enums/contentTypes";
import { eventTypes } from "../../../../data/enums/eventTypes";

const academyTab = "academyTab";
const COURSES = "courses";
const RESOURCES = "resources";
export const Home = (props) => {
  //State Variables
  let academyTabFound = localStorage.getItem(academyTab);

  if (academyTabFound !== COURSES && academyTabFound !== RESOURCES)
    academyTabFound = null;

  const [tab, setTab] = useState(academyTabFound ?? COURSES);
  const [showLoader, setShowLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const [category, setCategory] = useState("");
  const [author, setAuthor] = useState("");
  const [courses, setCourses] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const [disciplineOptions, setDisciplineOptions] = useState([]);
  const [resourceTypeOptions, setResourceTypeOptions] = useState([]);
  const [resources, setResources] = useState([]);
  const [upgradePopup, setUpgradePopup] = useState(false);
  const [resourcesFilter, setResourcesFilter] = useState({
    disciplineId: null,
    typeIds: null,
  });

  const isInitialRender = useRef(true);
  const demoAccess = useSelector(
    (state) => state.authDetails?.userProfile?.demoAccess
  );
  // contexts
  const HeaderMetaData = useContext(HeaderContext);

  //Others
  const dispatch = useDispatch();

  const generateTypeOptions = (rawTypeOptions, nameKey, idKey) => {
    const typeMap = new Map();    
    rawTypeOptions.forEach((to) => {
      // type name is assumed to be "External ..." or "Internal ..."
      let typeName = to[nameKey];
      let key =
        typeName.includes("External") || typeName.includes("Internal")
          ? typeName.split(" ").slice(1).join(" ")
          : typeName;      
      if (typeMap.has(key)) {
        typeMap.set(key, [...typeMap.get(key), to[idKey]]);
      } else {
        typeMap.set(key, [to[idKey]]);
      }
    });
    return [...typeMap.entries()].map((tm) => ({
      label: tm[0].endsWith("s") ? tm[0] : tm[0] + "s",
      value: tm[1].join(","),
    }));
  };

  //Data Functions
  const getInitialData = async () => {
    setShowLoader(true);
    let categoriesData = await dispatch(getCategories());
    let authorsData = await dispatch(getAuthors());
    let discOptions = await dispatch(getDisciplines());
    discOptions = discOptions
      .filter(
        (disc) =>
          !disc.dName.includes("Complexity") &&
          !disc.dName.includes("Company Life-Cycle Stage")
      )
      .map((disc) => ({
        value: disc.disciplineId,
        label: disc.dName,
      }));
    let typeOptions = await dispatch(getResourceTypes());
    const typeMap = new Map();
    typeOptions.forEach((to) => {
      // type name is assumed to be "External ..." or "Internal ..."
      let typeName = to.name;
      let key =
        typeName.includes("External") || typeName.includes("Internal")
          ? typeName.split(" ").slice(1).join(" ")
          : typeName;
      if (typeMap.has(key)) {
        typeMap.set(key, [...typeMap.get(key), to.id]);
      } else {
        typeMap.set(key, [to.id]);
      }
    });
    typeOptions = [...typeMap.entries()].map((tm) => ({
      label: tm[0].endsWith("s") ? tm[0] : tm[0] + "s",
      value: tm[1].join(","),
    }));
    let authorObjAray = [];
    for (let author of authorsData) {
      authorObjAray.push({
        name: author,
      });
    }
    setAuthors(convertToLabelValue(authorObjAray, "name", "name"));
    setCategories(convertToLabelValue(categoriesData, "name", "id"));
    setDisciplineOptions(discOptions);
    setResourceTypeOptions(typeOptions);
  };

  const getCoursesData = async () => {
    let filters = { type : 'COURSE'};
    if (author || category || searchText) {
      filters = {
        ...filters,
        author: author,
        category_id: category,
        course: searchText,
      };
    }
    let coursesData = await dispatch(getCourses("all", filters));
    for (let i in coursesData) {
      let course = coursesData[i];
      let status = course.status.replace("%", "");
      if (isNaN(status)) {
        course.progress = null;
      } else {
        course.progress = parseInt(status);
      }
    }

    setCourses(coursesData);

    setShowLoader(false);
  };

  const getResourcesData = async () => {
    setShowLoader(true);
    try {
      let filters = {};
      Object.keys(resourcesFilter).forEach((key) => {
        if (resourcesFilter[key]) filters[key] = resourcesFilter[key];
      });
      if (debouncedSearchText && debouncedSearchText !== "") {
        filters.search = debouncedSearchText;
      }

      let resourcesData = await dispatch(getResources(filters));
      let resourceTypesFoundInData = [];
      resourcesData = resourcesData.map((rd) => {
        resourceTypesFoundInData.push({
          id: rd.resourceTypeId,
          name: rd.resourceType,
        });
        return {
          ...rd,
          disciplineName: rd.discipline,
        };
      });
      setResources(resourcesData);
      setResourceTypeOptions(
        generateTypeOptions(resourceTypesFoundInData, "name", "id")
      );
    } catch (err) {
      setResources([]);
    } finally {
      setShowLoader(false);
    }
  };

  const recordClick =  async (id, type) => {
    await dispatch(recordEvent({
      id, 
      type,
      event : eventTypes.CLICK
    }));
  }

  useEffect(async () => {
    await getInitialData();
    isInitialRender.current = false;
    if (tab === COURSES) {
      getCoursesData();
    } else if (tab === RESOURCES) {
      getResourcesData();
    }
  }, []);

  useEffect(() => {
    HeaderMetaData.setHeaderMetadata({
      headerTitle: "Scaling Resources",
      HeaderActions: () => null,
    });
  }, []);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (tab === COURSES) {
        getCoursesData();
      } else if (tab === RESOURCES) {
        getResourcesData();
      }
    }
  }, [tab, author, category, debouncedSearchText, resourcesFilter]);

  useEffect(() => {
    localStorage.setItem("academyTab", tab);
  }, [tab]);

  //Render Functions
  const renderHeader = () => {
    const filters = [
      <StandardInput
        className="ms-0 ms-lg-4"
        inputClasses={"search-box"}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        type="text"
        placeholder={`Search ${
          tab === COURSES ? "Courses" : tab === RESOURCES ? "Resources" : ""
        }`}
        afterIcon={<span class="material-icons">search</span>}
      />,
    ];
    if (tab === COURSES) {
      filters.push(
        <StandardSelect
          className="ms-0 ms-lg-4"
          value={category}
          placeholder="Select Category"
          options={categories}
          onChange={(e) => setCategory(e.value)}
        />
      );
      filters.push(
        <StandardSelect
          value={author}
          className="ms-0 ms-lg-4"
          placeholder="Select Author"
          options={authors}
          onChange={(e) => setAuthor(e.value)}
        />
      );
    } else if (tab === RESOURCES) {
      filters.push(
        <StandardSelect
          className="ms-0 ms-lg-4"
          value={resourcesFilter.disciplineId}
          placeholder="Filter by Discipline"
          options={disciplineOptions}
          onChange={(e) =>
            setResourcesFilter((prev) => ({
              ...prev,
              disciplineId: e ? e.value : null,
            }))
          }
          isClearable={true}
        />
      );
      filters.push(
        <StandardSelect
          value={resourcesFilter.typeIds}
          className="ms-0 ms-lg-4"
          placeholder="Filter by Type"
          options={resourceTypeOptions}
          onChange={(e) =>
            setResourcesFilter((prev) => ({
              ...prev,
              typeIds: e ? e.value : null,
            }))
          }
          isClearable={true}
        />
      );
    }

    return (
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="toggler flex-fill">
                  <StandardButton
                    color={tab === COURSES ? "active" : ""}
                    bold={true}
                    text="Courses & Content"
                    onClick={() => {
                      setTab(COURSES);
                    }}
                  />
                  <StandardButton
                    color={tab === RESOURCES ? "active" : ""}
                    bold={true}
                    text="Resources"
                    onClick={() => {
                      setTab(RESOURCES);
                    }}
                  />
                </div>
                <div
                  className="ms-3 d-inline-block d-lg-none"
                  id="filterToggle"
                  onClick={() => setShowFilters((prev) => !prev)}
                >
                  <span
                    className="material-icons search-icon"
                    style={{ position: "relative", top: "4px" }}
                  >
                    search
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-8">
              {/* on large screens show filters */}
              <div className="d-none d-lg-inline">
                <div className="row">
                  {filters.map((fil) => (
                    <div className="col-12 col-lg-4 ps-0">{fil}</div>
                  ))}
                </div>
              </div>
              {/* on small screens show filters only when toggled */}
              <div className="d-inline d-lg-none">
                {showFilters ? (
                  <div className="row">
                    {filters.map((fil) => (
                      <div className="col-12 col-lg-4">{fil}</div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const renderUpgradePlanPopup = () => {
    if (upgradePopup) {
      return (
        <UpgradeConfirmationPopup
          title={"Upgrade"}
          content={<>Talk to us to unlock access to resources, it’s free!</>}
          closePopup={() => setUpgradePopup(false)}
          isOpen={upgradePopup}
          upgradePopup={true}
        />
      );
    }
  };

  

  if (showLoader) {
    return <LoadingAnimation />;
  }

  
  return (
    <div className="academy-home">
      {renderHeader()}
      <div className={tab === RESOURCES && demoAccess ? "row blocked" : "row"}>
        {tab === COURSES ? (
          courses.length > 0 ? (
            courses.map((course) => {
              return (
                <div className="col-12 col-md-6 col-xl-4 py-3">
                  <CourseCard onClick={() => {recordClick(course.id, contentTypes.COURSE)}} course={course} key={course.id} />
                </div>
              );
            })
          ) : (
            <h3 className="cs-sub-heading mt-3">No Courses & Content found</h3>
          )
        ) : null}
        {tab === RESOURCES ? (
          resources.length > 0 ? (
            resources.map((resource) => (
              <div
                className={
                  demoAccess
                    ? "col-12 col-md-6 col-xl-4 py-3 pe-none"
                    : "col-12 col-md-6 col-xl-4 py-3"
                }                
              >
                <ResourceCard onClick={() => {recordClick(resource.id, contentTypes.RESOURCE)}} resource={resource} key={resource.id} />
              </div>
            ))
          ) : (
            <h3 className="cs-sub-heading mt-3">No Resources found</h3>
          )
        ) : null}
      </div>
      {tab === RESOURCES && demoAccess ? (
        <div className="row">
<div className="col-12 col-md-6 col-xl-4 offset-md-3 offset-xl-4">
          <Card className="upgrade-area">
        <div className="card-body p-5">
          

        <div className="d-flex text-center flex-column">
          <img src="/assets/images/academy-upgrade.svg" alt="Upgrade" />
          <div className="my-4">Talk to us to unlock access to resources, it’s free!</div>
          <StandardButton
            color="btn-success btn-sm mx-auto"  
            text={"Talk to our team"}
            onClick={() => {
              setUpgradePopup(true);
            }}
          ></StandardButton>
        </div>
        </div>
          </Card>
          </div>                  
        </div>
        
      ) : null}
      {renderUpgradePlanPopup()}
    </div>
  );
};
