import React from 'react';
import { Card, Image } from 'react-bootstrap';
import './no-data.scss';

const NoData = ({ title, content, CTAs, style, imageSrc }) => {
  return (
    <Card className="no-data-card" style={style}>
      <Card.Body className="text-center mt-4 d-flex flex-column justify-content-center align-items-center">
        <div className="title mb-4">{title}</div>
        <Image
          className="mb-4"
          src={imageSrc ?? '/assets/images/data-not-found.svg'}
          fluid
        />
        <div className="content mb-4 text-muted">{content}</div>
        {CTAs}
      </Card.Body>
    </Card>
  );
};

export default NoData;
