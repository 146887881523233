import React from 'react'
import { useHistory } from 'react-router-dom'

const StandardBackButton = () => {
    const history = useHistory()
    return (
        <div className="back-button-div mb-2 d-flex align-items-center" onClick={() => history.goBack()}>
            <img src="/assets/images/back.svg" alt="back" />
            <span className="ms-1">Back</span>
        </div>
    )
}

export default StandardBackButton;