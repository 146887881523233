import React, { Component } from 'react';
import './standard-input.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import validationUtils from '../../../../utils/validationUtils';

class StandardInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputId: this.props.id,
      valid: false,
      errorText: '',
      dirty: false,
      showPassword: false,
    };
  }

  componentDidMount() {
    this.checkValidity(this.props.value);
  }
  getStandardInputClass = () => {
    const { disabled, className } = this.props;
    return classNames('standard-input', 'position-relative', 'form-group', {
      disabled: disabled,
      [className]: className,
      error: (this.state.dirty || this.props.showError) && !this.state.valid,
    });
  };

  getInputClassName = () => {
    const { error, inputClasses } = this.props;
    return classNames('form-control', {
      error: error,
      [inputClasses]: inputClasses,
      'has-feedback': this.props.type === 'password',
      'after-icon': this.props.afterIcon,
    });
  };

  renderLabel = () => {
    const { inputId } = this.state;
    const { label, showRequired } = this.props;
    let classes = showRequired ? 'required' : '';
    if (label) {
      return <label htmlFor={inputId} className={classes}>{label}</label>;
    }
    return null;
  };

  renderHelp = () => {
    const { helpText } = this.props;

    if (helpText) {
      return <p className="help-text mb-0 mt-2">{helpText}</p>;
    }
    return null;
  };

  renderError = () => {
    const { errorText } = this.state;

    if (errorText && (this.state.dirty || this.props.showError)) {
      return <p className="error-text mb-0 mt-2">{errorText}</p>;
    }
    return null;
  };

  checkValidity = (value) => {
    let isValid = true;
    let errorText = '';
    for (let validation of this.props.validations || []) {
      if (
        !validation.disabled &&
        !validationUtils[validation.type](value, validation.value)
      ) {
        errorText = validation.message;
        isValid = false;
        break;
      }
    }
    this.setState({
      valid: isValid,
      errorText: errorText,
    });
  };

  checkCustomValidity = (e) => {
    if (this.props.checkValidity) {
      const { isValid, errorText } = this.props.checkValidity(e.target.value);
      this.setState({
        valid: isValid,
        errorText: errorText,
      });
    }
  };

  onChange = (e) => {
    if (this.props.overloadValidity) {
      this.checkCustomValidity(e);
    } else {
      this.checkValidity(e.target.value);
      this.checkCustomValidity(e);
    }
    this.props.onChange(e);
  };

  onBlur = () => {
    this.setState({
      dirty: true,
    });
  };

  toggleShowPassword = () => {
    this.setState((state, props) => ({
      showPassword: !state.showPassword,
    }));
  };

  getIcon = () => {
    return this.state.showPassword ? 'visibility_off' : 'visibility';
  };

  render() {
    const { inputId, showPassword } = this.state;
    const { placeholder, disabled, value, type, innerRef } = this.props;

    return (
      <div className={this.getStandardInputClass()}>
        {this.renderLabel()}
        <input
          {...this.props.inputProps}
          type={showPassword ? 'text' : type}
          className={this.getInputClassName()}
          id={inputId}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyUp={this.props.onKeyUp}
          onKeyPress={this.props.onKeyPress}
          onKeyDown={this.props.onKeyDown}
          pattern={this.props.pattern}
          inputMode={this.props.inputmode}
          formNoValidate={false}
          onPaste={this.props.onPaste}
          ref={innerRef ?? null}
        />

        {this.props.icon ? (
          <label className="icon-text" htmlFor={inputId}>
            {this.props.icon}
          </label>
        ) : null}

        {this.props.afterIcon ? (
          <label className="icon-text after" htmlFor={inputId}>
            {this.props.afterIcon}
          </label>
        ) : null}

        {this.props.afterButtonIcon ? this.props.afterButtonIcon : null}

        {this.props.type === 'password' ? (
          <div className="icon" onClick={this.toggleShowPassword}>
            <span className="material-icons">{this.getIcon()}</span>
          </div>
        ) : null}

        {this.state.valid ? this.renderHelp() : this.renderError()}
      </div>
    );
  }
}

StandardInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  helpText: PropTypes.string,
  errorText: PropTypes.string,
  error: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterButtonIcon: PropTypes.func,
  checkValidity: PropTypes.func,
  overloadValidity: PropTypes.bool,
  inputProps: PropTypes.object,
};

StandardInput.defaultProps = {
  type: 'text',
  disabled: false,
  error: false,
  placeholder: '',
  value: '',
  overloadValidity: false,
};

export default StandardInput;
