export const QuestUrls = {
    QUEST_TEAM_SUCCESS_SCORE: "/quest/team_success_score",
  QUEST_LAST_WEEK_TEAM_SUCCESS: "/quest/last_week_team_success/{weekId}",
  QUEST_ACTION_PANEL: "/quest/action_panel",
  QUEST_PLAYBOOKS: "/jobseekers/{jobseeker_id}/courses",
  QUEST_IMPROVE_TEAM_SCORE: "/quest/improving_your_team_score",
  QUEST_IMPROVE_YOUR_SCORE: "/quest/improving_your_score",
  QUEST_TEAM_HABIT_MYSTERY: "/quest/team_habit_mastery/{weekId}",
  QUEST_WEEK_LIST: "/quest/week_list",

  // QUEST COMMON DATA
  QUEST_COMMON_DATA: "/quest/quest_common_data",

  QUEST_SCORE_BREAKDOWN: "/quest/score_break_down",
  QUEST_USER_WEEK_LIST: "/quest/user_week_list",
};