import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentDiscipline: {},
  users: [],
  surveyDetails: {},
  xRayState: {},
  disciplines: [],
  coachRecommendations: [],
  defaultCoachRecommendations: [],
  defaultRecommedations: [],
};

function setCustomRank(discRecom) {
  if (!discRecom) return discRecom;
  return discRecom.map((recom, index) => {
    return { ...recom, customRank: index + 1 };
  });
}

function setOverallRank(discRecoms, updatedRecoms) {
  return discRecoms.map((discRecom) => {
    const index = updatedRecoms.findIndex((r) => r.id === discRecom.id);
    if (index < 0) return { ...discRecom, overallRank: null };
    else
      return {
        ...updatedRecoms[index],
        overallRank: index + 1,
      };
  });
}

const XraySlice = createSlice({
  name: "xrayReport",
  initialState: initialState,
  reducers: {
    // recommendationsData: (state, actions) => {
    //   // state.disciplines = [];
    //   // state.coachRecommendations = [];
    //   // state.defaultCoachRecommendations = [];
    //   // state.defaultRecommedations = [];
    // },
    setCurrentDiscipline: (state, actions) => {
      state.currentDiscipline = actions.payload;
    },
    setUsers: (state, actions) => {
      const { users } = actions.payload;
      state.users = users;
    },
    setSurveyDetails: (state, actions) => {
      state.surveyDetails = { ...state.surveyDetails, ...actions.payload };
    },
    setXRayState: (state, actions) => {
      state.xRayState = actions.payload;
    },
    setDisciplineRead: (state, actions) => {
      const { disciplineId } = actions.payload;
      const index = state.disciplines.findIndex(
        (e) => e.disciplineId === disciplineId
      );
      if (index >= 0) state.disciplines[index].read = true;
    },
    setCoachRecommendations: (state, actions) => {
      let {
        discipline,
        recommendations,
        defaultRecommendations,
        isInitial,
        disciplines,
      } = actions.payload;
      if (isInitial) {
        state.defaultCoachRecommendations = recommendations;
        state.disciplines = disciplines;
      }
      if (discipline === null || discipline !== "Overall") {
        if (Array.isArray(recommendations)) {
          recommendations = setCustomRank(recommendations);
        } else {
          Object.keys(recommendations).forEach((disc) => {
            recommendations[disc] = setCustomRank(recommendations[disc]);
          });
        }
      }
      if (discipline) {
        if (discipline === "Overall") {
          Object.keys(state.coachRecommendations).forEach((disc) => {
            state.coachRecommendations[disc] = setOverallRank(
              state.coachRecommendations[disc],
              recommendations
            );
          });
        } else {
          state.coachRecommendations[discipline] = recommendations;
        }
      } else state.coachRecommendations = recommendations;
      state.defaultRecommedations = defaultRecommendations;
    },
  },
});
export default XraySlice;
