import React from "react";
import { Modal } from "react-bootstrap";
import {
  BigBoldText,
  BoldText,
  LightText,
  SmallLightText,
} from "../../../utils/typographyUtils";
import StandardButton from "../formComponents/StandardButton/StandardButton";
import "./standard-popup.scss";
import { ConditionalRender } from "../../shared/ConditionalRender/ConditionalRender";
import classNames from "classnames";

export const StandardPopup = ({
  size = "lg",
  imageName,
  applyCustomWidth,
  confirmButtonVariant,
  noIconButton,
  hideFooter,
  showBackIcon,
  onClose,
  headerTitle,
  headerSubtitle,
  noFooterPadding,
  noBodyPadding,
  bodyContent,
  cancelButtonText,
  onCancel,
  confirmButtonText,
  hideCloseButton,
  onConfirm,
  disabled,
  isLoading,
  hideStartActionButton,
  footerContent,
  hideCancelButton,
  disabledButtonVariant,
}) => {
  const renderButtons = () => {
    let buttons = [];
    if (!hideCancelButton) {
      buttons.push(
        <StandardButton
          text={cancelButtonText ? cancelButtonText :"Cancel"}
          icon={
            showBackIcon ? <i class="fa-solid fa-chevron-right ms-2"></i> : null
          }
          disabled={isLoading}
          color="btn-outline-dark"
          className={` font-weight-normal py-2 me-3`}
          onClick={onCancel}
        />,
      );
    }
    if (!hideStartActionButton) {
      buttons.push(
        <StandardButton
          icon={
            noIconButton ? null : <i class="fa-solid fa-chevron-right ms-2"></i>
          }
          disabled={disabled || isLoading}
          isLoading={isLoading}
          text={`${confirmButtonText ? confirmButtonText : "Confirm"}`}
          color={`${disabled && disabledButtonVariant
              ? disabledButtonVariant
              : confirmButtonVariant
                ? confirmButtonVariant
                : "btn-success"
            }`}
          className="py-2"
          onClick={onConfirm}
        />,
      );
    }
    let classes = classNames("d-flex w-100 align-items-center m-0", {
      "justify-content-between": buttons.length > 1,
      "justify-content-center": buttons.length === 1,
    });
    return (
      <div id="btn-div" className={classes}>
        {buttons}
      </div>
    );
  };

  let bodyClasses = classNames({
    "p-0": noBodyPadding,
    "p-4": !noBodyPadding,
  });

  let footerClasses = classNames("text-start d-flex justify-content-start", {
    "p-0": noFooterPadding,
    "p-4": !noFooterPadding,
  });

  let dialogClassName = classNames({
    "popup-w-600": applyCustomWidth,
    "standard-popup": !applyCustomWidth,
  });

  return (
    <Modal show centered size={size} dialogClassName={dialogClassName}>
      <Modal.Header>
        <div
          className="d-flex w-100 align-items-top"
          id="standard-popup-header"
        >
          <div className="d-flex align-items-top">
            <div id="standard-popup-header-image">
              <img src={`/assets/images/${imageName}`} alt={imageName} />
            </div>
            <div className="d-flex flex-column justify-content-center ms-3 me-3">
              <BoldText children={headerTitle} />
              <LightText children={headerSubtitle} />
            </div>
          </div>
          {hideCloseButton ? null : (
            <div>
              {isLoading ? (
                <img
                  src="/assets/images/close_icon_disabled.svg"
                  className="cursor-pointer ms-auto"
                  alt="close-disabled"
                />
              ) : (
                <img
                  onClick={onClose}
                  src="/assets/images/close_icon.svg"
                  className="cursor-pointer ms-auto"
                  alt="close"
                />
              )}
            </div>
          )}
        </div>
      </Modal.Header>
      <Modal.Body className={bodyClasses}>{bodyContent}</Modal.Body>
      <ConditionalRender condition={!hideFooter}>
        <Modal.Footer className={footerClasses}>
          {renderButtons()}
          {footerContent}
        </Modal.Footer>
      </ConditionalRender>
    </Modal>
  );
};
