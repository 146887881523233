import axios from "axios";
// import { AcademySlice } from "./AcademySlice";
import { errorNoti } from "../../../../base/BasicNotifications";
import { AcademyUrls } from "./AcademyUrls";
import queryString from "query-string";
import { getErrorMessage } from "../../../../utils/generalUtils";
import { ACADEMY } from "../../../../data/RouteUrls";
// const { actions } = AcademySlice;

const lmsInstance = axios.create({ baseURL: process.env.REACT_APP_LMS_URL });
const instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
const nodeInstance = axios.create({
  baseURL: process.env.REACT_APP_NEST_BASE_URL,
});

export const getCourses = (type, filters) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let userId = getState().authDetails.userProfile.id;

    let url = AcademyUrls.COURSES.replace("{jobseeker_id}", userId);
    if (filters) {
      if (type === "my") {
        filters.jobseeker_id = userId;
      }
      url += "/filter?" + queryString.stringify(filters);
    } else if (type === "all") {
      url += "/all";
    }
    lmsInstance
      .get(url, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((responses) => {
        resolve([...responses.data.otherPlaybooks || [], ...responses.data.roleRelatedPlaybooks || []]);
      })
      .catch((err) => {
        resolve(false);
        console.log(err);
        errorNoti(err.response.data.message);
      });
  });
};

export const getCourseDetail = (courseId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let userId = getState().authDetails.userProfile.id;
    let url = AcademyUrls.COURSE_OVERVIEW.replace(
      "{jobseeker_id}",
      userId
    ).replace("{id}", courseId);
    lmsInstance
      .get(url, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((responses) => {
        resolve(responses.data);
      })
      .catch((err) => {
        resolve(false);
        console.log(err);
        errorNoti(err.response.data.message);
      });
  });
};

export const getCourseContent = (courseId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let userId = getState().authDetails.userProfile.id;
    let url = AcademyUrls.COURSE_CONTENT.replace(
      "{jobseeker_id}",
      userId
    ).replace("{id}", courseId);
    lmsInstance
      .get(url, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((responses) => {
        resolve(responses.data);
      })
      .catch((err) => {
        resolve(false);
        console.log(err);
        errorNoti(err.response.data.message);
      });
  });
};

export const getCategories = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    lmsInstance
      .get(AcademyUrls.CATEGORIES, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((responses) => {
        resolve(responses.data);
      })
      .catch((err) => {
        resolve(false);
        console.log(err);
        errorNoti(err.response.data.message);
      });
  });
};

export const getAuthors = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    lmsInstance
      .get(AcademyUrls.AUTHORS, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((responses) => {
        resolve(responses.data);
      })
      .catch((err) => {
        resolve(false);
        console.log(err);
        errorNoti(err.response.data.message);
      });
  });
};

export const getDisciplines = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let url = AcademyUrls.DISCIPLINES;
    instance
      .get(url, {
        headers: { Authorization: getState().authDetails.accessToken },
      })
      .then((response) => resolve(response.data.data))
      .catch((err) => {
        let errMsg = getErrorMessage(err);
        errorNoti(errMsg);
        reject(errMsg);
      });
  });
};

export const getResourceTypes = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let url = AcademyUrls.RESOURCE_TYPES;
    instance
      .get(url, {
        headers: { Authorization: getState().authDetails.accessToken },
      })
      .then((response) => resolve(response.data.data))
      .catch((err) => {
        let errMsg = getErrorMessage(err);
        errorNoti(errMsg);
        reject(errMsg);
      });
  });
};

export const getResources = (filters) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let url = AcademyUrls.RESOURCES + "?" + queryString.stringify(filters);
    instance
      .get(url, {
        headers: { Authorization: getState().authDetails.accessToken },
      })
      .then((response) => resolve(response.data.data))
      .catch((err) => {
        let errMsg = getErrorMessage(err);
        errorNoti(errMsg);
        reject(errMsg);
      });
  });
};

export const getResourceDetail = (resourceId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let url = AcademyUrls.RESOURCE_DETAIL.replace("{resourceId}", resourceId);
    instance
      .get(url, {
        headers: { Authorization: getState().authDetails.accessToken },
      })
      .then((response) => resolve(response.data.data))
      .catch((err) => {
        let errMsg = getErrorMessage(err);
        errorNoti(errMsg);
        reject(errMsg);
      });
  });
};

export const enrollCourseAction = (courseId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let userId = getState().authDetails.userProfile.id;
    let url = AcademyUrls.COURSE_ENROLL.replace(
      "{jobseeker_id}",
      userId
    ).replace("{id}", courseId);
    lmsInstance
      .post(
        url,
        {},
        {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        }
      )
      .then((responses) => {
        resolve(responses.data);
      })
      .catch((err) => {
        resolve(false);
        console.log(err);
        errorNoti(err.response.data.message);
      });
  });
};

export const chapterCompleteAction = (chapterId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let userId = getState().authDetails.userProfile.id;
    let url = AcademyUrls.COMPLETE_CHAPTER.replace(
      "{jobseeker_id}",
      userId
    ).replace("{chapterId}", chapterId);
    lmsInstance
      .post(
        url,
        {},
        {
          headers: {
            Authorization: getState().authDetails.accessToken,
          },
        }
      )
      .then((responses) => {
        resolve(responses.data);
      })
      .catch((err) => {
        resolve(false);
        console.log(err);
        errorNoti(err.response.data.message);
      });
  });
};

export const submitFeedback = (courseId, data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    data.jobseeker_id = getState().authDetails.userProfile.id;
    data.email = getState().authDetails.userProfile.email;
    let url = AcademyUrls.FEEDBACK.replace("{courseId}", courseId);
    lmsInstance
      .post(url, data, {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      })
      .then((responses) => {
        resolve(responses.data);
      })
      .catch((err) => {
        resolve(false);
        console.log(err);
        errorNoti(err.response.data.message);
      });
  });
};

export const recordEvent = (data) => (dispatch, getState) => {
  nodeInstance
    .post(AcademyUrls.REGISTER_EVENT, data, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((responses) => {})
    .catch((err) => {
      console.log(err);
    });
};
