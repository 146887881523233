import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingAnimation from '../../../../../base/LoadingAnimation';
import * as actions from '../../_redux/xrayActions';
import StandardButton from '../../../../shared/formComponents/StandardButton/StandardButton';
import './divergence-card.scss';
import { getGreenToRed } from '../../../../../utils/generalUtils';
import { useParams } from 'react-router-dom';

export const DivergenceCard = (props) => {
  const dispatch = useDispatch();
  const [dataType, setDataType] = useState('stackholder');
  const [showLoader, setShowLoader] = useState(true);
  const [divergenceScore, setDivergenceScore] = useState();
  const params = useParams();
  const currentDiscipline = useSelector(
    (state) => state.xrayReport.currentDiscipline
  );
  const getDivergenceScore = async () => {
    setShowLoader(true);
    let divergenceData = await dispatch(
      actions.getDivergenceScore(
        params.userId,
        dataType,
        currentDiscipline.disciplineId
      )
    );
    let formattedDivergenceData = {
      headings: [],
      rows: [],
    };
    if (currentDiscipline.disciplineId) {
      formattedDivergenceData.rows = divergenceData.discipline.subDisciplines;
    } else {
      formattedDivergenceData.rows = divergenceData.disciplines;
    }

    let idKey = dataType === 'stackholder' ? 'teamMemberId' : 'roleId';
    let groupedInfo = new Map();
    formattedDivergenceData.rows.forEach((row) =>
      row.data.forEach((tm) => groupedInfo.set(tm[idKey], tm.name))
    );
    let columnNames = [...groupedInfo.values()];
    let columnIds = [...groupedInfo.keys()];

    columnNames.forEach((name) => formattedDivergenceData.headings.push(name));

    for (let row of formattedDivergenceData.rows) {
      let highestScore = 0,
        lowestScore = 5;
      let companyScore = row.actualScore;
      row.data = columnIds.map((tmId) => {
        let columnIndex = row.data.findIndex((tm) => tm[idKey] === tmId);
        return columnIndex >= 0 ? row.data[columnIndex] : null;
      });
      for (let score of row.data) {
        if (score === null) continue;
        let currentScore = Math.abs(score.actualScore - companyScore);
        if (currentScore > highestScore) {
          highestScore = currentScore;
        }
        if (currentScore < lowestScore) {
          lowestScore = currentScore;
        }
      }
      let difference = highestScore - lowestScore;
      row.isCalculate = false;
      for (let memberIdx in row.data) {
        let score = row.data[memberIdx];
        if (score === null) {
          row.data[memberIdx] = { color: '#BBBBBB' };
          continue;
        }
        let currentScore = Math.abs(score.actualScore - companyScore);
        let pct =
          difference === 0
            ? 0
            : 100 - (100 * (highestScore - currentScore)) / difference;
        row.isCalculate |= score.isCalculate;
        score.color =
          score.isCalculate && score.isActive ? getGreenToRed(pct) : '#BBBBBB';
      }
    }
    setDivergenceScore(formattedDivergenceData);
    setShowLoader(false);
  };
  useEffect(() => {
    // This code runs on loading the component
    getDivergenceScore();
  }, [currentDiscipline, dataType]);

  if (showLoader) {
    return (
      <div style={{ height: '40vh' }}>
        <LoadingAnimation
          style={{
            display: 'block',
            justifyContent: 'center',
            textAlign: 'center',
            position: 'relative',
            top: '50%',
          }}
        />
      </div>
    );
  }

  const renderDivergenceMatrix = () => {
    return (
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th scope="col"></th>
            <th scope="col" className="text-center">
              Company Score
            </th>
            {divergenceScore.headings.length <= 10 ? (
              <th scope="col" className="text-center">
                Divergence
              </th>
            ) : null}
            {divergenceScore.headings.map((heading) => {
              return (
                <th scope="col" className="text-center">
                  {heading}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {divergenceScore.rows.map((row) => {
            let divergenceClasses = 'material-icons mt-1 mr-3 ';
            divergenceClasses += row.isRoleDivergence ? 'check' : 'clear';
            return (
              <tr>
                <td>{row.name}</td>
                <td className="text-center">
                  {row.isCalculate ? row.actualScore.toFixed(1) : ''}
                </td>
                {divergenceScore.headings.length <= 10 ? (
                  <td className="text-center">
                    <span className={divergenceClasses}>
                      {row.isRoleDivergence ? 'check' : 'clear'}
                    </span>
                  </td>
                ) : null}

                {row.data.map((column) => {
                  return (
                    <td
                      className="text-center"
                      style={{
                        backgroundColor: column.color,
                      }}
                    >
                      {column.isActive ? column.actualScore.toFixed(1) : ''}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  return (
    <div className="divergence-card">
      <div className="bg-grey p-3">
        <div className="d-flex justify-content-between">
          <div className="toggler">
            <StandardButton
              color={dataType === 'role' ? 'active' : ''}
              bold={true}
              text="Role"
              onClick={() => {
                setDataType('role');
              }}
            ></StandardButton>
            <StandardButton
              color={dataType === 'stackholder' ? 'active' : ''}
              bold={true}
              text="Stakeholder"
              onClick={() => {
                setDataType('stackholder');
              }}
            ></StandardButton>
          </div>
        </div>
      </div>
      <div className="px-4 py-3">{renderDivergenceMatrix()}</div>
    </div>
  );
};
