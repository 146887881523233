import React, { useState, useEffect } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import moment from "moment";

import LoadingAnimation from "../../../../base/LoadingAnimation";
import StandardButton from "../../../shared/formComponents/StandardButton/StandardButton";

import {
  getSurveyProgress,
  getSurveyProgressFromNode,
} from "../../Xray/_redux/xrayActions";

import "./progress-card-popup.scss";

// as this popup will only be shown to the accounts whose xray isn't complete
// so xray will always be not reviewed.
const xrayReviewed = false;

const ProgressCardPopup = ({
  open,
  handleCancel,
  companyName,
  userId,
  surveyId,
}) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);
  const [userProgress, setUserProgress] = useState({ notStarted: true });
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleHide = () => {
    setShowLoader(true);
    setUserProgress({ notStarted: true });
    handleCancel();
  };

  const fetchProgressOfUser = async () => {
    // let progress = await dispatch(getSurveyProgress(userId));

    let progress = await dispatch(getSurveyProgressFromNode(surveyId));

    if (progress && progress.dueDate) {
      setUserProgress(progress);
    } else {
      setUserProgress({ ...progress, notStarted: true });
    }
    setShowLoader(false);
  };

  useEffect(() => {
    if (open && userId) {
      fetchProgressOfUser();
    }
  }, [open, userId]);

  const renderPill = () => {
    const dueDatePassed = new Date() > new Date(userProgress.dueDate);
    if (userProgress.notStarted) {
      return (
        <div>
          <div className="status-pill dark-red">Not Started</div>
        </div>
      );
    }
    return userProgress?.completed ? (
      !xrayReviewed ? (
        <div>
          <div className="status-pill yellow">Awaiting Coach Review</div>
        </div>
      ) : (
        <div>
          <div className="status-pill green">Complete</div>
        </div>
      )
    ) : dueDatePassed ? (
      <div>
        <div className="status-pill dark-magenta">Extend Due-Date</div>
      </div>
    ) : (
      <div>
        <div className="status-pill red">In Progress</div>
      </div>
    );
  };

  const updateExpandedIndex = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const renderDisciplineData = () => {
    return (
      <>
        <div class="accordion" id="disciplineData">
          {userProgress.disciplines.map((discipline, index) => {
            return (
              <>
                <div className="row mx-0">
                  <div className="col-12" id={`discipline${index}`}>
                    <div
                      onClick={() => updateExpandedIndex(index)}
                      className={`row align-items-center collapsed mb-0 discipline-card my-2 p-3 ${
                        expandedIndex == index
                          ? "no-bottom-radius"
                          : "border-all-radius"
                      }`}
                      data-bs-toggle="collapse"
                      data-bs-target={`#disciplineCollapse${index}`}
                      aria-expanded="false"
                      aria-controls={`disciplineCollapse${index}`}
                    >
                      <div className="col-4">
                        <div className="d-flex align-items-center">
                          <img src="" />
                          <div className="discipline-text">
                            {discipline.disicplineName}
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="d-flex align-items-center justify-content-end ">
                          {discipline.status == "Not Started" ? (
                            <div className="status-pill grey py-0">
                              Not Started
                            </div>
                          ) : discipline.status == "In Progress" ? (
                            <div className="status-pill red py-0">
                              In Progress
                            </div>
                          ) : discipline.status == "Complete" ? (
                            <div className="status-pill py-0 green">
                              Complete
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-4">
                        <StandardButton
                          className="d-flex align-items-center justify-content-center expand-btn ms-auto"
                          color="btn-outline-dark"
                          bold={true}
                          onClick={() => updateExpandedIndex(index)}
                          icon={
                            index === expandedIndex ? (
                              <span className="material-icons">
                                expand_less
                              </span>
                            ) : (
                              <span className="material-icons">
                                expand_more
                              </span>
                            )
                          }
                          text={index === expandedIndex ? "Collapse" : "Expand"}
                        ></StandardButton>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        id={`disciplineCollapse${index}`}
                        class="collapse discipline-content p-3"
                        aria-labelledby={`discipline${index}`}
                        data-bs-parent="#disciplineData"
                      >
                        <div className="">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: "25%" }}>
                                  Participant
                                </th>
                                <th scope="col" style={{ width: "25%" }}>
                                  Role
                                </th>
                                <th scope="col" style={{ width: "25%" }}>
                                  Progress
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {renderTeamMemberRows(discipline.teamMembers)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id={`discipline${index}`}>
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#disciplineCollapse${index}`}
                      aria-expanded="false"
                      aria-controls={`disciplineCollapse${index}`}
                    >
                      Accordion Item #1
                    </button>
                  </h2>
                  <div
                    id={`disciplineCollapse${index}`}
                    class="accordion-collapse collapse "
                    aria-labelledby={`discipline${index}`}
                    data-bs-parent="#disciplineData"
                  >
                    <div class="accordion-body">
                      <strong>This is the first item's accordion body.</strong>{" "}
                      It is shown by default, until the collapse plugin adds the
                      appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div> */}
              </>
            );
          })}
        </div>
      </>
    );
  };

  const renderTeamMemberRows = (teamMembers) => {
    let teamMemberRows = [];

    for (let teamMember of teamMembers) {
      let userProgress;
      if (teamMember.progress === 100) {
        userProgress = (
          <>
            <ProgressBar
              className="complete me-3"
              now={parseInt(teamMember.progress)}
            />
            {parseInt(teamMember.progress)}%<br />
          </>
        );
      } else if (teamMember.progress === 0) {
        userProgress = <div className="status-pill grey">Not Started</div>;
      } else {
        userProgress = (
          <>
            <ProgressBar
              className="in-progress me-4"
              now={parseInt(teamMember.progress)}
            />
            {parseInt(teamMember.progress)}%
          </>
        );
      }
      teamMemberRows.push(
        <tr>
          <td>
            <div className="d-flex align-items-center h-100">
              {teamMember.name}
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center h-100">
              {teamMember.role} {teamMember?.isMainUser ? "(You)" : ""}
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center h-100">
              {userProgress}
            </div>
          </td>
        </tr>
      );
    }

    return teamMemberRows;
  };

  // const renderTeamMemberList = () => {
  //   let teamMemberItems = [];
  //   for (let discipline of userProgress.disciplines) {
  //     for (let teamMember of discipline.teamMembers) {
  //       let userProgress;
  //       if (teamMember.progress === 100) {
  //         userProgress = (
  //           <>
  //             <ProgressBar
  //               className="complete me-3"
  //               now={parseInt(teamMember.progress)}
  //             />
  //             {parseInt(teamMember.progress)}%<br />
  //           </>
  //         );
  //       } else if (teamMember.progress === 0) {
  //         userProgress = <div className="status-pill grey">Not Started</div>;
  //       } else {
  //         userProgress = (
  //           <>
  //             <ProgressBar
  //               className="in-progress me-4"
  //               now={parseInt(teamMember.progress)}
  //             />
  //             {parseInt(teamMember.progress)}%
  //           </>
  //         );
  //       }
  //       teamMemberItems.push(
  //         <li className="row">
  //           <div className="col-6 col-sm-4 mb-2 mb-sm-0">
  //             <label className="field-label">Participant</label>
  //             <div className="alt-success">{teamMember.name}</div>
  //           </div>
  //           <div className="col-6 col-sm-4 mb-2 mb-sm-0">
  //             <label className="field-label">Role</label>
  //             <div className="alt-label">
  //               {teamMember.role} {teamMember?.isMainUser ? "(You)" : ""}
  //             </div>
  //           </div>

  //           <div className="col-6 col-sm-4 mb-2 mb-sm-0">
  //             <label className="field-label">Role</label>
  //             <div>{userProgress}</div>
  //           </div>
  //         </li>
  //       );
  //     }
  //   }
  //   return <ul className="team-member-list">{teamMemberItems}</ul>;
  // };

  return (
    <Modal
      show={open}
      onHide={handleHide}
      dialogClassName="progress-card"
      size="lg"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Progress of <span className="alt-primary">{companyName}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showLoader ? (
          <LoadingAnimation
            style={{
              display: "block",
              justifyContent: "center",
              textAlign: "center",
              position: "relative",
              top: "40%",
            }}
          />
        ) : (
          <>
            <div className="px-3">
              <div className="d-flex align-items-center flex-row-reverse flex-md-row mb-4">
                <div className="d-flex flex-column flex-md-row">
                  {userProgress.startDate ? (
                    <div>
                      <img
                        src="/assets/images/calendar_start.svg"
                        alt=""
                        className="me-md-2  d-none d-md-inline"
                      />
                      Started:{" "}
                      {moment(new Date(userProgress.startDate)).format(
                        "D MMM YYYY"
                      )}
                    </div>
                  ) : null}

                  {userProgress.dueDate ? (
                    <div>
                      <img
                        src="/assets/images/calendar_end.svg"
                        alt=""
                        className="ms-sm-3 me-sm-2 d-none d-md-inline"
                      />
                      Due:{" "}
                      {moment(new Date(userProgress.dueDate)).format(
                        "D MMM YYYY"
                      )}
                    </div>
                  ) : null}
                </div>
                {/* {renderPill()} */}
              </div>
              <div className="mt-3">{renderDisciplineData()}</div>
            </div>
            {/* <div className="d-none d-md-block">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Participant</th>
                    <th scope="col">Role</th>
                    <th scope="col">Progress</th>
                  </tr>
                </thead>
                <tbody>{renderTeamMemberRows()}</tbody>
              </table>
            </div> */}
            {/* <div className="d-block d-md-none">{renderTeamMemberList()}</div> */}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="p-3 d-flex">
        <StandardButton
          text="Go Back"
          onClick={handleHide}
          color="btn-success"
          className="btn-sm"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ProgressCardPopup;
