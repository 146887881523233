import { ValidationEnum } from '../data/enums/ValidationEnum';
export const ChangePasswordValidation = {
  old: [
    {
      type: ValidationEnum.REQUIRED,
      message: 'Old Password is required.',
    },
  ],
  new: [
    {
      type: ValidationEnum.REQUIRED,
      message: 'New Password is required.',
    },
  ],
  confirm: [
    {
      type: ValidationEnum.REQUIRED,
      message: 'Confirm Password is required.',
    },
  ],
};
