import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  AUTH_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  OTP_ROUTE,
  RESET_PASSWORD,
  RESET_PASSWORD_UPDATE,
} from '../../data/RouteUrls';
import AuthSideInfoContainer from '../shared/AuthSideInfoContainer/AuthSideInfoContainer';
import Login from './steps/Login';
import GetEmail from './steps/GetEmail';
import Signup from './steps/Signup';
import CaptureOtp from './steps/CaptureOtp';
import { ResetPassword, ResetPasswordUpdate } from './steps/ResetPassword';

const AuthContainer = () => {
  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-12 col-md-6 col-lg-5">
          <div className="row my-auto h-100">
            <div className="col-12 col-md-8 offset-md-2 my-md-auto">
              <Switch>
                <Route exact path={LOGIN_ROUTE} component={Login} />
                <Route exact path={SIGNUP_ROUTE} component={Signup} />
                <Route exact path={OTP_ROUTE} component={CaptureOtp} />
                <Route exact path={AUTH_ROUTE} component={GetEmail} />
                <Route exact path={RESET_PASSWORD} component={ResetPassword} />
                <Route
                  path={RESET_PASSWORD_UPDATE}
                  component={ResetPasswordUpdate}
                />
                <Route path="*">
                  <Redirect to={AUTH_ROUTE} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-7 px-0 d-md-block d-none">
          <Switch>
            <Route exact path={OTP_ROUTE}>
              <AuthSideInfoContainer
                img="/assets/images/otp-side.svg"
                heading="Check your email"
              />
            </Route>
            <Route path="*">
              <AuthSideInfoContainer
                img="/assets/images/auth-side.svg"
                heading="Art of Scale for Business Coaches"
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
