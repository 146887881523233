import { ValidationEnum } from '../data/enums/ValidationEnum';
export const OtpValidation = {
  otp: [
    {
      type: ValidationEnum.REQUIRED,
      message: 'OTP is required.',
    },
    {
      type: ValidationEnum.PATTERN,
      value: /^[0-9]{4}$/,
      message: 'Invalid OTP.',
    },
  ],
};
