import { ValidationEnum } from "../data/enums/ValidationEnum";

export const BillingDetailsValidations = {
  companyName: [
    {
      type: ValidationEnum.REQUIRED,
      message: "Company name is required",
    },
  ],
  country: [
    {
      type: ValidationEnum.REQUIRED,
      message: "Country is required",
    },
  ],
};
