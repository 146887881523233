import { ValidationEnum } from '../data/enums/ValidationEnum';
import { pickFromObject } from '../utils/generalUtils';
import { SignupValidation } from './Signup';

export const CoachProfileValidations = {
  ...pickFromObject(SignupValidation, ['firstName', 'lastName', 'email']),
  companyName: [
    {
      type: ValidationEnum.REQUIRED,
      message: 'Company Name is required.',
    },
  ],
};
