import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import StandardTable from "../../../shared/StandardTable/StandardTable";

export const ScorePopup = (props) => {
  return (
    <>
      <Modal show={true} centered onHide={props.closePopup} scrollable>
        <Modal.Header closeButton className="d-flex justiy-content-center">
          <Modal.Title>{props.heading}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          {!props.data.length ? (
            <span className="font-weight-bold">No Data found</span>
          ) : (
            <StandardTable columns={props.columns} data={props.data} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
