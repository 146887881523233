import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import validationUtils from "../../../utils/validationUtils";
import { OtpValidation } from "../../../validations/Otp";
import StandardButton from "../../shared/formComponents/StandardButton/StandardButton";
import StandardOtp from "../../shared/formComponents/StandardOtp/StandardOtp";
import * as actions from "../_redux/authAction";
import { COACH_DETAILS, DASHBOARD } from "../../../data/RouteUrls";
import { areDigits } from "../../../utils/formUtils";

const CaptureOtp = () => {
  const OTP_LENGTH = 6;
  const history = useHistory();
  const dispatch = useDispatch();

  const [email] = useState(useSelector((state) => state.authDetails.email));
  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState(false);

  const onOtpChange = (value) => {
    if (areDigits(value)) setOtp(value.slice(0, OTP_LENGTH));
  };

  const validations = OtpValidation;
  const submit = async (e) => {
    e.preventDefault();
    let data = { otpnum: otp, email: email };
    if (!validationUtils.checkFormValidity(data, validations)) {
      setShowError(true);
      return;
    }
    try {
      let success = await dispatch(actions.validateOTP(data));
      if (success) {
        history.push(DASHBOARD);
      }
    } catch (err) {}
  };
  return (
    <div className="d-flex flex-column h-100 p-md-3">
      <form onSubmit={submit}>
        <div className="text-center">
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className="m-auto auth-side-logo"
          />
          <div className="mt-3 cs-sub-heading">Verify your email</div>
          <div className="hint p-3 p-md-5 text-center">
            We've sent a 6-digit confirmation code to
            <span className="link-alike"> {email}</span>. Copy it and paste it
            below
          </div>
          <StandardOtp
            otpChange={onOtpChange}
            numberOfDigits={OTP_LENGTH}
            showError={showError}
          ></StandardOtp>
        </div>
        <div className="mt-auto">
          <StandardButton
            className="mt-5 mb-3"
            color="btn-success"
            bold={true}
            block={true}
            text="Verify OTP"
            type="submit"
          ></StandardButton>
        </div>
      </form>
    </div>
  );
};

export default CaptureOtp;
