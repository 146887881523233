import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import StandardButton from '../../../../shared/formComponents/StandardButton/StandardButton';

import * as actions from '../../_redux/xrayActions';
import './publish-xray-results-popup.scss';

const PublishXrayResultsPopup = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { show, handleClose, contact } = props;
  const [btnDisabled, setBtnDisabled] = useState(false);

  const publishResults = async () => {
    try {
      setBtnDisabled(true);
      await dispatch(actions.publishXrayResults(params.userId));
      handleClose();
    } catch (err) {
    } finally {
      setBtnDisabled(false);
    }
  };

  return (
    <Modal
      show={show}
      size="md"
      onHide={handleClose}
      dialogClassName="publish-results-popup"
      fullscreen="md-down"
      centered
      className="text-center"
    >
      <Modal.Header className="mx-auto px-5 pt-4">
        <Modal.Title>You’re about to hit send!</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5 pb-5">
        <div className="publish-results-image mb-5">
          <img
            src="/assets/images/publish-results-cloud.svg"
            alt="Publish Results Cloud"
          />
          <img
            src="/assets/images/publish-results-books.svg"
            alt="Publish Results Books"
          />
        </div>
        <p className="mb-3">
          You’re about to send{' '}
          <span className="contact-name">{contact?.firstName}</span>{' '}
          <span className="contact-company">
            <span className="text-dark">(</span>
            {contact?.companyName}
            <span className="text-dark">)</span>
          </span>{' '}
          their X-Ray!
        </p>
        <p className="mb-3">
          Make sure you’ve checked recommendations and added your own where
          necessary, before hitting Send!
        </p>
        <div className="d-flex justify-content-around align-items-center">
          <StandardButton
            color="btn-outline-dark"
            onClick={handleClose}
            text="Back"
            className="me-4"
          />
          <StandardButton
            color="btn-success"
            onClick={publishResults}
            text="Send"
            disabled={btnDisabled}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PublishXrayResultsPopup;
