import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import ProtectedRoute from '../../../base/ProtectedRoute';
import {
  ACADEMY,
  COURSE_OVERVIEW,
  FEEDBACK_COURSE,
  STUDY_COURSE,
  RESOURCE_DETAIL,
} from '../../../data/RouteUrls';
import './academy.scss';
import { CourseOverview } from './CourseOverview/CourseOverview';
import { FeedbackCourse } from './FeedbackCourse/FeedbackCourse';
import { Home } from './Home/Home';
import { StudyCourse } from './StudyCourse/StudyCourse';
import ResourceDetails from './ResourceDetails/ResourceDetails';

export const Academy = (props) => {
  useEffect(() => {
    document.title = 'Art of Scale | Academy';
  }, []);
  return (
    <>
      <Switch>
        <ProtectedRoute path={ACADEMY} exact component={Home} />
        <ProtectedRoute path={COURSE_OVERVIEW} component={CourseOverview} />
        <ProtectedRoute path={STUDY_COURSE} component={StudyCourse} />
        <ProtectedRoute path={FEEDBACK_COURSE} component={FeedbackCourse} />
        <ProtectedRoute path={RESOURCE_DETAIL} component={ResourceDetails} />
        <Redirect path="/*" to={ACADEMY} component={Home} />
      </Switch>
    </>
  );
};
