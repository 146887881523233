import classNames from "classnames";
import React from "react";
import Table from "react-bootstrap/Table";
import { getQuestScoreColor } from "../../../../utils/generalUtils";
import "./QuestDashboardTable.scss";

export const QuestDashboardTable = ({ columns, data, fullData }) => {
  const renderHeaders = () => {
    let headersTh = [];
    for (let column of columns) {
      let classes = classNames("dark-grey", {
        "text-left": column.dataField === "objective",
        "text-center": column.dataField !== "objective",
        "highlight-column font-weight-bold": column.loggedInUser,
      });
      headersTh.push(<th className={classes}>{column.title}</th>);
    }
    return <tr>{headersTh}</tr>;
  };

  const renderBooleanField = (column, data, color) => {
    let icon;
    if (data) {
      icon = <i class="fa-solid fa-check" style={{ color: "#000000" }}></i>;
    } else {
      icon = <i class="fa-solid fa-xmark" style={{ color: "#000000" }}></i>;
    }
    return (
      <div className={`d-flex justify-content-center align-items-center`}>
        <span
          className={`d-flex justify-content-center align-items-center ${column.className ? column.className : ""} rounded-1`}
          style={{ backgroundColor: color, width: "73px", height: "37px" }}
        >
          {icon}
        </span>
      </div>
    );
  };

  const renderData = () => {
    let rows = [];

    for (let record of data) {
      let columnElements = [];
      for (let column of columns) {
        let classes = classNames("text-center dark-grey", {
          "highlight-column font-weight-bold": column.loggedInUser,
        });
        let color;
        let finalElement;
        if (column.colored) {
          if (record[column.dataField] === null && record.type !== "streak") {
            color = getQuestScoreColor(100);
          } else {
            if (typeof record[column.dataField] === "boolean") {
              color = getQuestScoreColor(record[column.dataField] ? 100 : 0);
            } else if (record.type === "streak") {
              color = "transparent";
            } else if (record.type === "promiseDelivered") {
              if (record[column.dataField] == "-") {
                color = getQuestScoreColor(100);
              } else {
                let n1 = record[column.dataField].split("/")[0].trim();
                let n2 = record[column.dataField].split("/")[1].trim();
                if (n1 == 0 && n2 == 0) {
                  color = getQuestScoreColor(0);
                } else {
                  color = getQuestScoreColor((n1 / n2) * 100);
                }
              }
            } else {
              color = getQuestScoreColor(
                record[column.dataField + "value-for-color"],
              );
            }
          }
        }
        if (typeof record[column.dataField] === "boolean") {
          finalElement = renderBooleanField(
            column,
            record[column.dataField],
            color,
          );
        } else {
          finalElement = (
            <div
              className={`d-flex ${column.dataField === "objective" ? "justify-content-start sub-content-font-size" : "justify-content-center"}`}
            >
              <span
                className={`d-flex justify-content-center align-items-center ${column.dataField !== "objective" ? "title-font-size" : ""} ${column.className ? column.className : ""} rounded-1`}
                style={{
                  width: `${typeof record[column.dataField] == "boolean" || column.dataField !== "objective" ? "73px" : ""}`,
                  height: "37px",
                  backgroundColor: color,
                }}
              >
                {record[column.dataField] ?? "-"}
              </span>
            </div>
          );
        }
        columnElements.push(
          <td width={column.width ? column.width : ""} className={classes}>
            {finalElement}
          </td>,
        );
      }

      rows.push(<tr>{columnElements}</tr>);
    }
    return rows;
  };
  return (
    <Table className="quest-dashboard-table">
      <thead>{renderHeaders()}</thead>
      <tbody className="overflow-scroll">{renderData()}</tbody>
    </Table>
  );
};
