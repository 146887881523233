import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import StandardButton from '../../../../shared/formComponents/StandardButton/StandardButton';
import LoadingAnimation from '../../../../../base/LoadingAnimation';
import * as xrayActions from '../../_redux/xrayActions';
import './explore-card.scss';
import '../RecommendationsCard/recomm-header.scss';

export const ExploreCard = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [expandedIndex, setExpandedIndex] = useState();
  const [expandedSubIndex, setExpandedSubIndex] = useState();
  const [expandedSubSubIndex, setExpandedSubSubIndex] = useState();
  const [practices, setPractices] = useState({
    data: [],
    loader: true,
  });
  const currentDiscipline = useSelector(
    (state) => state.xrayReport.currentDiscipline
  );

  useEffect(() => {
    // This code runs on loading the component
    if (currentDiscipline.disciplineId) {
      let index = 0;
      for (let discipline of props.xrayScore.disciplines) {
        if (currentDiscipline.disciplineId === discipline.disciplineId) {
          setExpandedIndex(index);
        }
        index += 1;
      }
    } else {
      setExpandedIndex(null);
    }
    setExpandedSubIndex(null);
  }, [currentDiscipline]);

  const updateExpandedIndex = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
    setExpandedSubIndex(null);
    setExpandedSubSubIndex(null);
  };

  const updateExpandedSubIndex = (
    index,
    fetchPractices = false,
    subDisciplineId = null
  ) => {
    if (index === expandedSubIndex) {
      setExpandedSubIndex(null);
    } else {
      setExpandedSubIndex(index);
      if (fetchPractices && subDisciplineId) {
        getSubDisciplinePractices(subDisciplineId);
      }
    }
    setExpandedSubSubIndex(null);
  };

  const updateExpandedSubSubIndex = (
    index,
    fetchPractices = false,
    subSubDisciplineId = null
  ) => {
    if (index === expandedSubSubIndex) {
      setExpandedSubSubIndex(null);
    } else {
      setExpandedSubSubIndex(index);
      if (fetchPractices && subSubDisciplineId) {
        getSubSubDisciplinePractices(subSubDisciplineId);
      }
    }
  };

  const getSubDisciplinePractices = async (subDisciplineId) => {
    setPractices({ data: [], loader: true });
    const prs = await dispatch(
      xrayActions.getSubDisciplinePractices(params.userId, subDisciplineId)
    );
    if (prs) setPractices({ data: prs, loader: false });
  };

  const getSubSubDisciplinePractices = async (subSubDisciplineId) => {
    setPractices({ data: [], loader: true });
    const prs = await dispatch(
      xrayActions.getSubSubDisciplinePractices(
        params.userId,
        subSubDisciplineId
      )
    );
    if (prs) setPractices({ data: prs, loader: false });
  };
  const renderPractices = () => {
    if (practices.loader)
      return (
        <div className="bg-grey-superdark" style={{ height: '80px' }}>
          <LoadingAnimation
            style={{
              display: 'block',
              justifyContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: '40%',
            }}
          />
        </div>
      );
    return practices.data.map((pr) => {
      let barChartData = [
        {
          label: 'Score',
          score: parseFloat(pr.score).toFixed(1),
        },
      ];
      return (
        <div className="bg-grey-superdark bottom-bordered p-4">
          <div className="row">
            <div className="col-3">
              <div className="practice cs-sub-heading font-weight-normal ms-2">
                {pr.praName}
              </div>
            </div>
            <div className="col-7">
              <div className="compare-chart-container pra-chart">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={150}
                    height={30}
                    data={barChartData}
                    layout="vertical"
                  >
                    <Bar dataKey="score" fill="#8884d8" barSize={20} label />
                    <XAxis type="number" hide domain={[0, 5]} />
                    <YAxis type="category" width={75} dataKey="label" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      );
    });
  };

  const renderSubDisciplineDetail = (sd, disciplineCategoryClassName) => {
    // if there are no sub sub discipline, render practices
    if (sd.subSubDisciplines.length === 0) {
      return renderPractices();
    }

    // else render sub sub disciplines
    return sd.subSubDisciplines.map((ssd, index) => {
      var barChartData = [
        {
          label: 'Score',
          score: ssd.actualScore.toFixed(1),
        },
      ];
      return (
        <>
          <div
            className="bg-grey-dark bottom-bordered p-4 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateExpandedSubSubIndex(index, true, ssd.subSubDisciplineId);
            }}
          >
            <div className="row">
              <div className="col-3">
                <div
                  className={`${disciplineCategoryClassName} sub-sub-discipline ms-2`}
                >
                  {ssd.name}
                </div>
              </div>
              <div className="col-7">
                <div className="compare-chart-container ssd-chart">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={150}
                      height={30}
                      data={barChartData}
                      layout="vertical"
                    >
                      <Bar dataKey="score" fill="#8884d8" barSize={20} label />
                      <XAxis type="number" hide domain={[0, 5]} />
                      <YAxis type="category" width={75} dataKey="label" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="col-2">
                <StandardButton
                  className="d-flex align-items-center justify-content-center expand-btn ms-auto"
                  color="btn-outline-dark"
                  bold={true}
                  icon={
                    index === expandedSubSubIndex ? (
                      <span className="material-icons icon-in-btn">
                        expand_less
                      </span>
                    ) : (
                      <span className="material-icons icon-in-btn">
                        expand_more
                      </span>
                    )
                  }
                  text={index === expandedSubSubIndex ? 'Collapse' : 'Expand'}
                  reversed
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateExpandedSubSubIndex(
                      index,
                      true,
                      ssd.subSubDisciplineId
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {index === expandedSubSubIndex ? renderPractices() : null}
        </>
      );
    });
  };

  const renderDisciplineDetail = (
    discipline,
    disciplineChartData,
    isCurrentTabOverall = false
  ) => {
    let disciplineDetailChartData = [
      disciplineChartData[0],
      disciplineChartData[1],
      disciplineChartData[2],
    ];
    const discName = discipline?.name?.toLowerCase()?.split(' ')[0];
    const disciplineCategoryClassName = `discipline-category ${discName}`;
    return (
      <div className="sd-detail">
        {!isCurrentTabOverall ? (
          <div className="p-4 bg-grey bottom-bordered">
            <div className="row">
              <div className="col-3 my-auto">
                <div className={`${disciplineCategoryClassName} discipline`}>
                  {discipline.name}
                </div>
              </div>
              <div className="col-7">
                <div className="compare-chart-container d-chart">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={150}
                      height={30}
                      data={disciplineDetailChartData}
                      layout="vertical"
                    >
                      <Bar dataKey="score" fill="#8884d8" barSize={20} label />
                      <XAxis type="number" hide domain={[0, 5]} />
                      <YAxis type="category" width={75} dataKey="label" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {discipline.subDisciplines.map((sd, index) => {
          sd.index = index;
          var barChartData = [
            {
              label: 'Score',
              score: sd.actualScore.toFixed(1),
            },
          ];
          return (
            <>
              <div
                className="p-4 bg-grey bottom-bordered cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  updateExpandedSubIndex(
                    sd.index,
                    sd.subSubDisciplines.length === 0,
                    sd.subDisciplineId
                  );
                }}
              >
                <div className="row">
                  <div className="col-3 my-auto">
                    <div
                      className={`${disciplineCategoryClassName} sub-discipline ms-2`}
                    >
                      {sd.name}
                    </div>
                  </div>
                  <div className="col-7 my-auto">
                    <div className="compare-chart-container sd-chart">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={150}
                          height={30}
                          data={barChartData}
                          layout="vertical"
                        >
                          <Bar
                            dataKey="score"
                            fill="#8884d8"
                            barSize={20}
                            label
                          />
                          <XAxis type="number" hide domain={[0, 5]} />
                          <YAxis type="category" width={75} dataKey="label" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className="col-2 my-auto">
                    <div>
                      <StandardButton
                        className="d-flex align-items-center justify-content-center expand-btn ms-auto"
                        color="btn-outline-dark"
                        bold={true}
                        icon={
                          sd.index === expandedSubIndex ? (
                            <span className="material-icons icon-in-btn">
                              expand_less
                            </span>
                          ) : (
                            <span className="material-icons icon-in-btn">
                              expand_more
                            </span>
                          )
                        }
                        text={
                          sd.index === expandedSubIndex ? 'Collapse' : 'Expand'
                        }
                        reversed
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          updateExpandedSubIndex(
                            sd.index,
                            sd.subSubDisciplines.length === 0,
                            sd.subDisciplineId
                          );
                        }}
                      ></StandardButton>
                    </div>
                  </div>
                </div>
              </div>
              {sd.index === expandedSubIndex
                ? renderSubDisciplineDetail(sd, disciplineCategoryClassName)
                : null}
            </>
          );
        })}
      </div>
    );
  };

  const renderDisciplines = () => {
    let disciplineCards = [];
    let index = 0;
    for (let discipline of props.xrayScore.disciplines) {
      discipline.index = index;

      disciplineCards.push(renderDisciplineCard(discipline));
      index += 1;
    }
    return disciplineCards;
  };

  const renderDisciplineCard = (discipline) => {
    var barChartData = [
      {
        label: 'Required',
        score: discipline.requiredScore.toFixed(1),
        maxScore: 5 - discipline.requiredScore.toFixed(1),
      },
      {
        label: 'Gut',
        score: discipline.gutScore.toFixed(1),
        maxScore: 5 - discipline.gutScore.toFixed(1),
      },
      {
        label: 'Actual',
        score: discipline.actualScore.toFixed(1),
        maxScore: 5 - discipline.actualScore.toFixed(1),
      },
    ];
    let disciplineCard;
    if (currentDiscipline.disciplineId) {
      if (currentDiscipline.disciplineId === discipline.disciplineId) {
        disciplineCard = (
          <Card className="mb-4 explore-card">
            {discipline.index === expandedIndex
              ? renderDisciplineDetail(discipline, barChartData)
              : null}
          </Card>
        );
      }
    } else {
      const RoundedBar = (props) => {
        const { fill, x, y, width, height } = props;
        const radius = 10;
        const d = `m ${x}, ${y} h ${Math.max(
          width - radius,
          0
        )} a ${radius},${radius} 0 0 1 ${radius},${radius} v ${Math.max(
          height - 2 * radius,
          0
        )} a ${radius},${radius} 0 0 1 -${radius},${radius} h-${Math.max(
          width - radius,
          0
        )} Z`;
        return <path d={d} stroke="none" fill={fill} />;
      };
      const discName = discipline?.name?.toLowerCase()?.split(' ')[0];
      const disciplineCategoryClassName = `discipline-category ${discName}`;
      disciplineCard = (
        <Card className="mb-4 explore-card">
          <div
            className="p-4 bottom-bordered cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateExpandedIndex(discipline.index);
            }}
          >
            <div className="row">
              <div className="col-3 my-auto">
                <div
                  className={`${disciplineCategoryClassName} discipline`}
                  style={{ fontSize: '1rem' }}
                >
                  {discipline.name}
                </div>
              </div>
              <div className="col-7">
                <div className="compare-chart-container d-chart">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={150}
                      height={30}
                      data={barChartData}
                      layout="vertical"
                    >
                      <Bar
                        dataKey="score"
                        stackId="barId"
                        fill="#8884d8"
                        barSize={20}
                        label
                        shape={<RoundedBar />}
                      />
                      {/* <Bar
                        dataKey="maxScore"
                        fill="#E8E8E8"
                        stackId="barId"
                        barSize={20}
                      /> */}
                      <XAxis type="number" hide domain={[0, 5]} />
                      <YAxis type="category" width={75} dataKey="label" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="col-2 my-auto align-items-right">
                <div>
                  {discipline.subDisciplines.length ? (
                    <StandardButton
                      className="d-flex align-items-center justify-content-center expand-btn ms-auto"
                      color="btn-outline-dark"
                      bold={true}
                      icon={
                        discipline.index === expandedIndex ? (
                          <span className="material-icons icon-in-btn">
                            expand_less
                          </span>
                        ) : (
                          <span className="material-icons icon-in-btn">
                            expand_more
                          </span>
                        )
                      }
                      text={
                        discipline.index === expandedIndex
                          ? 'Collapse'
                          : 'Expand'
                      }
                      reversed
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateExpandedIndex(discipline.index);
                      }}
                    ></StandardButton>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {discipline.index === expandedIndex
            ? renderDisciplineDetail(discipline, barChartData, true)
            : null}
        </Card>
      );
    }
    return disciplineCard;
  };

  return (
    <>
      <div className="bg-grey p-3">
        <div className="d-flex justify-content-end"></div>
      </div>
      <div className="px-4 py-3">{renderDisciplines()}</div>
    </>
  );
};
