export const AcademyUrls = {
  COURSES: '/jobseekers/{jobseeker_id}/courses',
  COURSE_OVERVIEW: '/jobseekers/{jobseeker_id}/course-overview/{id}',
  COURSE_CONTENT: '/jobseekers/{jobseeker_id}/course-overview-content/{id}',
  COURSE_ENROLL: '/jobseekers/{jobseeker_id}/course/{id}/action/enroll',
  COMPLETE_CHAPTER: '/jobseekers/{jobseeker_id}/complete/{chapterId}',
  CATEGORIES: '/public/category',
  AUTHORS: '/public/author-list',
  FEEDBACK: '/public/course/{courseId}/action/review',
  RESOURCES: '/api/resources/user',
  RESOURCE_DETAIL: '/api/resources/{resourceId}',
  RESOURCE_TYPES: '/api/resources/type',
  DISCIPLINES: '/api/open/discipline',
  REGISTER_EVENT : '/user/eventRegister'
};
