import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { AUTH_ROUTE } from '../data/RouteUrls';

const ProtectedRoute = ({
  component: Component,
  protectionCondition,
  redirectTo,
  ...rest
}) => {
  const token = useSelector((state) => state.authDetails.accessToken);
  let allow = token ? true : false;
  if (protectionCondition !== null && protectionCondition !== undefined) {
    allow = allow & protectionCondition;
  }
  const redirectionPath = !token ? AUTH_ROUTE : redirectTo || AUTH_ROUTE;
  return (
    <Route
      {...rest}
      render={(props) =>
        allow ? (
          <Route {...rest} component={Component} />
        ) : (
          <Redirect
            to={{ pathname: redirectionPath, state: { from: props.location } }}
          />
        )
      }
    />
  );
};
export default ProtectedRoute;
