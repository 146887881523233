import React, { useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
import { kNeighborsOfIndex } from '../../../utils/generalUtils';

import './standard-pagination.scss';

function getTotalPages(totalCount, pageSize) {
  if (!totalCount) return 0;
  return Math.ceil(totalCount / pageSize);
}

function getLowerPageRange(page, pageSize) {
  return 1 + pageSize * (page - 1);
}

function getUpperPageRange(page, pageSize, totalCount) {
  return Math.min(totalCount, pageSize * page);
}

const StandardPagination = ({ page, totalCount, setPage, pageSize = 20 }) => {
  const totalPages = useMemo(
    () => getTotalPages(totalCount, pageSize),
    [totalCount, pageSize]
  );
  return (
    <Pagination className="standard-pagination justify-content-end align-items-center">
      <div className="me-3 entries-text">
        Showing {getLowerPageRange(page, pageSize)} -{' '}
        {getUpperPageRange(page, pageSize, totalCount)} of {totalCount} contacts
      </div>
      <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
      <Pagination.Prev
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      />
      {kNeighborsOfIndex(
        [...Array(totalPages).keys()].map((x) => ++x),
        page - 1,
        5
      ).map((pageNo) => (
        <Pagination.Item
          active={page === pageNo}
          onClick={() => setPage(pageNo)}
        >
          {pageNo}
        </Pagination.Item>
      ))}
      <Pagination.Next
        onClick={() => setPage(page + 1)}
        disabled={page === totalPages}
      />
      <Pagination.Last
        onClick={() => setPage(totalPages)}
        disabled={page === totalPages}
      />
    </Pagination>
  );
};

export default StandardPagination;
