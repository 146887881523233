import React, { Component } from 'react';
import './standard-otp.scss';
import StandardInput from '../StandardInput/StandardInput';
import validationUtils from '../../../../utils/validationUtils';

class StandardOtp extends Component {
  constructor(props) {
    super(props);

    const numberOfDigits = this.props.numberOfDigits || 6;
    this.state = {
      values: [...Array(numberOfDigits).keys()].map(() => ''),
      numberOfDigits,
    };
  }

  componentDidMount() {
    this.checkValidatity('');
    const firstInp = document.querySelector('.standard-otp input');
    if (firstInp) firstInp.focus();
  }

  setOtpDigit = (index, value) => {
    const otps = [...this.state.values];
    otps[index] = value.slice(0, 1);
    this.setState({ values: otps });
    return otps;
  };

  textBoxValueChanged = (index, value) => {
    const otps = this.setOtpDigit(index, value);
    const otpString = otps.join('');

    if (index < this.state.numberOfDigits - 1 && value) {
      const nextInpEle = document.getElementById(`otp-digit-${index + 1}`);
      if (nextInpEle) {
        nextInpEle.focus();
      }
    }
    this.otpChange(otpString);
  };

  otpChange = (otp) => {
    this.checkValidatity(otp);
    this.props.otpChange(otp);
  };

  onKeyDown = (index, e) => {
    const keyboardkey = e.keyCode ? e.keyCode : e.charCode;
    // 8 means backspace
    if (keyboardkey === 8) {
      if (this.state.values[index]) {
        const newOtp = this.setOtpDigit(index, '');
        this.otpChange(newOtp);
      } else {
        document.getElementById(`otp-digit-${index - 1}`)?.focus();
      }
    }
  };

  renderHelp = () => {
    const { helpText } = this.props;

    if (helpText) {
      return <p className="help-text mb-0 mt-2">{helpText}</p>;
    }
    return null;
  };

  renderError = () => {
    const { errorText } = this.state;

    if (errorText && (this.state.dirty || this.props.showError)) {
      return <p className="error-text mb-2 mt-0">{errorText}</p>;
    }
    return null;
  };

  checkValidatity = (value) => {
    let isValid = true;
    let errorText = '';
    for (let validation of this.props.validations || []) {
      if (
        !validation.disabled &&
        !validationUtils[validation.type](value, validation.value)
      ) {
        errorText = validation.message;
        isValid = false;
        break;
      }
    }
    this.setState({
      valid: isValid,
      errorText: errorText,
    });
  };

  onPaste = (e) => {
    e.preventDefault();
    e.clipboardData.items[0].getAsString((text) => {
      if (!isNaN(text) && text.length === this.state.numberOfDigits) {
        this.otpChange(text);
        let optValues = [];
        for (let i = 0; i < text.length; i++) {
          optValues.push(text[i]);
        }
        this.setState({ values: optValues });
        document
          .getElementById(`otp-digit-${this.state.numberOfDigits - 1}`)
          ?.focus();
      }
    });
  };

  render() {
    return (
      <div className="row standard-otp">
        {this.state.values.map((otpDigit, index) => (
          <div className="col-2 text-center" key={`otp-${index}`}>
            <StandardInput
              id={`otp-digit-${index}`}
              value={otpDigit}
              onChange={(e) => this.textBoxValueChanged(index, e.target.value)}
              onKeyDown={(e) => this.onKeyDown(index, e)}
              onPaste={this.onPaste}
              className="otp"
              inputClasses="text-center"
            />
          </div>
        ))}
        <div className="col-12">
          {this.state.valid ? this.renderHelp() : this.renderError()}
        </div>
      </div>
    );
  }
}

export default StandardOtp;
