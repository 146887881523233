import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { COACH_PANEL } from '../data/RouteUrls';

const PublicRoute = ({ component: Component, ...rest }) => {
  const token = false;
  // const token = useSelector((state) => state.authDetails.accessToken);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: COACH_PANEL,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
export default PublicRoute;
