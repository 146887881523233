export const CONTACT_XRAY_STATE_ENUMS = {
  ADDED: 'ADDED',
  INVITED: 'INVITED',
  ACCEPTED: 'ACCEPTED',
  REGISTERED: 'REGISTERED',
  XRAY_STARTED: 'X_STARTED',
  XRAY_SUBMITTED: 'X_SUBMITTED',
  XRAY_COMPLETED: 'X_COMPLETED',
};

export const CONTACT_XRAY_STATE_INVERSE_ENUMS = {
  ADDED: 'ADDED',
  INVITED: 'INVITED',
  ACCEPTED: 'ACCEPTED',
  REGISTERED: 'REGISTERED',
  X_STARTED: 'XRAY_STARTED',
  X_SUBMITTED: 'XRAY_SUBMITTED',
  X_COMPLETED: 'XRAY_COMPLETED',
};

export const CONTACT_XRAY_STATE_MSGS = {
  ADDED: 'Contact added on',
  INVITED: 'Invited to X-Ray on',
  REGISTERED: 'Registered on',
  XRAY_STARTED: 'X-Ray started on',
  XRAY_SUBMITTED: 'X-Ray completed on',
  XRAY_COMPLETED: 'X-Ray reviewed on',
};
