import React, { Component } from "react";
import "./standard-score.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import validationUtils from "../../../../utils/validationUtils";

class StandardScore extends Component {
  constructor(props) {
    super(props);
    this.state = {        
      valid : false,
      errorText : "",
      dirty : false
    };

  }

  componentDidMount(){
    this.checkValidatity(this.props.value);
  }
  getStandardScoreClass = () => {
    const { disabled, className } = this.props;
    return classNames("standard-score", "form-group", {
      disabled: disabled,
      [className]: className,
      error : (this.state.dirty || this.props.showError) && !this.state.valid
    });
  };

  getScoreClassName = () => {
    const { error } = this.props;
    return classNames({      
      error: error
    });
  };

  renderLabel = () => {
    const { inputId } = this.state;
    const { label } = this.props;

    if (label) {
      return (
        <label htmlFor={inputId}>
          {label}
        </label>
      );
    }
    return null;
  };

  renderHelp = () => {
    const { helpText } = this.props;

    if (helpText) {
      return <p className="help-text mb-0 mt-2">{helpText}</p>;
    }
    return null;
  };

  renderError = () => {
    const { errorText } = this.state;

    if (errorText && (this.state.dirty || this.props.showError)) {
      return <p className="error-text mb-0 mt-2">{errorText}</p>;
    }
    return null;
  };

  checkValidatity = (value) => {    
    let isValid = true;
    let errorText = "";
    for(let validation of this.props.validations || []){            
      if(!validation.disabled && !validationUtils[validation.type](value,validation.value)){
        errorText  = validation.message;
        isValid = false;
        break;
      }
    }    
    this.setState({
      valid : isValid,
      errorText : errorText,      
    })
  }

  onChange = (e) => {        
    this.checkValidatity(e);
    this.props.onChange(e);
  }
  
  renderNumbers = () => {
    const { start, end, value } = this.props;
    let arr = [];
    for(let i=start; i<= end ; i++){
      let classes = "number-box";
      if(value === i){
        classes += " active"
      }      
      arr.push(<div className={classes} onClick={() => this.onChange(i)} >{i}</div>)
    }
    return arr;
  }

  render() {        

    return (
      <div className={this.getStandardScoreClass()}>
        {this.renderLabel()}
        <div className={this.getScoreClassName()}>
          {
            this.renderNumbers()
          }
        </div>
        

        {this.state.valid ? this.renderHelp() : this.renderError()}
      </div>
    );
  }
}

StandardScore.propTypes = {
  type : PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  helpText: PropTypes.string,
  errorText: PropTypes.string,
  error: PropTypes.bool
};

StandardScore.defaultProps = {
  type : "text",
  disabled: false,
  error: false,
  placeholder: "",
  value: ""
};

export default StandardScore;
