import React, { useEffect, useState, useReducer, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";

import StandardButton from "../../shared/formComponents/StandardButton/StandardButton";
import StandardInput from "../../shared/formComponents/StandardInput/StandardInput";
import * as coachContactsActions from "./_redux/contactActions";
import LoadingAnimation from "../../../base/LoadingAnimation";
import "./contacts.scss";
import { useDebounce } from "../../../base/hooks";
import ContactForm from "./ContactForm/ContactForm";
import ContactCard from "./ContactCard";
import StandardPagination from "../../shared/Pagination/StandardPagination";
import { HeaderContext } from "../../../HeaderContext";
import NoData from "../../shared/NoData/NoData";

const PAGE_SIZE = 20;

const sortOptions = [
  { label: "Name", value: "NAME" },
  { label: "Date Added", value: "CREATED_AT" },
  { label: "Company", value: "COMPANY" },
  { label: "Email", value: "EMAIL" },
];

function contactOptionsReducer(state, action) {
  switch (action.type) {
    case "sortBy":
      return { ...state, sortBy: action.payload, pageNo: 1 };
    case "pageNo":
      return { ...state, pageNo: action.payload };
    case "sortType":
      return { ...state, sortType: action.payload, pageNo: 1 };
    case "toggleSortType":
      return {
        ...state,
        sortType: state.sortType === "ASC" ? "DESC" : "ASC",
        pageNo: 1,
      };
    default:
      return state;
  }
}

const Contacts = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const HeaderMetaData = useContext(HeaderContext);

  const coachContacts = useSelector((state) => state.coachContacts);
  const { contacts } = coachContacts;

  const [contactsToShow, setContactsToShow] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 200);
  const [options, optionsDispatcher] = useReducer(contactOptionsReducer, {
    pageNo: 1,
    sortBy: sortOptions[1].value,
    sortType: "DESC",
  });

  const [open, setOpen] = useState(false);
  const [contactToEdit, setContactToEdit] = useState(null);
  const demoAccess = useSelector(
    (state) => state.authDetails?.userProfile?.demoAccess
  );
  const getCoachContacts = async () => {
    try {
      setShowLoader(true);
      let contactOptions = { ...options };
      if (debouncedSearchText && debouncedSearchText !== "") {
        contactOptions.search = debouncedSearchText;
      }
      await dispatch(coachContactsActions.getCoachContacts(contactOptions));
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(async () => {
    document.title = "Art of Scale | Clients";
    if (searchParams.get("add")) {
      setOpen(true);
    }
    HeaderMetaData.setHeaderMetadata({
      headerTitle: "Clients",
      HeaderActions: () => (
        <StandardButton
          text="Add New Contact"
          icon={<span className="material-icons position-relative">add</span>}
          color="btn-success"
          className="btn-sm"
          reversed
          onClick={() => setOpen(true)}
          textClassName="m-0"
        />
      ),
    });
  }, []);

  useEffect(() => {
    getCoachContacts();
  }, [options, debouncedSearchText]);

  useEffect(() => {
    setContactsToShow(contacts?.dataList ?? []);
  }, [contacts]);

  const handleContactClose = () => {
    setContactToEdit(null);
    setOpen(false);
  };

  return (
    <div className="container-fluid px-0 w-100 h-100 contacts">
      {/* Header */}
      <div className="w-100 d-flex d-md-none justify-content-between align-items-center">
        <div className="cs-heading">Clients</div>
        <StandardButton
          text="Add New Contact"
          icon={<span className="material-icons position-relative">add</span>}
          color="btn-success"
          className="btn-sm"
          reversed
          onClick={() => setOpen(true)}
          textClassName="m-0"
        />
      </div>
      <ContactForm
        contactInfo={contactToEdit}
        open={open}
        setOpen={setOpen}
        getCoachContacts={getCoachContacts}
        handleContactClose={handleContactClose}
        demoAccess={demoAccess}
      />
      {/* Toobar */}
      <div className="d-flex justify-content-between align-items-center">
        {/* Search */}
        <StandardInput
          afterButtonIcon={
            <button
              onClick={() => setSearchText("")}
              className="icon-text after after-icon-button"
            >
              {searchText === "" ? (
                <span className="material-icons">search</span>
              ) : (
                <span className="material-icons">clear</span>
              )}
            </button>
          }
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search"
          className="mt-4 w-50 search-input-container"
          inputClasses="search-input"
        />
        <div className="d-inline-flex align-items-center">
          <DropdownButton
            title={
              <span>
                <span className="me-2" style={{ fontWeight: 600 }}>
                  Sort By :
                </span>
                <span className="me-2">
                  {
                    sortOptions.filter((so) => so.value === options.sortBy)[0]
                      ?.label
                  }
                </span>
                <span
                  className="material-icons position-relative me-2"
                  style={{ top: "7px" }}
                >
                  sort
                </span>
              </span>
            }
            id="sort-dropdown"
          >
            {sortOptions.map((so) => (
              <Dropdown.Item
                onClick={() =>
                  optionsDispatcher({ type: "sortBy", payload: so.value })
                }
              >
                {so.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <div title="Sorting Order">
            <StandardButton
              onClick={() => optionsDispatcher({ type: "toggleSortType" })}
              icon={
                <span
                  className="material-icons"
                  style={{ position: "relative", top: "3px" }}
                >
                  {options.sortType === "ASC"
                    ? "arrow_upward"
                    : "arrow_downward"}
                </span>
              }
            />
          </div>
        </div>
      </div>
      {/* Contacts  */}
      {showLoader ? (
        <LoadingAnimation />
      ) : contactsToShow && contactsToShow.length === 0 ? (
        <NoData
          style={{ height: "70vh", marginTop: "1.5rem" }}
          title="No contacts available"
          content="Please add a contact"
          CTAs={
            <StandardButton
              text="Add New Contact"
              icon={
                <span className="material-icons position-relative">add</span>
              }
              color="btn-success"
              className="btn-sm"
              reversed
              onClick={() => setOpen(true)}
              textClassName="m-0"
            />
          }
        />
      ) : (
        <>
          <ul className="contact-list my-4">
            {contactsToShow &&
              contactsToShow.map((contact, index) => (
                <li key={`contact-${index}`}>
                  <ContactCard
                    contact={contact}
                    cardActionOnClick={() => {
                      setContactToEdit(contact);
                      setOpen(true);
                    }}
                  />
                </li>
              ))}
          </ul>
          <StandardPagination
            page={options.pageNo}
            totalCount={contacts?.count}
            pageSize={PAGE_SIZE}
            setPage={(pg) => optionsDispatcher({ type: "pageNo", payload: pg })}
          />
        </>
      )}
    </div>
  );
};

export default Contacts;
