import classNames from "classnames";

/**
 *
 * @param children -> Whatever text needs to be rendered
 * @param size -> 'l' | 'xl' | 's' | 'xs'
 * @param weight -> 'Bold' | 'Light'
 * @param color -> 'Primary' | 'Secondary'
 * @returns
 */
export const Typography = ({
  children,
  size,
  weight,
  onClick,
  color = "text-dark-grey",
}) => {
  let classes = classNames({
    "font-weight-bold": weight === "Bold",
    "font-weight-semi-bold": weight === "Semi-Bold",
    "light-text": weight === "Light",
    big: size === "l",
    bigger: size === "xl",
    small: size === "s",
    "cs-fs-16": size === "m",
    "extra-small": size === "xs",
    "text-primary": color === "text-primary",
    "text-purple": color === "text-purple",
    "text-white": color === "text-white",
    "dark-grey": color === "text-dark-grey",
  });
  return (
    <span className={classes} onClick={onClick}>
      {children}
    </span>
  );
};

// TYPOGRAPHY ACCORDING TO UI REVAMP

// SMALL REGULAR TEXT TYPOGRAPHY
export const SmallRegularText = ({ children }) => {
  return (
    <Typography size={"s"} weight={"Normal"}>
      {children}
    </Typography>
  );
};
// SMALL SEMIBOLD TEXT TYPOGRAPHY
export const SmallSemiBoldText = ({ children }) => {
  return (
    <Typography size={"s"} weight={"Semi-Bold"}>
      {children}
    </Typography>
  );
};
// MEDIUM REGULAR TEXT TYPOGRAPHY
export const MediumRegularText = ({ children }) => {
  return (
    <Typography size={"m"} weight={"Normal"}>
      {children}
    </Typography>
  );
};
// MEDIUM SEMIBOLD TEXT TYPOGRAPHY
export const MediumSemiBoldText = ({ children }) => {
  return (
    <Typography size={"m"} weight={"Semi-Bold"}>
      {children}
    </Typography>
  );
};
// BIG REGULAR TEXT TYPOGRAPHY
export const BigRegularText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Normal"}>
      {children}
    </Typography>
  );
};
// BIG SEMIBOLD TEXT TYPOGRAPHY
export const BigSemiBoldText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Semi-Bold"}>
      {children}
    </Typography>
  );
};

// NORMAL TEXT TYPOGRAPHY
export const NormalText = ({ children }) => {
  return <Typography>{children}</Typography>;
};
// BOLD TEXT TYPOGRAPHY
export const BoldText = ({ children, size }) => {
  return (
    <Typography size={size} weight={"Bold"}>
      {children}
    </Typography>
  );
};
// LIGHT TEXT TYPOGRAPHY
export const LightText = ({ children, size }) => {
  return (
    <Typography size={size} weight={"Light"}>
      {children}
    </Typography>
  );
};
// SMALL NORMAL TEXT TYPOGRAPHY
export const SmallNormalText = ({ children }) => {
  return (
    <Typography size={"s"} weight={"Normal"}>
      {children}
    </Typography>
  );
};
// SMALL SEMIBOLD TEXT TYPOGRAPHY
// export const SmallSemiBoldText = ({children,}) => {
//     return <Typography size={"s"} weight={'Semi-Bold'}>{children}</Typography>
// }
// SMALL BOLD TEXT TYPOGRAPHY
export const SmallBoldText = ({ children }) => {
  return (
    <Typography size={"s"} weight={"Bold"}>
      {children}
    </Typography>
  );
};
// SMALL LIGHT TEXT TYPOGRAPHY
export const SmallLightText = ({ children }) => {
  return (
    <Typography size={"s"} weight={"Light"}>
      {children}
    </Typography>
  );
};
// EXTRA SMALL NORMAL TEXT TYPOGRAPHY
export const ExtraSmallNormalText = ({ children }) => {
  return (
    <Typography size={"xs"} weight={"Normal"}>
      {children}
    </Typography>
  );
};
// EXTRA SMALL BOLD TEXT TYPOGRAPHY
export const ExtraSmallBoldText = ({ children }) => {
  return (
    <Typography size={"xs"} weight={"Bold"}>
      {children}
    </Typography>
  );
};
// EXTRA SMALL LIGHT TEXT TYPOGRAPHY
export const ExtraSmallLightText = ({ children }) => {
  return (
    <Typography size={"xs"} weight={"Light"}>
      {children}
    </Typography>
  );
};
// BIG NORMAL TEXT TYPOGRAPHY
export const BigNormalText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Normal"}>
      {children}
    </Typography>
  );
};
// BIG BOLD TEXT TYPOGRAPHY
export const BigBoldText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Bold"}>
      {children}
    </Typography>
  );
};
// BIG LIGHT TEXT TYPOGRAPHY
export const BigLightText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Light"}>
      {children}
    </Typography>
  );
};
// BIG NORMAL TEXT TYPOGRAPHY
export const BiggerNormalText = ({ children }) => {
  return (
    <Typography size={"xl"} weight={"Normal"}>
      {children}
    </Typography>
  );
};
// BIGGER BOLD TEXT TYPOGRAPHY
export const BiggerBoldText = ({ children }) => {
  return (
    <Typography size={"xl"} weight={"Bold"}>
      {children}
    </Typography>
  );
};
// BIGGER LIGHT TEXT TYPOGRAPHY
export const BiggerLightText = ({ children }) => {
  return (
    <Typography size={"xl"} weight={"Light"}>
      {children}
    </Typography>
  );
};
// PRIMARY NORMAL TEXT TYPOGRAPHY
export const PrimaryNormalText = ({ children }) => {
  return <Typography color={"text-primary"}>{children}</Typography>;
};
// PRIMARY BOLD TEXT
export const PrimaryBoldText = ({ children }) => {
  return <Typography color={"text-primary"}>{children}</Typography>;
};
// PRIMARY LIGHT TEXT
export const PrimaryLightText = ({ children }) => {
  return <Typography color={"text-primary"}>{children}</Typography>;
};
// PRIMARY SMALL NORMAL TEXT
export const PrimarySmallNormalText = ({ children }) => {
  return (
    <Typography size={"s"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY SMALL BOLD TEXT
export const PrimarySmallBoldText = ({ children }) => {
  return (
    <Typography size={"s"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY SMALL LIGHT TEXT
export const PrimarySmallLightText = ({ children }) => {
  return (
    <Typography size={"s"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY EXTRA SMALL NORMAL TEXT
export const PrimaryExtraSmallNormalText = ({ children }) => {
  return (
    <Typography size={"xs"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY EXTRA SMALL BOLD TEXT
export const PrimaryExtraSmallBoldText = ({ children }) => {
  return (
    <Typography size={"xs"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY EXTRA SMALL LIGHT TEXT
export const PrimaryExtraSmallLightText = ({ children }) => {
  return (
    <Typography size={"xs"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY BIG NORMAL TEXT
export const PrimaryBigNormalText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Normal"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY BIG BOLD TEXT
export const PrimaryBigBoldText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Bold"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY BIG LIGHT TEXT
export const PrimaryBigLightText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Light"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY BIGGER NORMAL TEXT
export const PrimaryBiggerNormalText = ({ children }) => {
  return (
    <Typography size={"xl"} weight={"Normal"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY BIGGER BOLD TEXT
export const PrimaryBiggerBoldText = ({ children }) => {
  return (
    <Typography size={"xl"} weight={"Bold"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// PRIMARY BIGGER LIGHT TEXT
export const PrimaryBiggerLightText = ({ children }) => {
  return (
    <Typography size={"xl"} weight={"Light"} color={"text-primary"}>
      {children}
    </Typography>
  );
};
// SECONDARY NORMAL TEXT
export const SecondaryNormalText = ({ children }) => {
  return <Typography color={"text-purple"}>{children}</Typography>;
};
// SECONDARY BOLD TEXT
export const SecondaryBoldText = ({ children }) => {
  return (
    <Typography weight={"Bold"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY LIGHT TEXT
export const SecondaryLightText = ({ children }) => {
  return <Typography color={"text-purple"}>{children}</Typography>;
};
// SECONDARY SMALL NORMAL TEXT
export const SecondarySmallNormalText = ({ children }) => {
  return (
    <Typography size={"s"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY SMALL BOLD TEXT
export const SecondarySmallBoldText = ({ children }) => {
  return (
    <Typography size={"s"} weight={"Bold"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY SMALL LIGHT TEXT
export const SecondarySmallLightText = ({ children }) => {
  return (
    <Typography size={"s"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY EXTRA SMALL NORMAL TEXT
export const SecondaryExtraSmallNormalText = ({ children }) => {
  return (
    <Typography size={"xs"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY EXTRA SMALL BOLD TEXT
export const SecondaryExtraSmallBoldText = ({ children }) => {
  return (
    <Typography size={"xs"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY EXTRA SMALL LIGHT TEXT
export const SecondaryExtraSmallLightText = ({ children }) => {
  return (
    <Typography size={"xs"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY BIG NORMAL TEXT
export const SecondaryBigNormalText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Normal"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY BIG BOLD TEXT
export const SecondaryBigBoldText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Bold"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY BIG LIGHT TEXT
export const SecondaryBigLightText = ({ children }) => {
  return (
    <Typography size={"l"} weight={"Light"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY BIGGER NORMAL TEXT
export const SecondaryBiggerNormalText = ({ children }) => {
  return (
    <Typography size={"xl"} weight={"Normal"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY BIGGER BOLD TEXT
export const SecondaryBiggerBoldText = ({ children }) => {
  return (
    <Typography size={"xl"} weight={"Bold"} color={"text-purple"}>
      {children}
    </Typography>
  );
};
// SECONDARY BIGGER LIGHT TEXT
export const SecondaryBiggerLightText = ({ children }) => {
  return (
    <Typography size={"xl"} weight={"Light"} color={"text-purple"}>
      {children}
    </Typography>
  );
};

// PRIMARY NORMAL TEXT TYPOGRAPHY
export const WhiteNormalText = ({ children }) => {
  return <Typography color={"text-white"}>{children}</Typography>;
};
// PRIMARY BOLD TEXT
export const WhiteBoldText = ({ children }) => {
  return (
    <Typography weight={"Bold"} color={"text-white"}>
      {children}
    </Typography>
  );
};
    