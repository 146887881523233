import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LoadingAnimation from '../../base/LoadingAnimation';
import AuthSideInfoContainer from '../shared/AuthSideInfoContainer/AuthSideInfoContainer';
import CoachInfo from './steps/CoachInfo';
import './coach-details.scss';
import { AUTH_ROUTE, OTP_ROUTE } from '../../data/RouteUrls';
import * as authActions from '../AuthContainer/_redux/authAction';

const CoachDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(true);
  const userProfile = useSelector((state) => state.authDetails.userProfile);

  const checkForUserProfile = async () => {
    const uspr = await dispatch(authActions.getUserDetails());
    if (!uspr.emailVerified) {
      dispatch(authActions.generateOTP({ email: uspr.email }));
    }
    setShowLoader(false);
  };

  useEffect(() => {
    checkForUserProfile();
  }, []);

  if (showLoader) return <LoadingAnimation />;
  else if (!userProfile) history.push(AUTH_ROUTE);
  else if (!userProfile.emailVerified) history.push(OTP_ROUTE);
  return (
    <div className="container-fluid h-100 coach-details">
      <div className="row h-100">
        <div className="col-12 col-md-6 col-lg-5 d-flex flex-column justify-content-center align-items-center p-md-5">
          <CoachInfo />
        </div>
        <div className="col-md-6 col-lg-7 px-0 d-md-block d-none">
          <AuthSideInfoContainer
            img="/assets/images/coach-details-side.svg"
            heading="Let's grow together"
          />
        </div>
      </div>
    </div>
  );
};

export default CoachDetails;
