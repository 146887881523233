import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getIndicesFromString } from "../../../utils/generalUtils";



function TooltipGenerator({gloassaryWords, text}) {
  // const [upgradePopup, setUpgradePopup] = useState(false);

  try {
    function addTextTooltip() {
      let dataText = text;
      let updates = [];
      gloassaryWords.forEach((e) => {
        let word = new RegExp("\\b" + e.word + "\\b", "ig");
        let wordIndices = getIndicesFromString(text, word, e.word);
        for (let index of wordIndices) {
          updates.push({
            word: text.substring(index, index + e.word.length),
            tooltip: e.tooltip,
            position: index,
          });
        }
      });
      let updatedText = [];
      if (!updates.length) {
        return dataText;
      }
      updates = updates.sort((a,b) => a.position - b.position);     
      let alreadyProccessedLength = 0;
      for (let update of updates) {        
        updatedText.push(
          dataText.substring(alreadyProccessedLength, update.position)
        );
        updatedText.push(
          <OverlayTrigger
            overlay={<Tooltip>{update.tooltip}</Tooltip>}
            placement="bottom"
          >
            <span class="tooltip-text">{update.word}</span>
          </OverlayTrigger>
        ); 
        alreadyProccessedLength = update.position + update.word.length;
        
      }
      updatedText.push( dataText.substring(alreadyProccessedLength));

      return updatedText;
    }
    return addTextTooltip();
  } catch (e) {
    return <div dangerouslySetInnerHTML={{__html : text}}></div>;
  }
}

export default TooltipGenerator;
