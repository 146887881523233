import React, { useEffect, useState } from "react";
import "./redirection-popup.scss";
import { StandardPopup } from "../StandardPopup/StandardPopup";
import {
  BoldText,
  SecondarySmallBoldText,
  SmallBoldText,
  SmallNormalText,
} from "../../../utils/typographyUtils";

const RedirectionPopup = () => {
  const [remainingSeconds, setRemainingSeconds] = useState(10);

  const handleSetRemainingSeconds = () => {
    setRemainingSeconds(remainingSeconds - 1);
  };

  useEffect(() => {
    if (remainingSeconds !== 0) {
      setTimeout(() => {
        handleSetRemainingSeconds();
      }, 1000);
    }
  }, [remainingSeconds]);

  useEffect(() => {
    redirectToNewUrl();
  }, []);

  const redirectToNewUrl = () => {
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_COACH_APP_NEW_URL;
    }, 10000);
  };

  return (
    <StandardPopup
      imageName={"rebranding.svg"}
      headerTitle={"NXT-9 is now Art of Scale!"}
      hideCloseButton={true}
      bodyContent={
        <div className="text-center py-4">
          <p className="pb-1 font-weight-bold">
            We’re excited to share that{" "}
            <span className="text-purple-old font-weight-bold small">
              NXT-9
            </span>{" "}
            is now{" "}
            <SecondarySmallBoldText>Art of Scale!</SecondarySmallBoldText>
          </p>
          <br />
          <div className="d-flex align-items-center justify-content-around pb-4">
            <img src="/assets/images/old-logo.svg" height={50} alt="old"/>
            <img
              src="/assets/images/right_arrows.svg"
              height={50}
              className="mx-2"
              alt="arrows"
            />
            <img src="/assets/images/new-logo.svg" height={50} 
            alt="new"/>
          </div>
          <SmallNormalText>
            Our rebranding marks the start of a new chapter filled with advanced
            solutions and user-focused enhancements.
          </SmallNormalText>
          <br />
          <SmallNormalText>
            To know more,{" "}
            <span className="text-primary-new">
              visit our new website - www.artofscale.io
            </span>
          </SmallNormalText>
        </div>
      }
      confirmButtonText={"Go to 'Art of Scale'"}
      footerContent={
        <div className="w-100 mt-3 d-flex justify-content-center text-center">
          <SmallNormalText>
            You will be automatically redirected in{" "}
            <span className="text-primary-new">{remainingSeconds}</span>{" "}
            second/s...
          </SmallNormalText>
        </div>
      }
      hideCancelButton={true}
      onConfirm={() =>
        (window.location.href = process.env.REACT_APP_COACH_APP_NEW_URL)
      }
    />
  );
};

export default RedirectionPopup;
