import React, { Component } from 'react';
import './standard-intl-phone-input.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

class StandardIntlPhoneInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputId: this.props.id,
      valid: false,
      errorText: '',
      dirty: false,
    };
  }

  componentDidMount() {
    this.checkValidity(this.props.value);
  }

  getStandardInputClass = () => {
    const { disabled, className } = this.props;
    return classNames('standard-intl-phone-input', 'position-relative', {
      disabled: disabled,
      [className]: className,
      error: (this.state.dirty || this.props.showError) && !this.state.valid,
    });
  };

  getInputClassName = () => {
    const { error, inputClasses } = this.props;
    return classNames('', {
      error: error,
      [inputClasses]: inputClasses,
      'has-feedback': this.props.type === 'password',
      'after-icon': this.props.afterIcon,
    });
  };

  renderLabel = () => {
    const { inputId } = this.state;
    const { label } = this.props;

    if (label) {
      return <label htmlFor={inputId}>{label}</label>;
    }
    return null;
  };

  renderHelp = () => {
    const { helpText } = this.props;

    if (helpText) {
      return <p className="help-text mb-0 mt-2">{helpText}</p>;
    }
    return null;
  };

  renderError = () => {
    const { errorText } = this.state;

    if (errorText && (this.state.dirty || this.props.showError)) {
      return <p className="error-text mb-0 mt-2">{errorText}</p>;
    }
    return null;
  };

  onBlur = () => {
    this.setState({
      dirty: true,
    });
  };

  checkValidity = (value) => {
    let isValid = isValidPhoneNumber(value);
    let errorText = value
      ? 'Invalid Phone Number.'
      : 'Phone Number is required.';
    this.setState({
      valid: isValid,
      errorText: errorText,
    });
  };

  checkCustomValidity = (value) => {
    if (this.props.checkValidity) {
      const { isValid, errorText } = this.props.checkValidity(value);
      this.setState({
        valid: isValid,
        errorText: errorText,
      });
    }
  };

  onChange = (value) => {
    let val = value ?? '';
    if (this.props.overloadValidity) {
      this.checkCustomValidity(val);
    } else {
      this.checkValidity(val);
      this.checkCustomValidity(val);
    }
    this.props.onChange(val);
  };

  onBlur = () => {
    this.setState({
      dirty: true,
    });
  };

  render() {
    const { disabled, value, innerRef, placeholder } = this.props;

    return (
      <div className={this.getStandardInputClass()}>
        {this.renderLabel()}
        <PhoneInput
          value={value}
          onChange={this.onChange}
          defaultCountry="ZA"
          className={this.getInputClassName()}
          disabled={disabled}
          ref={innerRef ?? null}
          onBlur={this.onBlur}
          placeholder={placeholder}
        />
        {this.state.valid ? this.renderHelp() : this.renderError()}
      </div>
    );
  }
}

StandardIntlPhoneInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  helpText: PropTypes.string,
  errorText: PropTypes.string,
  error: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  checkValidity: PropTypes.func,
  overloadValidity: PropTypes.bool,
  placholder: PropTypes.string,
};

StandardIntlPhoneInput.defaultProps = {
  disabled: false,
  error: false,
  value: '',
  overloadValidity: false,
};

export default StandardIntlPhoneInput;
