import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoadingAnimation from '../../../../../base/LoadingAnimation';
import StandardButton from '../../../../shared/formComponents/StandardButton/StandardButton';
import * as actions from '../../_redux/xrayActions';
import './recommendations-card.scss';
import RenderRecommendation from './RenderRecommendation';
import './recomm-header.scss';

export const RecommendationsCard = (props) => {
  const [expandedIndex, setExpandedIndex] = useState();
  const dispatch = useDispatch();
  const [dataType, setDataType] = useState('topten');
  const [showLoader, setShowLoader] = useState(true);
  const params = useParams();

  const [inProgressCount, setInProgressCount] = useState(0);
  const [pendingForApprovalCount, setPendingForApprovalCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [recommendations, setRecommendations] = useState([]);
  const [surveyId, setSurveyId] = useState()
  const recommendationsToShow = useMemo(() => {
    if (!recommendations || !recommendations[dataType]) return [];
    else if (recommendations[dataType].length > 0)
      return recommendations[dataType];
    else if (dataType === 'coachPriority') return recommendations['priority'];
    else if (dataType === 'coachTop40') return recommendations['priority'];
    else if (dataType === 'coachTopten') return recommendations['topten'];
    else return [];
  }, [dataType, recommendations]);

  const currentDiscipline = useSelector(
    (state) => state.xrayReport.currentDiscipline
  );

  const getRecommendations = async (updateDataType = true) => {
    setShowLoader(true);
    let recommendationsData = await dispatch(
      actions.getRecommendations(params.userId, currentDiscipline.disciplineId)
    );
    let pendingForApproval = [];
    let completed = [];
    let inProgress = [];
    completed.push(recommendationsData?.completed);
    pendingForApproval.push(recommendationsData?.pendingForApproval);
    setCompletedCount(recommendationsData?.completed?.length);
    setSurveyId(recommendationsData.surveyId)
    setPendingForApprovalCount(recommendationsData?.pendingForApproval?.length);
    inProgress.push(recommendationsData?.inProgress);
    setInProgressCount(recommendationsData?.inProgress?.length);
    setRecommendations(recommendationsData);
    if (updateDataType) {
      if (currentDiscipline.disciplineId) {
        setDataType('coachPriority');
      } else {
        setDataType('coachTopten');
      }
    }

    setShowLoader(false);
  };

  const updateCoachNotes = async (recomData) => {
    try {
      setShowLoader(true);
      // let currLev = recomData?.answerMap.findIndex((ans) => ans.isCurrentLevel);
      // recomData["customRank"]= currLev;
      await dispatch(actions.editCoachNotes(recomData));
      await getRecommendations(false);
    } catch (err) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    // This code runs on loading the component
    getRecommendations();
  }, [currentDiscipline]);

  useEffect(() => {
    const expandedCard = document.getElementById(`recom-${expandedIndex}`);
    if (expandedCard) expandedCard.scrollIntoView({ behavior: 'smooth' });
  }, [expandedIndex]);

  useEffect(() => {
    setExpandedIndex(null);
  }, [dataType]);

  const updateExpandedIndex = (index, ignoreIfOpen = false) => {
    if (index === expandedIndex && !ignoreIfOpen) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  if (showLoader) {
    return (
      <div style={{ height: '40vh' }}>
        <LoadingAnimation
          style={{
            display: 'block',
            justifyContent: 'center',
            textAlign: 'center',
            position: 'relative',
            top: '50%',
          }}
        />
      </div>
    );
  }
  return (
    <>
      <div className="bg-grey p-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="toggler">
            {currentDiscipline.disciplineId ||
              recommendations['coachTopTen']?.length === 0 ? null : (
              <StandardButton
                color={dataType === 'coachTopten' ? 'active' : ''}
                bold={true}
                text={recommendations?.coachTopten?.length > 0 ?  'Coach Top 10' : "Priority"}
                onClick={() => {
                  setDataType('coachTopten');
                }}
              ></StandardButton>
            )}
            {currentDiscipline.disciplineId ? null : (
              <StandardButton
                color={dataType === 'coachTop40' ? 'active' : ''}
                bold={true}
                text="Top 40"
                icon={
                  <img
                    src={
                      dataType === 'coachTop40'
                        ? '/assets/images/logo-small.png'
                        : '/assets/images/logo-small.png'
                    }
                    className="me-1"
                    width="18px"
                  />
                }
                reversed
                onClick={() => {
                  setDataType('coachTop40');
                }}
              ></StandardButton>
            )}
            {currentDiscipline.disciplineId ? (
              <StandardButton
                color={dataType === 'coachPriority' ? 'active' : ''}
                bold={true}
                text="Coach Priority"
                onClick={() => {
                  setDataType('coachPriority');
                }}
              ></StandardButton>
            ) : null}
            {currentDiscipline.disciplineId ? (
              <StandardButton
                color={dataType === 'all' ? 'active' : ''}
                bold={true}
                text={
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        dataType === 'all'
                          ? '/assets/images/logo-small.png'
                          : '/assets/images/logo-small.png'
                      }
                      className="me-1"
                      width="18px"
                    />
                    All Gaps
                  </div>
                }
                onClick={() => {
                  setDataType('all');
                }}
              ></StandardButton>
            ) : null}
            <StandardButton
              color={dataType === "inProgress" ? "active" : ""}
              bold={true}
              text={`In Progress (${inProgressCount})`}
              onClick={() => {
                setDataType("inProgress");
              }}
            ></StandardButton>
            <StandardButton
              color={
                dataType === "pendingForApproval" ? "active" : ""
              }
              bold={true}
              text={`Pending for approval (${pendingForApprovalCount})`}
              onClick={() => {
                setDataType("pendingForApproval");
              }}
            ></StandardButton>
            <StandardButton
              color={dataType === "completed" ? "active" : ""}
              bold={true}
              text={`Completed (${completedCount})`}
              onClick={() => {
                setDataType("completed");
              }}
            ></StandardButton>

          </div>
        </div>
      </div>
      <div className="px-4 py-3">
        {recommendationsToShow
          .map((recommendation, index) => ({
            ...recommendation,
            index,
          }))
          .map((recommendation) => (
            <RenderRecommendation
              key={`recommendation-${recommendation.rank}-${recommendation.index}`}
              recommendation={recommendation}
              surveyId={surveyId}
              updateExpandedIndex={updateExpandedIndex}
              expandedIndex={expandedIndex}
              updateCoachNotes={updateCoachNotes}
            />
          ))}
      </div>
    </>
  );
};
