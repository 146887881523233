import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import ReactSpeedometer from "react-d3-speedometer";
import * as actions from "../../_redux/xrayActions";

import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
  Text,
  PolarRadiusAxis,
  Tooltip,
} from "recharts";
import "./stats-card.scss";
import { useDispatch, useSelector } from "react-redux";
import { EditRespondantsPopup } from "../EditRespondantsPopup/EditRespondantsPopup";
import StandardButton from "../../../../shared/formComponents/StandardButton/StandardButton";
import { speedoMeterColors } from "../../../../../data/SpeedoMeterColors";
import { useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import EditRecommPopup from "../EditRecommendation/EditRecommPopup";
import { generateChartData } from "../../../../../utils/xrayUtils";
import PublishXrayResultsPopup from "../PublishXrayResultsPopup/PublishXrayResultsPopup";
import { SurveyScoreEditingEnums } from "../../../../../data/enums/SurveyEums";
import { downloadFileAsBlob } from "../../../../../utils/fileUtils";
import { toast } from "react-toastify";
import { loadingNoti } from "../../../../../base/BasicNotifications";
import { QUEST } from "../../../../../data/RouteUrls";
import * as xrayActions from "../../_redux/xrayActions";

export const StatsCard = (props) => {
  const theme = useTheme();
  const xlDown = useMediaQuery(theme.breakpoints.down("xl"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();
  const params = useParams();

  const [currentDisciplineIndex, setCurrentDisciplineIndex] = useState(0);
  const [xrayScore, setXrayScore] = useState();
  const [showEditRespondantsPopup, setShowEditRespondantsPopup] =
    useState(false);
  const [showEditRecommPopup, setShowEditRecommPopup] = useState(false);
  const [showPublishPopup, setShowPublishPopup] = useState(false);
  const dispatch = useDispatch();

  const xrayState = useSelector((state) => state.xrayReport.xRayState);
  const progressToast = useRef(null);

  const updateCurrentDisciplineIndex = (index) => {
    setCurrentDisciplineIndex(index);
    dispatch(actions.updateCurrentDiscipline(xrayScore[index]));
  };




  const renderDisciplineList = () => {
    return xrayScore.map((discipline, index) => {
      let classList = "d-flex align-items-center";
      let imgEnd = discipline.tabName
        .replace(/ /g, "-")
        .replace("&", "")
        .replace("--", "-");
      let imgPath =
        "/assets/images/disciplineIcons/discipline-icon-" + imgEnd + ".svg";

      if (currentDisciplineIndex === index) {
        classList += " active";
        imgPath =
          "/assets/images/disciplineIcons-active/discipline-icon-" +
          imgEnd +
          ".svg";
      }

      return (
        <div
          className={classList}
          onClick={() => updateCurrentDisciplineIndex(index)}
        >
          <div className="img-container">
            <img src={imgPath} alt="disciplien logo" />
          </div>
          <div className="ms-3">{discipline.tabName}</div>
        </div>
      );
    });
  };

  const renderEditRespondantsPopup = () => {
    if (showEditRespondantsPopup) {
      return (
        <EditRespondantsPopup
          handleClose={() => setShowEditRespondantsPopup(false)}
        />
      );
    }
  };

  const closePopup = async () => {
    // await dispatch(actions.removeData());
    setShowEditRecommPopup(false);
  };

  const renderEditRecommendationsPopup = () => {
    if (showEditRecommPopup) {
      return (
        <EditRecommPopup
          surveyId={props.surveyId}
          show={showEditRecommPopup}
          handleClose={closePopup}
          xrayScore={props.xrayScore}
        />
      );
    }
  };

  const renderPublishXrayResultsPopup = () => {
    if (showPublishPopup) {
      return (
        <PublishXrayResultsPopup
          show={showPublishPopup}
          handleClose={() => setShowPublishPopup(false)}
          contact={xrayState.contact}
        />
      );
    }
  };

  const downloadProgressCB = (pe) => {
    const pct = Math.floor((pe.loaded / pe.total) * 100);
    if (progressToast.current) {
      if (pct === 100) {
        toast.dismiss(progressToast.current);
        progressToast.current = null;
      } else
        toast.update(progressToast.current, { render: `Downloading ${pct}%` });
    } else progressToast.current = loadingNoti(`Downloading ${pct}%`);
  };

  const downloadPDF = async () => {
    progressToast.current = loadingNoti(`Downloading ${0}%`);
    let pdfUrl = await dispatch(actions.getPdfUrl(xrayState.surveyId));
    let pdfComponents = pdfUrl.split("/");
    let pdfName = pdfComponents[pdfComponents.length - 1];
    downloadFileAsBlob(pdfUrl, downloadProgressCB, pdfName);
  };

  const renderXrayNav = () => {
    return (
      <div className="d-flex justify-content-between align-items-center p-3 xray-nav">
        <div className="d-flex align-items-center">
          <StandardButton
            icon={<span className="material-icons icon">arrow_back</span>}
            text="Back"
            onClick={() => history.goBack()}
            reversed
          />
          <h3 className="xray-company-name ms-2">
            {xrayState.contact?.companyName} X-Ray
          </h3>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <StandardButton
            text="Edit Recommendations"
            color={
              !xrayState.recommendationsEdited
                ? "btn-success"
                : "btn-outline-success"
            }
            className={`me-3 ${!xrayState.recommendationsEdited ? "blink" : ""
              }`}
            icon={<i className="fa-solid fa-pen-to-square"></i>}
            reversed
            onMouseOver={() => {
              if (!xrayState.recommendationsEdited) {
                dispatch(
                  actions.updateReadStatus(
                    params.userId,
                    SurveyScoreEditingEnums.RECOMM_EDITED
                  )
                );
              }
            }}
            onClick={() => {
              if (!xrayState.recommendationsEdited) {
                dispatch(
                  actions.updateReadStatus(
                    params.userId,
                    SurveyScoreEditingEnums.RECOMM_EDITED
                  )
                );
              }
              setShowEditRecommPopup(true);
            }}
          />

          {!xrayState.xrayPublished && !xrayState.isDummy ? (
            <StandardButton
              text="Grant X-Ray Access"
              color="btn-success"
              icon={<i className="fa-solid fa-paper-plane"></i>}
              reversed
              onClick={() => setShowPublishPopup(true)}
            />
          ) : null}
          {xrayState.xrayPublished ? (
            <StandardButton
              text="Download PDF"
              color="btn-success"
              icon={<i className="fa-solid fa-file-lines"></i>}
              reversed
              onClick={() => downloadPDF()}
            />
          ) : null}
        </div>
      </div>
    );
  };

  useEffect(() => {
    // This code runs on loading the component

    let formattedData = generateChartData(props.xrayScore);
    setXrayScore(formattedData);
    dispatch(actions.updateCurrentDiscipline(formattedData[0]));
  }, []);
  if (!xrayScore) {
    return null;
  }

  const customRadarTicks = ({ payload, x, y, textAnchor, stroke, radius }) => {
    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        <Text
          fontSize={12}
          width="200"
          radius={radius}
          stroke={stroke}
          x={x}
          y={y}
          textAnchor={textAnchor}
        >
          {payload.value}
        </Text>
      </g>
    );
  };

  const radarOuterRadius = mdDown
    ? "40%"
    : lgDown
      ? "50%"
      : xlDown
        ? "60%"
        : "70%";
  return (
    <div className="stats-card">
      <Card>
        <div className="card-body p-0">
          {renderXrayNav()}
          <div className="row m-0">
            <div className="col-3 col-xl-2  discipline-list">
              {renderDisciplineList()}
            </div>
            <div className="col-9 col-xl-10 p-3">
              <div
                // className="row h-100"
                className="d-flex h-100 w-100 align-items-center charts-container"
                style={{ overflow: "auto" }}
              >
                <div
                  // className="col-8 h-100"
                  className="h-100"
                  style={{ flex: 2 }}
                >
                  <ResponsiveContainer
                    width="100%"
                    height="90%"
                    className={"mt-auto"}
                  >
                    <RadarChart
                      cx="50%"
                      cy="50%"
                      // outerRadius={radarOuterRadius}
                      outerRadius={25 * 5}
                      data={xrayScore[currentDisciplineIndex].spiderChartData}
                    >
                      <Tooltip />
                      <PolarGrid
                        polarRadius={[0, 1, 2, 3, 4, 5].map((r) => r * 25)}
                      />
                      <PolarAngleAxis dataKey="label" tick={customRadarTicks} />
                      <PolarRadiusAxis
                        domain={[0, 5]}
                        axisLine={false}
                        scale="sequential"
                        angle={90}
                        tick={false}
                      />
                      <Radar
                        name="Required Score"
                        dataKey="requiredScore"
                        stroke="grey"
                        fill="transparent"
                      />

                      <Radar
                        name="Gut Score"
                        dataKey="gutScore"
                        stroke="#2d4aa3"
                        fill="#2d4aa3"
                        fillOpacity={0.3}
                      />
                      <Radar
                        name="Actual Score"
                        dataKey="actualScore"
                        stroke="#056C75"
                        fill="#056C75"
                        fillOpacity={0.3}
                      />
                    </RadarChart>
                  </ResponsiveContainer>
                  <div
                    id="legend"
                    className="legend d-flex justify-content-center align-items-center"
                  >
                    <div className="required d-flex justify-content-center align-items-center">
                      <div className="indicator"></div>
                      <div>Required Score</div>
                    </div>
                    <div className="gut d-flex justify-content-center align-items-center">
                      <div className="indicator"></div>
                      <div>Gut Score</div>
                    </div>
                    <div className="actual d-flex justify-content-center align-items-center">
                      <div className="indicator"></div>
                      <div>Actual Score</div>
                    </div>
                  </div>
                </div>
                <div
                  // className="col-4 my-auto"
                  className=""
                  style={{ flex: 1 }}
                >
                  <div className="d-flex align-items-center justify-content-center flex-column h-100">
                    <div className="bmi-box d-flex flex-column align-items-center justify-content-center p-3">
                      <div className="cs-sub-heading mb-3">
                        {xrayScore[currentDisciplineIndex].disciplineId
                          ? xrayScore[currentDisciplineIndex].tabName
                          : "Business Maturity Index"}
                      </div>
                      <div>
                        <ReactSpeedometer
                          value={Math.round(
                            xrayScore[currentDisciplineIndex].actualScore * 20
                          )}
                          width={lgDown ? 200 : 300}
                          height={lgDown ? 200 : 300}
                          ringWidth={lgDown ? 30 : 60}
                          maxValue={100}
                          segments={5}
                          needleColor={"#414042"}
                          textColor={
                            speedoMeterColors[
                            Math.floor(
                              xrayScore[currentDisciplineIndex].actualScore
                            )
                            ]
                          }
                          currentValueText={
                            parseInt(
                              xrayScore[currentDisciplineIndex].actualScore * 20
                            ) + "%"
                          }
                          valueTextFontSize={lgDown ? "20px" : "30px"}
                          valueTextFontWeight="1000"
                          paddingVertical={7}
                          segmentColors={speedoMeterColors}
                          forceRender={true}
                        />
                      </div>
                    </div>
                    <div>
                      <StandardButton
                        className={`mt-3 ${!xrayState.respondantsEdited ? "blink" : ""
                          }`}
                        color={
                          !xrayState.respondantsEdited
                            ? "btn-success"
                            : "btn-success"
                        }
                        bold={true}
                        icon={<i className="fa-solid fa-pen-to-square"></i>}
                        text={"Edit X-Ray Respondents"}
                        reversed
                        onMouseOver={() => {
                          if (!xrayState.respondantsEdited) {
                            dispatch(
                              actions.updateReadStatus(
                                params.userId,
                                SurveyScoreEditingEnums.RESPONDANT_EDITED
                              )
                            );
                          }
                        }}
                        onClick={() => {
                          if (!xrayState.respondantsEdited) {
                            dispatch(
                              actions.updateReadStatus(
                                params.userId,
                                SurveyScoreEditingEnums.RESPONDANT_EDITED
                              )
                            );
                          }
                          setShowEditRespondantsPopup(true);
                        }}
                      ></StandardButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      {renderEditRespondantsPopup()}
      {renderEditRecommendationsPopup()}
      {renderPublishXrayResultsPopup()}
    </div>
  );
};
