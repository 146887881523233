import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { AUTH_ROUTE, OTP_ROUTE, SIGNUP_ROUTE } from "../../../data/RouteUrls";
import validationUtils from "../../../utils/validationUtils";
import { SignupValidation } from "../../../validations/Signup";
import StandardButton from "../../shared/formComponents/StandardButton/StandardButton";
import StandardInput from "../../shared/formComponents/StandardInput/StandardInput";
import StandardIntlPhoneInput from "../../shared/formComponents/StandardIntlPhoneInput/StandardIntlPhoneInput";
import LoadingAnimation from "../../../base/LoadingAnimation";
import * as actions from "../_redux/authAction";
import { warnNoti } from "../../../base/BasicNotifications";

const Signup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stateEmail = useSelector((state) => state.authDetails.email);
  const stateSignupDetails = useSelector(
    (state) => state.authDetails.signupDetails
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get("source") || "NONE";
  const [email, setEmail] = useState(stateEmail);
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isFreeAccess, setIsFreeAccess] = useState(false);
  const validateInviteCode = async (inviteCode) => {
    try {
      let success = await dispatch(actions.validateInviteCode(inviteCode));
      setShowLoader(false);
      return success;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  useEffect(() => {
    async function fetchData() {
      const INVITE_CODE_KEY = "inviteCode";
      const searchParams = new URLSearchParams(location.search);
      const hasInviteCodeKey = searchParams.has(INVITE_CODE_KEY);
      const inviteCode = searchParams.get(INVITE_CODE_KEY);

      if (!hasInviteCodeKey) {
        setIsFreeAccess(true);
        setShowLoader(false);
      } else {
        const isValidCode = await validateInviteCode(inviteCode);
        if (!isValidCode) {
          history.push(AUTH_ROUTE);
        }
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_IS_PROD) {      
      const script = document.createElement("script");

      script.src = "https://crm.zoho.com/crm/javascript/zcga.js";
      script.type = "text/javascript";

      document.body.appendChild(script);

      return () => {
        // clean up the script when the component in unmounted
        document.body.removeChild(script);
      };
    }
  }, []);


  useEffect(() => {
    if (stateSignupDetails) {
      setFirstName(stateSignupDetails?.firstName);
      setLastName(stateSignupDetails?.lastName);
      setEmail(stateSignupDetails?.email? stateSignupDetails?.email.trim(): stateSignupDetails?.email);
    }
  }, [stateSignupDetails]);

  const validations = SignupValidation;
  const submit = async (e) => {
    e.preventDefault();
    let data = {
      email,
      password,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phoneNo,
      companyName,
      source,
    };
    if (!validationUtils.checkFormValidity(data, validations)) {
      setShowError(true);
      return;
    }
    try {
      setButtonDisabled(true);
      const signupSuccess = await dispatch(actions.signup(data, isFreeAccess));
      if (signupSuccess) {
        history.push(OTP_ROUTE);
      }
    } catch (err) {
    }
     finally {
      setButtonDisabled(false);
    }
  };

  if (showLoader) {
    return (
      <LoadingAnimation
        style={{
          display: "block",
          justifyContent: "center",
          textAlign: "center",
          position: "relative",
          top: "40%",
        }}
      />
    );
  }

  return (
    <div className="d-flex flex-column h-100 p-md-3">
      <form onSubmit={submit}>
        <div className="text-center">
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className="m-auto auth-side-logo"
          />
          <div className="mt-3 cs-sub-heading">{isFreeAccess ?'Signup - For Business Coaches' : 'Signup'}</div>
          <StandardInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-3"
            label="Email"
            type="email"
            placeholder="example@mail.com"
            validations={validations.email}
            showError={showError}
            inputProps={{
              autoComplete: "off",
            }}
            showRequired={true}
          />
          {stateEmail ? (
            <div className="hint text-left">
              Hmm, we don't recognize that email address. Let's get you signed
              up!
            </div>
          ) : null}
          <div className="row">
            <div className="col-6">
              <StandardInput
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="mt-3"
                label="First Name"
                type="text"
                validations={validations.firstName}
                showError={showError}
                inputProps={{
                  autoComplete: "off",
                }}
                showRequired={true}
              />
            </div>
            <div className="col-6">
              <StandardInput
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="mt-3"
                label="Last Name"
                type="text"
                validations={validations.lastName}
                showError={showError}
                inputProps={{
                  autoComplete: "off",
                }}
                showRequired={true}
              />
            </div>
          </div>

          <StandardIntlPhoneInput
            value={phoneNo}
            onChange={(value) => setPhoneNo(value)}
            className="mt-3"
            label="Phone Number"
            showError={showError}
          />

          <StandardInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-3"
            label="Create Password"
            type="password"
            validations={validations.password}
            showError={showError}
            inputProps={{
              autoComplete: "off",
            }}
            showRequired={true}
          />
          <StandardInput
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="mt-3"
            label="Company Name"
            type="text"
            validations={validations.companyName}
            showError={showError}
            inputProps={{
              autoComplete: "off",
            }}
            showRequired={true}
          />
        </div>
        <div className="mt-auto">
          <StandardButton
            className="mt-3 mb-3"
            color="btn-success"
            bold={true}
            block={true}
            text="Sign up"
            type="submit"
            disabled={buttonDisabled}
            onClick={submit}
          ></StandardButton>
        </div>
        <div className="terms-conditions text-center">
          By signing up, you're agreeing to our{" "}
          <a href="https://artofscale.io/terms-and-conditions" target="_blank">
            Customer Terms of Service
          </a>{" "}
          and{" "}
          <a href="https://artofscale.io/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </div>
        <input type="hidden" id="zc_gad" name="zc_gad" value="" />
      </form>
    </div>
  );
};

export default Signup;
