import classNames from "classnames";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./standard-tooltip.scss";

export const StandardTooltip = ({
  tooltTipContent,
  children,
  applyMarginTop,
}) => {
  const renderTooltip = (props) => (
    <Tooltip id="standard-tooltip" {...props}>
      {tooltTipContent}
    </Tooltip>
  );
  let childrenClasses = classNames("cursor-pointer cs-mb-28", {
    "mt-1": applyMarginTop,
  });
  return (
    <OverlayTrigger overlay={renderTooltip} placement="auto">
      {children || (
        <img
          src="/assets/images/notice_light.svg"
          alt="notice"
          height={16}
          width={16}
          className={childrenClasses}
        />
      )}
    </OverlayTrigger>
  );
};
