import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import LoadingAnimation from '../../../base/LoadingAnimation';
import { getFileAsBlob } from '../../../utils/fileUtils';

const AsyncImage = ({ src, ...rest }) => {
  const [loader, setLoader] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  async function getImageFromServer() {
    setLoader(true);
    const imageUrl = await getFileAsBlob(src);
    setImgSrc(imageUrl);
    setLoader(false);
  }

  useEffect(() => {
    getImageFromServer();
  }, [src]);

  return loader ? (
    rest.hideLoader ? null : (
      <LoadingAnimation
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative',
          top: '50%',
          width: '100px',
          height: '100px',
        }}
      />
    )
  ) : (
    <Image src={imgSrc} {...rest} />
  );
};

export default AsyncImage;
