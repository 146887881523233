export const ValidationEnum = {
  REQUIRED: 'Required',
  MAXLENGTH: 'MaxLength',
  PATTERN: 'Pattern',
  COMPAREVALUE: 'CompareValue',
  RSAID: 'RSAidnumber',
  MIN: 'Min',
  MAX: 'Max',
  PHONENUMBER: 'PhoneNumber',
  INTLPHONENUMBER: 'IntlPhoneNumber',
};
