import React from "react";
import { ProSidebar, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { ChapterIcons } from "../../../../../data/enums/ChapterIcons";
import { LineProgressBar } from "../../../../shared/LineProgressBar/LineProgressBar";
import { COURSE_OVERVIEW } from "../../../../../data/RouteUrls";
import { Link } from "react-router-dom";
import StandardButton from "../../../../shared/formComponents/StandardButton/StandardButton";
import "./course-progress-sidebar.scss";

const CourseProgressSidebar = (props) => {
  const {
    courseProgressSidebarVisible,
    section,
    course,
    hideProgressSidebar,
    goToChapter,
  } = props;
  const renderChapters = () => {
    let chapters = [];
    let index = 0;
    for (let chapter of section.chapters) {
      chapter.index = index;
      let chapter_type = chapter.chapter_type;
      let classes =
        "chapter d-flex align-items-center justify-content-start text-start";
      let progressIcon;
      if (chapter.is_current) {
        progressIcon = (
          <div className="progress-icon current">
            <span class="material-icons">adjust</span>
          </div>
        );
        classes += " active";
        chapter_type += "_ACTIVE";
      } else if (chapter.is_completed) {
        progressIcon = (
          <div className="progress-icon completed">
            <span class="material-icons">check_circle</span>
          </div>
        );
      } else {
        progressIcon = (
          <div className="progress-icon blank">
            <span class="material-icons">radio_button_unchecked</span>
          </div>
        );
      }
      let chapterDesign = (
        <div
          className={classes}
          onClick={() => {
            hideProgressSidebar();
            goToChapter(chapter.index);
          }}
        >
          <div>{progressIcon}</div>
          <div className="ms-3">
            <img src={ChapterIcons[chapter_type]} alt="chapter type" />
          </div>
          <div className="ms-3">{chapter.name}</div>
        </div>
      );
      chapters.push(chapterDesign);
      index += 1;
    }
    return chapters;
  };

  return (
    <div className="course-progress-sidebar">
      <ProSidebar
        breakPoint="xl"
        rtl={true}
        toggled={courseProgressSidebarVisible}
        onToggle={hideProgressSidebar}
      >
        <SidebarHeader className="text-center position-relative">
          {/* <div
            className="material-icons close-icon d-inline-block d-xl-none"
            onClick={hideProgressSidebar}
          >
            close
          </div> */}
          <div className="title cs-sub-heading">{section.name}</div>
          <div className="progress-bar-container">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <LineProgressBar
                percentage={course.course_status.replace("%", "")}
              />
              <div className="d-flex justify-content-center">
                <div className="percentage">{course.course_status}</div>
                <div className="ms-2">Complete</div>
              </div>
            </div>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <div className="chapters">
            <div>{renderChapters()}</div>
          </div>
          <div className="px-3">
            <Link to={COURSE_OVERVIEW.replace(":courseId", course.id)}>
              <StandardButton
                color="btn-success"
                bold={true}
                block={true}
                text="Back To Course Overview"
              ></StandardButton>
            </Link>
          </div>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default CourseProgressSidebar;
