import React, { useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import * as authActions from '../_redux/authAction';
import validationUtils from '../../../utils/validationUtils';
import StandardButton from '../../shared/formComponents/StandardButton/StandardButton';
import StandardInput from '../../shared/formComponents/StandardInput/StandardInput';
import { LoginValidation } from '../../../validations/Login';
import { ChangePasswordValidation } from '../../../validations/ChangePassword';
import { pickFromObject } from '../../../utils/generalUtils';
import { GetEmailValidation } from '../../../validations/GetEmail';
import { COACH_PANEL } from '../../../data/RouteUrls';

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(
    useSelector((state) => state.authDetails.email)
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const validations = LoginValidation;
  const [showError, setShowError] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const data = { email };
    if (!validationUtils.checkFormValidity(data, validations)) {
      setShowError(true);
      return;
    }
    setButtonDisabled(true);
    try {
      let success = await dispatch(authActions.forgetPassword(data));
    } catch (err) {
      console.error(err);
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <div className="d-flex flex-column h-100 p-md-3">
      <form onSubmit={submit}>
        <div className="my-auto text-center">
          <img src="/assets/images/logo.png" alt="logo" className="m-auto auth-side-logo" />
          <div className="mt-3 cs-sub-heading">Reset Password</div>
          <StandardInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="my-3"
            label="Email"
            type="email"
            placeholder="example@mail.com"
            showError={showError}
            validations={validations.email}
          />
        </div>
        <div className="mt-auto">
          <StandardButton
            className="my-3"
            color="btn-success"
            bold={true}
            block={true}
            text="Reset Password"
            type="submit"
            disabled={buttonDisabled}
          ></StandardButton>
        </div>
      </form>
    </div>
  );
};

const INITAL_STATE = {
  new: '',
  confirm: '',
};

function passwordReducer(state, action) {
  switch (action.type) {
    case 'new':
      return { ...state, new: action.payload };
    case 'confirm':
      return { ...state, confirm: action.payload };
    case 'reset':
      return { ...INITAL_STATE };
    default:
      return state;
  }
}

export const ResetPasswordUpdate = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const [passwords, passwordDispatcher] = useReducer(
    passwordReducer,
    INITAL_STATE
  );
  const [email, setEmail] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('email')) {
      setEmail(searchParams.get('email'));
    }
  }, []);

  const changePasswordSubmit = async (e) => {
    e.preventDefault();
    const validations = {
      email: GetEmailValidation.email,
      ...pickFromObject(ChangePasswordValidation, ['new', 'confirm']),
    };
    if (
      !validationUtils.checkFormValidity(
        { new: passwords.new, confirm: passwords.confirm, email },
        validations
      ) ||
      passwords.new !== passwords.confirm
    ) {
      setShowError(true);
      return;
    } else setShowError(false);

    try {
      setShowLoader(true);
      // Email injected from state in action
      let data = {
        password: passwords.new,
        resetCode: params.resetCode,
        email,
      };
      await dispatch(authActions.resetPassword(data));
      history.push(COACH_PANEL);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div className="d-flex flex-column h-100 p-md-3">
      <div className="mb-3 text-center">
        <img src="/assets/images/logo.png" alt="logo" className="m-auto" />
        <div className="mt-3 cs-sub-heading">Change your password</div>
      </div>
      <form onSubmit={changePasswordSubmit}>
        <StandardInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-4 mb-3"
          label="Email"
          type="email"
          placeholder="example@mail.com"
          showError={showError}
        />
        <StandardInput
          value={passwords.new}
          onChange={(e) =>
            passwordDispatcher({ type: 'new', payload: e.target.value })
          }
          className="mb-3"
          label="New Password"
          type="password"
          showError={showError}
          validations={ChangePasswordValidation.new}
        />
        <StandardInput
          value={passwords.confirm}
          onChange={(e) =>
            passwordDispatcher({ type: 'confirm', payload: e.target.value })
          }
          className="mb-4"
          label="Confirm Password"
          type="password"
          validations={ChangePasswordValidation.confirm}
          showError={showError}
          checkValidity={(value) => ({
            isValid: value === passwords.new,
            errorText: 'Passwords must match',
          })}
        />
        <StandardButton
          color="btn-success"
          block={true}
          text="Update"
          type="submit"
          disabled={showLoader}
        />
      </form>
    </div>
  );
};
