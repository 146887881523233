import React, { useState } from "react";
import { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import StandardButton from "../formComponents/StandardButton/StandardButton";
import "./Initiative-stepper.scss";

export const InitiativeStepper = ({ status, practiceOwnerName }) => {
  const [userProfile, setUserProfile] = useState(
    useSelector((state) => state.authDetails.userProfile)
  );
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setStepperData(status)
  }, [status]);
  const setStepperData = (status) => {
    let stepsData = [
      {
        title: "Create Initiative",
        img: function () {
          if (this.completed)
            return `/assets/images/initiative-icons/completed.svg`;
          else
            return `/assets/images/initiative-icons/create${this.active ? "-active" : ""
              }.svg`;
        },
        completed: [
          "IN_PROGRESS",
          "SUBMIT_FOR_APPROVAL",
          "APPROVE",
          "REJECTED",
        ].includes(status),
        active: status == "NOT_STARTED",
        tooltip: "Create one or more initiatives to accomplish the recommendation and improve the practice",
      },
      {
        title: "Complete Initiative",
        completed: ["SUBMIT_FOR_APPROVAL", "APPROVE", "REJECTED"].includes(status),
        active: status == "IN_PROGRESS" || status == "REJECTED",
        tooltip: "Implement and complete the initiative to make progress towards improving the practice",
        img: function () {
          if (this.completed)
            return `/assets/images/initiative-icons/completed.svg`;
          else
            return `/assets/images/initiative-icons/complete${this.active ? "-active" : ""
              }.svg`;
        },
      },
      {
        title: "Submit for Approval",

        img: function () {
          if (this.completed)
            return `/assets/images/initiative-icons/completed.svg`;
          else
            return `/assets/images/initiative-icons/submit${this.active ? "-active" : ""
              }.svg`;
        },
        completed: ["APPROVE"].includes(status),
        active: status == "SUBMIT_FOR_APPROVAL" || status == "REJECTED",
        tooltip: "Submit your initiative(s) to the CEO for approval once you believe the recommendation has been accomplished",
      },
      {
        title: "Goal Achieved",

        img: function () {
          if (this.completed)
            return `/assets/images/initiative-icons/completed.svg`;
          else
            return `/assets/images/initiative-icons/improve${this.active ? "-active" : ""
              }.svg`;
        },
        completed: ["APPROVE"].includes(status),
        active: false,
        tooltip: "Congratulations! Your initiative(s) have been approved by the CEO and the goal of improving the practice has been achieved",
      },
    ];
    setSteps(stepsData);
  }

  const renderBottomStates = (step, index) => {
    if (!practiceOwnerName && userProfile?.roleName === "CEO" && index === 0) {
      return <>
      <div className="d-flex align-items-center">
        <div className="font-weight-bold ms-1">
          Owner Not Assigned
        </div>
      </div>
    </>;
    } else {
      if (index === 2) {
        if (step.active) {
          let imgPath, statusText;
          if (status === "SUBMIT_FOR_APPROVAL") {
            imgPath = "/assets/images/initiative-icons/await.svg"
            statusText = "Awaiting Approval"
          }
          if (status === "REJECTED") {
            imgPath = "/assets/images/rejected.svg"
            statusText = "Rejected"
          }
          return <>
            <div className="d-flex align-items-center">
              <img src={imgPath} />
              <div className="font-weight-bold ms-1">
                {statusText}
              </div>
            </div>
          </>
        }
      }
    }
  }

  return (
    <>
      <div className="initiative-wrapper my-3">
        <div className="font-weight-bold big">Initiative Status</div>
        <div className="ini-stepper-container my-5">
          <div className="position-relative">
            <ul className="init-stepper w-100">
              {
                steps.map((step, index) => (

                  <li
                    key={`lvl-${step.title}`}
                    className={`${step.active ? "active" : ""} ${!step.completed ? "grey" : ""
                      }`}
                  >
                    <div
                      className={`top-text ${step.active ? "active-text" : ""}`}
                    >
                      {" "}
                      <span>{step.title}</span>{" "}
                      <span>
                        <OverlayTrigger
                          overlay={<Tooltip>{step.tooltip}</Tooltip>}
                          placement="bottom"
                        >
                          <img
                            src="/assets/images/notice_purple.svg"
                            alt="notice"
                            height={14}
                            width={14}
                            className="ms-1"
                          />
                        </OverlayTrigger>
                      </span>
                    </div>

                    <div className="d-flex align-items-center justify-content-center position-relative">
                      <div className="init-img-wrapper">
                        <img src={step.img()} />
                      </div>
                    </div>
                    <div className="bottom-text text-center">
                      {renderBottomStates(step, index)}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
