import React, { useCallback, useEffect, useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import StandardButton from "../../../../shared/formComponents/StandardButton/StandardButton";
import "./recommendations-card.scss";
import RecommHeader from "./RecommHeader";
import StandardTextarea from "../../../../shared/formComponents/StandardTextarea/StandardTextarea";
import { useDispatch, useSelector } from "react-redux";
import { addTextTooltip } from "../../../../../utils/generalUtils";
import { InitiativeStepper } from "../../../../shared/InitiativeStepper/InitiativeStepper";
import TooltipGenerator from "../../../../shared/TooltipGenerator/TooltipGenerator";
import * as actions from "../../_redux/xrayActions";
import { Link } from "react-router-dom";
import {
  COURSE_OVERVIEW,
  RESOURCE_DETAIL,
} from "../../../../../data/RouteUrls";
import { eventTypes } from "../../../../../data/enums/eventTypes";

import * as academyActions from "../../../Academy/_redux/AcademyAction";
import { contentTypes } from "../../../../../data/enums/contentTypes";
import cheerio from "cheerio";

import { InitiativeContent } from "./InitiativeContent";
import { CustomBarChart } from "../../../../shared/CustomBarChart/CustomBarChart";
import LoadingAnimation from "../../../../../base/LoadingAnimation";

const levelIcons = (lvl, currentLevel, nextLevel) => {
  if (lvl < currentLevel) return "check_circle_outline";
  else if (lvl === currentLevel) return "check_circle";
  else if (lvl === nextLevel) return "radio_button_checked";
  else return "circle";
};

export const RenderTimelineChart = ({
  recommendation,
  textToShow,
  setLineGraphLevel,
  recomType,
  setTextToShow,
}) => {
  const { currentLevel, nextLevel } = recommendation;
  const getIcon = (lvl) => {
    const iconActive =
      (textToShow === "recommendation" && lvl <= nextLevel) ||
      (textToShow !== "recommendation" && lvl <= textToShow);
    if (lvl <= nextLevel) {
      return (
        <span className={`material-icons icon ${iconActive ? "active" : ""}`}>
          <div className={`sharp-bottom ${textToShow === lvl ? "show" : ""}`} />
          <div className={`bottom-line ${textToShow === lvl ? "active" : ""}`}>
            <div className="line" />
            <div className="dot" />
          </div>
          {levelIcons(lvl, currentLevel, nextLevel)}
        </span>
      );
    } else
      return (
        <span
          className={`material-icons-outlined circle icon ${iconActive ? "active" : ""
            }`}
        >
          <div className="sharp-bottom" />
          <div className={`bottom-line ${textToShow === lvl ? "active" : ""}`}>
            <div className="line" />
            <div className="dot" />
          </div>
          {levelIcons(lvl, currentLevel, nextLevel)}
        </span>
      );
  };
  const greyAfter = (lvl) =>
    textToShow === "recommendation" ? lvl >= nextLevel : lvl >= textToShow;
  const liActive = (ans) =>
    textToShow === ans.level ||
    (textToShow === "recommendation" && ans.level === nextLevel);
  return (
    <div className="timeline-container">
      <ul className="timeline w-100">
        {recommendation.answerMap.map((ans) => (
          <li
            key={`lvl-${ans.level}`}
            className={`${greyAfter(ans.level) ? "grey" : ""} ${liActive(ans) ? "active" : ""
              }`}
            onClick={() => setLineGraphLevel(ans.level, ans, recommendation)}
          >
            <div className="top-text">Level {ans.level}</div>
            {getIcon(ans.level)}
            <div className="bottom-text">
              {ans.level === currentLevel
                ? "You are here"
                : ans.level === nextLevel
                  ? "You should be here"
                  : null}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
const RoundedBar = (props) => {
  const { fill, x, y, width, height } = props;
  const radius = 5;
  const d = `m ${x}, ${y} h ${width} a ${radius},${radius} 0 0 1 ${radius},${radius} v ${height - 2 * radius
    } a ${radius},${radius} 0 0 1 -${radius},${radius} h-${width} Z`;
  return <path d={d} stroke="none" fill={fill} />;
};
export const RenderBarChart = ({ setLineGraphLevel, answerMap, recomType, ans }) => {
  const CustomBarLabel = (props) => {
    const { x, y, width, height, value, style } = props;
    const { pct, text } = value;
    return (
      <g>
        <text
          x={x + 5}
          y={y + height / 2 + 5}
          style={style}
          className="bar-label"
        >
          <tspan style={{ fontWeight: 700, fontSize: "14px" }}>{pct}%</tspan>
          &emsp;
          <tspan style={{ fontSize: "14px" }}>{text}</tspan>
        </text>
      </g>
    );
  };
  return (
    <div className="bar-container mb-3">
      <div className="w-100">
        <ResponsiveContainer width="100%" height={150}>
          <BarChart
            data={answerMap} layout="vertical" maxBarSize={30}>
            <Bar
              dataKey="percent"
              fill="#8884d8"
              label={false}
              stackId="barId"
              shape={<RoundedBar />}
              isAnimationActive={true}
            >
              {answerMap.map((bd, idx) => (
                <Cell
                  key={`cell-${idx}`}
                  fill={
                    (bd.isCurrentLevel && recomType === "situation") ||
                      (bd.isNextLevel && recomType === "recommendation")
                      ? "#72C6FF"
                      : "#C7C7C7"
                  }
                />
              ))}
              <LabelList
                dataKey="text"
                style={{ fill: "black" }}
                content={<CustomBarLabel />}
              />
            </Bar>
            <XAxis type="number" hide domain={[0, 100]} />
            <YAxis
              tickLine={false}
              axisLine={false}
              type="category"
              width={180}
              dataKey="label"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const prepareBarChartData = (recom) => {
  const currentLvlIndex = recom.answerMap.findIndex(
    (bd) => bd.isCurrentLevel === true
  );
  const nextLvlIndex = currentLvlIndex + 1;

  return {
    nextLevel: nextLvlIndex,
    currentLevel: currentLvlIndex,
    answerMap: recom.answerMap.map((bd, index) => ({
      ...bd,
      text: { pct: bd.percent, text: bd.answer },
      isNextLevel: nextLvlIndex === index,
      active: bd.isCurrentLevel,
      completed: index < currentLvlIndex ? true : false,
      label: bd.isCurrentLevel
        ? "You are here"
        : nextLvlIndex === index
          ? "You need to be here"
          : "",
    })),
  };
};


export const RenderRecomToggler = ({ recomType, setRecomType, inEdit }) => {
  return (
    <div className="tabs p-3 w-100 d-flex justify-content-center align-items-center">
      <div className="toggler">
        <StandardButton
          color={recomType === "situation" ? "active" : ""}
          text="Survey Response"
          onClick={() => setRecomType("situation")}
        />
        <StandardButton
          color={recomType === "recommendation" ? "active" : ""}
          text="Recommendation"
          onClick={() => setRecomType("recommendation")}
        />
        {inEdit ? null : <StandardButton
          color={recomType === "initiative" ? "active" : ""}
          text="Initiative"
          onClick={() => setRecomType("initiative")}
        />}
      </div>
    </div>
  );
};

export const RenderSituationAndRecommendation = ({
  recomType,
  recommendation,
  textToShow,
  showLoader,
  setTextToShow,
  setLineGraphLevel,
  CoachNoteComponent,
  recommText,
  coachNote,
  gloassaryWords,
  recommendationData,
  recommendationPretext,
  setCoachNote
}) => {
  const dispatch = useDispatch();
  const { specificGraphType, answerMap } = recommendation;
  const currentLevel = answerMap.findIndex((ans) => ans.isCurrentLevel);
  const nextLevel = answerMap.findIndex((ans) => ans.isNextLevel);
  const [isOpen, setIsOpen] = useState(false);

  let text = "";
  if (textToShow === "recommendation") text = recommendation.recommText;
  else if (textToShow !== null && textToShow !== undefined)
    text = recommendation.answerMap[textToShow]?.answer || "";

  const renderCustomRecommText = (text) => {
    if (text) {
      return text;
    } else {
      return recommendation?.answerMap[textToShow]?.customRecomm;
    }
  }
  coachNote = renderCustomRecommText(recommendation?.answerMap[textToShow]?.coachNote);
  if (recomType === "recommendation") text = renderCustomRecommText(recommendation?.answerMap[textToShow]?.coachNote);
  else if (textToShow !== null && textToShow !== undefined)
    text = recommendation.answerMap[textToShow]?.answer || "";

  const textIcon =
    textToShow === "recommendation" ? (
      <img src="/assets/images/logo-small.png" alt="" width="35px" />
    ) : null;

  const recordClick = async (id, type) => {
    await dispatch(
      academyActions.recordEvent({
        id,
        type,
        event: eventTypes.CLICK,
      })
    );
  };
  const getRecommendationContentList = () => {
    if (!recommendationData || !recommendationData.length) {
      return [];
    }
    if (isOpen) {
      return recommendationData;
    } else {
      return [recommendationData[0]]
    }
  }  

  // console.log(currentLevel, textToShow, answerMap, answerMap[textToShow]?.customRecomm);
  return (
    <>
      {recomType === "situation" || recomType === "recommendation" ? (
        <div className="mb-3 unselectable">
          <div className="question-text title-font-size">Our Survey Question</div>
          <div className="sub-content-font-size">
            <TooltipGenerator
              gloassaryWords={gloassaryWords}
              text={recommendation.question}
            ></TooltipGenerator>
          </div>
        </div>
      ) : null}
      <div className="chart-container">
        <div className="w-100">
          {(recomType === "situation" || recomType === "recommendation") &&
            specificGraphType.toLowerCase() === "line" ? (
            <RenderTimelineChart
              recommendation={{
                ...recommendation,
                nextLevel,
                currentLevel,
              }}
              textToShow={textToShow}
              setLineGraphLevel={setLineGraphLevel}
              setTextToShow={setTextToShow}
            />
          ) : (recomType === "situation" || recomType === "recommendation") &&
            specificGraphType.toLowerCase() === "bar" ? (
            <>
              <div className="question-text">Your Team's Response</div>
              <CustomBarChart
                recommendation={prepareBarChartData(recommendation)}
                currentLevel={currentLevel}
                improved={recommendation.improved}
                recomType={recomType}
                textToShow={textToShow}
                setLineGraphLevel={setLineGraphLevel}
              />
            </>
          ) : null}
        </div>
      </div>
      {(recomType === "recommendation") && recommText ? (
        <div className="recom-text my-2 d-flex justify-content-start align-items-center">
          <div className={`me-3`}>
            <img src="/assets/images/logo-small.png" alt="" width="35px" />
          </div>
          <div>{recommText}</div>
        </div>
      ) : null}
      {recomType === "situation" && specificGraphType.toLowerCase() !== "bar" ? <div className="recom-text d-flex justify-content-start align-items-center">
        <div className={`me-3`}>{textIcon}</div>
        <div className="sub-content-font-size">
          {text}
        </div>
      </div> : null}

      {recomType === "recommendation"  && currentLevel === textToShow? (
        <div className="d-flex align-items-center recom-text mt-3">
          <div className="me-3">
            <img src="/assets/images/bulb.svg" alt="" width="35px" />
          </div>
          <div className="flex-fill">{CoachNoteComponent(coachNote)}</div>
        </div>
      ) : null}
      {recomType === "recommendation" && recommendationData?.length ? (
        <div className="recom-text mt-3">
          <div className="row ms-0 me-0 ">
            <div className="mt-0 font-weight-bold title-font-size">
              Recommended Content
            </div>
            <h5 className="mb-0 recommendation-pretext sub-content-font-size" dangerouslySetInnerHTML={{ __html: recommendationPretext }}></h5>
          </div>

          <div>
            {getRecommendationContentList().map((e, index) => {
              return (
                <>
                  <div className="row ms-0 me-0 ">
                    <div className="col-10">
                      <div className="d-flex  align-items-center">
                        <img src="/assets/images/rightArrow.svg" />
                        <div className="ms-2">
                          <div
                            className="mb-0 title-font-size"
                          >
                            {e?.title}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center my-2">
                        <div className="hb-light-gray px-2 sub-content-font-size">
                          {e?.type[0].toUpperCase() + e?.type.slice(1)}
                        </div>

                        {e?.type !== "course" && (
                          <div className="d-flex align-items-center px-2 divider">
                            <img
                              src={`/assets/images/resource-icons/${e?.subType
                                .toLowerCase()
                                .includes("external")
                                ? "external"
                                : e?.subType
                                  .toLowerCase()
                                  ?.replace(/ /g, "-")
                                  .replace("&", "")
                                  .replace("--", "-")
                                  .replace("internal-", "")
                                }.svg`}
                              className="me-2"
                            />
                            <span className="hb-light-gray ms-1">
                              {e?.subType}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-2 text-end">
                      {e?.type == "course" ? (
                        <Link
                          to={COURSE_OVERVIEW.replace(
                            ":courseId",
                            e?.contentId
                          )}
                          target={"_blank"}
                          className="text-decoration-none"
                        >
                          <StandardButton
                            color="btn-outline-dark"
                            bold={true}
                            text={"Learn More"}
                            className="d-flex justify-content-center align-items-center expand-btn ms-auto"
                            onClick={() =>
                              recordClick(
                                e?.contentId,
                                e?.type == "course"
                                  ? contentTypes.COURSE
                                  : contentTypes.RESOURCE
                              )
                            }
                          />
                        </Link>
                      ) : e?.type == "resource" &&
                        e?.subType.includes("External") ? (
                        <a
                          href={e?.link}
                          target={"_blank"}
                          className="text-decoration-none"
                        >
                          <StandardButton
                            color="btn-outline-dark"
                            bold={true}
                            text={"Learn More"}
                            className="d-flex justify-content-center align-items-center expand-btn ms-auto"
                            onClick={() =>
                              recordClick(
                                e?.contentId,
                                e?.type == "course"
                                  ? contentTypes.COURSE
                                  : contentTypes.RESOURCE
                              )
                            }
                          />
                        </a>
                      ) : (
                        <Link
                          to={RESOURCE_DETAIL.replace(
                            ":resourceId",
                            e?.contentId
                          )}
                          target={"_blank"}
                          className="text-decoration-none"
                        >
                          <StandardButton
                            color="btn-outline-dark"
                            bold={true}
                            text={"Learn More"}
                            className="d-flex justify-content-center align-items-center expand-btn ms-auto"
                            onClick={() =>
                              recordClick(
                                e?.contentId,
                                e?.type == "course"
                                  ? contentTypes.COURSE
                                  : contentTypes.RESOURCE
                              )
                            }
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="hr-divider my-2"></div>
                </>
              )
            })}
          </div>
          {recommendationData.length !== 1 && (
            <div className="row ms-0 me-0 ">
              <div
                className="text-primary col-2 ms-auto d-flex align-items-center justify-content-end cursor-pointer sub-content-font-size"
                onClick={() => setIsOpen((pre) => !pre)}
              >
                <span>View {isOpen ? "Less" : "More"}</span>
                <img
                  src={
                    isOpen
                      ? "/assets/images/upArrowPrimary.svg"
                      : "/assets/images/downArrowPrimary.svg"
                  }
                  className="ms-1"
                />
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

const RenderRecommendation = ({
  recommendation,
  surveyId,
  updateExpandedIndex,
  expandedIndex,
  updateCoachNotes,
}) => {
  const dispatch = useDispatch();
  const { answerMap } = recommendation;
  const nextLevel = answerMap.findIndex((ans) => ans.isNextLevel);
  const [userProfile, setUserProfile] = useState(
    useSelector((state) => state.authDetails.userProfile)
  );
  const survId = surveyId;
  const [showLoader, setShowLoader] = useState(false)
  const currentLevel = answerMap.findIndex((ans) => ans.isCurrentLevel);
  const [levelInView, setLevelInView] = useState();
  const [initiativeStatus, setInitiativeStatus] = useState("NOT_STARTED");
  const [userQuestionsAnsData, setUserQuestionsAnsData] = useState();
  const [recomType, setRecomType] = useState("recommendation");
  const [textToShow, setTextToShow] = useState(currentLevel);
  const [coachNote, setCoachNote] = useState("");
  const [initiatives, setInitiatives] = useState([])
  const [recommendationData, setRecommendationData] = useState([]);
  const [recommendationPretext, setRecommendationPretext] = useState("");
  const [recommText, setRecommText] = useState()

  const gloassaryWords = useSelector(
    (state) => state.sharedRedux.gloassaryWords
  );

  useEffect(() => {
    // setCoachNote(recommendation.customRecomm);
    for(let answer of recommendation.answerMap){
      if(answer.level === textToShow){
        setCoachNote(answer.customRecomm)
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (recomType === "recommendation") {
      if (expandedIndex === recommendation.index) {
        getRecomendationContentData(recommendation?.recommendationId);
        recommendation?.answerMap?.map((answer, index) => {
          if (answer?.isCurrentLevel) {
            setLevelInView(answer?.level)
          }
        })
      }
      // setTextToShow(nextLevel);
    } else if (recomType === "situation") {
      setRecommendationData([]);
      setTextToShow(currentLevel);
    } else if (recomType === "Initiative") {
      getInitiatives(recommendation.practiceId, survId, textToShow);
      getUserQuestionsData(recommendation.practiceId, surveyId, textToShow);
    }
  }, [recomType]);

  const setLineGraphLevel = async (level, levelDetails) => {
    if (recomType == "recommendation" || recomType == "situation") {
      setShowLoader(true);
      let data = await dispatch(
        actions.getRecommendationLevelBase(
          recommendation.practiceId,
          survId,
          level
        )
      );      
      if (data) {
        setRecommText(data?.recommendation);
        getInitiatives(level);
        setCoachNote(levelDetails?.customRecomm);
        setInitiativeStatus(data?.status);
        setLevelInView(level);
        if (data?.questionAnswer) {
          let formatedData = {
            recommendationText: data.recommendation,
            remark: data?.questionAnswer.remark,
            answerText: data?.questionAnswer?.answers[0].answer,
            questionText: data?.questionAnswer.question,
            ceoRemark: data?.questionAnswer.ceoRemark,
          };
          setUserQuestionsAnsData(formatedData);
        } else {
          setUserQuestionsAnsData(null);
        }
        getRecomendationContentData(data?.recommendationId);
      } else {
        setInitiativeStatus("NOT_STARTED")
      }
    }
    setTextToShow(level);
    setShowLoader(false);
  };

  const getRecomendationContentData = async (recommendationId) => {
    let data = await dispatch(
      actions.getRecomendationContentsData(recommendationId)
    );
    if (data) {
      if (data.pretext) {
        let $ = cheerio.load(data.pretext);
        $("a").each(function () {
          $(this).attr("target", "_blank");
        });
        data.pretext = $.html();

        setRecommendationPretext(data.pretext);
      }
      setRecommendationData(data.content);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="" {...props}>Recommended content available to help you implement the recommendation</Tooltip>
  );


  const renderCoachNoteTooltip = (props) => (
    <Tooltip id="" {...props}>This recommendation has customised note from coach</Tooltip>
  );

  const renderRLCToolTip = () => {
    return (
      <div className="mx-1">
        <OverlayTrigger
          key={`${recommendation.practiceId}`}
          placement={"bottom"}
          overlay={
            renderTooltip
          }
        >
          <img src="/assets/images/bulb1.svg" />
        </OverlayTrigger>
      </div>
    )
  }

  function CoachNoteComponent(note) {    
    return (
      <>
        <StandardTextarea
          value={coachNote}
          onChange={(e) => setCoachNote(e.target.value)}
          placeholder="Type your recommendations here ..."
          rows={3}
        />
        <StandardButton
          text="Save"
          color="btn-success"
          className="btn-sm d-flex ms-auto mt-3"
          onClick={() =>
            updateCoachNotes({
              ...recommendation,
              customRecomm: coachNote,
            })
          }
        />
      </>
    );
  }
  const getUserQuestionsData = async (practiceId, surveyId, textToShow) => {
    setShowLoader(true);
    let data = await dispatch(
      actions.getUserQuestionAnswers(practiceId, surveyId)
    );
    if (data) {
      let formatedData = {
        recommendationText: recommText,
        remark: data?.remark,
        answerText: data?.answers[0]?.answer,
        questionText: data?.question,
        ceoRemark: data?.ceoRemark ? data?.ceoRemark : "",
      };
      setUserQuestionsAnsData(formatedData);
    }
    setShowLoader(false);
  };


  const getInitiatives = async (level) => {
    setShowLoader(true);
    let data = await dispatch(
      actions.getInitiativesData(recommendation?.practiceId, survId, level)
    );
    if (data.length > 0) {
      data.map((e) => ({ ...e, isEdit: false }));
      setInitiatives(data);
    } else {
      if (initiativeStatus !== "APPROVE") {
        setInitiatives(
          recommendation.practiseOwner == userProfile?.id ||
            userProfile?.roleName == "CEO"
            ? [
              {
                initiative: "",
                goal: "",
                due_date: null,
                isEdit: true,
                progress: 0,
                survId,
                practiceId: recommendation?.practiceId,
              },
            ]
            : []
        );
      } else {
        setInitiatives([])
        setInitiativeStatus("APPROVE");
      }
    }

    setShowLoader(false);
  };

  const renderRecommendationContent = () => {
    return (
      <>
        {recomType === "situation" || recomType === "recommendation" ? (
          <RenderSituationAndRecommendation
            recomType={recomType}
            recommendation={recommendation}
            coachNote={coachNote}
            setCoachNote={setCoachNote}
            textToShow={textToShow}
            recommText={recommText}
            setTextToShow={setTextToShow}
            CoachNoteComponent={CoachNoteComponent}
            setLineGraphLevel={setLineGraphLevel}
            gloassaryWords={gloassaryWords}
            recommendationData={recommendationData}
            recommendationPretext={recommendationPretext}
          />
        ) : null}
        {!showLoader && levelInView <= currentLevel && recomType === "recommendation" ? (
          <InitiativeStepper
            status={initiativeStatus}
            practiceOwnerName={recommendation.practiceOwnerName}
            practiseOwner={recommendation.practiseOwner}
            isCurrentLevel={currentLevel === levelInView}
            recomType={() => setRecomType("Initiative")}
          />
        ) : null}
      </>
    )
  }

  const renderInitiativeContent = () => {
    return (
      <InitiativeContent recommendation={recommendation} surveyId={surveyId} userQuestionsAnsData={userQuestionsAnsData} initiatives={initiatives} initiativeStatus={initiativeStatus} />
    )
  }

  return (
    <Card
      className="recommendations-card mb-4"
      id={`recom-${recommendation.rank}-${recommendation.index}`}
    >
      <RecommHeader
        userProfile={userProfile}
        recommendation={recommendation}
        cardClick={() => {
          recommendation.answerMap.map((answer, index) => {
            if (answer.isCurrentLevel) {
              setLevelInView(answer?.level);
              setLineGraphLevel(answer.level, answer)
              getInitiatives(answer.level)
            }
          })
          updateExpandedIndex(recommendation.index);
          setRecomType("recommendation");
        }}
        actions={
          <div className="d-flex justify-content-end">
            {recommendation?.recommendationFlag ? (
              renderRLCToolTip()) : null}
            <StandardButton
              color={
                recommendation.customRecomm
                  ? "btn-outline-success"
                  : "btn-success"
              }
              className="btn-sm me-2 coach-note-btn"
              text={
                recommendation.customRecomm ? (
                  <OverlayTrigger
                    key={`${recommendation.practiceId}`}
                    placement={"bottom"}
                    overlay={
                      renderCoachNoteTooltip
                    }
                  >
                    <span className="material-icons">edit_note</span>
                  </OverlayTrigger>
                ) : (
                  <i className="fa-solid fa-pen-to-square"></i>
                )
              }
              bold={true}
              onClick={(e) => {
              }}
            />
            <StandardButton
              color="btn-outline-dark"
              bold={true}
              className="d-flex justify-content-center align-items-center expand-btn ms-auto"
              text={
                recommendation.index === expandedIndex ? "Collapse" : "Expand"
              }
              icon={
                recommendation.index === expandedIndex ? (
                  <span className="material-icons icon-in-btn">
                    expand_less
                  </span>
                ) : (
                  <span className="material-icons icon-in-btn">
                    expand_more
                  </span>
                )
              }
              reversed={true}
              onClick={(e) => {

                e.preventDefault();
                e.stopPropagation();
                recommendation.answerMap.map((answer, index) => {
                  if (answer.isCurrentLevel) {
                    setLevelInView(answer?.level);
                    setLineGraphLevel(answer.level, answer)
                    getInitiatives(answer.level)
                  }
                })
                updateExpandedIndex(recommendation.index);
                setRecomType("recommendation");
              }}
            />
          </div>
        }
      />
      {expandedIndex === recommendation.index ? (
        <>
          {/* RECOM TABS */}
          <RenderRecomToggler
            recomType={recomType}
            setRecomType={setRecomType}
          />
          <Card.Body className="card-body">
            {recomType === "initiative" ? renderInitiativeContent() : renderRecommendationContent()}
          </Card.Body>
        </>
      ) : null}
    </Card>
  );
};

export default RenderRecommendation;
