import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contacts: null,
  associatedContacts: null,
};

const contactSlice = createSlice({
  name: 'coachContacts',
  initialState,
  reducers: {
    setContacts: (state, action) => {
      const { contacts } = action.payload;
      state.contacts = contacts;
    },
    setAssociatedContacts: (state, action) => {
      const { associatedContacts } = action.payload;
      state.associatedContacts = associatedContacts;
    },
  },
});
export default contactSlice;
