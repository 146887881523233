import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import cheerio from "cheerio";

import { downloadFileAsBlob } from '../../../../utils/fileUtils';
import LoadingAnimation from '../../../../base/LoadingAnimation';
import { loadingNoti } from '../../../../base/BasicNotifications';
import * as academyActions from '../_redux/AcademyAction';

import './resource-details.scss';
import { eventTypes } from '../../../../data/enums/eventTypes';
import { contentTypes } from '../../../../data/enums/contentTypes';

const ResourceDetails = () => {
  const [resource, setResource] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const progressToast = useRef(null);

  const dispatch = useDispatch();
  const params = useParams();

  const getResourceDetailData = async () => {
    setShowLoader(true);
    const resourceId = params.resourceId;
    try {
      const disciplines = await dispatch(academyActions.getDisciplines());
      const resourceDetail = await dispatch(
        academyActions.getResourceDetail(resourceId)
      );
      disciplines.forEach((disc) => {
        if (disc.disciplineId === resourceDetail.disciplineId) {
          resourceDetail.disciplineName = disc.dName;
        }
      });
      let $ = cheerio.load(resourceDetail.content);
          $("oembed").each(function () {
            let videoUrlParts = $(this).attr("url").split("/");

            $(this).replaceWith(
              `<iframe src="https://player.vimeo.com/video/${
                videoUrlParts[videoUrlParts.length - 1]
              }" height="500x" width="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>`
            );
          });
          $("a").each(function(){
            $(this).attr("target", "_blank");
          })
          resourceDetail.content = $.html();
      setResource(resourceDetail);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoader(false);
    }
  };

  const recordView =  async () => {
    await dispatch(academyActions.recordEvent({
      id : params.resourceId, 
      type : contentTypes.RESOURCE, 
      event : eventTypes.VIEW
    }));
  }

  const recordDownload =  async () => {
    await dispatch(academyActions.recordEvent({
      id : params.resourceId, 
      type : contentTypes.RESOURCE, 
      event : eventTypes.DOWNLOAD
    }));
  }

  useEffect(() => {
    getResourceDetailData();
    recordView();
  }, [params]);

  if (showLoader) return <LoadingAnimation />;
  else if (!resource) return <h4>Some error occurred!</h4>;

  const downloadProgressCB = (pe) => {
    const pct = Math.floor((pe.loaded / pe.total) * 100);
    if (progressToast.current) {
      if (pct === 100) {
        toast.dismiss(progressToast.current);
        progressToast.current = null;
      } else
        toast.update(progressToast.current, { render: `Downloading ${pct}%` });
    } else progressToast.current = loadingNoti(`Downloading ${pct}%`);
  };

  const downloadFileFromApi = (e) => {
    e.stopPropagation();
    let target = e.target;
    while (
      target.parentNode &&
      target.parentNode.nodeName.toLowerCase() != 'body'
    ) {
      if (target.tagName === 'A') {
        break;
      } else target = target.parentNode;
    }
    if (target.tagName === 'A') {
      let href = target.getAttribute('href');
      let fileName = target.innerText;
      if (href && href.startsWith(process.env.REACT_APP_LMS_URL)) {
        e.preventDefault();
        recordDownload();
        downloadFileAsBlob(href, downloadProgressCB, fileName);
      }
    }
  };

  let resourceDiscplineClassName = resource.disciplineName
    ?.split(' ')[0]
    ?.toLowerCase();
  let discImgEnd = resource.disciplineName
    ?.replace(/ /g, '-')
    .replace('&', '')
    .replace('--', '-');
  let resourceTypeName = resource.resourceType.toLowerCase();
  let resourceTypeImgEnd = resourceTypeName.includes('external')
    ? 'external'
    : resourceTypeName
        ?.replace(/ /g, '-')
        .replace('&', '')
        .replace('--', '-')
        .replace('internal-', '');
  return (
    <div className="resource-details-container">
      <Card>
        <Card.Header
          className={`text-center d-flex justify-content-center align-items-center position-relative`}
          style={{
            backgroundImage: `url(/assets/images/resources-banners/big/${discImgEnd}.png)`,
          }}
        >
          <div className="d-flex justify-content-center align-items-center cs-heading resource-discipline">
            <img
              src={`/assets/images/disciplineIcons-white/discipline-icon-${discImgEnd}.svg`}
              className="me-3"
              width="30px"
            />
            {resource.disciplineName}
          </div>
          <div className="resource-type-pill">
            <div className="d-flex align-items-center">
              <img
                src={`/assets/images/resource-icons/${resourceTypeImgEnd}.svg`}
                className="me-2"
                style={{ position: 'relative', top: '-1px' }}
                height="13px"
              />
              {resource.resourceType
                .replace('Internal ', '')
                .replace('External ', '')}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <h3
            className={`cs-sub-heading mb-3 alt-${resourceDiscplineClassName}`}
          >
            {resource.title}
          </h3>
          <div
            className="resource-content"
            dangerouslySetInnerHTML={{ __html: resource.content }}
            onClick={downloadFileFromApi}
          ></div>
          {resource.link ? (
            <a href={resource.link} target="_blank">
              {resource.link}
            </a>
          ) : null}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ResourceDetails;
