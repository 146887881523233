import axios from "axios";
import queryString from "query-string";
import contactSlice from "./contactSlice";

import { errorNoti, successNoti } from "../../../../base/BasicNotifications";
import { CONTACT_URLS } from "./contactUrls";
import { getErrorMessage } from "../../../../utils/generalUtils";

const instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
const { actions } = contactSlice;

const DEFAULT_OPTIONS = { pageNo: 1, sortBy: "CREATED_AT", sortType: "DESC" };
const nodeInstance = axios.create({
  baseURL: process.env.REACT_APP_NEST_BASE_URL,
});

export const getCoachContacts = (_options) => (dispatch, getState) => {
  let options = {
    ...DEFAULT_OPTIONS,
    ..._options,
  };
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");
  let url = CONTACT_URLS.GET_CONTACTS.replace("{coachId}", coachId).replace(
    "{pageNo}",
    options.pageNo
  );
  url = url + "?" + queryString.stringify(options);
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      dispatch(actions.setContacts({ contacts: response.data.data }));
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const getUninvitedCoachContacts = () => (dispatch, getState) => {
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");
  let url = CONTACT_URLS.GET_UNINVITED_CONTACTS.replace("{coachId}", coachId);
  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      dispatch(actions.setContacts({ contacts: response.data.data }));
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const addCoachContact = (data) => (dispatch, getState) => {
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");
  let url = CONTACT_URLS.SAVE_CONTACT.replace("{coachId}", coachId);
  return instance
    .post(url, data, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      successNoti("Contact successfully added");
      return true;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const editCoachContact = (data) => (dispatch, getState) => {
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");
  let url = CONTACT_URLS.SAVE_CONTACT.replace("{coachId}", coachId);
  return instance
    .put(url, data, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      successNoti("Contact successfully edited");
      return true;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const deleteCoachContact = (coachCeoId) => (dispatch, getState) => {
  let url = CONTACT_URLS.DELETE_CONTACT.replace("{coachCeoId}", coachCeoId);
  return instance
    .delete(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      successNoti("Contact deleted successfully");
      return true;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const inviteContactToXray = (userId) => (dispatch, getState) => {
  let coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) {
    errorNoti("Coach not found");
    return Promise.reject("Coach missing");
  }
  let url = CONTACT_URLS.INVITE_CONTACT.replace("{coachId}", coachId).replace(
    "{userId}",
    userId
  );
  return nodeInstance
    .post(
      url,
      { isSponsored: true },
      {
        headers: {
          Authorization: getState().authDetails.accessToken,
        },
      }
    )
    .then((response) => {
      successNoti("Invitation sent successfully");
      return response.data.data.inviteCode;
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const getAssociatedContacts = (options) => (dispatch, getState) => {
  const coachId = getState().authDetails?.userProfile?.coachId;
  if (!coachId) return Promise.reject("Coach Id missing");

  let url =
    CONTACT_URLS.ASSOCIATED_CONTACTS.replace("{coachId}", coachId) +
    "?" +
    queryString.stringify(options);

  return instance
    .get(url, {
      headers: {
        Authorization: getState().authDetails.accessToken,
      },
    })
    .then((response) => {
      dispatch(
        actions.setAssociatedContacts({
          associatedContacts: response.data.data,
        })
      );
    })
    .catch((err) => {
      let errMsg = getErrorMessage(err);
      errorNoti(errMsg);
      throw new Error(errMsg);
    });
};

export const sendInvitationNotification = (data) => {
  return nodeInstance
    .post("/shared/mail_notification", {
      type: "invite",
      json_data: data,
    })
    .then((response) => {})
    .catch((err) => {
      console.log(err);
    });
};
