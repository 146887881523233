import React from 'react';
import './auth-side-info-container.scss';

const AuthSideInfoContainer = (props) => {
  return (
    <div className="auth-side-info-container text-center d-flex align-items-center justify-content-center flex-column">
      <div className="d-flex justify-content-center align-items-center">
        <img src={props.img} alt="img" className="img-fluid" />
      </div>
      {props.heading ? (
        <div className="mt-3 cs-sub-heading">{props.heading}</div>
      ) : null}
      {props.text ? <div className="mt-3 cs-content">{props.text}</div> : null}
    </div>
  );
};
export default AuthSideInfoContainer;
