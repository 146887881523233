import { Card } from 'react-bootstrap';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { XRAY_USER_SCORE } from '../../../../data/RouteUrls';
import * as xrayActions from '../../Xray/_redux/xrayActions';
import { CONTACT_XRAY_STATE_ENUMS } from '../../../../data/enums/XrayStateEnums';
import moment from 'moment';

const ContactColumn = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    headerIconSrc,
    headerText,
    associatedContacts,
    headerClassName,
    status,
    viewContact,
  } = props;

  return (
    <div className="d-flex flex-column contact-column">
      <Card
        className={`contact-header text-center ${headerClassName ? headerClassName : ''
          } mb-3 position-relative overflow-visible`}
      >
        {status === CONTACT_XRAY_STATE_ENUMS.XRAY_SUBMITTED ? (
          <img
            src="/assets/images/need_review_banner.svg"
            className="need-review-banner"
          />
        ) : null}
        <Card.Body className="d-flex flex-column justify-content-center align-items-center h-100">
          <div className="mb-2">
            <img src={headerIconSrc} alt={headerText} height="40px" />
          </div>
          <div
            className="header-text"
            dangerouslySetInnerHTML={{ __html: headerText }}
          />
        </Card.Body>
      </Card>
      {associatedContacts &&
        associatedContacts.map((asContacts) => {
          let xrayAllowed = false;
          if (
            status === CONTACT_XRAY_STATE_ENUMS.XRAY_COMPLETED ||
            status === CONTACT_XRAY_STATE_ENUMS.XRAY_SUBMITTED
          )
            xrayAllowed = true;
          return (
            <Card
              className="mb-3 p-0 contact clickable"
              as={'button'}
              onClick={() => viewContact(asContacts)}
            >
              <Card.Body className="contact-card w-100">
                <div className="contact-company d-flex align-items-center mb-2">
                  <img
                    src="/assets/images/company_icon.svg"
                    alt="Company"
                    className="me-2 position-relative"
                    height="60%"
                    width="auto"
                    style={{ top: '-1px' }}
                  />
                  <span className="ellipsis-text">
                    {asContacts?.companyName || 'NA'}
                  </span>
                </div>
                <div className="contact-info d-flex text-start">
                  <div className="contact-name d-inline-flex align-items-center">
                    <img
                      src="/assets/images/user_formals.svg"
                      alt="User"
                      className="me-2"
                      height="60%"
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <span className="ellipsis-text">
                      {asContacts?.firstName + ' ' + asContacts?.lastName}
                    </span>
                  </div>
                  <div className="seperator mx-2"></div>
                  <div className="contact-date d-flex align-items-center">
                    <span
                      className="material-icons me-2"
                      style={{ fontSize: '1em' }}
                    >
                      calendar_today
                    </span>
                    {moment(asContacts?.date).format('DD MMM') || 'NA'}
                  </div>
                </div>
              </Card.Body>
            </Card>
          );
        })}
    </div>
  );
};

export default ContactColumn;
