import { combineReducers } from "redux";
import authSlice from "../components/AuthContainer/_redux/authSlice";
import coachDetailsSlice from "../components/CoachDetails/_redux/coachDetailsSlice";
import contactSlice from "../components/CoachPanel/Contacts/_redux/contactSlice";
import dashboardSlice from "../components/CoachPanel/Dashboard/_redux/dashboardSlice";
import xraySlice from "../components/CoachPanel/Xray/_redux/xraySlice";

import { sharedReduxSlice } from "../components/sharedRedux/sharedSlice";

const appReducer = combineReducers({
  sharedRedux: sharedReduxSlice.reducer,
  authDetails: authSlice.reducer,
  coachDetails: coachDetailsSlice.reducer,
  coachContacts: contactSlice.reducer,
  xrayReport: xraySlice.reducer,
  dashboardDetails: dashboardSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === authSlice.actions.removeAccessToken.type) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
